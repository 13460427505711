import {
    FETCH_NUTRITION_PLAN,
    ADD_NUTRITION_PLAN,
    EDIT_NUTRITION_PLAN,
    DELETE_NUTRITION_PLAN,
    SINGLE_NUTRITION_PLAN} from '../../actionTypes';

  const INIT_STATE = {
        fetchNutritionPlanData: null,
        addNutritionPlanData: null,
        editNutritionPlanData: null,
        deleteNutritionPlanData: null,
        singleNutritionPlanData: null
    };
    
    export default (state = INIT_STATE, action) => {
        switch(action.type) {
        case FETCH_NUTRITION_PLAN:
             return {
                ...state,
                fetchNutritionPlanData: action.payload
        }
        case ADD_NUTRITION_PLAN:
             return {
                ...state,
                addNutritionPlanData: action.payload
        }
        case EDIT_NUTRITION_PLAN:
             return {
                ...state,
                editNutritionPlanData: action.payload
        }
        case DELETE_NUTRITION_PLAN:
            return {
               ...state,
               deleteNutritionPlanData: action.payload
       }
       case SINGLE_NUTRITION_PLAN:
        return {
           ...state,
           singleNutritionPlanData: action.payload
        }
        default:
              return state;
        }
    }
    