import './workouts.css';
import React, { Fragment,useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardFooter, Media, Button, Form, FormGroup, Input, Pagination, PaginationItem, PaginationLink,CardBody,Label,TabContent, TabPane, Nav, NavItem, NavLink, CardTitle, CardText, } from 'reactstrap'
import classnames from 'classnames';
import {DATE_TIME_FORMAT, Follower,Following,TotalPost} from '../../constant'
import axios from 'axios'
import { DELETE_WORKOUT, STORED_TRANSLATIONS } from '../../redux/actionTypes';
import { Loader } from 'react-bootstrap-typeahead';
import Moment from 'react-moment';
import * as General from '../../util/General'
import { useHistory } from 'react-router';
import { editWorkout, getWorkouts,deleteWorkout } from '../../redux/workouts/action';
import { toast } from 'react-toastify';
import AddWorkout from './addWorkout';
import SweetAlert from 'sweetalert2'
var Carousel = require('react-responsive-carousel').Carousel;


const WorkoutsPicker = (props) => {
  
  const userLocale = General.getLocale();
  const history = useHistory();
  const dispatch = useDispatch();

  const userInfo = useSelector(({auth}) => auth.authUser);
  const workoutsData = useSelector(({workouts}) => workouts.workoutsData);
  const addWorkoutsRreponse = useSelector(({workouts}) => workouts.addWorkoutsData);
  const deleteWorkoutRreponse = useSelector(({workouts}) => workouts.deleteWorkoutData);

  const trans = useSelector(({translation}) => translation.translationsData);
  const userIsAdmin = useSelector(({auth}) => auth.userIsAdmin);
  const userIsManager = useSelector(({auth}) => auth.userIsManager);
  const userIsCoach = useSelector(({auth}) => auth.userIsCoach);


  //DATA
  const [curPage, setCurPage] = useState(0);
  const [email, setEmail] = useState(userInfo != null ? userInfo.email : "");
  const [descr, setDescr] = useState("");
  const [addWorkoutsData, setAddWorkoutsData] = useState(null);

  //SEARCH
  const [searchText, setSearchText] = useState('');
  var searchKeyword = "";

  //TABS
  const [activeTab, setActiveTab] = useState('1');
  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }


  const [standarWorkouts, setStandarWorkouts] = useState([]);
  const [weeklyWorkouts, setWeeklyWorkouts] = useState([]);

  useEffect(() => {
    if (workoutsData != null){
      props.workoutsDataLoaded(true);

      var sWorkouts = [];
   

      workoutsData.workouts.map((item,i) => {
        if (!item.isWeekly){
          sWorkouts.push(item);
        }
      })

      var wWorkouts = [];
      workoutsData.workouts.map((item,i) => {
        if (item.isWeekly){
          if (props.weekOfMonth == item.weekOfMonth && props.month == item.month && props.year == item.year){
            wWorkouts.push(item);
          }
        }
      })
      setWeeklyWorkouts(wWorkouts);

      setStandarWorkouts(sWorkouts);
     

    }
}, [workoutsData]);

useEffect(() => {
  if (deleteWorkoutRreponse != null){
    if (deleteWorkoutRreponse.status){
      toast.success(deleteWorkoutRreponse.message, {
        position: toast.POSITION.TOP_RIGHT
        });
        getWorkoutssData();
    } else {
      toast.error(deleteWorkoutRreponse.message, {
        position: toast.POSITION.TOP_RIGHT
        });
    }

    dispatch({type: DELETE_WORKOUT, payload: null});
  }
}, [deleteWorkoutRreponse]);

  useEffect(() => {
    // if (workoutsData != null){
    //   var wWorkouts = [];
    //   workoutsData.workouts.map((item,i) => {
    //     if (item.isWeekly){
    //       if (props.weekOfMonth == item.weekOfMonth && props.month == item.month && props.year == item.year){
    //         wWorkouts.push(item);
    //       }
    //     }
    //   })
    //   setWeeklyWorkouts(wWorkouts);
    // }
    getWorkoutssData();
  }, [props.weekOfMonth,props.month,props.year]);

  // useEffect(() => {
  //   getWorkoutssData();
  // }, []);

 
  const getWorkoutssData = () => {
    let postData = {
      page: curPage+1,
      sort: {"_id": -1},
      query: {"$or":[
        {
          isWeekly:false
        },
        {
          isWeekly:true,
          weekOfMonth:props.weekOfMonth,
          month:props.month,
          year:props.year,
        }]},
      searchKeyword: searchKeyword}
    dispatch(getWorkouts(postData));
}

  useEffect(() => {
    getWorkoutssData();
  }, [curPage]);



  useEffect(() => {
    console.log("props.reloadWorkoutData",props.reloadWorkoutData);

    if (props.reloadWorkoutData == true){
      getWorkoutssData();
      props.onWorkoutReloaded();
    }
  
  }, [props.reloadWorkoutData]);

  

  const addEditWorkoutsModaltoggle = () => {

  }

  const handleSearchKeyword = async (keyword)   => {
    searchKeyword = keyword;
    setSearchText(keyword);
    getWorkoutssData();
  }

  const handleWorkoutsEditClick = (e,user) => {
    e.preventDefault();
    // history.push(`/app/users/userEdit/${user.nickname}`);
  }

  const removeWorkouts = (user)=> {

  }

  const handleFeedBackAnswer = (e,item) => {
    e.preventDefault();
    if ( item != null){
      window.location = `mailto:${item.email}`
    }
  }

  const handlePageClick = (e,newPage) => {
    e.preventDefault();
    setCurPage(newPage);
  }


  const onChange = () => {

  }

  const onClickItem = () => {
    
  }

  const onClickThumb = () => {
    
  }

  const onWorkoutStored = () => {
    getWorkoutssData();
  }

  const dragWorkout = (e,editWorkout) => {
    // e.preventDefault();
    // history.push(`/app/workouts/single/${editWorkout.id}`);
  }

  const askDeleteWorkout = (e,item) => {
    e.preventDefault();
    SweetAlert.fire({
      title:trans.delete_dialog_title,
      text:trans.delete_dialog_desc,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText:trans.ok_btn,
      cancelButtonText: trans.cancel_btn,
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
          //OK
            //REMOVE
          let postData = {workoutId: item.id}
          dispatch(deleteWorkout(postData));
      }
      else {
        
      }
    })
  }

  function renderWorkoutItemPick(item,i) {
    return (<div data={item.id} title={item.name} key={`workouts_item_${i}`} className="_workout_picker_item">
        <div>
          <div className="_workout_picker_item_header_bg">
          <div style={{float:'left', padding:'4px',color:'black',cursor:'grabbing',fontSize:'14px'}}>
              <i style={{cursor:'pointer'}} onClick={(e)=> {dragWorkout(e,item)}}  className="fa fa-th-large"></i>
            </div>
            <div style={{float:'left'}}>
              <h5   className="_workout_item_header">{item.name}</h5>
            </div>
            <div style={{float:'right', padding:'3px',color:'red',cursor:'grabbing',fontSize:'14px'}}>
              <i style={{cursor:'pointer'}} onClick={(e)=> {askDeleteWorkout(e,item)}}  className="fa fa-trash"></i>
            </div>
          </div>
        </div>
    </div>)
  }

  return (
    <Fragment>
    

 

      <Row>
       <Col md="12">
        <Card>
         
       
            <Row>


            <Col xl="12" sm="12"> 
                  <br/>
                    <div style={{alignItems: 'center',}}> 
                     <h4 className="card-title mb-0 ml-2 font-weight-bold" style={{float: "left"}}>{trans.workouts_title}</h4>
                    </div>
                  </Col>

              <Col>
              <Form>
                            <FormGroup className="ml-2 mt-3 mr-2">
                              <Input
                                className="form-control"
                                type="text"
                                placeholder={trans.search_hint}
                                value={searchText}
                                onChange={(e) => handleSearchKeyword(e.target.value)}
                                />
                            </FormGroup>
                            </Form>
              
              </Col>


            </Row>



          {userIsManager || userIsCoach ||  userIsAdmin && workoutsData != null ? 
                    <div>
                      <Nav tabs fill >
                        <NavItem>
                          <NavLink
                            style={{cursor:'pointer'}}
                            className={classnames({ active: activeTab === '1' })}
                            onClick={() => { toggle('1'); }}
                          >
                            {trans.weekly_workouts_tab}
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                           style={{cursor:'pointer'}}
                            className={classnames({ active: activeTab === '2' })}
                            onClick={() => { toggle('2'); }}
                          >
                            {trans.standar_workouts_tab}
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <br/>
                      <Row>
                            <Col sm="12">
                            <div key="pick_workouts" ref={props.elRef} id='workout-events' className="table-responsive ">
                            {activeTab == 1 ? 
                             weeklyWorkouts.map((item,i) => {
                                      return renderWorkoutItemPick(item,i)
                                }
                                ) : standarWorkouts.map((item,i) => {
                                  return renderWorkoutItemPick(item,i)
                            }
                            )}
                              </div>
                            </Col>
                          </Row>
                      {/* <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                          <Row>
                            <Col sm="12">
                            <div key="pick_workouts" ref={props.elRef} id='workout-events' className="table-responsive ">
                            {weeklyWorkouts.map((item,i) => {
                                      return renderWorkoutItemPick(item,i)
                                }
                                )}
                              </div>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="2">
                          <Row>
                          <Col sm="12">
                            <div key="pick_workouts" ref={props.elRef} id='workout-events' className="table-responsive ">
                            {standarWorkouts.map((item,i) => {
                                      return renderWorkoutItemPick(item,i)
                                }
                                )}
                              </div>
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent> */}
                   

                </div>
                : <Loader/>}

             </Card>
             </Col>
          </Row>
       
    
    </Fragment>
  );
}

export default WorkoutsPicker;