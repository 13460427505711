import React, { Fragment ,useState,useEffect } from 'react';
import {Col,Row,Card,CardBody,Media, CardHeader, Button} from 'reactstrap'
import Lightbox from "react-image-lightbox";
import axios from 'axios'
import { PortfolioTitle } from "../../constant";
import { addUserMedia, deleteUserMedia, getUserMedia } from '../../redux/usermedia/action';
import { postUploadImageFile } from '../../redux/upload/action';
import { useDispatch, useSelector } from 'react-redux';
import { USER_ID_SET } from '../../redux/actionTypes';
import SweetAlert from 'sweetalert2'


const UserMedia = (props) => {

    const {viewUser} = props;

    const dispatch = useDispatch();
    //LETS GET THE TRANSLATIONS and settings
    const trans = useSelector(({translation}) => translation.translationsData);
    const settingsData = useSelector(({settings}) => settings.settingsData);

      //SO LETS CREATE A BOOLEAN TO HOLD IF THE USER IS EDITABLE
    const [canEditUser,setCanEditUser] = useState(false);
   

  const userIsAdmin = useSelector(({auth}) => auth.userIsAdmin);  
  const userIsManager = useSelector(({auth}) => auth.userIsManager); 
  const userIsCoach = useSelector(({auth}) => auth.userIsCoach); 

  
    const initilindex = {index:0,isOpen:false}
    const[photoIndex,setPhotoIndex] = useState(initilindex)

    const onMovePrev = () => {
        const prev = (photoIndex.index + mediaData.usermedia.length - 1) % mediaData.usermedia.length
        if (mediaData.usermedia.length >= prev){
            setPhotoIndex({...photoIndex,index:prev})
        }
    }

    const  onMoveNext = () => {
        const next = (photoIndex.index + 1) % mediaData.usermedia.length
        if (mediaData.usermedia.length >= next){
            setPhotoIndex({...photoIndex,index:next})
        }
    }


    const mediaData = useSelector(({usermedia}) => usermedia.userMediaData);
    const uploadImagesData = useSelector(({upload}) => upload.uploadImageFileData);
    const addMediaData = useSelector(({usermedia}) => usermedia.addUserMediaData);
    const deleteMediaData = useSelector(({usermedia}) => usermedia.deleteUserMediaData);

    useEffect(() => {
        //LOAD IMAGES
        console.log(viewUser.nickname);
        //IF BOTH USERS HAVE THE SAME ID THEN WE CAN EDIT
        const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
        if (viewUser.userId == userId){
            setCanEditUser(true);
        }
         //USER CAN ALSO EDIT OTHER USERS IF HE IS A MANAGER OR SUPER ADMIN
         if (userIsAdmin || userIsCoach || userIsManager){
             setCanEditUser(true);
         }

        getMediaData();
    },[])

    useEffect(() => {
        if (uploadImagesData != null){
            //console.log(uploadImagesData);
            addMedia(uploadImagesData)
        }
    },[uploadImagesData])

    useEffect(() => {
        if (addMediaData != null && addMediaData.status){
            getMediaData();
        }
    },[addMediaData])
    

    useEffect(() => {
        if (deleteMediaData != null && deleteMediaData.status){
            getMediaData();
        }
    },[deleteMediaData])

    const getMediaData = () => {
            console.log(viewUser.userId);

        let postData = {userId: viewUser.userId,query: {}, page: 1, limit: 8, sort: {"_id": -1}};
        dispatch(getUserMedia(postData))
    }

    const addMedia = (newImages) => {
        if (newImages != null && newImages.images.length > 0){
            let image = newImages.images[0];
            let postData = {
                "type": 1,
                "image": image,
                "visible": true
            }
            dispatch(addUserMedia(postData));
        }
       
    }
    


    const _handleImageChange = (event) => {
        if (event.target.files.length === 0)
        return;
      
  
      var mimeType = event.target.files[0].type;
  
      if (mimeType.match(/image\/*/) == null) {
        return;
      }
    //   let file = event.target.files[0];
      
      let reader = new FileReader();
      reader.onloadend = () => {
       // setProfileImage({url:reader.result, file: file});
      }
     // reader.readAsDataURL(file)

      var type = "userMedia";

      var images = [];
      images.push(event.target.files[0]);
  
      dispatch(postUploadImageFile(type, images))
    }

    const uploadImage = () => {
        document.getElementById("post-images").click()
    }

    const deleteSingleMedia = (e,item) => {
        e.preventDefault();
        SweetAlert.fire({
            title:trans.delete_dialog_title,
            text:trans.delete_dialog_desc,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText:trans.ok_btn,
            cancelButtonText: trans.cancel_btn,
            reverseButtons: true
          }).then((result) => {
            if (result.value) {
                //OK
              let postData = {usermediaId: item.id};
              dispatch(deleteUserMedia(postData));
            }
            else {
  
            }
          })
    }

    return (
        <Fragment>
            <Row>
                {mediaData != null ?
                <Col sm="12">
                    <Card>
                      <CardHeader>
                        <div style={{alignItems: 'center',}}> 
                        <h4 className="card-title mb-0" style={{float: "left"}}>{trans.user_media_title}</h4>
                        <div className="card-title mb-0" style={{float: "right"}}>
                         { canEditUser ?
                        <div id="wrapper" className="extra-div">
                        <div id="box" onClick={uploadImage}>
                            <Button color="primary" size="sm"><i className="fa fa-plus"></i> {trans.add_btn}</Button>
                            <input id="post-images" type="file" name="post-images" style={{display: 'none'}}
                                    accept="image/x-png,image/jpeg" onChange={(e)=>_handleImageChange(e)} />
                            </div>
                        </div>: null }
                        </div> 
                    </div>
                      </CardHeader>
                      {mediaData.usermedia.length == 0 ? <div style={{margin:'23px'}}>
                    <span>{trans.no_user_images}</span>
                </div> :
                        <CardBody className="my-gallery row gallery-with-description">
                            {mediaData.usermedia.map((item,index) => {
                            return (<figure key={`user_image_${item.id}`} className="col-xl-3 col-sm-6"><div role="button"  data-size="1600x950">
                                <Media
                                style={{height:'150px', width:'100%', objectFit:'cover'}}
                                    src={item.image.img_thumb}
                                    alt={`Image ${index}`}
                                    className="img-thumbnail"
                                    onClick={() =>
                                    setPhotoIndex({ ...photoIndex,index:index, isOpen:true})
                                    }
                                />
                                {canEditUser ? <div className="caption">
                                    <span onClick={(e)=> {deleteSingleMedia(e,item)}} style={{float:'right',color:'red',fontSize:'13px',margin:'4px'}}>{trans.delete_btn}</span>
                                </div> : null }
                                </div>
                            </figure>)
                        })}
                          
                        </CardBody> }
                    </Card>
                </Col>
                :""}
           </Row>
            {photoIndex.isOpen && (
                    <Lightbox
                        mainSrc={mediaData.usermedia[photoIndex.index].image.img_full}
                        nextSrc={mediaData.usermedia[(photoIndex.index + 1) % mediaData.usermedia.length].image.img_full}
                        prevSrc={mediaData.usermedia[(photoIndex.index + mediaData.usermedia.length - 1) % mediaData.usermedia.length].image.img_full}
                        imageTitle={photoIndex.index + 1 + "/" + mediaData.usermedia.length}
                        onCloseRequest={() => setPhotoIndex({ ...photoIndex,isOpen:false})}
                        onMovePrevRequest={onMovePrev}
                        onMoveNextRequest={onMoveNext}
                    />
            )}

          

        </Fragment>
    );
};

export default UserMedia;