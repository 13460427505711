import './nutritionPlan.css';
import React, { Fragment,useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from '../../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardFooter, Media, Button, Form, FormGroup, Input, Pagination, PaginationItem, PaginationLink,CardBody,Label } from 'reactstrap'

import * as General from '../../../util/General'
import { useHistory } from 'react-router';
import {getNutritionPlans, addNutritionPlan, deleteNutritionPlan, editNutritionPlan } from '../../../redux/nutrition/nutritionPlan/action';
import NoAccess from '../../../components/reuse/noaccess';
import LoaderBox from '../../../components/reuse/loaderbox';
import { useParams } from 'react-router';
import { singleNutritionMenu } from '../../../redux/nutrition/nutritionMenu/action';
import { checkUserSub } from '../../../redux/subscription/action';

const NutritionPlanList = (props) => {
  
  const userLocale = General.getLocale();
  const history = useHistory();
  const dispatch = useDispatch();

  const userInfo = useSelector(({auth}) => auth.authUser);
  const nutritionPlanData = useSelector(({nutritionPlan}) => nutritionPlan.fetchNutritionPlanData);
  const nutritionSingleMenuData = useSelector(({nutritionMenu}) => nutritionMenu.singleNutritionMenuData);
  const trans = useSelector(({translation}) => translation.translationsData);
  const userIsAdmin = useSelector(({auth}) => auth.userIsAdmin);
  const userIsManager = useSelector(({auth}) => auth.userIsManager);
  const userIsCoach = useSelector(({auth}) => auth.userIsCoach);

  const checkSubData = useSelector(({subscription}) => subscription.checkSubData);

  const [userHasOneSub,setUserHasOneSub] = useState(false);

  const [headerTitle, setHeaderTitle] = useState("");
  const [headerImg, setHeaderImg] = useState("#");

  var selectedMenu = "";
  let { id } = useParams();
  if (id != null){
    selectedMenu = id;
  }

  useEffect(() => {
      getPlansData();
      getUserSubs();
      getSingleMenu();
      setHeaderTitle(trans.nutrition_plans);
  }, []);

    useEffect(() => {
      if (checkSubData != null){
        setUserHasOneSub(checkSubData.hasOneSub)
      }
    
    }, [checkSubData]);


  const getUserSubs = () => {
      let postData = {}
      dispatch(checkUserSub(postData));
    }

  useEffect(() => {
    if (nutritionSingleMenuData != null){
      var title = `${trans.nutrition_plans} • ${nutritionSingleMenuData.title}`
      setHeaderTitle(title);
      setHeaderImg(nutritionSingleMenuData.imgObj.img_thumb)
    }
    
}, [nutritionSingleMenuData]);

  const getPlansData = () => {
    let postData = {menuId: selectedMenu,visible: true,fromApp: true};
    dispatch(getNutritionPlans(postData));
  }

  const getSingleMenu = () => {
    let postData = {itemId: selectedMenu};
    dispatch(singleNutritionMenu(postData));
  }

  const handleUserClick = (e,item) => {
    e.preventDefault();

    //WE NEED TO OPEN THE MEAL 
    history.push(`/app/nutritionmeal/${item.id}/${selectedMenu}`);
  }
 
  const getNutritionPlansUI = () => {
    var nutritionPlans = [];

    if (nutritionPlanData == null){
      return(<LoaderBox></LoaderBox>)
    }

    nutritionPlanData.data.map((item,i) => {
      nutritionPlans.push(<Col key={item.id}>
        <div onClick={(e) => {handleUserClick(e,item)}} style={{cursor:'pointer'}}>
        <Card className="custom-card">
          <div>
             <div style={{fontWeight:'700',alignItems:'center',textAlign:'center'}}>
              <span >{item.title}</span>
            </div>
          </div>
         </Card>
        </div>
    </Col>);

      
    });

    if (nutritionPlans.length > 0){
      return (<div >
           {nutritionPlans}
      </div>);
    } else {
      return (<div className="m-10">
          {trans.no_training_nutritionPlans}
        </div>)
    }
  }


  return (
    <Fragment>
      <Breadcrumb parent={trans.nutrition_plans} title={trans.nutrition_plans} />
      {checkSubData == null ? <div></div> :
      <Container fluid={true}>
        {(!userIsAdmin || !userIsCoach || !userIsManager) && !userHasOneSub ?
          <Col md="12">
          <NoAccess/>
          </Col>
        : 
          
        <div>
      
                <Card>
                  <CardHeader >
               
                  <div class="row align-items-center">
                          <div class="col-md-11">
                            <h4 className="card-title font-weight-bold" style={{float: "left"}}>{headerTitle}</h4>
                          </div>
                          <div class="col-md-1">
                            {headerImg != "#" ? 
                              <div className="card-title" style={{float: "right"}}>
                                  <img alt="MENUIMAGE" style={{height:'60px',width:'60px',objectFit:'cover',borderRadius:'10px'}} src={headerImg} />
                              </div> : null
                            }
                          </div>
                      </div>
                  
                  </CardHeader>
                  <div>
                        {getNutritionPlansUI()}
                      </div>

                  </Card>
                  
          </div>}
      </Container> }
    </Fragment>
  );
}

export default NutritionPlanList;