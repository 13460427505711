import {
  FETCH_SETTINGS,
  SEND_PUSH_DATA,
  NOTIFICATIONS_LIST
} from '../actionTypes';
  
    const INIT_STATE = {
        settingsData: null,
        sendPushData: null,
        notificationsData: null
      };
      
      export default (state = INIT_STATE, action) => {
          switch(action.type) {
            case FETCH_SETTINGS:
                     return {
                       ...state,
                       settingsData: action.payload
                  }
           case SEND_PUSH_DATA:
                    return {
                      ...state,
                      sendPushData: action.payload
                 }
            case NOTIFICATIONS_LIST:
                return {
                  ...state,
                  notificationsData: action.payload
               }
          default:
                return state;
          }
      }
      