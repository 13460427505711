import {
  FETCH_TRANSLATIONS ,
    ADD_TRANSLATION,
    EDIT_TRANSLATION ,
    DELETE_TRANSLATION ,
    FETCH_ADMIN_TRANSLATIONS} from '../actionTypes';
  
    const INIT_STATE = {
        addTranslation: null,
        editTranslation: null,
        translationsData: null,
        adminTranslations: null,
        deleteTranslation: null
      };
      
      export default (state = INIT_STATE, action) => {
          switch(action.type) {
            case ADD_TRANSLATION:
                     return {
                       ...state,
                     addTranslation: action.payload
                  }
            case EDIT_TRANSLATION:
                       return {
                       ...state,
                       editTranslation: action.payload
                }
            case FETCH_TRANSLATIONS:
                       return {
                       ...state,
                       translationsData: action.payload
               }
            case FETCH_ADMIN_TRANSLATIONS:
                       return {
                       ...state,
                       adminTranslations: action.payload
              }
            case DELETE_TRANSLATION:
                  return {
                    ...state,
                    deleteTranslation: action.payload
              }
          default:
                return state;
          }
      }
      