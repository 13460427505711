import {
    INIT_URL,
    FETCH_SETTINGS,
    USER_ID_SET,
    SEND_PUSH_DATA,
    NOTIFICATIONS_LIST
  } from '../actionTypes';
  import axios from '../../util/Api'
  import * as General from '../../util/General'
  
  import {GENERAL_ERROR} from '../../constant';
  
  export const setInitUrl = (url) => {
    return {
      type: INIT_URL,
      payload: url
    };
  };
  
  export const getSettings = () => {
      //Get user locale of the machine he is on
    const userLocale = General.getLocale();
    let postData = {langLoc: userLocale,platform: "web"};

    return (dispatch) => {
      axios.post('settings', postData
      ).then(({data}) => {
        if (data){
          dispatch({type: FETCH_SETTINGS, payload: data});
        } 
      }).catch(function (error) {
        dispatch({type: FETCH_SETTINGS, payload: null});
      });
    }
  };


  export const postSendNotification = (postData) => {
    //Get user locale of the machine he is on
  const userLocale = General.getLocale();

  const userId = JSON.parse(localStorage.getItem(USER_ID_SET));


  postData.langLoc = userLocale;
  postData.userId = userId;

  return (dispatch) => {
    axios.post('admin/sendpush', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: SEND_PUSH_DATA, payload: data});
      } 
    }).catch(function (error) {
      dispatch({type: SEND_PUSH_DATA, payload: null});
    });
  }
};


  
export const postNotificationsList = (postData) => {
  //Get user locale of the machine he is on
const userLocale = General.getLocale();

const userId = JSON.parse(localStorage.getItem(USER_ID_SET));


postData.langLoc = userLocale;
postData.userId = userId;

return (dispatch) => {
  axios.post('admin/notifications', postData
  ).then(({data}) => {
    if (data){
      dispatch({type: NOTIFICATIONS_LIST, payload: data});
    } 
  }).catch(function (error) {
    dispatch({type: NOTIFICATIONS_LIST, payload: null});
  });
}
};
