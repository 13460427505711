import './userNotificationSettings.css';
import React, { Fragment,useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter, Media, Form, FormGroup, Label, Input, Button } from 'reactstrap'
import axios from 'axios'
import { MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, ARG_FIREBASE_TOPIC_ALL, ARG_NOTIFICATION_TYPE_GENERAL} from '../../constant'
import { useParams } from 'react-router';
import NoAccess from '../../components/reuse/noaccess';
import { getUserData } from '../../redux/user/action';
import { Loader } from 'react-bootstrap-typeahead';
import { toast } from 'react-toastify';
import { editUser, forgotPass, getUser,editRoles, changeAccountStatus, postNotificationSettings, postChangeNotificationSettings } from '../../redux/auth/action';

import DatePicker from "react-datepicker";
import { Link } from 'react-router-dom';
import * as General from '../../util/General'
import SweetAlert from 'sweetalert2'
import { checkUserSub } from '../../redux/subscription/action';
import LoaderBox from '../../components/reuse/loaderbox';
import { subscribeToTopic, unsubscribeToTopic } from '../../firebase/FirebaseInit';


const UserNotificationSettings = (props) => {

    const dispatch = useDispatch();

    //LETS GET THE TRANSLATIONS
    const trans = useSelector(({translation}) => translation.translationsData);

    //LETS GET THE LOGGED IN USER DATA
   const userInfo = useSelector(({auth}) => auth.authUser);


   const notData = useSelector(({auth}) => auth.userNotificationSettings);

   const [notificationList, setNotificationList] = useState([]);

   useEffect(() => {
    getNotSettings();
  }, []);

  useEffect(() => {
    if (notData != null){
      setNotificationList(notData.notSettings)
    }
    
  }, [notData]);
   
   const getNotSettings = () => {
    let postData = {};
    dispatch(postNotificationSettings(postData));
  }
 

  const changeNotificationSettings = (item) => {
    let postData = {type: item.type,enabled: item.enabled};
    dispatch(postChangeNotificationSettings(postData));
  }

  const handleSetting = (e,item,index) => {
    const value = e.target.checked;
   // console.log(value);
    var newSettingList = [];
    notificationList.forEach(element => {
      newSettingList.push(element);
    });

    newSettingList[index].enabled = value;
    changeNotificationSettings(newSettingList[index])
    setNotificationList(newSettingList);

    if (item.type  ==  ARG_NOTIFICATION_TYPE_GENERAL) {
      let topicAll = `${ARG_FIREBASE_TOPIC_ALL}`;
        if (value){
          subscribeToTopic(topicAll);
        } else {
          unsubscribeToTopic(topicAll);
        }
    }

  }

    return (
            <Card>
                <CardHeader>
                        <h4 className="font-weight-bold">{trans.settings_notifications_title}</h4>
                </CardHeader>
                <CardBody>
                   <Row>
                     <Col>
                        {notData == null ? <LoaderBox/> : 
                         <div className="">
                      
        
                                {notificationList.map((item,index) => {
                                
                                return (<div key={`item_not_settings_${item.type}`}  className="_notifications_settings_item">
                                        <Row>

                                          <Col md="10">
                                            <div>
                                              <div>
                                                <span className="font-weight-bold">{item.title}</span>
                                              </div>
                                              <div>
                                               <span>{item.descr}</span>
                                              </div>
                                            </div>
                                          </Col>

                                          <Col md="2">

                                              <div className="_notifications_settings_item_switch">
                                              <label className="form-switch _custom_ios_switch">
                                                <Input
                                                  name={`input_${item.type}`}
                                                  type="checkbox"
                                                  id={`input_${item.type}`}
                                                  defaultChecked={item.enabled}
                                                  onChange={(e)=>handleSetting(e,item,index)}
                                                />
                                                <i></i>

                                              </label>
                                            </div>
                                          
                                          </Col>
                                          
                                        </Row>
                                        
                                         
                                      <hr />
                                </div>)
                                }
                                )}
                                
                         </div>
                        }
                     </Col>   
                    </Row>  
                </CardBody>
            </Card>
  );
}

export default UserNotificationSettings;