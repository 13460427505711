import {
  FETCH_NUTRITION_MENU_TYPE,
  ADD_NUTRITION_MENU_TYPE,
  EDIT_NUTRITION_MENU_TYPE,
  DELETE_NUTRITION_MENU_TYPE,
  SINGLE_NUTRITION_MENU_TYPE,
  USER_ID_SET
} from '../../actionTypes';
import axios from '../../../util/Api'
import * as General from '../../../util/General'
  
  

  export const getNutritionMenuTypes = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;
    console.log(postData);
    return (dispatch) => {
      axios.post('nutritionmenutype/all', postData
      ).then(({data}) => {
         console.log("blogs",data);
        if (data){
          dispatch({type: FETCH_NUTRITION_MENU_TYPE, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };

  export const addNutritionMenuType = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.creator = userId;
    postData.langLoc = userLocale;
   // console.log(postData);
    return (dispatch) => {
      axios.post('nutritionmenutype/add', postData
      ).then(({data}) => {
        // console.log(data);
        if (data){
          dispatch({type: ADD_NUTRITION_MENU_TYPE, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };


  export const editNutritionMenuType = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;
    console.log(postData);
    return (dispatch) => {
      axios.post('nutritionmenutype/edit', postData
      ).then(({data}) => {
        // console.log(data);
        if (data){
          dispatch({type: EDIT_NUTRITION_MENU_TYPE, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };
  
  export const deleteNutritionMenuType = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;
    //console.log(postData);
    return (dispatch) => {
      axios.post('nutritionmenutype/delete', postData
      ).then(({data}) => {
        // console.log(data);
        if (data){
          dispatch({type: DELETE_NUTRITION_MENU_TYPE, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };
  
  export const singleNutritionMenuType = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;
    //console.log(postData);
    return (dispatch) => {
      axios.post('nutritionmenutype/single', postData
      ).then(({data}) => {
        // console.log(data);
        if (data){
          dispatch({type: SINGLE_NUTRITION_MENU_TYPE, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };

