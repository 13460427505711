import firebase from 'firebase';
import Api from '../util/Api';
import * as Const from '../constant';

var firebaseConfig = {
  apiKey: "AIzaSyDnOXIkDjs3Z9-JqME4fxpIQOgVR6XsJM8",
  authDomain: "atplab-app.firebaseapp.com",
  databaseURL: "https://atplab-app-default-rtdb.firebaseio.com",
  projectId: "atplab-app",
  storageBucket: "atplab-app.appspot.com",
  messagingSenderId: "456349061441",
  appId: "1:456349061441:web:e281773007397a06e491c0",
  measurementId: "G-VZ2Z2YCH2L"
};

export const initializeFirebase = () => {
  if (firebase.apps.length === 0) {
      firebase.initializeApp(firebaseConfig);
    }else {
        firebase.app(); // if already initialized, use that one
    }

  firebase.analytics();


  // navigator.serviceWorker
  //   .register('/my-sw.js')
  //   .then((registration) => {
  //     firebase.messaging().useServiceWorker(registration);
  //   });


}


export const askForPermissioToReceiveNotifications = async () => {
  try {
    const messaging = firebase.messaging();

    await messaging.requestPermission();
    const token = await messaging.getToken();

    // let userId = JSON.parse(localStorage.getItem('userId'));
    // if (userId !== ""){
    //   subscribeToTopic(`${Const.ARG_FIREBASE_TOPIC_USER}${userId}`);
    // }

    // subscribeToTopic()


    return token;
  } catch (error) {
    console.error(error);
  }
}

export const subscribeToTopic = async (topic) => {
    const messaging = firebase.messaging();
    await messaging.requestPermission();
    const token = await messaging.getToken();

    let postData = {topic,token};

    Api.post('/settings/subscribeToTopic', postData)
    .then(function (response) {
      if (Const.DEBUG_MODE) {
        console.log(response);
      }
    })
    .catch(function (error) {
      if (Const.DEBUG_MODE) {
        console.log(error);
      }
    });
}

export const unsubscribeToTopic = async (topic) => {
    const messaging = firebase.messaging();
    await messaging.requestPermission();
    const token = await messaging.getToken();

    let postData = {topic,token};

    Api.post('/settings/unsubscribeToTopic', postData)
    .then(function (response) {
      if (Const.DEBUG_MODE) {
        console.log(response);
      }
    })
    .catch(function (error) {
      if (Const.DEBUG_MODE) {
        console.log(error);
      }
    });
}

export const logEvent = async (eventName,eventData) => {
  const analytics = firebase.analytics();
  analytics.logEvent(eventName,eventData)
}
