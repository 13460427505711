import {
    FETCH_ADMIN_USERS,
    FETCH_VIEW_USER_DATA,
    FETCH_COACHES} from '../actionTypes';
  
    const INIT_STATE = {
        usersListData: null,
        userViewData: null,
        coachesData: null
      };
      
      export default (state = INIT_STATE, action) => {
          switch(action.type) {
            case FETCH_ADMIN_USERS:
                       return {
                       ...state,
                       usersListData: action.payload
              }
         case FETCH_VIEW_USER_DATA:
                return {
                ...state,
                userViewData: action.payload
            }
         case FETCH_COACHES:
              return {
              ...state,
              coachesData: action.payload
          }
          default:
                return state;
          }
      }
      