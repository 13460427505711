import  './schedule.css';
import React,{useEffect, useState} from 'react';
import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Form, FormGroup, Input, Label, Button,NavItem, NavLink, Nav,TabContent,TabPane, Breadcrumb, Card, CardHeader, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import WorkoutLeaderboard from './workoutLeaderboard';


const WorkoutLeaderboardModal = (props) => {

    const dispatch = useDispatch();

    const {onWorkoutLeaderboardClose, open, scheduleWorkout} = props;
    const trans = useSelector(({translation}) => translation.translationsData);

    return (<Modal isOpen={open} toggle={onWorkoutLeaderboardClose} centered size="xl">
             <ModalHeader toggle={onWorkoutLeaderboardClose}>{scheduleWorkout == null ? trans.workout_leaderboard_title : `${trans.workout_leaderboard_title} • ${scheduleWorkout.workoutdata.name}`}</ModalHeader>
             <ModalBody>
                <WorkoutLeaderboard 
                    scheduleWorkout={scheduleWorkout}
                    fromProgress={false}
                />
            </ModalBody>
     </Modal>);
};

export default WorkoutLeaderboardModal;