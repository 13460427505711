import './nutritionMenuType.css';
import React, { Fragment,useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from '../../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardFooter, Media, Button, Form, FormGroup, Input, Pagination, PaginationItem, PaginationLink,CardBody,Label } from 'reactstrap'

import {DATE_TIME_FORMAT, Follower,Following,TotalPost} from '../../../constant'
import axios from 'axios'
import { DELETE_TRACK, STORED_TRANSLATIONS } from '../../../redux/actionTypes';
import { Loader } from 'react-bootstrap-typeahead';
import Moment from 'react-moment';
import * as General from '../../../util/General'
import { useHistory } from 'react-router';
import {getNutritionMenuTypes, addNutritionMenuType, deleteNutritionMenuType, editNutritionMenuType } from '../../../redux/nutrition/nutritionMenuType/action';
import { toast } from 'react-toastify';
import AddNutritionMenuType from './addNutritionMenuType';
import NoAccess from '../../../components/reuse/noaccess';
import SweetAlert from 'sweetalert2'
import LoaderBox from '../../../components/reuse/loaderbox';


const NutritionMenuTypeList = (props) => {
  
  const userLocale = General.getLocale();
  const history = useHistory();
  const dispatch = useDispatch();

  const userInfo = useSelector(({auth}) => auth.authUser);
  const nutritionMenuTypeData = useSelector(({nutritionMenuType}) => nutritionMenuType.fetchNutritionMenuTypeData);
  const trainingsData = useSelector(({trainings}) => trainings.trainingsData);
  const addNutritionMenuTypeRreponse = useSelector(({nutritionMenuType}) => nutritionMenuType.addNutritionMenuTypeData);

  const trans = useSelector(({translation}) => translation.translationsData);
  const userIsAdmin = useSelector(({auth}) => auth.userIsAdmin);

  //SEARCH
  const [searchText, setSearchText] = useState('');
  var searchKeyword = "";

  //DATA
  const [curPage, setCurPage] = useState(0);
  const [email, setEmail] = useState(userInfo != null ? userInfo.email : "");
  const [descr, setDescr] = useState("");


  const addNutritionMenuTypeData = useSelector(({nutritionMenuType}) => nutritionMenuType.addNutritionMenuTypeData);
  const editNutritionMenuTypeData = useSelector(({nutritionMenuType}) => nutritionMenuType.editNutritionMenuTypeData);
  const deleteNutritionMenuTypeData = useSelector(({nutritionMenuType}) => nutritionMenuType.deleteNutritionMenuTypeData);
  const [addEditNutritionMenuTypeModal, setAddEditNutritionMenuTypeModal] = useState(false);
  const [nutritionMenuTypeToEdit, setNutritionMenuTypeToEdit] = useState(null);
  const [nutritionMenuTypeIsEdit, setNutritionMenuTypeIsEdit] = useState(false);

  useEffect(() => {
    if (addNutritionMenuTypeData && addNutritionMenuTypeData.status){
      getMenuTypesData();
        }
    }, [addNutritionMenuTypeData]);

    useEffect(() => {
        if (editNutritionMenuTypeData && editNutritionMenuTypeData.status){
          getMenuTypesData();
        }
    }, [editNutritionMenuTypeData]);


    useEffect(() => {
      if (deleteNutritionMenuTypeData != null){
        if (deleteNutritionMenuTypeData.status){
          toast.success(deleteNutritionMenuTypeData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
            getMenuTypesData();
        } else {
          toast.error(deleteNutritionMenuTypeData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
        }
    
        dispatch({type: DELETE_TRACK, payload: null});
      }
    }, [deleteNutritionMenuTypeData]);


    useEffect(() => {
      getMenuTypesData();
    }, []);

 
  const getMenuTypesData = () => {
    let postData = {}
    dispatch(getNutritionMenuTypes(postData));
  }



  const handleUserClick = (e,item) => {
    e.preventDefault();
    setNutritionMenuTypeToEdit(item);
    setNutritionMenuTypeIsEdit(true);
    setAddEditNutritionMenuTypeModal(true);
  }


  const askToDeleteNutritionMenuType = (e,item) => {
    e.preventDefault();
    SweetAlert.fire({
      title:trans.delete_dialog_title,
      text:trans.delete_dialog_desc,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText:trans.ok_btn,
      cancelButtonText: trans.cancel_btn,
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
          //OK
            //REMOVE
          let postData = {nutritionMenuTypeId: item.id}
          dispatch(deleteNutritionMenuType(postData));
      }
      else {
        
      }
    })
  }
 
  const getNutritionMenuTypesUI = () => {
    var nutritionMenuTypes = [];

    if (nutritionMenuTypeData == null){
      return(<LoaderBox></LoaderBox>)
    }

    nutritionMenuTypeData.data.map((item,i) => {
 
      var privateText = item.access_type == 1 ? trans.value_yes : trans.value_no;
     

      nutritionMenuTypes.push(<tr  key={`nutritionMenuTypes_item_${i}`} className="_table_nutritionMenuTypes_item">
                    <td>{item.title}</td>
                    {/* <td>{privateText}</td> */}
                    <td>{item.numId}</td>
                    {/* <td><Moment locale={userLocale} format={DATE_TIME_FORMAT}>{item.publish_time}</Moment></td> */}
                    <td className="text-right">
                            {/* <Button style={{marginRight:'5px'}} onClick={(e) => {askToDeleteNutritionMenuType(e,item)}} color="secondary" size="sm"><i className="fa fa-trash"></i> {trans.delete_btn}</Button> */}
                             
                            <Button onClick={(e) => {handleUserClick(e,item)}} color="primary" size="sm"><i className="fa fa-pencil"></i> {trans.edit_btn}</Button>
                    </td>
                  </tr>)
      
    });

    if (nutritionMenuTypes.length > 0){
      return (<div className="table-responsive">
      <table className="table card-table table-vcenter text-nowrap">
          <thead>
            <tr className="_table_nutritionMenuTypes_item">
              <th key={"title"}>{trans.nutrition_menu_type_name}</th>
              {/* <th key={"access_type"}>{trans.training_is_private}</th> */}
              <th key={"active"}>NUMBER ID</th>
              {/* <th key={"publish_time"}>{trans.nutritionMenuType_publish_date}</th> */}
              <th key={"nutritionMenuType_edit"}></th>
            </tr>
          </thead>
          <tbody>
            {nutritionMenuTypes}
          </tbody>
        </table>
      </div>);
    } else {
      return (<div className="m-10">
          {trans.no_training_nutritionMenuTypes}
        </div>)
    }

  }

  const addEditModaltoggle = (e) => {
    setNutritionMenuTypeToEdit(null); 
    setNutritionMenuTypeIsEdit(false); 
    setAddEditNutritionMenuTypeModal(!addEditNutritionMenuTypeModal)
  }

  const onSaveNutritionMenuType = (data) => {
    if (nutritionMenuTypeIsEdit){
      dispatch(editNutritionMenuType(data));
    } else {
      dispatch(addNutritionMenuType(data));
    }
  }

  return (
    <Fragment>
      <Breadcrumb parent={trans.nutrition_menu_types} title={trans.nutrition_menu_types} />
      <Container fluid={true}>



      {!userIsAdmin  ?
       <Col md="12">
       <NoAccess/>
       </Col>
       : 
        
       <div>
    
              <Card>
                <CardHeader >
                  <Row>
                  <Col xl="12" sm="12"> 
                    <div style={{alignItems: 'center',}}> 
                        <h4 className="card-title mb-0 font-weight-bold" style={{float: "left"}}>{trans.nutrition_menu_types}</h4>
                        <div className="card-title mb-0" style={{float: "right"}}>
                            <Button onClick={addEditModaltoggle} color="primary" size="sm"><i className="fa fa-pencil"></i> {trans.add_btn}</Button>
                        </div>
                    </div>
                  </Col>
                    </Row>
                </CardHeader>
                <div>
                      {getNutritionMenuTypesUI()}
                    </div>

                </Card>
                
  </div>}
      </Container>
      <AddNutritionMenuType
        open={addEditNutritionMenuTypeModal}
        onAddNutritionMenuTypeClose={addEditModaltoggle}
        onSaveNutritionMenuType={(data) => onSaveNutritionMenuType(data)}
        isEditNutritionMenuType={nutritionMenuTypeIsEdit}
        nutritionMenuTypeToEdit={nutritionMenuTypeToEdit}
        trainings={trainingsData != null ? trainingsData.trainings : null}
      />
    </Fragment>
  );
}

export default NutritionMenuTypeList;