import './workouts.css';
import React, { Fragment,useState,useEffect ,useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardFooter, Media, Button, Form, FormGroup, Input, Pagination, PaginationItem, PaginationLink,CardBody,Label } from 'reactstrap'
import CKEditors from "react-ckeditor-component";
import {DATE_TIME_FORMAT, Follower,Following,TotalPost} from '../../constant'
import axios from 'axios'
import { ADD_WORKOUT, EDIT_SCHEDULE_WORKOUT, STORED_TRANSLATION,EDIT_WORKOUT_DATA } from '../../redux/actionTypes';
import { Loader } from 'react-bootstrap-typeahead';
import Moment from 'react-moment';
import * as General from '../../util/General'
import { useHistory } from 'react-router';
import { getWorkout,addWorkout, editWorkout } from '../../redux/workouts/action';
import { toast } from 'react-toastify';
import { getScoreTypes } from '../../redux/scoretypes/action';
import {editScheduleWorkout} from '../../redux/schedule/action';
import {
  EDIT_WORKOUT} from '../../redux/actionTypes';
  import JoditEditor from "jodit-react";

  	
	const config = {
		readonly: false ,
    iframe: true,
    video: true
	}

  
const AddWorkout = (props) => {
  
  const userLocale = General.getLocale();
 
  const dispatch = useDispatch();

  const userInfo = useSelector(({auth}) => auth.authUser);
  const scoreTypesData = useSelector(({scoreTypes}) => scoreTypes.scoreTypesData);
  const workoutsData = useSelector(({workouts}) => workouts.workoutsData);
  const addWorkoutData = useSelector(({workouts}) => workouts.addWorkoutData);
  const editWorkoutData = useSelector(({workouts}) => workouts.editWorkoutData);
  const editScheduleWorkoutData = useSelector(({schedules}) => schedules.editScheduleWorkoutData);

  const trans = useSelector(({translation}) => translation.translationsData);
  const userIsAdmin = useSelector(({auth}) => auth.userIsAdmin);

  const clearData = () => {
    //console.log("CLEARING_DATA");
    setName('');
    setDesc("");
    setAmount(1);
    setScoreType("");
    setVisibleTo(1);
    setCoachNotes("");
    setAthletesNotes("");
    setYoutubeLink("");
    setVideoGallery([]);
  }
 
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [amount, setAmount] = useState(1);
  const [scoreType,setScoreType] = useState("");
  const [visibleTo,setVisibleTo] = useState(1);
  const [coachNotes, setCoachNotes] = useState("");
  const [athletesNotes, setAthletesNotes] = useState("");

  const [youtubeLink, setYoutubeLink] = useState("");

  const [videoGallery, setVideoGallery] = useState([]);

  const [isWeekly, setIsWeekly] = useState(false);

  var currentDate = props.currentDate;
  var currentWeekOfMonth = General.getISOWeekInMonth(currentDate);
  var currentMonth = General.getISOMonth(currentDate);
  var currentYear = General.getISOYear(currentDate);
  var dayOfMonth = currentDate.getDay();

  const [weekOfMonth, setWeekOfMonth] = useState(currentWeekOfMonth);
  const [month, setMonth] = useState(currentMonth);
  const [year, setYear] = useState(currentYear);
  const [weekDate, setWeekDate] = useState(currentDate);

  const [fromModal, setFromModal] = useState(false);

  const editorDesc = useRef(null);
  const editorCoachNotes = useRef(null);
  const editorAthletesNotes = useRef(null);

  useEffect(() => {
    if (props.fromModal != null){
      setFromModal(props.fromModal);
    }

    getScoreTypessData();
  }, []);

  useEffect(() => {
    if (addWorkoutData != null && addWorkoutData.status){
      dispatch({type: ADD_WORKOUT, payload: null});
      clearData();
      console.log("WORKOUT STORED");
      props.onWorkoutStored();
      
    }
}, [addWorkoutData]);

useEffect(() => {
  if (editScheduleWorkoutData != null ){
    if (editScheduleWorkoutData.status){
      
      toast.success(editScheduleWorkoutData.message, {
        position: toast.POSITION.TOP_RIGHT
        });
        props.onSchdeuleWorkoutEdited();
        clearData();
    }else {
      toast.error(editScheduleWorkoutData.message, {
        position: toast.POSITION.TOP_RIGHT
        });
      }
    dispatch({type: EDIT_SCHEDULE_WORKOUT, payload: null});
  
  }
}, [editScheduleWorkoutData]);



  useEffect(() => {
    if (editWorkoutData != null){
      if (editWorkoutData.status){
        // toast.success(editWorkoutData.message, {
        //   position: toast.POSITION.TOP_RIGHT
        //   });
          props.onWorkoutStored();
          clearData();
      }else {
        toast.error(editWorkoutData.message, {
          position: toast.POSITION.TOP_RIGHT
          });
        }
        dispatch({type: EDIT_WORKOUT_DATA, payload: null});
      }
      
  }, [editWorkoutData]);


useEffect(() => {
  if (scoreTypesData != null){
    setScoreType(scoreTypesData.scoretypes[0].id)
    console.log(props);
    if (props.isEdit || props.fromEditSchedule){
        let workoutItem = props.singleWorkoutItem;

        if (props.fromEditSchedule != null && props.fromEditSchedule){
          workoutItem = props.singleWorkoutItem.workoutdata;
        }

       // console.log("WORKOUTITEM",workoutItem);
        setName(workoutItem.name);
        setDesc(workoutItem.desc);
        setAmount(workoutItem.scoring.amount);
        setScoreType(workoutItem.scoring.id);
        setVisibleTo(workoutItem.visibleTo);
        setCoachNotes(workoutItem.coachNotes);
        setAthletesNotes(workoutItem.athleteNotes);
        setVideoGallery(workoutItem.videogallery);
        setIsWeekly(workoutItem.isWeekly);
        setWeekOfMonth(workoutItem.weekOfMonth);
        setMonth(workoutItem.month);
        setYear(workoutItem.year);
        setWeekDate(workoutItem.weekDate);
    } else {
      if (props.fromSchedule){
        setIsWeekly(true);
      }
    }
  
  }
}, [scoreTypesData]);

  

  const getScoreTypessData = () => {
    let postData = {page: 1,sort: {"_id": -1},query: {},searchKeyword: ""}
    dispatch(getScoreTypes(postData));
  }   

  const saveWorkout = (e) => {
    e.preventDefault();
    let postData = {
          "name":name,
          "desc": desc,
          "scoring": {
              "amount": amount,
              "scoreType": scoreType
          },
          "visibleTo": visibleTo,
          "coachNotes": coachNotes,
          "athleteNotes": athletesNotes,
          "videoUrl": "",
          "imageUrl": "",
          "videogallery":videoGallery,
          "type": 1,
          "isWeekly": isWeekly,
          "weekOfMonth": weekOfMonth,
          "month": month,
          "year": year,
          "weekDate": weekDate
      }
      if (props.fromEditSchedule){  
          postData.workoutId = props.singleWorkoutItem.id;
          dispatch(editScheduleWorkout(postData))
      } else {
        if (props.isEdit){
          postData.workoutId = props.singleWorkoutItem.id;
          dispatch(editWorkout(postData))
        } else {
          dispatch(addWorkout(postData))
        }
    }
  }

  const addVideo = () => {
    //HOLDDATA
    setVideoGallery([...videoGallery, youtubeLink]);
    setYoutubeLink('');
  }

  const onDescChange = (evt) => {
    const newContent = evt.editor.getData();
    setDesc(newContent)
  }


  const onCoachNotesChange = (evt) => {
    const newContent = evt.editor.getData();
    setCoachNotes(newContent)
  }

  const onAthletesNotesChange = (evt) => {
    const newContent = evt.editor.getData();
    setAthletesNotes(newContent)
  }

  const deleteSingleVideo = (e,item,index) => {
      var newVideoCallery = [];
      videoGallery.forEach(element => {
        newVideoCallery.push(element);
      });
      
      newVideoCallery.splice(index, 1);
      setVideoGallery(newVideoCallery);
  }

 

  return (
    <Fragment>
    
      <Container fluid={true}>

  

      <Form className="card">
                {fromModal ? null :
                <CardHeader>
                  <h4 className="card-title mb-0 font-weight-bold">{props.isEdit ? trans.edit_workout : trans.add_workout }</h4>
                  <div className="card-options">
                    <a className="card-options-collapse" href="#javascript">
                      <i className="fe fe-chevron-up"></i>
                    </a>
                    <a className="card-options-remove" href="#javascript">
                      <i className="fe fe-x"></i>
                    </a>
                  </div>
                </CardHeader>
                }
                <CardBody>
                  <Row>
                    <Col sm="12" md="12">
                      <FormGroup>
                        <Label className="form-label font-weight-bold">{trans.workout_name}</Label>
                        <Input value={name} className="form-control" type="text" placeholder={trans.workout_name} onChange={(e)=>{setName(e.target.value)}}/>
                      </FormGroup>
                    </Col>
                   
                    <Col sm="12" md="12">
                      <div className="form-group">
                        <Label className="form-label font-weight-bold">{trans.workout_desc}</Label>
                    
                        <JoditEditor
                          ref={editorDesc}
                           value={desc}
                          config={config}
                          tabIndex={1} // tabIndex of textarea
                          onBlur={newContent => setDesc(newContent)} // preferred to use only this option to update the content for performance reasons
                          onChange={newContent => {}} />

                        {/* <CKEditors
                                        activeclassName="p10"
                                        content={desc}
                                        events={{
                                            "change": onDescChange,
                                            "mode": editDescSource
                                        }}
                                    /> */}
                      </div>
                    </Col>
                    

                    
                      <Col  sm="6" md="6">
                        <FormGroup>
                          <Label className="form-label font-weight-bold">{trans.workout_set}</Label>
                          <Input value={amount} className="form-control" type="number" placeholder={trans.workout_set} onChange={(e)=>{setAmount(e.target.value)}}/>
                        </FormGroup>
                      </Col>
                    
                

                      <Col  sm="6" md="6">
                      {scoreTypesData != null ?
                                <FormGroup>
                                    <Label className="form-label font-weight-bold" for="exampleSelect">{trans.workout_scoring}</Label>
                                    <Input className="custom-select" value={scoreType} onChange={(e)=> setScoreType(e.target.value)} type="select" name="scoreType" id="scoreType">
                                        {scoreTypesData.scoretypes.map((item,index) => {
                                         return(<option key={item.id} value={item.id}>{item.name}</option>)
                                        })}
                                    </Input>
                                </FormGroup>
                                : null}
                      </Col>

                      <Col  sm="12" md="12">
                                <FormGroup>
                                    <Label className="form-label font-weight-bold" for="exampleSelect">{trans.workout_visible_to}</Label>
                                    <Input className="custom-select" value={visibleTo} onChange={(e)=> setVisibleTo(e.target.value)} type="select" name="visibleTo" id="visibleTo">
                                    <option key={`visible_to_${1}`} value={1}>{trans.workout_visible_to_everyone}</option>
                                    <option  key={`visible_to_${2}`} value={2}>{trans.workout_visible_to_coaches}</option>
                                    </Input>
                                </FormGroup>
                       </Col>

                       <Col  sm="12" md="12">
                        <FormGroup>
                          <Label className="form-label font-weight-bold">{trans.workout_coach_notes}</Label>
                          <JoditEditor
                          ref={editorCoachNotes}
                           value={coachNotes}
                          config={config}
                          tabIndex={1} // tabIndex of textarea
                          onBlur={newContent => setCoachNotes(newContent)} // preferred to use only this option to update the content for performance reasons
                          onChange={newContent => {}} />
                         
                        </FormGroup>
                      </Col>
                  
                      <Col  sm="12" md="12">
                        <FormGroup>
                          <Label className="form-label font-weight-bold">{trans.workout_athlete_notes}</Label>
                          <JoditEditor
                          ref={editorAthletesNotes}
                           value={athletesNotes}
                          config={config}
                          tabIndex={1} // tabIndex of textarea
                          onBlur={newContent => setAthletesNotes(newContent)} // preferred to use only this option to update the content for performance reasons
                          onChange={newContent => {}} />
                       
                        </FormGroup>
                      </Col>

                      <Col  sm="12" md="12">
                                <FormGroup>
                                    <Label className="form-label font-weight-bold" for="exampleSelect">{trans.workout_is_weekly}</Label>
                                    <Input className="custom-select" value={isWeekly} onChange={(e)=> setIsWeekly(e.target.value)} type="select" name="isWeekly" id="isWeekly">
                                    <option key={`isWeekly_${1}`} value={true}>{trans.value_yes}</option>
                                    <option  key={`isWeekly_${2}`} value={false}>{trans.value_no}</option>
                                    </Input>
                                </FormGroup>
                       </Col>

                      <Col  sm="12" md="8">
                            <FormGroup>
                              <Label className="form-label font-weight-bold">{trans.workout_add_video}</Label>
                              <Input value={youtubeLink} className="form-control" type="text" placeholder={trans.workout_add_video} onChange={(e)=>{setYoutubeLink(e.target.value)}}/>
                            </FormGroup>
                     </Col>
                     <Col  sm="12" md="4">
                     <FormGroup>
                              <Label className="form-label font-weight-bold">&nbsp;</Label>
                              <button  disabled={youtubeLink == ""} onClick={(e)=> {addVideo(e)}} className="form-control btn btn-primary" type="submit">{trans.add_btn}</button>
                            </FormGroup>
                     </Col>

                     <Col>
                        {videoGallery.length > 0 ?
                            <div >
                              <ul className="_workout_item_video_ul">
                                {videoGallery.map((item,videoIndex) => {
                                   var myId = General.getId(item);
                                    return (<li key={`video_url_${item}`}>
                                      <div>
                                         <iframe className="_workout_video" width="200" height="150" src={`//www.youtube.com/embed/${myId}`} frameBorder="0"  allow="fullscreen;"></iframe>
                                         <div className="caption">
                                          <span onClick={(e)=> {deleteSingleVideo(e,item,videoIndex)}} style={{float:'right',color:'red',fontSize:'13px',margin:'4px',cursor:'pointer'}}>{trans.delete_btn}</span>
                                        </div>
                                     </div>
                                  </li>)
                                  })}
                                </ul>
                          </div>
                        : <div>
                            <p>{trans.workout_no_videos}</p>
                          </div>}
                     </Col>

                  </Row>
                </CardBody>
                <CardFooter className="text-right">
                  <button disabled={name ==""} onClick={(e)=> {saveWorkout(e)}} className="btn btn-primary" type="submit">{trans.submit_btn}</button>
                </CardFooter>
              </Form>

      
       
      </Container>
    </Fragment>
  );
}

export default AddWorkout;