import  './schedule.css';
import React,{useEffect, useState} from 'react';
import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Form, FormGroup, Input, Label, Button,NavItem, NavLink, Nav,TabContent,TabPane, Breadcrumb, Card, CardHeader, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import WorkoutLeaderboard from './workoutLeaderboard';
import parse from 'html-react-parser';
import * as General from '../../util/General';

const ScheduleWorkout = (props) => {

    const dispatch = useDispatch();

    const {event,openLeaderBoards, openNotes,openDoneSchedule,openEditDoneSchedule,index,fromProgress,showLeaderboard} = props;
    const trans = useSelector(({translation}) => translation.translationsData);

    const settingsData = useSelector(({settings}) => settings.settingsData);


  const userIsAdmin = useSelector(({auth}) => auth.userIsAdmin);  
  const userIsManager = useSelector(({auth}) => auth.userIsManager); 
  const userIsCoach = useSelector(({auth}) => auth.userIsCoach); 

    function renderEventContent() {
        var videoView = null;
     //   console.log("event",event);
        if (event != null  && event.workoutdata != null){
    
        if (event.workoutdata.videogallery.length > 0){
            videoView = (
              <div className="_workout_item_content_bg">
                 <div >
                 <ul className="_schedule_workout_video_list">
                   { event.workout.videogallery.map((item,index) => {
                      var myId = General.getId(item);
                       return (<li key={`video_url_${item}`}>
                         <iframe className="_workout_video" width="200" height="150" src={`//www.youtube.com/embed/${myId}`} frameBorder="0"  allow="fullscreen;"></iframe>
                     </li>)
                     })}
                   </ul>
               </div>
              </div>
            );
        }
    
        var bgColor = settingsData.settings.workout_bg;
    
        // if (index == 0){
        //     bgColor = "#f50e022c";
        // }
        
        if (event.doneSchedule != null || fromProgress){
          bgColor = settingsData.settings.done_workout_bg;
        }
     
     
       // console.log("event.workoutdata.athlete_notes",event.workoutdata.athlete_notes);
    
       var find = '<a';
       var re = new RegExp(find, 'g');
       var descData = event.workoutdata.desc;
       var htmlData = descData.replace(re, '<a target="_blank" ');
      
    
        return(<div key={event.id} style={{backgroundColor:`${bgColor}`}} className="_daily_workout_container" >
    
             <div  className="_daily_workout_item">
            
            <div className="">
               <h5 className="_daily_workout_container_title font-weight-bold">{event.workoutdata.name}</h5>
            </div>
            
            <hr/>
            
            <div>
              {/* <h6 className="card-title mb-0">{trans.workout_desc}</h6> */}
             <div className="_daily_workout_descr_text"> { parse(htmlData) } {}</div>
            </div>
    
     
            {event.workoutdata.athleteNotes != null && event.workoutdata.athleteNotes != ""? 
            <div>
              <br/>
              <h6 className="_notes_btn" onClick={(e)=>{openNotes(e,event.workoutdata.athleteNotes,trans.workout_athlete_notes)}}>{trans.workout_athlete_notes}</h6>
              {/* <div className="_daily_workout_descr_text"> { parse(event.workoutdata.athlete_notes != null ? event.workoutdata.athlete_notes : "") } {}</div> */}
            </div> : null}
    
     
            {event.workoutdata.coachNotes != null && event.workoutdata.coachNotes != ""? 
            userIsAdmin || userIsManager || userIsCoach ? 
            <div>
              <br/>
              <h6 className="_notes_btn" onClick={(e)=>{openNotes(e,event.workoutdata.coachNotes,trans.workout_athlete_notes)}}>{trans.workout_coach_notes}</h6>
              {/* <div className="_daily_workout_descr_text"> { parse(event.workoutdata.coach_notes != null ? event.workoutdata.coach_notes : "") } {}</div> */}
            </div>: null : null}
            
            
            {/* <div>
              <p className="_daily_workout_sets_text">{`${event.workout.scoring.amount} ${trans.workout_set} ${trans.workout_of} ${event.workout.scoring.name}`}</p>
            </div> */}
    
            {videoView}
               </div>     
           
           
    
            <Row>
                <Col md="12">
                  { fromProgress ? null : event.doneSchedule == null ? 
                  <button onClick={(e)=> {openDoneSchedule(event)}} className="btn btn-secondary _daily_workout_submit_btn">{trans.schedule_submit}</button> :
                   <button onClick={(e)=> {openEditDoneSchedule(event)}} className="btn btn-primary _daily_workout_submit_btn">{trans.edit_result}</button>}
                </Col>
            
                {showLeaderboard != null && showLeaderboard == true ? 
                <Col md="12">
                  <button onClick={(e)=> {openLeaderBoards(e,event)}} className="btn btn-primary _daily_workout_submit_btn">{trans.leaderboard_btn}</button>
                </Col>
                 : null}
              </Row>
     
          </div>);
        }
      }
    

    return (<div>
        {renderEventContent()}
    </div>);
};

export default ScheduleWorkout;