import  './workouts.css';
import React,{useEffect, useState} from 'react';
import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Form, FormGroup, Input, Label, Button,NavItem, NavLink, Nav,TabContent,TabPane, Breadcrumb, Card, CardHeader, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import AddWorkout from './addWorkout';

const AddWorkoutModal = (props) => {

    const {onWorkoutStored, onAddWorkoutModalClose, open,currentDate} = props;
  
  
    const settingsData = useSelector(({settings}) => settings.settingsData);
    const trans = useSelector(({translation}) => translation.translationsData);

    const [langsArray,setLangArray] = useState([]); 

  
 


  return (
      <Modal isOpen={open} toggle={onAddWorkoutModalClose} centered size="lg">
                      <ModalHeader toggle={onAddWorkoutModalClose}>
                      {props.fromEditSchedule ? trans.edit_workout :trans.add_workout}
                      </ModalHeader>
                      <ModalBody>
                      <Row>
                    <Col sm="12" md="12">
                      <AddWorkout
                      currentDate={currentDate}
                        onSchdeuleWorkoutEdited={props.onSchdeuleWorkoutEdited}
                        singleWorkoutItem={props.singleWorkoutItem}
                        fromEditSchedule={props.fromEditSchedule}
                        fromModal={true}
                        onWorkoutStored={onWorkoutStored}
                        fromSchedule={props.fromSchedule}
                        isEdit={props.isEdit}
                        />
                        </Col>
                        </Row>
                      </ModalBody>
                </Modal>
  );
}

export default AddWorkoutModal;