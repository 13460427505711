import React from 'react';
import {ConnectedRouter} from 'connected-react-router'
import {Provider} from 'react-redux';
import {Route, Switch} from 'react-router-dom';

import configureStore, {history} from './store';
import Root from './root';

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

export const store = configureStore();



// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_live_51JhHvjF6RbCZ4l4EWVJUVj92x7F02xykDUHuA8s9cF3ZYAYY3fMkt3Bdt6DasPEO3cTNznRYyh7s85KxCOXSBMMb0068fGoVE7');


const MainApp = () =>
<Elements stripe={stripePromise}>
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/" component={Root}/>
      </Switch>
    </ConnectedRouter>
  </Provider>
  </Elements>;

export default MainApp;
