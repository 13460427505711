import './users.css';
import React, { Fragment,useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter, Media, Form, FormGroup, Label, Input, Button } from 'reactstrap'
import axios from 'axios'
import { MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete} from '../../constant'
import { useParams } from 'react-router';
import NoAccess from '../../components/reuse/noaccess';
import { getUserData } from '../../redux/user/action';
import { Loader } from 'react-bootstrap-typeahead';
import { toast } from 'react-toastify';
import { editUser, forgotPass, getUser,editRoles, changeAccountStatus } from '../../redux/auth/action';

import DatePicker from "react-datepicker";
import { Link } from 'react-router-dom';
import * as General from '../../util/General'
import SweetAlert from 'sweetalert2'
import UserSubscriptionSettings from './userSubscriptionSettings';
import UserNotificationSettings from './userNotificationSettings';
import UserGeneralSettings from './userGeneralSettings';

    const UserStatus = (props) => {

    const dispatch = useDispatch();

    //LETS GET THE TRANSLATIONS
    const trans = useSelector(({translation}) => translation.translationsData);

    //LETS GET THE LOGGED IN USER DATA
   const userInfo = useSelector(({auth}) => auth.authUser);

    return (
        <Fragment>
        <Breadcrumb parent={trans.settings_title} title={trans.settings_title} />
        <Container fluid={true}>
        <Row>
            <Col md="12">
                <UserGeneralSettings/>
            </Col>
            <Col md="12">
                <UserNotificationSettings/>
            </Col>
            <Col md="12">
                <UserSubscriptionSettings/>
            </Col>
           
            </Row>
      </Container>
    </Fragment>
  );
}

export default UserStatus;