import  './nutritionMenu.css';
import React,{useEffect, useState} from 'react';
import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Form, FormGroup, Input, Label, Button,NavItem, NavLink, Nav,TabContent,TabPane, Breadcrumb, Card, CardHeader, Modal, ModalHeader, ModalBody, ModalFooter,Media } from 'reactstrap';
import DatePicker from "react-datepicker";
import {getNutritionMenuTypes } from '../../../redux/nutrition/nutritionMenuType/action';
import { getNutritionMenu } from '../../../redux/nutrition/nutritionMenu/action';

import { postUploadImageFile } from '../../../redux/upload/action';

const AddNutritionMenu = (props) => {
    const dispatch = useDispatch();


    const {onSaveNutritionMenu, onAddNutritionMenuClose, open, nutritionMenuToEdit, isEditNutritionMenu} = props;
  

  
    const settingsData = useSelector(({settings}) => settings.settingsData);
    const trans = useSelector(({translation}) => translation.translationsData);
    const nutritionMenuTypeData = useSelector(({nutritionMenuType}) => nutritionMenuType.fetchNutritionMenuTypeData);
    const nutritionMenuData = useSelector(({nutritionMenu}) => nutritionMenu.fetchNutritionMenuData);
    const uploadImagesData = useSelector(({upload}) => upload.uploadImageFileData);
    
    const [langsArray,setLangArray] = useState([]); 

  
    //VALUES
 
    const [visible,setVisible] = useState(true); 
    const [menuType,setMenuType] = useState(1); 
    const [parent,setParent] = useState(null); 

    const [menuTypes,setMenuTypes] = useState([]);
    const [menuCategories,setMenuCategories] = useState([]);


    //IMAGES
    const [image,setImage] = useState(null);
    const [imgThumb,setImgThumb] = useState("");

    

    useEffect(() => {
       if (open){
           clearData();
           var arr = [];
           settingsData.settings.langs.map((item,index) => {
               arr.push({langLoc: item.langLoc, text: ""});
           })
           
           if (isEditNutritionMenu){

            setVisible(nutritionMenuToEdit.visible);
            setImage(nutritionMenuToEdit.image);
            if (nutritionMenuToEdit.parent != null){
                setParent(nutritionMenuToEdit.parent._id);
            }
            

            if (nutritionMenuToEdit.imgObj != null){
                setImgThumb(nutritionMenuToEdit.imgObj.img_thumb);
              }

               arr.forEach((item,langIndex) => {
                nutritionMenuToEdit.titles.forEach(trans => {
                        if (trans.langLoc == item.langLoc){
                           arr[langIndex].text = trans.text;
                        }
                    });
                });
            }

            setLangArray(arr);
        
            getMenuTypesData();
            getMenusData();
             
       }
    }, [open]);

    useEffect(() => {
        if (nutritionMenuTypeData != null){
            setMenuTypes(nutritionMenuTypeData.data)
        }
     }, [nutritionMenuTypeData]);

     useEffect(() => {
        if (nutritionMenuData != null){
            var menuData = [];
            menuData.push(null);

            nutritionMenuData.data.forEach(element => {
                menuData.push(element);
            });

            setMenuCategories(menuData)
        }
     }, [nutritionMenuData]);
    

    const getMenuTypesData = () => {
        let postData = {}
        dispatch(getNutritionMenuTypes(postData));
    }


    const getMenusData = () => {
        let postData = {}
        dispatch(getNutritionMenu(postData));
    }

  
    const clearData = () => {
        setLangArray([]);
        setVisible(true);
        setImage(null);
        setParent(null);
        setImgThumb("");
    }

    const setLang = (e) => {
        let langIndex = e.target.id.replace("lang_value_","");
        langsArray[langIndex].text = e.target.value;
    }
  
    const uploadImage = () => {
        document.getElementById("post-images").click()
      }

      const _handleImageChange = (event) => {
        if (event.target.files.length === 0)
          return;
        
    
        var mimeType = event.target.files[0].type;
    
        if (mimeType.match(/image\/*/) == null) {
          return;
        }
      
        let file = event.target.files[0];
        
        let reader = new FileReader();
        reader.onloadend = () => {
          setImgThumb(reader.result);
        }
       reader.readAsDataURL(file)
    
        var type = "nutritionmenu";
    
        var images = [];
        images.push(file);
    
    
        dispatch(postUploadImageFile(type, images))
      }

      useEffect(() => {
        if (uploadImagesData != null){
              let image = uploadImagesData.images[0];
              setImage(image);
          }
      },[uploadImagesData])

      
  return (
      <Modal isOpen={open} toggle={onAddNutritionMenuClose} centered>
                      <ModalHeader toggle={onAddNutritionMenuClose}>
                      {trans.nutrition_add_edit}
                      </ModalHeader>
                      <ModalBody>
                      <div className="form-row">
                                {/* <FormGroup className="col-md-12">
                                  <Label>{"NUMBER ID"}</Label>
                                  <Input value={numId} type="number" onChange={e => setNumId(e.target.value)}/>
                                </FormGroup> */}

                              <span className="font-weight-bold">{trans.nutrition_titles}</span>  
                                {langsArray.map((item,index) => {
                                    return (<FormGroup key={`trans_${index}`} className="col-md-12">
                                    <Label>{settingsData.settings.langs[index].name}</Label>
                                    <Input defaultValue={item.text} id={`lang_value_${index}`} onChange={(e)=> setLang(e)} type="text"/>
                                  </FormGroup>)
                                })}

                            <br/>

                             <FormGroup className="col-md-12">
                                    <Label for="exampleSelect">{trans.visible}</Label>
                                    <Input className="custom-select" value={visible} onChange={(e)=> setVisible(e.target.value)} type="select" name="visible" id="visible">
                                    <option value={true}>{trans.value_yes}</option>
                                    <option value={false}>{trans.value_no}</option>
                                    </Input>
                                </FormGroup> 

                                <FormGroup className="col-md-12">
                                    <Label className="form-label" for="exampleSelect">{trans.nutrition_menu_type_name}</Label>
                                    <Input className="custom-select" value={menuType} onChange={(e)=> setMenuType(e.target.value)} type="select" name="menuType" id="menuType">
                                        {menuTypes.map((item,index) => {
                                         return(<option key={item.numId} value={item.numId}>{item.title}</option>)
                                        })}
                                    </Input>
                                </FormGroup>

                                <FormGroup className="col-md-12">
                                    <Label className="form-label" for="exampleSelect">{trans.nutrition_menu_category}</Label>
                                    <Input className="custom-select" value={parent} onChange={(e)=> setParent(e.target.value)} type="select" name="parent" id="parent">
                                        {menuCategories.map((item,index) => {
                                            if (item == null){
                                                return (<option key={"home"} value={"home"}>{"HOME"}</option>)
                                            }
                                         return(<option key={item.id} value={item.id}>{item.title}</option>)
                                        })}
                                    </Input>
                                </FormGroup>

                              </div>
                           <Row>
                               <Col>
                        <div>
                          
                          <div style={{float:'left'}}>
                            <Label className="form-label font-weight-bold">{trans.nutrition_image}</Label>
                          </div>
                            
                          <div style={{float:'right'}}>
                            <div  onClick={uploadImage}>
                              <Button color="primary" size="sm"><i className="fa fa-plus"></i> {trans.add_btn}</Button>
                              <input id="post-images" type="file" name="post-images" style={{display: 'none'}}
                                      accept="image/x-png,image/jpeg" onChange={(e)=>_handleImageChange(e)} />
                            </div>
                          </div> 

                        </div>      
                        </Col> 
                        {/* <Col md="9">
                           <Label className="form-label font-weight-bold">{trans.nutrition_image}</Label>
                        </Col>  
                        <Col  md="4">
                          <div  onClick={uploadImage}>
                              <Button color="primary" size="sm"><i className="fa fa-plus"></i> {trans.add_btn}</Button>
                              <input id="post-images" type="file" name="post-images" style={{display: 'none'}}
                                      accept="image/x-png,image/jpeg" onChange={(e)=>_handleImageChange(e)} />
                              </div>
                        </Col> */}
                        <Col md="12">
                         <div style={{marginTop: '10px',width: '100%',height:'200px'}}>
                              <Media
                                style={{height:'100%', width:'100%', objectFit:'contain'}}
                                    src={imgThumb}
                                    alt={`Image Article`}
                                    className="img-thumbnail"
                                />
                          </div>
                        </Col>
                  
                      </Row>   
                      </ModalBody>
                      <ModalFooter>
                        <Button color="primary"  onClick={() => {
                            onAddNutritionMenuClose();
                            if (isEditNutritionMenu){
                                // var transArr = [];
                                // langsArray.forEach(lang => {
                                //     nutritionMenuToEdit.titles.forEach(trans => {
                                //         if (trans.langLoc == lang.lang_loc){
                                //             transArr.push({id: trans.id,text: lang.value})
                                //         }
                                //     });
                                // });
                                onSaveNutritionMenu(
                                    {
                                        'titles': langsArray,
                                        'image' : image,
                                        'visible': visible,
                                        'parent': parent,
                                        'menuType': menuType,
                                        'itemId' : nutritionMenuToEdit.id
                                    });
                            } else {      
                                onSaveNutritionMenu(
                                {
                                    'titles': langsArray,
                                    'image' : image,
                                    'visible': visible,
                                    'parent': parent,
                                    'menuType': menuType
                                });
                                clearData();
                            }

                           clearData();
                        }}>{trans.submit_btn}</Button>
                      </ModalFooter>
                </Modal>
  );
}

export default AddNutritionMenu;