import  './nutritionPlan.css';
import React,{useEffect, useState} from 'react';
import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Form, FormGroup, Input, Label, Button,NavItem, NavLink, Nav,TabContent,TabPane, Breadcrumb, Card, CardHeader, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DatePicker from "react-datepicker";
import { getNutritionMenu } from '../../../redux/nutrition/nutritionMenu/action';


const AddNutritionPlan = (props) => {
    const dispatch = useDispatch();

    const {onSaveNutritionPlan, onAddNutritionPlanClose, open, nutritionPlanToEdit, isEditNutritionPlan} = props;
  

  
    const settingsData = useSelector(({settings}) => settings.settingsData);
    const trans = useSelector(({translation}) => translation.translationsData);
    const nutritionMenuData = useSelector(({nutritionMenu}) => nutritionMenu.fetchNutritionMenuData);
    
    const [langsArray,setLangArray] = useState([]); 
    const [menuCategories,setMenuCategories] = useState([]);

  
    //VALUES
    const [visible,setVisible] = useState(true); 
    const [menuId,setMenuId] = useState(""); 
    const [dailyKcal,setDailyKcal] = useState(0); 
    const [menus,setMenus] = useState([]); 


    useEffect(() => {
       if (open){
           clearData();
           var arr = [];
           settingsData.settings.langs.map((item,index) => {
               arr.push({langLoc: item.langLoc, text: ""});
           })
           
           if (isEditNutritionPlan){

               setVisible(nutritionPlanToEdit.visible);

               if (nutritionPlanToEdit.menuId != null){
                setMenuId(nutritionPlanToEdit.menuId._id);
               } 

               setDailyKcal(nutritionPlanToEdit.dailyKcal);

               if (nutritionPlanToEdit.menus != null){
                 
                   setMenus(nutritionPlanToEdit.menus);
               }
               
               arr.forEach((item,langIndex) => {
                nutritionPlanToEdit.titles.forEach(trans => {
                        if (trans.langLoc == item.langLoc){
                           arr[langIndex].text = trans.text;
                        }
                    });
                });
            }

            setLangArray(arr);
        
            getMenusData();
       }
    }, [open]);

    const getMenusData = () => {
        let postData = {}
        dispatch(getNutritionMenu(postData));
    }


    useEffect(() => {
        if (nutritionMenuData != null){
            var menuData = [];

            nutritionMenuData.data.forEach(element => {
                menuData.push(element);
            });

            setMenuCategories(menuData)
            if (menuId == "") {
                setMenuId(menuData[0].id);
            }
        }
     }, [nutritionMenuData]);
    
  
    const clearData = () => {
        setLangArray([]);
        setVisible(true);
        setMenuId("");
        setMenus([]);
        setDailyKcal(0);
    }

    const setLang = (e) => {
        let langIndex = e.target.id.replace("lang_value_","");
        langsArray[langIndex].text = e.target.value;
    }
    
    const addPlanToMenu = () => {
        const found = menus.some(item => item == menuId);
        if (!found){
            setMenus([...menus, menuId]);
        }
    }

    const deleteMenuItem = (menuItem) => {
        setMenus(menus.filter(item => item !== menuItem))
    }

  return (
      <Modal isOpen={open} toggle={onAddNutritionPlanClose} centered>
                      <ModalHeader toggle={onAddNutritionPlanClose}>
                      {trans.nutrition_add_edit}
                      </ModalHeader>
                      <ModalBody>
                      <div className="form-row">

                      {langsArray.map((item,index) => {
                                    return (<FormGroup key={`trans_${index}`} className="col-md-12">
                                    <Label>{settingsData.settings.langs[index].name}</Label>
                                    <Input defaultValue={item.text} id={`lang_value_${index}`} onChange={(e)=> setLang(e)} type="text"/>
                                  </FormGroup>)
                                })}

                            <FormGroup className="col-md-12">
                                    <Label>{trans.nutrition_daily_kcal}</Label>
                                    <Input value={dailyKcal} id={`dailyKcal`} onChange={(e)=> setDailyKcal(e.target.value)} type="number"/>
                            </FormGroup>

                        
                             <FormGroup className="col-md-12">
                                    <Label for="exampleSelect">{trans.visible}</Label>
                                    <Input className="custom-select" value={visible} onChange={(e)=> setVisible(e.target.value)} type="select" name="visible" id="visible">
                                    <option value={true}>{trans.value_yes}</option>
                                    <option value={false}>{trans.value_no}</option>
                                    </Input>
                                </FormGroup> 

                                
                                <FormGroup className="col-md-9">
                                        <Label className="form-label" for="exampleSelect">{trans.nutrition_menu_category}</Label>
                                        <Input className="custom-select" value={menuId} onChange={(e)=> setMenuId(e.target.value)} type="select" name="menuId" id="menuId">
                                            {menuCategories.map((item,index) => {
                                            return(<option key={item.id} value={item.id}>{`${item.title} ${item.parent != null ? `${` - ${item.parent.title}`}`: ''}`}</option>)
                                            })}
                                        </Input>
                                </FormGroup>
                                <Button className="col-md-3" color="primary" style={{height:'35px',marginTop:'30px'}}  onClick={() => { addPlanToMenu()}}>{trans.add_btn}</Button>
                           

                               {menus.length > 0 && menuCategories.length > 0 ? <div>
                                    <br/>
                                    <Row>
                                        {
                                            menus.map((menuItem) => {
                                                var menuName = '';
                                                menuCategories.map((menuCategory,index) => {
                                                    if (menuCategory.id == menuItem){
                                                        menuName = `${menuCategory.title} ${menuCategory.parent != null ? `${` - ${menuCategory.parent.title}`}`: ''}`
                                                    }
                                                })


                                                return (<Col key={menuItem}>
                                                    <div style={{backgroundColor:"#eeeeee",borderRadius:'5px',padding:'2px',margin:'2px'}}>
                                                        <div style={{display:'flex'}}>
                                                            <div>
                                                                 {menuName}
                                                            </div>
                                                            <div>
                                                            &nbsp;
                                                            </div>
                                                            <div onClick={() => {deleteMenuItem(menuItem)}} style={{fontWeight:'700',cursor:'pointer'}}>
                                                                 X
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>)
                                            })
                                        }
                                    </Row>
                               </div> : null
                               }
                            
                        </div>
                           
                      </ModalBody>
                      <ModalFooter>
                        <Button color="primary"  onClick={() => {
                            onAddNutritionPlanClose();
                            if (isEditNutritionPlan){
                                // var transArr = [];
                                // langsArray.forEach(lang => {
                                //     nutritionPlanToEdit.titles.forEach(trans => {
                                //         if (trans.langLoc == lang.lang_loc){
                                //             transArr.push({id: trans.id,text: lang.value})
                                //         }
                                //     });
                                // });
                                onSaveNutritionPlan(
                                    {
                                        'titles': langsArray,
                                        'visible' : visible,
                                        'menus' : menus,
                                        'dailyKcal': dailyKcal,
                                        'itemId' : nutritionPlanToEdit.id
                                    });
                            } else {      
                                onSaveNutritionPlan(
                                {
                                    'titles': langsArray,
                                    'visible' : visible,
                                    'menus' : menus,
                                    'dailyKcal': dailyKcal
                                });
                                clearData();
                            }

                           clearData();
                        }}>{trans.submit_btn}</Button>
                      </ModalFooter>
                </Modal>
  );
}

export default AddNutritionPlan;