import {
  FETCH_CLIENT_TOKEN,
  GET_SUBSCRIPTION,
  USER_ID_SET,
  CHECK_SUB,
  CUSTOM_SUBS,
  CREATE_CUSTOM_SUB,
  DELETE_CUSTOM_SUB,
  CANCEL_SUB
  } from '../actionTypes';
  import axios from '../../util/Api'
  import * as General from '../../util/General'
  
  import {GENERAL_ERROR} from '../../constant';
  
  

  export const getClientToken = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;
    //console.log(postData);
    return (dispatch) => {
      axios.post('subscription/client_token', postData
      ).then(({data}) => {
        // console.log(data);
        if (data){
          dispatch({type: FETCH_CLIENT_TOKEN, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };


  export const getSubPlan = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;
    //console.log(postData);
    return (dispatch) => {
      axios.post('subscription/sub-plan', postData
      ).then(({data}) => {
        // console.log(data);
        if (data){
          dispatch({type: GET_SUBSCRIPTION, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };

  export const checkUserSub =   (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;
    // console.log(postData);
    return (dispatch) => {
      axios.post('subscription/checkSub', postData
      ).then(({data}) => {
       //  console.log("CHECKSUB",data);
        if (data){
          dispatch({type: CHECK_SUB, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };

  export const createCustomSub = (postData) => {
    //Get user locale of the machine he is on
    // const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    // postData.userId = userId;
    postData.langLoc = userLocale;
    console.log(postData);
    return (dispatch) => {
      axios.post('subscription/create-custom-sub', postData
      ).then(({data}) => {
         console.log(data);
        if (data){
          dispatch({type: CREATE_CUSTOM_SUB, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };

  export const customSubs = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;
    console.log(postData);
    return (dispatch) => {
      axios.post('subscription/customsubs', postData
      ).then(({data}) => {
         console.log(data);
        if (data){
          dispatch({type: CUSTOM_SUBS, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };

  export const deleteCustomSub = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;
    console.log(postData);
    return (dispatch) => {
      axios.post('subscription/delete-custom-sub', postData
      ).then(({data}) => {
         console.log(data);
        if (data){
          dispatch({type: DELETE_CUSTOM_SUB, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };

  export const cancelSub = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;
   // console.log(postData);
    return (dispatch) => {
      axios.post('subscription/cancel-sub', postData
      ).then(({data}) => {
        // console.log(data);
        if (data){
          dispatch({type: CANCEL_SUB, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };