import {
  FETCH_NUTRITION_MENU,
  ADD_NUTRITION_MENU,
  EDIT_NUTRITION_MENU,
  DELETE_NUTRITION_MENU,
  SINGLE_NUTRITION_MENU,
USER_ID_SET
  } from '../../actionTypes';
  import axios from '../../../util/Api'
  import * as General from '../../../util/General'
  

  

  export const getNutritionMenu = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;
    console.log(postData);
    return (dispatch) => {
      axios.post('nutritionmenu/all', postData
      ).then(({data}) => {
         console.log("nutritionmenus",data);
        if (data){
          dispatch({type: FETCH_NUTRITION_MENU, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };

  export const addNutritionMenu = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.creator = userId;
    postData.langLoc = userLocale;
   // console.log(postData);
    return (dispatch) => {
      axios.post('nutritionmenu/add', postData
      ).then(({data}) => {
        // console.log(data);
        if (data){
          dispatch({type: ADD_NUTRITION_MENU, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };


  export const editNutritionMenu = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;
    //console.log(postData);
    return (dispatch) => {
      axios.post('nutritionmenu/edit', postData
      ).then(({data}) => {
        // console.log(data);
        if (data){
          dispatch({type: EDIT_NUTRITION_MENU, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };
  
  export const deleteNutritionMenu = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;
    //console.log(postData);
    return (dispatch) => {
      axios.post('nutritionmenu/delete', postData
      ).then(({data}) => {
        // console.log(data);
        if (data){
          dispatch({type: DELETE_NUTRITION_MENU, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };
  
  export const singleNutritionMenu = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;
    //console.log(postData);
    return (dispatch) => {
      axios.post('nutritionmenu/single', postData
      ).then(({data}) => {
        // console.log(data);
        if (data){
          dispatch({type: SINGLE_NUTRITION_MENU, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };
