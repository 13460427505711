import React, { Fragment,useEffect,useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardHeader, Col, Media, Row } from 'reactstrap';

const LoaderBox = (props) => {

    return (<div className="loader-box" >
                <div className="loader-9"></div>
              </div>);

}

export default LoaderBox;