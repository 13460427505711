import React, { Fragment, useState, useEffect } from 'react';
import { Type,Home, Airplay, Box, FolderPlus, Command, Cloud, FileText, Server, BarChart, Users, Layers, ShoppingBag, Aperture, Mail, MessageCircle, GitPullRequest, Monitor, Heart, Clock, Zap, CheckSquare, Calendar, Image, Film, HelpCircle, Radio, Map, Edit, Sunrise, Package, Triangle, Database, User, Volume, Anchor,Bell, Rss, Sliders } from 'react-feather'
import { useSelector } from 'react-redux'
import { ArrowRight, ArrowLeft, Grid } from 'react-feather';
import { Link } from 'react-router-dom'
import { translate } from 'react-switch-lang';
import configDB from '../../data/customizer/config';

const Sidebar = (props) => {


  const [margin, setMargin] = useState(0);
  const [width, setWidth] = useState(0);
  const [sidebartoogle, setSidebartoogle] = useState(true)
  const wrapper = useSelector(content => content.Customizer.sidebar_types.type) || configDB.data.settings.sidebar.type;
  const authUserData = useSelector(({auth}) => auth.authUser);
 

  const trans = useSelector(({translation}) => translation.translationsData);
  const settingsData = useSelector(({settings}) => settings.settingsData);

  const userIsAdmin = useSelector(({auth}) => auth.userIsAdmin);  
  const userIsManager = useSelector(({auth}) => auth.userIsManager); 
  const userIsCoach = useSelector(({auth}) => auth.userIsCoach); 

  var MENU_ITEMS_DATA = [

    {
        menutitle:trans.menu_general,
        menucontent:trans.menu_general_desc,
        Items:[
             { path: `${process.env.PUBLIC_URL}/dashboard/default`,icon:Home, title: trans.menu_dashboard, type: 'link',active: false , slug: "dashboard"}
        ]
    },

    {
        menutitle:trans.menu_application,
        menucontent:trans.menu_application_desc,
        Items:[
            { path: `${process.env.PUBLIC_URL}/app/schedule`,icon:Calendar, title: trans.menu_schedule, type: 'link',active: false, slug: "schedule" },
            { path: `${process.env.PUBLIC_URL}/app/users/progress`,icon:Heart, title: trans.bottom_nav_progress, type: 'link',active: false, slug: "progress" },
            { path: `${process.env.PUBLIC_URL}/app/athleteslab`,icon:Anchor, title: trans.bottom_nav_athletes_lab, type: 'link',active: false, slug: "athleteslab" },
            { path: `${process.env.PUBLIC_URL}/app/nutrition`,icon: Aperture, title: trans.bottom_nav_nutrition, type: 'link',active: false, slug: "nutrition" },   
            { path: `${process.env.PUBLIC_URL}/app/users/userProfile/${authUserData.nickname}`,icon:User, title: trans.bottom_nav_profile, type: 'link',active: false, slug: "profile" },
            { path: `${process.env.PUBLIC_URL}/app/users/coaches`,icon:CheckSquare, title: trans.menu_coaches, type: 'link',active: false, slug: "coaches" },
            { path: `${process.env.PUBLIC_URL}/app/users/userList`,icon:Users, title: trans.menu_users, type: 'link',active: false, slug: "users" },
            { path: `${process.env.PUBLIC_URL}/app/workouts`,icon:Database, title: trans.menu_workouts, type: 'link',active: false, slug: "workouts" },
            { path: `${process.env.PUBLIC_URL}/app/trainings`,icon:Layers, title: trans.menu_trainings, type: 'link',active: false, slug: "trainings" },
            { path: `${process.env.PUBLIC_URL}/app/tracks`,icon:Triangle, title: trans.menu_tracks, type: 'link',active: false, slug: "tracks" }, 
            
            { path: `${process.env.PUBLIC_URL}/app/scoretypes`,icon:Type, title: trans.menu_score_types, type: 'link',active: false, slug: "scoretypes" },
            { path: `${process.env.PUBLIC_URL}/app/send-notification`,icon:Bell, title: 'Push Notifications', type: 'link',active: false, slug: "send-notification" },
            { path: `${process.env.PUBLIC_URL}/app/blog-list`,icon:Rss, title: trans.blog_list_title, type: 'link',active: false, slug: "blog-list" }
        ]
    },

    {
        menutitle:trans.menu_settings,
        menucontent:trans.menu_settings_desc,
        Items:[
            { path: `${process.env.PUBLIC_URL}/feedback`,icon:Mail, title: trans.feedback_title, type: 'link',active: false, slug: "feedback" },
            { path: `${process.env.PUBLIC_URL}/translations`,icon:Command, title: trans.translations_title, type: 'link',active: false , slug: "translations" }
        ]
    }];

  // console.log("appItems",appItems);
  //   console.log("menuItem",MENU_ITEMS_DATA);

    var menuDataItems = [];

    MENU_ITEMS_DATA.map(menuItem => {

      var newMnuItem = {menutitle:menuItem.menutitle,menucontent:menuItem.menucontent,Items:[]};

      //console.log("menuItem",menuItem);
      menuItem.Items.forEach((innerMenuItem,innerMenuItemIndex) => {
      //  console.log("innerMenuItem.slug",innerMenuItem.slug);
        

        if (innerMenuItem.slug == "translations" || innerMenuItem.slug == "users" ||
        innerMenuItem.slug == "trainings" || innerMenuItem.slug == "scoretypes"|| 
        innerMenuItem.slug == "tracks"  || innerMenuItem.slug == "users"|| 
        innerMenuItem.slug == "workouts" || innerMenuItem.slug == "schedule" || innerMenuItem.slug == "send-notification"  || innerMenuItem.slug ==  "blog-list" ){
          var hasAcess = false;

          if (innerMenuItem.slug == "translations" || 
          innerMenuItem.slug == "trainings"  || 
          innerMenuItem.slug == "tracks"   ){
            if (userIsAdmin){
              hasAcess = true;
            }
          } else {
            if (userIsManager || userIsAdmin){
              hasAcess = true;
            }
          }

          if ( innerMenuItem.slug == "users"|| 
          innerMenuItem.slug == "workouts" || innerMenuItem.slug == "schedule" || innerMenuItem.slug == "send-notification" || innerMenuItem.slug ==  "blog-list" ) {
            if (userIsCoach){
              hasAcess = true;
            }
          }
        

          if (hasAcess){
            newMnuItem.Items.push(innerMenuItem);
          }
        } else {
          newMnuItem.Items.push(innerMenuItem)
        }
      });
      if ( newMnuItem.Items.length > 0){
         menuDataItems.push(newMnuItem);
      }
    }); 

    // MENU_ITEMS_DATA.map(menuItem => {
    //   //console.log("menuItem",menuItem);
    //   menuItem.Items.forEach((innerMenuItem,innerMenuItemIndex) => {
    //   //  console.log("innerMenuItem.slug",innerMenuItem.slug);
    //     if (innerMenuItem.slug == "translations" || innerMenuItem.slug == "users" ||
    //     innerMenuItem.slug == "trainings" || innerMenuItem.slug == "scoretypes"|| 
    //     innerMenuItem.slug == "tracks" || innerMenuItem.slug == "users"|| 
    //     innerMenuItem.slug == "workouts" || innerMenuItem.slug == "schedule" ){
    //       var hasAcess = false;
    //       authUserData.roles.map(userRole => {
    //         if (userRole.role_id == 1 || userRole.role_id == 2){
    //           hasAcess = true;
    //         }
    //       })
    //       if (!hasAcess){
    //           menuItem.Items.splice(innerMenuItemIndex, 1);
    //       }
    //     }
    //   });
    //   if ( menuItem.Items.length > 0){
    //    menuDataItems.push(menuItem);
    //   }
    // }); 

    const [mainmenu, setMainMenu] = useState(menuDataItems);


  
  useEffect(() => {

    document.querySelector(".left-arrow").classList.add("d-none")

    window.addEventListener('resize', handleResize)
    handleResize();
    

    

    const currentUrl = window.location.pathname;
    mainmenu.map(items => {
      items.Items.filter((Items) => {
        if (Items.path === currentUrl)
          setNavActive(Items)
        if (!Items.children) return false
        Items.children.filter(subItems => {
          if (subItems.path === currentUrl)
            setNavActive(subItems)
          if (!subItems.children) return false
          subItems.children.filter(subSubItems => {
            if (subSubItems.path === currentUrl) {
              setNavActive(subSubItems)
              return true
            }
            else {
              return false
            }
          })
          return subItems
        })
        return Items
      })
      return items
    })




    return () => {
      window.removeEventListener('resize', handleResize)
    }

    // eslint-disable-next-line
  }, []);

  const handleResize = () => {
    setWidth(window.innerWidth - 500);
  }

  const setNavActive = (item) => {
    var tempMenu = mainmenu;
    tempMenu.map(menuItems => {
      menuItems.Items.filter(Items => {
        if (Items !== item)
          Items.active = false
        if (Items.children && Items.children.includes(item))
          Items.active = true
        if (Items.children) {
          Items.children.filter(submenuItems => {
            if (submenuItems.children && submenuItems.children.includes(item)) {
              Items.active = true
              submenuItems.active = true
              return true
            }
            else {
              return false
            }
          })
        }
        return Items
      })
      return menuItems
    })
    item.active = !item.active
    setMainMenu(tempMenu)
  }

  const toggletNavActive = (item) => {

    
    if(window.innerWidth <= 991){
      document.querySelector(".page-header").className = "page-header close_icon";
      document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper close_icon "
      document.querySelector(".mega-menu-container").classList.remove("d-block")
      if(item.type === "sub"){
        document.querySelector(".page-header").className = "page-header ";
        document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper "
      }
    }

    //SET ALL INACTIVE
    var tempMenu = mainmenu;
    tempMenu.map(a => {
         a.Items.map(a => {
          a.active = false;
      });
    });

    if (!item.active) {
      tempMenu.map(a => {
        a.Items.filter((Items) => {
          if (a.Items.includes(item))
            Items.active = false
          if (!Items.children) return false
          Items.children.forEach(b => {
            if (Items.children.includes(item)) {
              b.active = false
            }
            if (!b.children) return false
            b.children.forEach(c => {
              if (b.children.includes(item)) {
                c.active = false
              }
            })
          })
          return Items
        });
        return a
      });
    }
    item.active = !item.active
    setMainMenu(tempMenu)
  }

  const scrollToRight = () => {
    if (margin <= -2598 || margin <= -2034) {
      if(width === 492){
        setMargin(-3570)
      }else{
        setMargin(-3464)
      }
      document.querySelector(".right-arrow").classList.add("d-none")
      document.querySelector(".left-arrow").classList.remove("d-none")
    }else {
      setMargin(margin => margin += (-width));
      document.querySelector(".left-arrow").classList.remove("d-none")
    }
  }

  const scrollToLeft = () => {
    if (margin >= -width) {
      setMargin(0)
      document.querySelector(".left-arrow").classList.add("d-none")
      document.querySelector(".right-arrow").classList.remove("d-none")
    } else {
      setMargin(margin => margin += width);
      document.querySelector(".right-arrow").classList.remove("d-none")
    }
  }

  const openCloseSidebar = (toggle) => {
    if (toggle) {
      setSidebartoogle(!toggle);
      document.querySelector(".page-header").className = "page-header close_icon";
      document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper close_icon "
    } else {
      setSidebartoogle(!toggle);
      document.querySelector(".page-header").className = "page-header";
      document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper "
    }
  };

  const responsiveSidebar = () => {
    document.querySelector(".page-header").className = "page-header close_icon";
    document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper close_icon"
  }
  
  return (
    <Fragment>
      <div className="sidebar-wrapper">
        <div className="logo-wrapper">
          <Link to={`${process.env.PUBLIC_URL}/dashboard/default`}>
            <img className="img-fluid for-light" src={require("../../assets/images/logo/logo.png")} alt="" />
            <img className="img-fluid for-dark" src={require("../../assets/images/logo/logo_dark.png")} alt="" />
          </Link>
          <div className="back-btn" onClick={() => responsiveSidebar()}><i className="fa fa-angle-left"></i></div>
          <div className="toggle-sidebar" onClick={() => openCloseSidebar(sidebartoogle)}><Grid className="status_toggle middle sidebar-toggle" /></div>
        </div>
        <div className="logo-icon-wrapper">
          <Link to={`${process.env.PUBLIC_URL}/dashboard/default`}><img className="img-fluid" src={require("../../assets/images/logo/logo-icon.png")} alt="" /></Link>
        </div>
        <nav className="sidebar-main">
            <div className="left-arrow" onClick={scrollToLeft}><ArrowLeft /></div>
            <div id="sidebar-menu" style={wrapper === 'horizontal-wrapper' ? { 'marginLeft': margin + 'px' } : { margin: '0px' }}>
              <ul className="sidebar-links custom-scrollbar" >
                <li className="back-btn">
                  <div className="mobile-back text-right"><span>{"Back"}</span><i className="fa fa-angle-right pl-2" aria-hidden="true"></i></div>
                </li>
                {
                  
                  mainmenu.map((Item, i) => 
                    <Fragment key={i}>
                      <li className="sidebar-main-title">
                        <div>
                          <h6 className="lan-1">{props.t(Item.menutitle)}</h6>
                          <p className="lan-2">{props.t(Item.menucontent)}</p>
                        </div>
                      </li>
                      {Item.Items.map((menuItem, i) =>
                        <li className="sidebar-list" key={i}>
                          {(menuItem.type === 'sub') ?
                            <a className={`sidebar-link sidebar-title  ${menuItem.active ? 'active' : ''}`} href="#javascript" onClick={() => setNavActive(menuItem)}>
                              <menuItem.icon />
                              <span>{props.t(menuItem.title)}</span>
                              {menuItem.badge ? <label className={menuItem.badge}>{menuItem.badgetxt}</label> : ""}
                              <div className="according-menu">
                                {menuItem.active ?
                                  <i className="fa fa-angle-down"></i>
                                  : <i className="fa fa-angle-right"></i>
                                }
                              </div>
                            </a>
                            : ''}

                          {(menuItem.type === 'link') ?
                            <Link  to={menuItem.path} className={`sidebar-link sidebar-title link-nav  ${menuItem.active ? 'active' : ''}`} href="#javascript" onClick={() => toggletNavActive(menuItem)}>
                            <div style={{display:'flex'}}> 
                             {menuItem.slug == "athleteslab" ? 
                              <div > 
                                <img style={{width:'20px',marginRight:'5px'}} className="img-fluid for-light" src={require("../../assets/images/logo/logo-icon.png")} alt="" /> 
                                <img style={{width:'20px',marginRight:'5px'}} className="img-fluid for-dark" src={require("../../assets/images/logo/logo-icon-white.png")} alt="" />
                              </div> : <menuItem.icon />} 
                              <span>{props.t(menuItem.title)}</span>
                              {menuItem.badge ? <label className={menuItem.badge}>{menuItem.badgetxt}</label> : ""}
                              </div>
                            </Link>
                            : ''}

                          {menuItem.children ?

                            <ul className="sidebar-submenu"
                              style={menuItem.active ? sidebartoogle ? { opacity: 1, transition: 'opacity 500ms ease-in' } : { display: "block" } : { display: "none" }}>

                              {menuItem.children.map((childrenItem, index) => {

                                return (
                                  <li key={index}>

                                    {(childrenItem.type === 'sub') ?
                                      <a className={`${childrenItem.active ? 'active' : ''}`} href="#javascript" onClick={() => toggletNavActive(childrenItem)}>{props.t(childrenItem.title)}
                                        <span className="sub-arrow">
                                          <i className="fa fa-chevron-right"></i>
                                        </span>
                                        <div className="according-menu">
                                          {childrenItem.active ?
                                            <i className="fa fa-angle-down"></i>
                                            : <i className="fa fa-angle-right"></i>
                                          }
                                        </div>
                                      </a>
                                      : ''}

                                    {(childrenItem.type === 'link') ?
                                      <Link  to={childrenItem.path} className={`${childrenItem.active ? 'active' : ''}`} onClick={() => toggletNavActive(childrenItem)}>{props.t(childrenItem.title)}</Link>
                                      : ''}

                                    {childrenItem.children ?
                                      <ul className="nav-sub-childmenu submenu-content"
                                        style={childrenItem.active ? { display: "block" } : { display: "none" }}
                                      >
                                        {childrenItem.children.map((childrenSubItem, key) =>
                                          <li key={key}>
                                            {(childrenSubItem.type === 'link') ?
                                              <Link to={childrenSubItem.path} className={`${childrenSubItem.active ? 'active' : ''}`} onClick={() => toggletNavActive(childrenSubItem)}>{props.t(childrenSubItem.title)}</Link>
                                              : ''}
                                          </li>
                                        )}
                                      </ul>
                                      : ""}

                                  </li>
                                )
                              })}
                            </ul>
                            : ''}
                        </li>)}
                    </Fragment>
                  )}
              </ul>
            </div>
            <div className="right-arrow" onClick={scrollToRight}><ArrowRight /></div>
        </nav>
      </div>
    </Fragment>
  );
}

export default translate(Sidebar);