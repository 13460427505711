export class ConfigDB {
	static data = {
		settings: {
			layout_type:  'ltr',
		sidebar: {
			type: 'horizontal-wrapper',
			body_type: 'horizontal-menu' 
		},
		sidebar_setting: 'default-sidebar', 
		},
		 color: {
			 primary_color: '#000000', 
			 secondary_color: '#f50e02', 
			 mix_background_layout: 'light-only', 
		 },
		 router_animation: 'fadeIn'
	 }
   }
export default ConfigDB;






