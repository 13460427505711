import {
  FETCH_SCORETYPES} from '../actionTypes';

  const INIT_STATE = {
      scoreTypesData: null
    };
    
    export default (state = INIT_STATE, action) => {
        switch(action.type) {
        case FETCH_SCORETYPES:
             return {
                ...state,
            scoreTypesData: action.payload
        }
        default:
              return state;
        }
    }
    