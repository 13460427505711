import {
  FETCH_TRACKS,
  ADD_TRACK,
  EDIT_TRACK,
  DELETE_TRACK,
USER_ID_SET
  } from '../actionTypes';
  import axios from '../../util/Api'
  import * as General from '../../util/General'
  
  import {GENERAL_ERROR} from '../../constant';
  
  

  export const getTracks = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;
    //console.log(postData);
    return (dispatch) => {
      axios.post('track/all', postData
      ).then(({data}) => {
      //   console.log("tracks",data);
        if (data){
          dispatch({type: FETCH_TRACKS, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };

  export const addTrack = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.creator = userId;
    postData.langLoc = userLocale;
   // console.log(postData);
    return (dispatch) => {
      axios.post('track/add', postData
      ).then(({data}) => {
        // console.log(data);
        if (data){
          dispatch({type: ADD_TRACK, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };


  export const editTrack = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;
    //console.log(postData);
    return (dispatch) => {
      axios.post('track/edit', postData
      ).then(({data}) => {
        // console.log(data);
        if (data){
          dispatch({type: EDIT_TRACK, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };
  
  export const deleteTrack = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;
    //console.log(postData);
    return (dispatch) => {
      axios.post('track/delete', postData
      ).then(({data}) => {
        // console.log(data);
        if (data){
          dispatch({type: DELETE_TRACK, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };
  