import './nutritionMeal.css';
import React, { Fragment,useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Breadcrumb from '../../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardFooter, Media, Button, Form, FormGroup, Input, Pagination, PaginationItem, PaginationLink,CardBody,Label } from 'reactstrap'


import * as General from '../../../util/General'
import { useHistory } from 'react-router';
import {getNutritionMeal, addNutritionMeal, deleteNutritionMeal, editNutritionMeal } from '../../../redux/nutrition/nutritionMeal/action';
import { toast } from 'react-toastify';
import AddNutritionMeal from './addNutritionMeal';
import NoAccess from '../../../components/reuse/noaccess';
import SweetAlert from 'sweetalert2'
import LoaderBox from '../../../components/reuse/loaderbox';
import { ADD_NUTRITION_USER_MEAL, DELETE_NUTRITION_MEAL } from '../../../redux/actionTypes';

import parse from 'html-react-parser';
import { addNutritionUserMeal } from '../../../redux/nutrition/nutritionUserMeal/action';


const NutritionMealItem = (props) => {


  const {item, onUserMealAdd,viewMode,onUserMealDelete} = props;


    const trans = useSelector(({translation}) => translation.translationsData);
    

    const addToMeal = (e,item) => {
      e.preventDefault();
      onUserMealAdd(item);
    }

    const deleteUserMeal = (e,item) => {
      e.preventDefault();
      onUserMealDelete();
    }

    
      var menuTypeText = "";

      var planText = "";

      if (item.menuId != null){
        menuTypeText = item.menuId.title;
      }

      if (item.planId != null){
        planText = item.planId.title;
      }
    
      var find = '<a';
      var re = new RegExp(find, 'g');
      var descData = item.desc;
      var htmlData =  descData.replace(re, '<a target="_blank" ');

      var fontSize = viewMode ? "13px" : "16px";

      return (
          <Card style={{padding:'10px',marginTop:'3px',borderRadius:'8px'}}>
        
              <span style={{fontSize:fontSize}} className="font-weight-bold">{item.title}</span>

              
              <hr/>
              
              <div>
              
              <div  style={{fontWeight:'700',fontSize:fontSize}}>
                    { parse(htmlData) }
                    <br/>
                    <br/>
                    <span>{`${trans.nutrition_calories}: ${item.calories}`}</span>
              </div>


              {!viewMode ? 
              <div>
                <hr/>
                <div style={{alignItems:'center',alignContent:'center',justifyContent:'center',display:'flex'}}>
                 <button style={{backgroundColor:'#F5E707',fontWeight:'bold',fontSize:fontSize}} onClick={(e) => {addToMeal(e,item)}}  className="btn">{trans.nutrition_submit}</button> 
                </div>
               
              </div>
                :  <div>
                <hr/>
                <div style={{alignItems:'center',alignContent:'center',justifyContent:'center',display:'flex'}}>
                 <button style={{fontWeight:'bold',fontSize:fontSize,color:'red'}} onClick={(e) => {deleteUserMeal(e,item)}}  className="btn">{trans.delete_btn}</button> 
                </div>
               
              </div>}
              </div>
          </Card>);

   
}

export default NutritionMealItem;