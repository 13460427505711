import  './nutritionMenuType.css';
import React,{useEffect, useState} from 'react';
import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Form, FormGroup, Input, Label, Button,NavItem, NavLink, Nav,TabContent,TabPane, Breadcrumb, Card, CardHeader, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DatePicker from "react-datepicker";


const AddNutritionMenuType = (props) => {

    const {onSaveNutritionMenuType, onAddNutritionMenuTypeClose, open, nutritionMenuTypeToEdit, isEditNutritionMenuType} = props;
  

  
    const settingsData = useSelector(({settings}) => settings.settingsData);
    const trans = useSelector(({translation}) => translation.translationsData);
    
    const [langsArray,setLangArray] = useState([]); 

  
    //VALUES

    const [numId,setNumId] = useState(''); 
   


    useEffect(() => {
       if (open){
           clearData();
           var arr = [];
           settingsData.settings.langs.map((item,index) => {
               arr.push({langLoc: item.langLoc, text: ""});
           })
           
           if (isEditNutritionMenuType){

            console.log("nutritionMenuTypeToEdit",nutritionMenuTypeToEdit);

               setNumId(nutritionMenuTypeToEdit.numId);
               arr.forEach((item,langIndex) => {
                nutritionMenuTypeToEdit.titles.forEach(trans => {
                        if (trans.langLoc == item.langLoc){
                           arr[langIndex].text = trans.text;
                        }
                    });
                });
            }

            setLangArray(arr);
        
             
       }
    }, [open]);

  
    const clearData = () => {
        setLangArray([]);
        setNumId('');
    }

    const setLang = (e) => {
        let langIndex = e.target.id.replace("lang_value_","");
        langsArray[langIndex].text = e.target.value;
    }
  

  return (
      <Modal isOpen={open} toggle={onAddNutritionMenuTypeClose} centered>
                      <ModalHeader toggle={onAddNutritionMenuTypeClose}>
                      {trans.nutrition_add_edit}
                      </ModalHeader>
                      <ModalBody>
                      <div className="form-row">
                                <FormGroup className="col-md-12">
                                  <Label>{"NUMBER ID"}</Label>
                                  <Input value={numId} type="number" onChange={e => setNumId(e.target.value)}/>
                                </FormGroup>
                                {langsArray.map((item,index) => {
                                    return (<FormGroup key={`trans_${index}`} className="col-md-12">
                                    <Label>{settingsData.settings.langs[index].name}</Label>
                                    <Input defaultValue={item.text} id={`lang_value_${index}`} onChange={(e)=> setLang(e)} type="text"/>
                                  </FormGroup>)
                                })}

                            {/* <FormGroup className="col-md-12">
                                    <Label for="exampleSelect">ISAPP</Label>
                                    <Input className="custom-select" value={isApp} onChange={(e)=> setIsApp(e.target.value)} type="select" name="isApp" id="isApp">
                                    <option value={1}>YES</option>
                                    <option value={0}>NO</option>
                                    </Input>
                                </FormGroup> */}
                              </div>
                           
                      </ModalBody>
                      <ModalFooter>
                        <Button color="primary"  onClick={() => {
                            onAddNutritionMenuTypeClose();
                            if (isEditNutritionMenuType){
                                // var transArr = [];
                                // langsArray.forEach(lang => {
                                //     nutritionMenuTypeToEdit.titles.forEach(trans => {
                                //         if (trans.langLoc == lang.lang_loc){
                                //             transArr.push({id: trans.id,text: lang.value})
                                //         }
                                //     });
                                // });
                                onSaveNutritionMenuType(
                                    {
                                        'titles': langsArray,
                                        'numId' : numId,
                                        'itemId' : nutritionMenuTypeToEdit.id
                                    });
                            } else {      
                                onSaveNutritionMenuType(
                                {
                                    'titles': langsArray,
                                    'numId' : numId
                                });
                                clearData();
                            }

                           clearData();
                        }}>{trans.submit_btn}</Button>
                      </ModalFooter>
                </Modal>
  );
}

export default AddNutritionMenuType;