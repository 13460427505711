import './nutritionUserMeal.css';
import React, { Fragment,useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Breadcrumb from '../../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardFooter,CardBody,FormGroup,Label } from 'reactstrap'
import DatePicker from "react-datepicker";

import * as General from '../../../util/General'
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import NoAccess from '../../../components/reuse/noaccess';
import SweetAlert from 'sweetalert2'
import LoaderBox from '../../../components/reuse/loaderbox';
import { ADD_NUTRITION_USER_MEAL } from '../../../redux/actionTypes';

import parse from 'html-react-parser';
import NutritionMealItem from '../nutritionmeal/NutritionMealItem';
import { deleteNutritionUserMeal, getNutritionUserMeals } from '../../../redux/nutrition/nutritionUserMeal/action';


const NutritionUserMealList = (props) => {


  
  const {progressUserId} = props;
  
  const userLocale = General.getLocale();
  const history = useHistory();
  const dispatch = useDispatch();

  const userInfo = useSelector(({auth}) => auth.authUser);
  const nutritionUserMealData = useSelector(({nutritionUserMeal}) => nutritionUserMeal.fetchNutritionUserMealData);
  const deleteNutritionUserMealData = useSelector(({nutritionUserMeal}) => nutritionUserMeal.deleteNutritionUserMealData);
  const trainingsData = useSelector(({trainings}) => trainings.trainingsData);
 

  const trans = useSelector(({translation}) => translation.translationsData);
  const userIsAdmin = useSelector(({auth}) => auth.userIsAdmin);

 
  const [selectedDate, setSelectedDate] = useState(new Date());
  
  const [userMealsArray, setUserMealsArray] = useState([]);

    useEffect(() => {
      getMealsData();
    }, []);
   
   
    useEffect(() => {
      if (deleteNutritionUserMealData != null){
        if (deleteNutritionUserMealData.status){
          toast.success(deleteNutritionUserMealData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
            getMealsData();
        } else {
          toast.error(deleteNutritionUserMealData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
        }
    
        dispatch({type:ADD_NUTRITION_USER_MEAL, payload: null});
      }
    }, [deleteNutritionUserMealData]);


    useEffect(() => {
      if (nutritionUserMealData != null){
       
        var month = selectedDate.getMonth() + 1;
        if (nutritionUserMealData.month == month && 
            nutritionUserMealData.year == selectedDate.getFullYear() && 
            nutritionUserMealData.monthDay == selectedDate.getDate()){
            setUserMealsArray(nutritionUserMealData.data);
        }
      }
    }, [nutritionUserMealData]);



    useEffect(() => {
      if (selectedDate != null){
        getMealsData();
      }
    }, [selectedDate]);


  

    useEffect(() => {
      if (progressUserId != null && progressUserId != ""){
        getMealsData();
      }
  }, [progressUserId]);
    
 
  const getMealsData = () => {
    let postData = {
      userId: progressUserId,
      fromApp: true,
      hasDayFilter: true,
      month: selectedDate.getMonth()+1,
      monthDay:selectedDate.getDate(),
      year: selectedDate.getFullYear()
    }
    dispatch(getNutritionUserMeals(postData));
  }



  const addToMeal = (e,item) => {
    
  }

  const removeUserMeal = (item) => {

    SweetAlert.fire({
      title:trans.delete_dialog_title,
      text:trans.delete_dialog_desc,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText:trans.ok_btn,
      cancelButtonText: trans.cancel_btn,
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
          //OK
            //REMOVE
          let postData = {itemId: item.id}
          dispatch(deleteNutritionUserMeal(postData));
      }
      else {
        
      }
    })
  }

 
  const getNutritionMealsUI = () => {
    var mealsUI = [];

    if (nutritionUserMealData == null){
      return(<LoaderBox></LoaderBox>)
    }




    userMealsArray.map((item,i) => {

      var userMeals = [];

      item.items.forEach(element => {
        if (element.mealData != null){
          userMeals.push(<Col  id={element.mealData.id}>
                  <NutritionMealItem
                    item={element.mealData}
                    onUserMealAdd={addToMeal}
                    viewMode={true}
                    onUserMealDelete={() => removeUserMeal(element)}
                  />
            </Col>);
        }
      });
 
      mealsUI.push(<Col  id={item.id}>


          <div>

            <div>
              <span className="font-weight-bold" style={{color:'#212121'}}>{item.menuTypeName}</span> 
              <hr/>
            </div>

            <div>

            {userMeals.length == 0 ?
              <div>
                <span style={{fontSize:'13px'}}>{trans.nutrition_no_user_meals}</span>
              </div>
            : 
            <Row>
              {userMeals}
            </Row>}

            </div>
            <hr/>
          </div>

      </Col>)
    
    
    });

    if (mealsUI.length > 0){
      return  mealsUI;
    } else {
      return (<div className="m-10">
          {trans.nutrition_no_user_meals}
        </div>)
    }

  }

  const selectedDayChanged = (val) =>{
    setSelectedDate(val);
  };

  return (<div>
          <Card>
            <CardHeader>
                
                <div class="row align-items-center">
                          <div class="col-md-9">
                            <div style={{display:'flex'}}>
                              <h5 style={{color:'#757575'}} className="card-title font-weight-bold">{nutritionUserMealData != null ? `${trans.nutrition_calories_today}:` : ""}</h5>
                              &nbsp;
                              <h5 className="card-title font-weight-bold" >{nutritionUserMealData != null ? `${nutritionUserMealData.caloriesThisDay}kcal` : ""}</h5>
                            </div>
                          </div>
                          <div class="col-md-3">
                           
                      
                          <DatePicker  
                                          id="dashboardDatePicker"
                                          dateFormat="EEEE, d MMM yyyy "
                                          className="form-control digits" 
                                          selected={selectedDate} 
                                          disabledKeyboardNavigation={true}
                                          onChange={selectedDayChanged} />
                            
                          </div>
                      </div>
            </CardHeader>
            <CardBody>
              <Row id="nutritionUserMeals">
                  {getNutritionMealsUI()}
              </Row>  

            </CardBody>
        </Card>
       </div>);


}

export default NutritionUserMealList;