import './faq.css';
import React, { Fragment } from 'react';
import {Container,Button} from 'reactstrap'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Faq from "react-faq-component";
import { useParams } from 'react-router';

const styles = {
   bgColor: '#141416',
  titleTextColor: "white",
  rowTitleColor: "yellow",
   rowContentColor: 'white',
   arrowColor: "white",
};

const config = {
  // animate: true,
  // arrowIcon: "V",
  // tabFocus: true
};


const FaqPage = (props) => {
  const trans = useSelector(({translation}) => translation.translationsData);


  var selectedLang = "";
  let { lang } = useParams();
  if (lang != null){
    selectedLang = lang;
  }


  let data = {
    title: trans.settings_faq,
    rows: [
        // {
        //     title: "How do I use the ATPLab App?",
        //     content: `*VIDEO OF GUIDANCE`,
        // },
        {
            title: "What about the FITNESS",
            content:
            <div>
            <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>
            Fitness is an effective and easy to follow training schedule that you can do in the gym, your house, your favourite outdoor place or wherever you might feel like sweating it out. How is this possible?</span>
            </p>
            <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
            <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>We provide you with 4 programs to choose from depending on your goals. Each one contains different exercises and equipment so that you can select the one that better suits your needs each and every day. If you are a strictly oriented individual concerning training, you can commit to one of the programs. If you are excited about following entirely different routines each day, then you can freely switch among the given programs based on your mood and training environment. All are created to be exciting, make you fitter, boost your mood and improve your looks.</span></p>
            <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
            <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>GYM: this program is all about training in a gym. Weights like barbells, dumbbells, bands and everything that is provided in a commercial gym form the basis of this option. You can create the fit and strong body you ever dreamed off through bodybuilding exercises, in the gym of your neighbourhood. </span></p>
            <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
            <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>HOME: the format and exercises of this program are developed to be performed anywhere you want. Bodyweight exercises, movements with basic equipment like bands and mats, and core training are the basis of this option and will allow you to train at your home or at your favourite outdoor place.</span></p>
            <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
            <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>CROSS: the basis of this program is functional training. Olympic weightlifting, Gymnastic exercises, Workouts and HIIT sessions will make you stronger, fitter and create a functional body capable of anything.</span></p>
            <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
            <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>ABS &amp; GLUTES: this option is created to sculpt the six-pack that every man wants and the glutes every woman desires. It can be a suitable addition to each of the Gym/Home/Cross programs that will boost development of these specifically targeted body areas.</span></p>
            <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
            <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>Our mobile APP creates the best possible conditions for you to train and improve each and every day.</span></p>
          </div>,
        },
        {
            title: "Who is FITNESS for?",
            content:  <div>
            <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>Fitness Training is for anyone who wants to be healthier, more active and needs flexibility in their training routine and a quality training program to complete fast and efficiently during their daily schedule.</span></p>
          </div>,
        },
        {
            title: "What do I get with FITNESS?",
            content: <div>
            <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>Access to ATPLab APP</span></p>
            <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>Access to the video library</span></p>
            <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>4 training programs</span></p>
            <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>5 training sessions per week</span></p>
            <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>3 portions per session</span></p>
            <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>Video Notes &amp; options</span></p>
            <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>Training resources (Aerobic capacity, Swimming)</span></p>
          </div>,
        },
        {
          title: "What about the PERFORM?",
          content: <div>
          <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>Perform Training is a combination of strength and conditioning sessions. Power, speed, accuracy, flexibility, cardio endurance are some of the physical skills to be improved and all these through amazing training sessions that will make you feel better than ever.</span></p>
          <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
          <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>The volume and structure are developed to improve the capabilities of individuals regardless of their fitness level. The program is fundamentally excellent for making you fitter and the demanding yet exciting weekly routine will keep you daily motivated and eager to train. </span></p>
          <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
          <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>Our mobile APP creates the best possible conditions for you to reach the higher level. Direct communication with the coaches and co-athletes, live leaderboard, full training record are only some of the benefits.</span></p>
        </div>,
      },
      {
          title: "Who is PERFORM for?",
          content: <div>
          <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>Perform Training is for individuals who are already engaged in fitness, are familiar with functional training and want to upgrade their training routine with a more exciting and higher quality training program.</span></p>
        </div>,
      },
      {
        title: "What do I get with PERFORM?",
        content:  <div>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>Access to ATPLab APP</span></p>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>Access to the video library</span></p>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>5 training sessions per program</span></p>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>4 portions per session</span></p>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>Notes &amp; options</span></p>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>Training resources (Strength cycle, Aerobic capacity, Weightlifting cycle, Gymnastics cycle)</span></p>
      </div>,
      },
      {
        title: "What about the ATHLETE?",
        content:  <div>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>To be an Athlete means exceeding your limits on a daily basis and constantly re-evaluating what you are truly capable of.</span></p>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>In ATPLab Training we help you develop the right mindset; that of a champion. Each day consists of six elements in this order: Warm-up, Weightlifting, Conditioning, Accessory training, Strength &amp; Gymnastics, Engine. Each element is constantly varied according to a full season training schedule, specifically designed to reveal your full athletic potential. This will be followed by one day of active recovery and one rest day.</span></p>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>Our mobile APP creates the best possible conditions for you to reach the highest level. Direct communication with the coaches and co-athletes, live leaderboard, full training record are only some of the benefits.</span></p>
      </div>,
      },
      {
        title: "Who is ATHLETE for?",
        content:  <div>
                <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>Athlete Training is targeted towards those who aim to participate in local competitions, the Open or Semifinal events and fight for a position in the Games. It is a full scale, high volume training program made for athletes who want to compete at the highest level. Nevertheless, one doesn’t have to be a professional athlete in order to follow it. The elasticity of the structure and the numerous given options make it suitable for even the most novice competitor to adjust, work properly and improve drastically.</span></p>
        </div>,
      },
      {
        title: "What do I get with ATHLETE?",
        content:    <div>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>Access to ATPLab APP</span></p>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>Access to the video library</span></p>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>Training resources (Swimming, Aerobic capacity, Strength cycle, Weightlifting cycle, Gymnastics cycle, Competition week, Deload week, Mindset)</span></p>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>20% off to training camps</span></p>
      </div>,
      },
      {
        title: "What equipment do Ι need for the ATHLETE and the PERFORM Training?",
        content:    <div>
         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>All you need is access to equipment provided by any standard Box or Functional Fitness Facility such as Assault bike, GHD Machine, Dumbbells, D-balls, Row machine, Kettlebells etc. Additionally, for the “Athlete” a wide variety of ergometric machines might be needed.</span></p>
      </div>,
      },
      {
        title: "How do I choose which training program is for me?",
        content:     <div>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>You can watch the introductory videos of each training program either in the “learn more section” of each program on the website or in the main section of the ATPLab APP.</span></p>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>It all comes down to what your goals are, what you want to focus on and how much time you can invest in your training</span></p>
      </div>,
      },
      {
        title: "How do I get my training program?",
        content:       <div>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>All accessible by your subscription training programs will be available in the ATPLab APP &amp; web platform.</span></p>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>Once you download the ATPLab APP create an account and purchase one of the programs, you will get a confirmation mail and a receipt to your subscribed mail. Go in ATPLab app or visit the online platform and use your mail and the password to log in. Your training plan will be visible on your dashboard.</span></p>
      </div>,
      },
      {
        title: "When to expect my training program?",
        content:    <div>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>The weekly program is online every Sunday evening, 19:00 GREECE TIME, UTC + 2.</span></p>

      </div>,
      },
      {
        title: "How does billing work?",
        content:    <div>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>Membership is activated once you register at ATPLab Training and subscribe in a training program. Upon completion of your purchase, your subscription will be valid for 30 calendar days. During this period you will have unlimited access to your training program. Your subscription will renew automatically. Should you wish to stop following our training programs cancel your subscription in your profile.</span></p>
      </div>,
      },
      {
        title: "Where can I refer to if I face a problem?",
        content:    <div>
             <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>You can contact the management by email&nbsp;</span><a href="mailto:info@atplabtraining.com"><span style={{fontFamily: 'Helvetica', color: '#DCA10D', textDecoration: 'none'}}>info@atplabtraining.com</span></a></p>
      </div>,
      },
      {
        title: "How do I interact with the team?",
        content:    <div>
              <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>The ATPLab APP gives you unlimited communication with the team and your co-athletes. You can chat with the coaches, post your scores, results and comments and those will be directly uploaded and saved to your journal where coaches can review.</span></p>
      </div>,
      },
      // {
      //   title: "How does the facebook group work?",
      //   content:    <div>
      //      <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>The facebook group is an easily accessible way to see any news or announcements post by the team concerning the program, other external events or updates.</span></p>
      // </div>,
      // },
    ],
};


 if (selectedLang == "el"){ 

  data = {
    title: trans.settings_faq,
    rows: [
        // {
        //     title: "Πώς χρησιμοποιώ την εφαρμογή ATPLab",
        //     content: `*ΒΙΝΤΕΟ ΟΔΗΓΗΣΗΣ`,
        // },
        {
            title: "Σχετικά με το FITNESS;",
            content:
            <div>
            <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>Το Fitness είναι ένα αποτελεσματικό και εύκολο να ακολουθηθεί πρόγραμμα προπόνησης που μπορείτε να κάνετε στο γυμναστήριο, στο σπίτι σας, στον αγαπημένο σας υπαίθριο χώρο ή όπου θέλετε να γυμναστείτε. Πώς είναι αυτό δυνατόν?</span></p>
            <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
            <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>Σας παρέχουμε 4 προγράμματα για να διαλέξετε ανάλογα με τους στόχους σας. Κάθε ένα από αυτά περιέχει διαφορετικές ασκήσεις και εξοπλισμό, ώστε να μπορείτε να επιλέξετε αυτό που ταιριάζει καλύτερα στις ανάγκες σας κάθε μέρα. Εάν είστε αυστηρά προσανατολισμένοi όσον αφορά την προπόνησή σας, μπορείτε να ακολουθήσετε αυστηρά ένα από τα προγράμματα. Εάν ενθουσιάζεστε με το να ακολουθείτε εντελώς διαφορετικά προπονητικά στυλ κάθε μέρα, τότε μπορείτε ελεύθερα να αλλάζετε μεταξύ των συγκεκριμένων προγραμμάτων με βάση τη διάθεσή σας και τον χώρο προπόνησης σας. Όλα έχουν δημιουργηθεί για να είναι συναρπαστικά, να σας κρατούν σε φόρμα, να αυξάνουν τη διάθεσή σας και να βελτιώνουν την εμφάνισή σας.</span></p>
            <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
            <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>GYM: αυτό το πρόγραμμα σχετίζεται με την προπόνηση σε γυμναστήριο. Βάρη όπως μπάρα και αλτήρες, λάστιχα και ό, τι παρέχεται σε ένα κλασσικό γυμναστήριο αποτελούν τη βάση αυτής της επιλογής. Μπορείτε να δημιουργήσετε το αθλητικό και δυνατό σώμα που έχετε ονειρευτεί μέσω ασκήσεων bodybuilding, στο γυμναστήριο της γειτονιάς σας.</span></p>
            <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
            <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>HOME: η μορφή και οι ασκήσεις αυτού του προγράμματος έχουν φτιαχτεί για να εκτελούνται οπουδήποτε θέλετε. Ασκήσεις σωματικού βάρους, κινήσεις με βασικό εξοπλισμό όπως λάστιχα, στρώματα και προπόνηση κοιλιακών αποτελούν τη βάση αυτής της επιλογής και θα σας επιτρέψουν να προπονηθείτε στο σπίτι σας ή στον αγαπημένο σας υπαίθριο χώρο.</span></p>
            <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
            <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>CROSS: η βάση αυτού του προγράμματος είναι η λειτουργική προπόνηση. Ολυμπιακή άρση βαρών, ενόργανη γυμναστική, workout και προπονήσεις υψηλής έντασης θα σας κάνουν πιο δυνατούς, πιο αθλητικούς και θα δημιουργήσουν ένα λειτουργικό σώμα ικανό για τα πάντα.</span></p>
            <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
            <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>ABS &amp; GLUTES: η επιλογή αυτή έχει δημιουργηθεί για να σας βοηθήσει να σμιλεύσετε τους κοιλιακούς που κάθε άνδρας επιθυμεί και τους γλουτούς που κάθε γυναίκα ονειρεύεται. Μπορεί να αποτελέσει μια ιδανική προσθήκη σε οποιοδήποτε από τα προγράμματα Gym/Home/Cross η οποία να ενισχύσει την βελτίωση των συγκεκριμένων περιοχών του σώματός σας.</span></p>
            <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
            <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>Η εφαρμογή μας για κινητά δημιουργεί τις καλύτερες δυνατές συνθήκες για να προπονήστε και να βελτιώνεστε κάθε μέρα!</span></p>
            <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
          </div>,
        },
        {
            title: "Σε ποιους απευθύνεται το FITNESS;",
            content:  <div>
              <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>Το Fitness είναι για όποιον θέλει να είναι πιο υγιής, πιο δραστήριος και χρειάζεται ευελιξία στην προπονητική του ρουτίνα και ένα ποιοτικό πρόγραμμα προπόνησης το οποίο μπορεί να ολοκληρωθεί γρήγορα και αποτελεσματικά κατά τη διάρκεια του καθημερινού του προγράμματος.</span></p>
          </div>,
        },
        {
            title: "Τι παίρνω με το FITNESS;",
            content:   <div>
            <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>Πρόσβαση στην εφαρμογή ATPLab</span></p>
            <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>Πρόσβαση στη βίντεο</span><span style={{fontFamily: 'Helvetica'}}>-</span><span style={{fontFamily: 'Helvetica'}}>βιβλιοθήκη&nbsp;</span></p>
            <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>4&nbsp;</span><span style={{fontFamily: 'Helvetica'}}>προπονητικά&nbsp;</span><span style={{fontFamily: 'Helvetica'}}>προγράμματα&nbsp;</span></p>
            <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>5 προπονήσεις ανά πρόγραμμα </span></p>
            <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>3 προπονητικά κομμάτια ανά προπόνηση</span></p>
            <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>Προπονητικές οδηγίες και επιλογές κλιμάκωσης των ασκήσεων</span></p>
            <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>Προπονητικές παροχές (Αερόβια ικανότητα, Κολύμβηση)</span></p>
          </div>,
        },
        {
          title: "Σχετικά με το PERFORM;",
          content:  <div>
          <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>Το Perform είναι ένας συνδυασμός προπόνησης δύναμης και καρδιοαναπνευστικής προπόνησης. Δύναμη, ταχύτητα, ακρίβεια, ευελιξία, καρδιοαντοχή είναι μερικές από τις φυσικές δεξιότητες που θα βελτιωθούν και όλα αυτά μέσα από εκπληκτικές προπονήσεις που θα σας κάνουν να νιώθετε καλύτερα από ποτέ.</span></p>
          <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
          <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>Ο όγκος και η δομή του προγράμματος έχουν σχεδιαστεί για να βελτιώνουν τις δυνατότητες των αθλούμενων ανεξάρτητα από το επίπεδο της φυσικής τους κατάστασης. Το πρόγραμμα είναι εκ βάσεως εξαιρετικό στο να σας διατηρεί σε φόρμα και το απαιτητικό αλλά συνάμα συναρπαστικό εβδομαδιαίο πλάνο προπόνησης θα σας κρατάει καθημερινά ορεξάτους και πρόθυμους να προπονηθείτε.</span></p>
          <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
          <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>Η εφαρμογή μας για κινητά δημιουργεί τις καλύτερες δυνατές συνθήκες για να φτάσετε στο υψηλότερο επίπεδο. Η άμεση επικοινωνία με τους προπονητές και τους συναθλητές, ο live πίνακας βαθμολογίας, το πλήρες ιστορικό προπόνησης είναι μόνο μερικά από τα οφέλη.</span></p>
        </div>,
      },
      {
          title: "Σε ποιους απευθύνεται το PERFORM;",
          content:  <div>
          <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>Το Perform είναι για αθλούμενους που ήδη ασχολούνται με τον αθλητισμό, είναι εξοικειωμένοι με τη λειτουργική προπόνηση και θέλουν να αναβαθμίσουν το προπονητικό τους πλάνο με ένα πιο συναρπαστικό και ανώτερο ποιοτικά πρόγραμμα προπόνησης.</span></p>
          <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
        </div>,
      },
      {
        title: "Τι παίρνω με το PERFORM;",
        content:   <div>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>Πρόσβαση στην εφαρμογή ATPLab</span></p>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>Πρόσβαση στη βίντεο-βιβλιοθήκη</span></p>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>5 προπονήσεις ανά πρόγραμμα</span></p>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>4 προπονητικά κομμάτια ανά προπόνηση</span></p>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>Προπονητικές οδηγίες και επιλογές κλιμάκωσης των ασκήσεων</span></p>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>Προπονητικές παροχές (Κύκλος δύναμης, Αερόβια ικανότητα, Κύκλος άρσης βαρών, Κύκλος ενόργανης γυμναστικής)</span></p>
      </div>,
      },
      {
        title: "Σχετικά με το ATHLETE;",
        content:  <div>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>Το να είσαι Αθλητής σημαίνει να ξεπερνάς τα όριά σου σε καθημερινή βάση και να επαναξιολογείς συνεχώς αυτό που πραγματικά μπορείς να καταφέρεις.</span></p>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>Στην ATPLab Training σας βοηθάμε να αναπτύξετε τη σωστή νοοτροπία. αυτή του πρωταθλητή. Κάθε μέρα αποτελείται από έξι κομμάτια με αυτή τη σειρά: Warm-up, Weightlifting, Conditioning, Accessory training, Strength &amp; Gymnastics, Engine. Κάθε στοιχείο μεταβάλλεται συνεχώς σύμφωνα με έναν αθλητικό προγραμματισμό προπόνησης πλήρους σεζόν, ειδικά σχεδιασμένο για να εμφανίσει τις πλήρεις αθλητικές σας δυνατότητες. Ακολουθεί μία ημέρα ενεργητικής ανάρρωσης και μία ημέρα ξεκούρασης.</span></p>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>Η εφαρμογή μας για κινητά δημιουργεί τις καλύτερες δυνατές συνθήκες για να φτάσετε στο υψηλότερο επίπεδο. Η άμεση επικοινωνία με τους προπονητές και τους συναθλητές, ο live πίνακας βαθμολογίας, το πλήρες ιστορικό προπόνησης είναι μόνο μερικά από τα οφέλη.</span></p>
      </div>,
      },
      {
        title: "Σε ποιους απευθύνεται το ATHLETE;",
        content:  <div>
   <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>Το Athlete απευθύνεται σε όσους στοχεύουν να συμμετάσχουν σε τοπικούς διαγωνισμούς, τα Open ή σε Sanctioned events και να αγωνιστούν για μια θέση στα Games. Eίναι ένα πρόγραμμα προπόνησης υψηλής έντασης και όγκου, σχεδιασμένο για αθλητές που θέλουν να αγωνιστούν στο υψηλότερο επίπεδο. Παρ 'όλα αυτά, δεν χρειάζεται να είναι κανείς επαγγελματίας αθλητής για να το ακολουθήσει. Η ελαστικότητα της δομής του και οι πολυάριθμες επιλογές κλιμάκωσης που έχει το καθιστούν κατάλληλο ακόμη και για έναν αρχάριο ώστε να προσαρμοστεί, να δουλέψει σωστά και να βελτιωθεί δραστικά.</span></p>        </div>,
      },
      {
        title: "Τι παίρνω με το ATHLETE;",
        content:     <div>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>Πρόσβαση στην εφαρμογή ATPLab</span></p>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>Πρόσβαση στη βίντεο-βιβλιοθήκη </span></p>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>Προπονητικές παροχές (Κολύμβηση, Αερόβια ικανότητα, Κύκλος δύναμης, Κύκλος άρσης βαρών, Κύκλος  Ενόργανης Γυμναστικής, Εβδομάδα Προετοιμασίας Αγώνα, Εβδομάδα Deload, Νοοτροπία)</span></p>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>Έκπτωση 20% στα Προπονητικά Camp</span></p>
      </div>,
      },
      {
        title: "Τι εξοπλισμό χρειάζομαι για το ATHLETE και το PERFORM;",
        content:    <div>
          <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>Το μόνο που χρειάζεστε είναι πρόσβαση στον εξοπλισμό που παρέχεται από ένα τυπικό Box ή Functional Fitness Facility, όπως ποδήλατο Assault, GHD Machine, Dumbbells, D-balls, Row machine, Kettlebells κ.λπ. Επιπλέον για το “Athlete“, μπορεί να χρειαστεί μια ποικιλία εργομετρικών μηχανημάτων.</span></p>
      </div>,
      },
      {
        title: "Πώς μπορώ να επιλέξω ποιο πρόγραμμα προπόνησης είναι για μένα;",
        content:    <div>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>Μπορείτε να παρακολουθήσετε τα εισαγωγικά βίντεο κάθε προγράμματος είτε στην ενότητα «learn more» κάθε προγράμματος στον ιστότοπο είτε στην κύρια ενότητα της εφαρμογής ATPLab.</span></p>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>Όλα εξαρτώνται από τους στόχους σας, σε τι θέλετε να εστιάσετε και πόσο χρόνο μπορείτε να επενδύσετε στην προπόνησή σας.</span></p>
      </div>,
      },
      {
        title: "Πώς μπορώ να αποκτήσω το πρόγραμμα προπόνησης μου;",
        content:        <div>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>Όλα τα προσβάσιμα προγράμματα που αποκτάτε με την συνδρομή σας θα είναι διαθέσιμα στην εφαρμογή ATPLab ή στην web πλατφόρμα.</span></p>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>Μόλις κατεβάσετε την εφαρμογή ATPLab δημιουργήστε έναν λογαριασμό και αγοράσετε ένα από τα προγράμματα, θα λάβετε ένα μήνυμα επιβεβαίωσης και μια απόδειξη στην εγγεγραμμένη αλληλογραφία σας. Μπείτε στην εφαρμογή ATPLab ή επισκεφτείτε την ηλεκτρονική πλατφόρμα και χρησιμοποιήστε το όνομα αλληλογραφία σας και τον κωδικό πρόσβασης για να συνδεθείτε. Το πλάνο προπόνησής σας θα είναι ορατό στον πίνακα ελέγχου.</span></p>
      </div>,
      },
      {
        title: "Πότε να περιμένω το πρόγραμμα προπόνησης μου;",
        content:    <div>
         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>Το εβδομαδιαίο πρόγραμμα είναι online κάθε Κυριακή βράδυ, 19:00 ΩΡΑ ΕΛΛΑΔΑΣ, UTC + 2.</span></p>
      </div>,
      },
      {
        title: "Πώς λειτουργεί η χρέωση;",
        content:    <div>
        <div>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>Η ιδιότητα ενεργού μέλους ενεργοποιείται μόλις εγγραφείτε στο ATPLab Training και γίνεται συνδρομητής σε ένα από τα προγράμματα. Με την ολοκλήρωση της αγοράς σας, η συνδρομή σας θα ισχύει για 30 ημέρες. Κατά τη διάρκεια αυτής της περιόδου θα έχετε απεριόριστη πρόσβαση στο πρόγραμμα σας. Η συνδρομή σας θα ανανεωθεί αυτόματα. Εάν θέλετε να σταματήσετε να ακολουθείτε τα προγράμματα, ακυρώστε τη συνδρομή σας στο προφίλ σας.</span></p>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
      </div>
      </div>,
      },
      {
        title: "Πού μπορώ να επικοινωνήσω αν αντιμετωπίσω κάποιο πρόβλημα;",
        content:    <div>
             <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>Μπορείτε να επικοινωνήσετε με τη διεύθυνση μέσω email&nbsp;</span><a href="mailto:info@atplabtraining.com"><span style={{fontFamily: 'Helvetica', color: '#DCA10D', textDecoration: 'none'}}>info@atplabtraining.com</span></a></p>
      </div>,
      },
      {
        title: "Πώς αλληλεπιδρώ με την ομάδα;",
        content:    <div>
              <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>Το ATPLab App σας δίνει απεριόριστη επικοινωνία με την ομάδα και τους συναθλητές σας. Μπορείτε να συνομιλήσετε με τους προπονητές, να δημοσιεύσετε τις βαθμολογίες, τα αποτελέσματα και τα σχόλιά σας και αυτά θα φορτωθούν απευθείας και θα αποθηκευτούν στο ημερολόγιό σας, όπου οι προπονητές θα μπορούν να τα δουν και να τα εκτιμήσουν.</span></p>
      </div>,
      },
      // {
      //   title: "Πώς λειτουργεί η ομάδα στο facebook;",
      //   content:    <div>
      //      <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>Η ομάδα στο facebook είναι ένας εύκολα προσβάσιμος τρόπος για να δείτε οποιεσδήποτε ειδήσεις ή ανακοινώσεις από την ομάδα σχετικά με το πρόγραμμα, άλλα εξωτερικά γεγονότα ή ενημερώσεις.</span></p>
      // </div>,
      // },
    ],
};

 }
    
return (
  <div className="_faq_container">
    <div style={{margin:'19px'}}>
      <Faq
            data={data}
            styles={styles}
            config={config}
        />
    </div>
     
  </div>
);

//     return (
// <div style={{margin:'19px'}}>
// <div>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><strong><span style={{fontFamily: 'Helvetica'}}>FAQs</span></strong></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><strong><span style={{fontFamily: 'Helvetica'}}>How do I use the ATPLab App?</span></strong></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><strong><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></strong></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>*VIDEO OF GUIDANCE</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><strong><span style={{fontFamily: 'Helvetica'}}>What about the A45?</span></strong></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><strong><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></strong></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>A45&nbsp;is an&nbsp;effective and easy to follow 45-minute training schedule that you can do in the gym, your house, your favourite outdoor place or wherever you might feel like sweating it out.&nbsp;How is this possible?</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>We provide you with 3 programs to choose from depending on your goals. Each one contains different exercises and equipment so that you can select the one that better suits your needs each and every day. If you are a strictly oriented individual concerning training, you can commit to one of the programs. If you are excited about following entirely different routines each day, then you can freely switch among the given programs based on your mood and training environment. All are created to be&nbsp;exciting, make you fitter, boost your mood and improve your looks.</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>HOME/OUTDOOR: the format and exercises of this program are developed to be performed anywhere you want. Bodyweight exercises,</span><strong><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></strong><span style={{fontFamily: 'Helvetica'}}>movements with basic equipment like bands</span><strong><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></strong><span style={{fontFamily: 'Helvetica'}}>and mats,</span><strong><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></strong><span style={{fontFamily: 'Helvetica'}}>and core training are the basis of this option and will allow you to train at your home or at your favourite outdoor place.</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>CROSS: the basis of this program is functional training. Olympic weightlifting, Gymnastic exercises, Workouts and HIIT sessions will make you stronger, fitter and create a functional body capable of anything.</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>GYM: this program is all about training in a gym. Weights like barbells, dumbbells, bands and everything that is provided in a commercial gym form the basis of this option. You can create the fit and strong body you ever dreamed off through bodybuilding exercises, in the gym of your neighbourhood.&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>Our mobile APP creates the best possible conditions for you to train and improve each and every day.</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>Train with us anytime you want, anywhere you want!</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', marginBottom: '2.0pt', textAlign: 'justify'}}><strong><span style={{fontFamily: 'Helvetica'}}>Who is A45 for?</span></strong></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', marginBottom: '2.0pt', textAlign: 'justify'}}><strong><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></strong></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>A45 is for anyone who wants to be healthier,&nbsp;more active and needs flexibility in their training routine and a quality training program to complete fast and efficiently during their daily schedule.</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><strong><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></strong></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', marginBottom: '2.0pt', textAlign: 'justify'}}><strong><span style={{fontFamily: 'Helvetica'}}>What do I get with A45?</span></strong></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>Access to ATPLab APP</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>Access to the video library</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>3 training programs</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>5 training sessions per week</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>3 portions per session</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>Video Notes &amp; options</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>Training resources (Aerobic capacity, Swimming)</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><strong><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></strong></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><strong><span style={{fontFamily: 'Helvetica'}}>What about the A90?</span></strong></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>Based on Αndrea’s Training routine, A90 is a combination of strength training and conditioning workouts. Power, speed, accuracy, flexibility, cardio endurance are some of the physical skills to be improved and all these through amazing and fun-to-do sessions that will make you feel better than ever.&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>The volume and structure are made to improve the capabilities of athletes regardless of their fitness level. While the program is excellent for making you fitter, Apostolaras is very thorough on providing a fun and exciting training routine that will keep you daily motivated and eager to train.&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>Our mobile APP creates the best possible conditions for you to reach the higher level. Direct communication with the coaches and co-athletes, live leaderboard, full training record are only some of the benefits.</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><strong><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></strong></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', marginBottom: '2.0pt', textAlign: 'justify'}}><strong><span style={{fontFamily: 'Helvetica'}}>Who is A90 for?</span></strong></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', marginBottom: '2.0pt', textAlign: 'justify'}}><strong><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></strong></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>A90 is for athletes who are already engaged in fitness, are familiar with functional training and want to upgrade their training routine with a more exciting and quality training program.</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><strong><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></strong></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', marginBottom: '2.0pt', textAlign: 'justify'}}><strong><span style={{fontFamily: 'Helvetica'}}>What do I get with A90?</span></strong></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', marginBottom: '2.0pt', textAlign: 'justify'}}><strong><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></strong></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>Access to ATPLab APP</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>Access to the video library</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>5 training sessions per week</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>4 portions per session</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>Notes &amp; options</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>Training resources (Strength cycle, Aerobic capacity, Weightlifting cycle, Gymnastics cycle)</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><strong><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></strong></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><strong><span style={{fontFamily: 'Helvetica'}}>What about the ATHLETES?</span></strong></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><strong><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></strong></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>To be an Athlete means exceeding your limits on a daily basis and constantly re-evaluating what you are truly capable of.&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>In ATPLab Training we help you develop the right mindset; that of a champion. Each day consists of six elements in this order: Warm-up, Weightlifting, Conditioning, Accessory training, Strength &amp; Gymnastics, Engine.&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>Each element is constantly varied according to a full season training schedule, specifically designed to reveal your full athletic potential. This will be followed by one day of active recovery and one rest day. Our mobile APP creates the best possible conditions for you to reach the highest level. Direct communication with the coaches and co-athletes, live leaderboard, full training record are only some of the benefits.</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><strong><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></strong></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', marginBottom: '2.0pt', textAlign: 'justify'}}><strong><span style={{fontFamily: 'Helvetica'}}>Who is ATHLETES for?</span></strong></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', marginBottom: '2.0pt', textAlign: 'justify'}}><strong><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></strong></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>Athletes is targeted towards those who aim to participate in local competitions, the Open or Semifinal Events and fight for a position in the Games. It is a full scale, high volume, interactive training program made for athletes who want to compete at the highest level. Nevertheless, one doesn’t have to be a professional athlete in order to follow it. The elasticity of the structure and the numerous given options make it suitable for even the most novice competitor to adjust, work properly and improve drastically.</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><strong><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></strong></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', marginBottom: '2.0pt', textAlign: 'justify'}}><strong><span style={{fontFamily: 'Helvetica'}}>What do I get with ATHLETES?</span></strong></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', marginBottom: '2.0pt', textAlign: 'justify'}}><strong><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></strong></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>Access to ATPLab APP</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>Access to the video library</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>Training resources (Swimming, Aerobic capacity, Strength cycle, Weightlifting cycle, Gymnastics cycle, Competition week, Deload week, Mindset)</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>20% off to training camps</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><strong><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></strong></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><strong><span style={{fontFamily: 'Helvetica'}}>What equipment do&nbsp;</span></strong><strong><span style={{fontFamily: 'Helvetica'}}>Ι</span></strong><strong><span style={{fontFamily: 'Helvetica'}}>&nbsp;need for the ATHLETES and the A90?</span></strong></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><strong><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></strong></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>All you need is access to equipment provided by any standard Box or Functional Fitness Facility such as Assault bike, Ghd Machine, Dumbbells, D-balls, Row machine, Kettlebells etc. Additionally for the ATHLETES, ideally, a wide variety of ergometric machines might be needed.</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><strong><span style={{fontFamily: 'Helvetica'}}>How do I choose which training program is for me?</span></strong></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><strong><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></strong></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>You can watch the introductory videos of each training program either in the “learn more section” of each program on the main site or in the main section of the ATPLab APP.</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>It all comes down to what your goals are, what you want to focus on and how much time you can invest in your training</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><strong><span style={{fontFamily: 'Helvetica'}}>How do I get my training program?</span></strong></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><strong><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></strong></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>All accessible by your subscription training programs will be available in the ATPLab APP &amp; web platform.</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>Once you download the ATPLab APP create an account and purchase one of the programs,</span><span style={{fontFamily: 'Helvetica'}}>&nbsp;you will get a confirmation mail&nbsp;</span><span style={{fontFamily: 'Helvetica'}}>and a receipt</span><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span><span style={{fontFamily: 'Helvetica'}}>to your subscribed mail. Go in ATPLab app or visit the online platform and use your mail and the password to log in. Your training plan will be visible on your dashboard.</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><strong><span style={{fontFamily: 'Helvetica'}}>When to expect my training program?</span></strong></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><strong><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></strong></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>The weekly program is online every Sunday evening, 19:00 GREECE TIME, UTC + 2.</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><strong><span style={{fontFamily: 'Helvetica'}}>How does billing work?</span></strong></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><strong><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></strong></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>Membership is activated once you register at ATPLab APP and subscribe in a training program. Upon completion of your purchase, your subscription will be valid for 30 calendar days. During this period you will have unlimited access to your training program.&nbsp;</span><span style={{fontFamily: 'Helvetica'}}>Your subscription will renew automatically.</span><span style={{fontFamily: 'Helvetica'}}>&nbsp;Should you wish to stop following our training programs cancel your subscription in your profile.</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><strong><span style={{fontFamily: 'Helvetica'}}>Where can I refer to if I face a problem?</span></strong></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><strong><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></strong></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>You can contact the management by email&nbsp;</span><a href="mailto:info@atplabtraining.com"><span style={{fontFamily: 'Helvetica', color: '#DCA10D', textDecoration: 'none'}}>info@atplabtraining.com</span></a></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><strong><span style={{fontFamily: 'Helvetica'}}>How do I interact with the team?</span></strong></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><strong><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></strong></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>The ATPLab APP gives you unlimited communication with the team and your co-athletes. You can chat with the coaches, post your scores, results and comments and those will be directly uploaded and saved to your journal where coaches can review.</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><strong><span style={{fontFamily: 'Helvetica'}}>How does the facebook group work?</span></strong></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><strong><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></strong></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>The facebook group is an easily accessible way to see any news or announcements post by the team concerning the program, other external events or updates.</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
//         <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Helvetica'}}>&nbsp;</span></p>
//       </div>
//     </div>
//     )

}

export default FaqPage;