import  './trainings.css';
import React,{useEffect, useState} from 'react';
import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Form, FormGroup, Input, Label, Button,NavItem, NavLink, Nav,TabContent,TabPane, Breadcrumb, Card, CardHeader, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DropIn from "braintree-web-drop-in-react";
import { getClientToken, getSubPlan } from '../../redux/subscription/action';
import { FETCH_CLIENT_TOKEN, GET_SUBSCRIPTION } from '../../redux/actionTypes';
import { toast } from 'react-toastify';
import SweetAlert from 'sweetalert2'
import { Loader } from 'react-bootstrap-typeahead';
import LoaderBox from '../../components/reuse/loaderbox';
import { PAYMENT_TYPE_BRAINTREE, PAYMENT_TYPE_STRIPE } from '../../constant';

//STRIPE
import {CardElement, ElementsConsumer,Elements, useStripe, useElements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

const GetTrainingModal = (props) => {

   const dispatch = useDispatch();

    const {getTrainingItem, onGetTrainingModalClose, open} = props;
  
  
    const settingsData = useSelector(({settings}) => settings.settingsData);
    const trans = useSelector(({translation}) => translation.translationsData);

    const clientTokenData = useSelector(({subscription}) => subscription.clientTokenData);
    const getSubData = useSelector(({subscription}) => subscription.getSubData);

    const [clientToken, setGetClientToken] = useState(null);

    const [isPaymentLoading, setIsPaymentLoading] = useState(false);

    const [isMonthly, setIsMonthly] = useState(true);

    const [clientTokenHasError, setClientTokenHasError] = useState(false);
    const [clientTokenMessage, setClientTokenMessage] = useState("");
    const [stripeCusID, setStripeCusID] = useState("");

    let newInstance = null;

 

    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
      if (open){
        getClientTokenData();
       
      }
    }, [open]);

    useEffect(() => {
      if (clientTokenData){
        console.log();
        setGetClientToken(clientTokenData.clientToken);
        setClientTokenMessage(clientTokenData.message);
        setClientTokenHasError(clientTokenData.status == false && clientTokenData.message != "");
        setStripeCusID(clientTokenData.stripeCusID);
        dispatch({type: FETCH_CLIENT_TOKEN, payload: null});
      }
    }, [clientTokenData]);

    useEffect(() => {
      if (getSubData){
        if (getSubData != null) {
          setIsPaymentLoading(false);
          if (getSubData.status){
            toast.success(getSubData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
              props.onGetTrainingSubscribe();
              onGetTrainingModalClose();
          } else {
            toast.error(getSubData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
          }
        }
        dispatch({type: GET_SUBSCRIPTION, payload: null});
      }
    }, [getSubData]);
   
    const getClientTokenData = () => {
      let postData = {};
      dispatch(getClientToken(postData));
    }  

    const onDropInInstance = (instance) => {
      console.log("CHANGING INSTANCE");
      if (instance != null){
        newInstance = instance;
      }
    }

    const onBuySubscription =  async ()  => {
      SweetAlert.fire({
        title:trans.complete_transaction_title,
        text:trans.complete_transaction_desc,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText:trans.ok_btn,
        cancelButtonText: trans.cancel_btn,
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
            //OK
            completeTransaction();
        }
        else {

        }
      })
  
    }

    const completeTransaction =  async ()  => {
      let planId = getTrainingItem.plan_id;

        if (!isMonthly){
          planId = `${getTrainingItem.plan_id}_year`;
        }

      if (settingsData.settings.subSystem == PAYMENT_TYPE_BRAINTREE){
      //WAIT TO GET THE INSTANCE PAYMENT METHOD NONCE FROM THE THE BRAINTREE DROP IN
      const { nonce } = await newInstance.requestPaymentMethod();
     // console.log(nonce);
     //NONCE GOT
      if (nonce != null && nonce != "")
      {
  

        let postData = {
          paymentNonce: nonce, 
          planId: planId
        };
        setIsPaymentLoading(true);
        dispatch(getSubPlan(postData));
     }
    } else {

      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }
  
      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardElement = elements.getElement(CardElement);

       // Use your card Element with other Stripe.js APIs
      const {error, paymentMethod} = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {
        console.log('[error]', error);
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT
          });
        return;
      } else {
        console.log('[PaymentMethod]', paymentMethod);
      }

      //TODO CONNECT WITH SERVER
      let postData = {
        stripeCusID,
        paymentMethodId: paymentMethod.id,
        paymentNonce: "", 
        planId: planId
      };
      setIsPaymentLoading(true);
      dispatch(getSubPlan(postData));
    }
   }

   const selectPerType = (e,typeBool) => {
     e.preventDefault();
     setIsMonthly(typeBool);
     setGetClientToken(null);
     getClientTokenData();
   }

  return (
      <Modal isOpen={open} toggle={onGetTrainingModalClose} centered size="lg">
                      <ModalHeader toggle={onGetTrainingModalClose}>
                        <span className="font-weight-bold">{trans.get_training}</span> 
                      </ModalHeader>
                      {isPaymentLoading ?
                      <ModalBody>
                        <div >
                                  <LoaderBox/>
                                </div>
                      </ModalBody>
                      :
                      <ModalBody>
                        {clientTokenHasError ?
                        
                          <div>
                              <p>{clientTokenMessage}</p>
                          </div>
                        
                        : 

                        getTrainingItem != null ?   
                       
                        <Row>
                            <Col sm="6" md="6">
                              <Button onClick={(e) => {selectPerType(e,true)}} className={`_btn_training_pick_type_per ${isMonthly ? "_btn_training_pick_type_per_selected" : ""}`}>{trans.per_month}</Button>
                          </Col>
                          <Col sm="6" md="6">
                            <Button onClick={(e) => {selectPerType(e,false)}} className={`_btn_training_pick_type_per ${!isMonthly ? "_btn_training_pick_type_per_selected" : ""}`}>{trans.per_year}</Button>
                          </Col>
                          <Col sm="12" md="12">
                            <div className="_traning_info_container_modal">
                              <div style={{display:'flex',margin:'0px',padding:'0px'}}>
                                <p style={{display:'flex',margin:'0px',padding:'0px'}}>{trans.training_name}: </p>
                                <p className="font-weight-bold _training_info_text">{` ${getTrainingItem.title}`}</p>
                              </div>
                              <div style={{display:'flex',margin:'0px',padding:'0px'}}>
                                <p style={{display:'flex',margin:'0px',padding:'0px'}} >{trans.value_price}: </p>
                                <p style={{color:'red'}} className="font-weight-bold _training_info_text">{` ${isMonthly ? getTrainingItem.price : getTrainingItem.price_year}`}€</p>
                              </div>

                              <div style={{display:'flex',margin:'0px',padding:'0px'}}>
                                <p style={{display:'flex',margin:'0px',padding:'0px'}} >{trans.training_desc}: </p>
                                <p className="font-weight-bold _training_info_text">{` ${getTrainingItem.desc}`}</p>
                              </div>
                            </div>

                            <div className="_traning_info_container_modal">
                              <p className="font-weight-bold">{trans.subscribe_description}</p>
                            </div>
                          </Col>

                          {settingsData.settings.subSystem == PAYMENT_TYPE_BRAINTREE && clientToken != null  ?  <Col sm="12" md="12">
                              <div>
                                <DropIn
                                    options={{
                                      authorization: clientToken,
                                       paypal: { flow: 'vault' } }}
                                    onInstance={(instance) => onDropInInstance(instance)}
                                  />
                              </div>  
                          </Col> : null}

                          {settingsData.settings.subSystem == PAYMENT_TYPE_STRIPE ?  <Col sm="12" md="12">
                              <div className="_stripe_payment_bg">
                               <h6>{trans.sub_add_card_details}</h6>
                               <br/>
                                  <CardElement />
                                <br/>
                              </div>  
                          </Col> : null}
                         
                        </Row>   
                        :<div>
                           <div >
                                  <div className="loader-box" >
                                    <div className="loader-9"></div>
                                  </div>
                                </div>
                                </div>}
                      </ModalBody>
                      }
                      <ModalFooter>
                        <Button color="primary" disabled={isPaymentLoading || (settingsData.settings.subSystem == PAYMENT_TYPE_BRAINTREE ? clientToken == "" : stripeCusID == "")}  onClick={() => { onBuySubscription(); }}>{trans.submit_btn}</Button>
                      </ModalFooter>
                </Modal>
  );
}

export default GetTrainingModal;