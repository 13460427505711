import  './nutritionMeal.css';
import React,{useEffect, useState} from 'react';
import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Form, FormGroup, Input, Label, Button,NavItem, NavLink, Nav,TabContent,TabPane, Breadcrumb, Card, CardHeader, Modal, ModalHeader, ModalBody, ModalFooter,Media } from 'reactstrap';
import DatePicker from "react-datepicker";
import {getNutritionMenuTypes } from '../../../redux/nutrition/nutritionMenuType/action';
import { getNutritionMenu } from '../../../redux/nutrition/nutritionMenu/action';

import { postUploadImageFile } from '../../../redux/upload/action';
import JoditEditor from "jodit-react";
import { getNutritionPlans } from '../../../redux/nutrition/nutritionPlan/action';
  	
const config = {
  readonly: false ,
  iframe: true,
  video: true,
  height: 150
}

const AddNutritionMeal = (props) => {
    const dispatch = useDispatch();


    const {onSaveNutritionMeal, onAddNutritionMealClose, open, nutritionMealToEdit, isEditNutritionMeal} = props;
  

  
    const settingsData = useSelector(({settings}) => settings.settingsData);
    const trans = useSelector(({translation}) => translation.translationsData);
    const nutritionPlanData = useSelector(({nutritionPlan}) => nutritionPlan.fetchNutritionPlanData);
    const nutritionMenuData = useSelector(({nutritionMenu}) => nutritionMenu.fetchNutritionMenuData);
 
    
    const [langsTitlesArray,setLangTitlesArray] = useState([]); 
    const [langsContentArray,setLangContentArray] = useState([]); 

  
    //VALUES
    const [visible,setVisible] = useState(true); 

    const [menuId,setMenuId] = useState(null); 
    const [planId,setPlanId] = useState(null);
    const [menuCategories,setMenuCategories] = useState([]);
    const [plans,setPlans] = useState([]);
    const [calories,setCalories] = useState(0); 

    const [fixedMenus,setFixedMenus] = useState([]);

    useEffect(() => {
       if (open){
           clearData();
           var arrTitles = [];
           var arrContents = [];
           settingsData.settings.langs.map((item,index) => {
            arrTitles.push({langLoc: item.langLoc, text: ""});
            arrContents.push({langLoc: item.langLoc, text: ""});
           })
           
           if (isEditNutritionMeal){

            if (nutritionMealToEdit.menuId != null){
              setMenuId(nutritionMealToEdit.menuId.id)
            }

            if (nutritionMealToEdit.planId != null){
              setPlanId(nutritionMealToEdit.planId.id)
            }
            

            setVisible(nutritionMealToEdit.visible);

            setCalories(nutritionMealToEdit.calories);
          
            

              arrTitles.forEach((item,langIndex) => {
                  nutritionMealToEdit.titles.forEach(trans => {
                          if (trans.langLoc == item.langLoc){
                            arrTitles[langIndex].text = trans.text;
                          }
                      });
              });

              arrContents.forEach((item,langIndex) => {
                nutritionMealToEdit.content.forEach(trans => {
                        if (trans.langLoc == item.langLoc){
                          arrContents[langIndex].text = trans.text;
                        }
                    });
              });
            }

            setLangTitlesArray(arrTitles);
            setLangContentArray(arrContents);
        
            getPlansData();
            getMenusData();
             
       }
    }, [open]);

    useEffect(() => {
        if (nutritionPlanData != null){
          setPlans(nutritionPlanData.data)

          if (planId == "" || planId == null){
            setPlanId(nutritionPlanData.data[0].id)
          }
        }
     }, [nutritionPlanData]);

     useEffect(() => {
        if (nutritionMenuData != null){

          if (menuId == "" || menuId == null){
            setMenuId(nutritionMenuData.data[0].id)
          }
          
            var menuDataParents = [];
            var menuData = [];
        

            nutritionMenuData.data.forEach(element => {
                

                if (element.parent == null){
                  menuDataParents.push(element);
                } else {
                  menuData.push(element);
                }
                
            });

            var finalMenu = [];

            menuDataParents.forEach(element => {
              finalMenu.push({parent: element,items:[]});
            });

            console.log(finalMenu);
            console.log(menuData);

            finalMenu.forEach((parentMenu,index) => {
              menuData.forEach(innerMenu => {
                if (innerMenu.parent != null){
                  if (innerMenu.parent.id == parentMenu.parent.id){
                    finalMenu[index].items.push(innerMenu);
                  }
                }
             
              });
            });

            setFixedMenus(finalMenu);
            setMenuCategories(menuData)

            
        
        }
     }, [nutritionMenuData]);
    

    const getPlansData = () => {
        let postData = {}
        dispatch(getNutritionPlans(postData));
    }


    const getMenusData = () => {
        let postData = {}
        dispatch(getNutritionMenu(postData));
    }

  
    const clearData = () => {
        setLangTitlesArray([]);
        setVisible(true);
        setMenuId(null);
    }

    const setLangTitle = (e) => {
        let langIndex = e.target.id.replace("lang_value_title_","");
        langsTitlesArray[langIndex].text = e.target.value;
    }
    
    const setLangDescription = (value,index) => {
      langsContentArray[index].text = value;
    }


      
  return (
      <Modal isOpen={open} toggle={onAddNutritionMealClose} centered size="lg">
                      <ModalHeader toggle={onAddNutritionMealClose}>
                      {trans.nutrition_add_edit}
                      </ModalHeader>
                      <ModalBody>
                      <div className="form-row">
                                {/* <FormGroup className="col-md-12">
                                  <Label>{"NUMBER ID"}</Label>
                                  <Input value={numId} type="number" onChange={e => setNumId(e.target.value)}/>
                                </FormGroup> */}

                              <span className="font-weight-bold">{trans.nutrition_titles}</span>  
                                {langsTitlesArray.map((item,index) => {
                                    return (<FormGroup key={`trans_${index}`} className="col-md-12">
                                    <Label>{settingsData.settings.langs[index].name}</Label>
                                    <Input defaultValue={item.text} id={`lang_value_title_${index}`} onChange={(e)=> setLangTitle(e)} type="text"/>
                                  </FormGroup>)
                                })}

                            <span className="font-weight-bold">{trans.nutrition_contents}</span>  
                                {langsContentArray.map((item,index) => {
                                    return (<FormGroup key={`trans_${index}`} className="col-md-12">
                                    <Label>{settingsData.settings.langs[index].name}</Label>
                                    <JoditEditor
                                        value={item.text}
                                        config={config}
                                        tabIndex={1} // tabIndex of textarea
                                        onBlur={newContent => setLangDescription(newContent,index)} // preferred to use only this option to update the content for performance reasons
                                        onChange={newContent => {}} 
                                    />
                                    {/* <Input defaultValue={item.text} id={`lang_value_desc_${index}`} onChange={(e)=> setLangDescription(e)} type="text"/> */}
                                  </FormGroup>)
                                })}
                            <br/>

                            <FormGroup className="col-md-6">
                                    <Label>{trans.nutrition_calories}</Label>
                                    <Input value={calories} id={`calories`} onChange={(e)=> setCalories(e.target.value)} type="number"/>
                            </FormGroup>

                             <FormGroup className="col-md-6">
                                    <Label for="exampleSelect">{trans.visible}</Label>
                                    <Input className="custom-select" value={visible} onChange={(e)=> setVisible(e.target.value)} type="select" name="visible" id="visible">
                                    <option value={true}>{trans.value_yes}</option>
                                    <option value={false}>{trans.value_no}</option>
                                    </Input>
                                </FormGroup> 


                                <FormGroup className="col-md-6">
                                    <Label className="form-label" for="exampleSelect">{trans.nutrition_menu_category}</Label>
                                    <Input className="custom-select" value={menuId} onChange={(e)=> setMenuId(e.target.value)} type="select" name="menuId" id="menuId">
                                        {fixedMenus.map((item,index) => {
                                          return( <optgroup key={item.parent.id} label={item.parent.title}>
                                            {item.items.map((innerMenu,index) => {
                                              return(<option key={innerMenu.id} value={innerMenu.id}>{innerMenu.title}</option>)
                                            })}
                                        </optgroup>)

                                        
                                        })}
                                    </Input>
                                </FormGroup>

                                <FormGroup className="col-md-6">
                                    <Label className="form-label" for="exampleSelect">{trans.nutrition_plan_type}</Label>
                                    <Input className="custom-select" value={planId} onChange={(e)=> setPlanId(e.target.value)} type="select" name="planId" id="planId">
                                        {plans.map((item,index) => {
                                         return(<option key={item.id} value={item.id}>{item.title}</option>)
                                        })}
                                    </Input>
                                </FormGroup>

                              </div>
                           <Row>
                               <Col>
                  
                        </Col> 
                      
                  
                      </Row>   
                      </ModalBody>
                      <ModalFooter>
                        <Button disabled={menuId == null || planId == null} color="primary"  onClick={() => {
                            onAddNutritionMealClose();
                            if (isEditNutritionMeal){
                                // var transArr = [];
                                // langsTitlesArray.forEach(lang => {
                                //     nutritionMealToEdit.titles.forEach(trans => {
                                //         if (trans.langLoc == lang.lang_loc){
                                //             transArr.push({id: trans.id,text: lang.value})
                                //         }
                                //     });
                                // });
                                onSaveNutritionMeal(
                                    {
                                        'titles': langsTitlesArray,
                                        'content': langsContentArray,
                                        'visible': visible,
                                        'menuId': menuId,
                                        'planId': planId,
                                        'calories': calories,
                                        'itemId' : nutritionMealToEdit.id
                                    });
                            } else {      
                                onSaveNutritionMeal(
                                {
                                  'titles': langsTitlesArray,
                                  'content': langsContentArray,
                                  'visible': visible,
                                  'menuId': menuId,
                                  'planId': planId,
                                  'calories': calories,
                                });
                                clearData();
                            }

                           clearData();
                        }}>{trans.submit_btn}</Button>
                      </ModalFooter>
                </Modal>
  );
}

export default AddNutritionMeal;