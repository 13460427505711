import  './translations.css';
import React,{useEffect, useState} from 'react';
import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Form, FormGroup, Input, Label, Button,NavItem, NavLink, Nav,TabContent,TabPane, Breadcrumb, Card, CardHeader, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const AddTransaltion = (props) => {

    const {onSaveTranslation, onAddTranslationClose, open, translationToEdit, isEditTranslation, translationObject} = props;
  
    const [slug,setSlug] = useState(''); 
    const [isApp,setIsApp] = useState(1); 
    const settingsData = useSelector(({settings}) => settings.settingsData);
    const trans = useSelector(({translation}) => translation.translationsData);

    const [langsArray,setLangArray] = useState([]); 

  

    useEffect(() => {
       if (open){
           clearData();
           var arr = [];
            settingsData.settings.langs.map((item,index) => {
                arr.push({lang_loc: item.langLoc, value: ""});
            })
            
            if (isEditTranslation){
                setSlug(translationToEdit.slug);
                arr.forEach((item,langIndex) => {
                     translationToEdit.values.forEach(trans => {
                         if (trans.langLoc == item.lang_loc){
                            arr[langIndex].value = trans.value;
                         }
                     });
                 });
                 setIsApp(translationToEdit.values[0].isApp);
             }

             setLangArray(arr);
             
       }
    }, [open]);

    const setLang = (e) => {
        let langIndex = e.target.id.replace("lang_value_","");
        langsArray[langIndex].value = e.target.value;
    }

    const clearData = () => {
        setSlug('');
        setLangArray([]);
        setIsApp(1);
    }
    

  return (
      <Modal isOpen={open} toggle={onAddTranslationClose} centered>
                      <ModalHeader toggle={onAddTranslationClose}>
                      {isEditTranslation ?   trans.edit_translation_title : trans.add_translation_title}
                      </ModalHeader>
                      <ModalBody>
                      <div className="form-row">
                                <FormGroup className="col-md-12">
                                  <Label>{"Name"}</Label>
                                  <Input value={slug} type="text" onChange={e => setSlug(e.target.value)}/>
                                </FormGroup>
                                {langsArray.map((item,index) => {
                                    return (<FormGroup key={`trans_${index}`} className="col-md-12">
                                    <Label>{settingsData.settings.langs[index].name}</Label>
                                    <Input defaultValue={item.value} id={`lang_value_${index}`} onChange={(e)=> setLang(e)} type="text"/>
                                  </FormGroup>)
                                })}

                            <FormGroup className="col-md-12">
                                    <Label for="exampleSelect">ISAPP</Label>
                                    <Input className="custom-select" value={isApp} onChange={(e)=> setIsApp(e.target.value)} type="select" name="isApp" id="isApp">
                                    <option value={1}>YES</option>
                                    <option value={0}>NO</option>
                                    </Input>
                                </FormGroup>
                              </div>
                      </ModalBody>
                      <ModalFooter>
                        <Button color="primary"  onClick={() => {
                            onAddTranslationClose();
                            if (isEditTranslation){
                                var transArr = [];
                                langsArray.forEach(lang => {
                                    translationToEdit.values.forEach(trans => {
                                        if (trans.langLoc == lang.lang_loc){
                                            transArr.push({id: trans.id,value: lang.value})
                                        }
                                    });
                                });
                                onSaveTranslation(
                                    {
                                        'transArr': transArr,
                                        'is_app': isApp,
                                        'slug' : slug
                                    });
                            } else {      
                                onSaveTranslation(
                                {
                                    'slug': slug,
                                    'translations': langsArray,
                                    'is_app': isApp
                                });
                                clearData();
                            }
                        }}>{trans.submit_btn}</Button>
                      </ModalFooter>
                </Modal>
  );
}

export default AddTransaltion;