import  './chats.css';
import React,{useEffect, useState} from 'react';
import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Form, FormGroup, Input, Label, Button,NavItem, NavLink, Nav,TabContent,TabPane, Breadcrumb, Card, CardHeader, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import parse from 'html-react-parser';
import ChatScreen from './chatScreen';

const ChatModal = (props) => {

    const dispatch = useDispatch();

    const {onChatModalClose, open, chatRoomPassedId,title,selectedUser,chatRoomUserId} = props;
    const trans = useSelector(({translation}) => translation.translationsData);



    return (<Modal isOpen={open} toggle={onChatModalClose} centered size="xl">
             <ModalHeader toggle={onChatModalClose}>{title}</ModalHeader>
             <ModalBody>
               <div>
                   <ChatScreen
                    chatRoomPassedId={chatRoomPassedId}
                    selectedUser={selectedUser}
                    chatRoomUserId={chatRoomUserId}
                    modalOpen={open}
                   />
               </div>
            </ModalBody>
     </Modal>);
};

export default ChatModal;