import './blog.css';
import React, { Fragment,useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter, Media, Form, FormGroup, Label, Input, Button , Pagination,
  PaginationItem,
  PaginationLink,  
  CardTitle} from 'reactstrap'
  import { useHistory } from 'react-router-dom';
import axios from 'axios'
import { DATE_TIME_FORMAT,MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, PaddingRight} from '../../constant'
import { useParams } from 'react-router';
import NoAccess from '../../components/reuse/noaccess';
import { getUserData } from '../../redux/user/action';
import { Loader } from 'react-bootstrap-typeahead';
import { toast } from 'react-toastify';
import { editUser, forgotPass, getUser,editRoles, changeAccountStatus } from '../../redux/auth/action';

import DatePicker from "react-datepicker";
import { Link } from 'react-router-dom';
import * as General from '../../util/General'
import SweetAlert from 'sweetalert2'
import { checkUserSub } from '../../redux/subscription/action';
import LoaderBox from '../../components/reuse/loaderbox';
import { fetchSingleBlog, singleBlog } from '../../redux/blog/action';
import Moment from "react-moment";

import parse from 'html-react-parser';

const SingleBlog = (props) => {

  const userLocale = General.getLocale();
    const dispatch = useDispatch();
    const history = useHistory();


    var blogId = "";
    let { id } = useParams();
    if (id != null){
      blogId = id;
    }

    const trans = useSelector(({translation}) => translation.translationsData);
    const singleBlogData = useSelector(({blog}) => blog.singleBlogData);

    const [blogList, setBlogList] = useState([]);
    const [curPage, setCurPage] = useState(1);

    useEffect(() => {
      fetchBlog();
    }, []);

  const fetchBlog = () => {
    let postData = {
        "articleId": blogId
    };
      dispatch((singleBlog(postData)));
   }


    return (
      <Fragment>
     
      <Container fluid={true}>

      <br />
      <br />
      <br />
      {singleBlogData != null ? 
        <Card>
      
         
         <CardHeader>
           <CardTitle>
             <h2 className="font-weight-bold">{singleBlogData.title}</h2>
           </CardTitle>
         </CardHeader>
        
        <CardBody>

            <Row>
              <Col md="3">
              <div>
                <div style={{marginTop: '10px',width: '100%'}}>
                  <img style={{ width:'100%', objectFit:'contain',borderRadius:'8px'}}  src={singleBlogData.imgObj.img_thumb}/>
                 </div>
          </div>     

          <div style={{color:'grey',fontSize:'13px' ,marginTop:'10px'}}>
                                  <Moment
                                    locale={userLocale}
                                    format={DATE_TIME_FORMAT}
                                    unix
                                    >
                                    {singleBlogData.timestamp}
                                  </Moment>
                                </div>
              
              </Col>

              <Col md="9">

              <div >{parse(singleBlogData.desc) }</div>
              
              </Col>

            </Row>

           

        </CardBody>

      
        </Card>
         : <LoaderBox/>}

      </Container>
    </Fragment>
  );
}

export default SingleBlog;