import {
  FETCH_DONE_SCHEDULES,
  ADD_DONE_SCHEDULE,
  EDIT_DONE_SCHEDULE,
  DELETE_DONE_SCHEDULE,
  SINGLE_DONE_SCHEDULE,
USER_ID_SET,
WORKOUTBOARD_DONE_SCHEDULE,
USER_PROGRESS,
USER_PROGRESS_CHECKBOX,
USER_PROGRESS_TIME,
USER_PROGRESS_KGS_LBS,
USER_PROGRESS_METERS,
USER_PROGRESS_REPS,
USER_PROGRESS_CALORIES,
USER_STATS,
  } from '../actionTypes';
  import axios from '../../util/Api'
  import * as General from '../../util/General'
  

  import { SCORE_TYPE_CALORIES, SCORE_TYPE_CHECKBOX, SCORE_TYPE_KGS_LBS, SCORE_TYPE_METERS, SCORE_TYPE_REPS, SCORE_TYPE_TIME } from '../../constant';
  
  

  export const getDoneSchedules = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;
    console.log(postData);
    return (dispatch) => {
      axios.post('doneSchedule/all', postData
      ).then(({data}) => {
         console.log(data);
        if (data){
          dispatch({type: FETCH_DONE_SCHEDULES, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };


  export const addDoneSchedule = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.creator = userId;
    postData.langLoc = userLocale;
    console.log(postData);
    return (dispatch) => {
      axios.post('doneSchedule/add', postData
      ).then(({data}) => {
         console.log(data);
        if (data){
          dispatch({type: ADD_DONE_SCHEDULE, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };

  export const editDoneSchedule = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;
    //console.log(postData);
    return (dispatch) => {
      axios.post('doneSchedule/edit', postData
      ).then(({data}) => {
     //   console.log(data);
        if (data){
          dispatch({type: EDIT_DONE_SCHEDULE, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };

  export const singleDoneSchedule = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;
    //console.log(postData);
    return (dispatch) => {
      axios.post('doneSchedule/single', postData
      ).then(({data}) => {
        // console.log(data);
        if (data){
          dispatch({type: SINGLE_DONE_SCHEDULE, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };


  export const fetchWorkoutLeaderboard = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;
    console.log(postData);
    return (dispatch) => {
      axios.post('doneSchedule/workoutboard', postData
      ).then(({data}) => {
         console.log(data);
        if (data){
          dispatch({type: WORKOUTBOARD_DONE_SCHEDULE, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };

  export const fetchProgress = (postData,scoreType) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;
    console.log(postData);
    return (dispatch) => {
      axios.post('doneSchedule/workoutboard', postData
      ).then(({data}) => {
         console.log(data);
        if (data){
          if (scoreType == SCORE_TYPE_CHECKBOX){
            dispatch({type: USER_PROGRESS_CHECKBOX, payload: data});
          } else  if (scoreType == SCORE_TYPE_TIME){
            dispatch({type: USER_PROGRESS_TIME, payload: data});
          } else  if (scoreType == SCORE_TYPE_KGS_LBS){
            dispatch({type: USER_PROGRESS_KGS_LBS, payload: data});
          } else  if (scoreType == SCORE_TYPE_METERS){
            dispatch({type: USER_PROGRESS_METERS, payload: data});
          } else  if (scoreType == SCORE_TYPE_REPS){
            dispatch({type: USER_PROGRESS_REPS, payload: data});
          } else  if (scoreType == SCORE_TYPE_CALORIES){
            dispatch({type: USER_PROGRESS_CALORIES, payload: data});
          } 
          
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };

  export const fetchUserStats = (postData) => {
    //Get user locale of the machine he is on
    //const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    //postData.userId = userId;
    postData.langLoc = userLocale;
    //console.log(postData);
    return (dispatch) => {
      axios.post('doneSchedule/user-stats', postData
      ).then(({data}) => {
         //console.log(data);
        if (data){
          dispatch({type: USER_STATS, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };




