import {
    FETCH_SCHEDULES,
    ADD_SCHEDULE,
    EDIT_SCHEDULE,
    DELETE_SCHEDULE,
    SINGLE_SCHEDULE,
    EDIT_SCHEDULE_WORKOUT,
    CLONE_WEEK} from '../actionTypes';

  const INIT_STATE = {
      schedulesData: null,
      addScheduleData: null,
      editScheduleData: null,
      singleScheduleData: null,
      deleteScheduleData: null,
      cloneWeekData: null,
      editScheduleWorkoutData: null
    };
    
    export default (state = INIT_STATE, action) => {
        switch(action.type) {
        case FETCH_SCHEDULES:
             return {
                ...state,
                schedulesData: action.payload
        }
        case ADD_SCHEDULE:
            return {
               ...state,
               addScheduleData: action.payload
       }
        case EDIT_SCHEDULE:
             return {
                ...state,
                editScheduleData: action.payload
        }
        case DELETE_SCHEDULE:
            return {
               ...state,
               deleteScheduleData: action.payload
           }
       case SINGLE_SCHEDULE:
        return {
           ...state,
           singleScheduleData: action.payload
         }
       case CLONE_WEEK:
         return {
            ...state,
            cloneWeekData: action.payload
         }
       case EDIT_SCHEDULE_WORKOUT:
        return {
            ...state,
            editScheduleWorkoutData: action.payload
         }

         
        default:
              return state;
        }
    }
    