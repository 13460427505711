import  './schedule.css';
import React,{useEffect, useState} from 'react';
import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Form, FormGroup, Input, Label, Button,NavItem, NavLink, Nav,TabContent,TabPane, Breadcrumb, Card, CardHeader, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import WorkoutLeaderboard from './workoutLeaderboard';
import parse from 'html-react-parser';

const WorkoutNotesModal = (props) => {

    const dispatch = useDispatch();

    const {onWorkoutNotesModalClose, open, notes,title} = props;
    const trans = useSelector(({translation}) => translation.translationsData);


   var find = '<a';
   var re = new RegExp(find, 'g');
   var descData = notes;
   var htmlData = descData.replace(re, '<a target="_blank" ');

    return (<Modal isOpen={open} toggle={onWorkoutNotesModalClose} centered >
             <ModalHeader toggle={onWorkoutNotesModalClose}>{title}</ModalHeader>
             <ModalBody>
               <div className="_daily_workout_descr_text"> { parse(htmlData) } {}</div>
            </ModalBody>
     </Modal>);
};

export default WorkoutNotesModal;