import React, { Fragment,useEffect,useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardHeader, Col, Media, Row } from 'reactstrap';

const NoAccess = (props) => {
  
  //LETS GET THE TRANSLATIONS
  const trans = useSelector(({translation}) => translation.translationsData);

    return (<Card>
      <CardHeader>
         <h4 className="card-title mb-0">{trans.no_access_title}</h4>
        </CardHeader>
        <br/>
        <br/>
        <Row className="text-center">
                <Media className="img-70 mx-auto d-block" alt="" src={require("../../assets/images/no_access.png")} />

        </Row>
        <br/>
        <br/>
        <Row className="mb-2 text-center">
            <Col>
                <h6 className="text-center text-justify">{trans.no_access_descr}</h6>
            </Col>
        </Row>
        <br/>
        <br/>
    </Card>);

}

export default NoAccess;