import  './tracks.css';
import React,{useEffect, useState} from 'react';
import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Form, FormGroup, Input, Label, Button,NavItem, NavLink, Nav,TabContent,TabPane, Breadcrumb, Card, CardHeader, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DatePicker from "react-datepicker";


const AddTrack = (props) => {

    const {onSaveTrack, onAddTrackClose, open, trackToEdit, isEditTrack, trackObject, trainings} = props;
  
    const [slug,setSlug] = useState(''); 
    const [isApp,setIsApp] = useState(1); 
    const settingsData = useSelector(({settings}) => settings.settingsData);
    const trans = useSelector(({translation}) => translation.translationsData);

    const [langsArray,setLangArray] = useState([]); 

  
    //VALUES
    const [title,setTitle] = useState(''); 
    const [access_type,setAccessType] = useState(1); 
    const [active,setActive] = useState(true); 
    const [training_id,setTrainingId] = useState(null);
    const [publish_time,setPublishTime] = useState(Date.now());

    useEffect(() => {
       if (open){
           clearData();
        
           if (isEditTrack){
            setTitle(trackToEdit.title);
            setAccessType(trackToEdit.access_type)
            setActive(trackToEdit.active)
            setTrainingId(trackToEdit.training.id)
            setPublishTime(new Date(trackToEdit.publish_time))
        }
             
       }
    }, [open]);

  
    const clearData = () => {
        setTitle('');
    }

  
    useEffect(() => {
        if (trainings != null && trainings.length > 0){
            setTrainingId(trainings[0].id)
        }
     }, [trainings]);

  return (
      <Modal isOpen={open} toggle={onAddTrackClose} centered>
                      <ModalHeader toggle={onAddTrackClose}>
                      {isEditTrack ?  trans.edit_track_title : trans.add_track_title}
                      </ModalHeader>
                      <ModalBody>
                      <div className="form-row">
                                <FormGroup className="col-md-12">
                                  <Label>{trans.track_name}</Label>
                                  <Input value={title} type="text" onChange={e => setTitle(e.target.value)}/>
                                </FormGroup>

                                {/* <FormGroup className="col-md-12">
                                    <Label for="exampleSelect">{trans.training_is_private}</Label>
                                    <Input className="custom-select" value={access_type} onChange={(e)=> setAccessType(e.target.value)} type="select" name="access_type" id="access_type">
                                    <option value={1}>{trans.value_yes}</option>
                                    <option value={0}>{trans.value_no}</option>
                                    </Input>
                                </FormGroup> */}
                             

                                <FormGroup className="col-md-12">
                                    <Label for="exampleSelect">{trans.value_status}</Label>
                                    <Input className="custom-select" value={active} onChange={(e)=> setActive(e.target.value)} type="select" name="active" id="active">
                                    <option value={true}>{trans.value_yes}</option>
                                    <option value={false}>{trans.value_no}</option>
                                    </Input>
                                </FormGroup>

                                {trainings != null ?
                                <FormGroup className="col-md-12">
                                    <Label for="exampleSelect">{trans.training_name}</Label>
                                    <Input className="custom-select" value={training_id} onChange={(e)=> setTrainingId(e.target.value)} type="select" name="training_id" id="training_id">
                                        {trainings.map((item,index) => {
                                         return(<option value={item.id}>{item.title}</option>)
                                        })}
                                    </Input>
                                </FormGroup>
                                : null}

                             {/* <FormGroup className="col-md-12">
                                <Label className="form-label">{trans.track_publish_date}</Label>
                                <DatePicker  selected={publish_time} placeholder={trans.date_birth_field} className="form-control digits" dateFormat="dd/MM/yyyy" selected={publish_time} onChange={(date) => {setPublishTime(date)}} />
                             </FormGroup> */}

                            </div>
                           
                      </ModalBody>
                      <ModalFooter>
                        <Button color="primary"  onClick={() => {
                            onAddTrackClose();
                            var data = {
                                'title': title,
                                'access_type': access_type,
                                'active': active,
                                'publish_time': publish_time,
                                'training_id': training_id
                            };

                            if (isEditTrack){
                                data.trackId = trackToEdit.id;
                            }

                            onSaveTrack(data);
                           clearData();
                        }}>{trans.submit_btn}</Button>
                      </ModalFooter>
                </Modal>
  );
}

export default AddTrack;