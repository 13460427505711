import {
    FETCH_PRWORKOUTS,
    DONE_PRWORKOUT_SUBMIT,
    DONE_PRWORKOUT_HISTORY,
    DONE_PRWORKOUT_EDIT,
    DONE_PRWORKOUT_DELETE
  } from '../actionTypes';

  const INIT_STATE = {
      prWorkoutsData: null,
      donePrWorkoutsData: null,
      doneHistoryPrWorkoutsData: null,
      doneEditPrworkoutData: null,
      doneDeletePrworkoutData: null,
    };
    
    export default (state = INIT_STATE, action) => {
        switch(action.type) {
        case FETCH_PRWORKOUTS:
             return {
                ...state,
                prWorkoutsData: action.payload
        }
        case DONE_PRWORKOUT_SUBMIT:
          return {
             ...state,
             donePrWorkoutsData: action.payload
        }
        case DONE_PRWORKOUT_HISTORY:
          return {
             ...state,
             doneHistoryPrWorkoutsData: action.payload
        }
        case DONE_PRWORKOUT_EDIT:
          return {
             ...state,
             doneEditPrworkoutData: action.payload
        }
        case DONE_PRWORKOUT_DELETE:
          return {
             ...state,
             doneDeletePrworkoutData: action.payload
        }
        default:
              return state;
        }
    }
    