import './blog.css';
import React, { Fragment,useEffect,useState,useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter, Media, Form, FormGroup, Label, Input, Button, Pagination,
  PaginationItem,
  PaginationLink  } from 'reactstrap'
import axios from 'axios'
import {DATE_TIME_FORMAT, MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete} from '../../constant'
import { useParams } from 'react-router';
import NoAccess from '../../components/reuse/noaccess';
import { getUserData } from '../../redux/user/action';
import { Loader } from 'react-bootstrap-typeahead';
import { toast } from 'react-toastify';
import { editUser, forgotPass, getUser,editRoles, changeAccountStatus } from '../../redux/auth/action';

import DatePicker from "react-datepicker";
import { Link } from 'react-router-dom';
import * as General from '../../util/General'
import SweetAlert from 'sweetalert2'
import { checkUserSub } from '../../redux/subscription/action';
import LoaderBox from '../../components/reuse/loaderbox';
import { addBlog, deleteBlog, editBlog, getBlogs, singleBlog } from '../../redux/blog/action';
import Moment from "react-moment";
import JoditEditor from "jodit-react";
import { Cpu } from 'react-feather';
import { postUploadImageFile } from '../../redux/upload/action';
import { useHistory } from 'react-router-dom';
import { ADD_BLOG, EDIT_BLOG } from '../../redux/actionTypes';
  	
const config = {
  readonly: false ,
  iframe: true,
  video: true,
  height: 1500
}


const AddBlog = (props) => {

  const history = useHistory();
  const userLocale = General.getLocale();
  const dispatch = useDispatch();

    var blogId = "";
    var isEdit = false;
    let { id } = useParams();
    if (id != null){
      isEdit = true;
      blogId = id;
    }

    //SELECTORS
    const trans = useSelector(({translation}) => translation.translationsData);
    const singleBlogData = useSelector(({blog}) => blog.singleBlogData);
    const uploadImagesData = useSelector(({upload}) => upload.uploadImageFileData);
    const addBlogData = useSelector(({blog}) => blog.addBlogData);
    const editBlogData = useSelector(({blog}) => blog.editBlogData);

    //STATE
    const [title,setTitle] = useState("");
    const [desc,setDesc] = useState("");
    const [image,setImage] = useState(null);
    const [imgThumb,setImgThumb] = useState("");
    const [videoUrl,setVideoUrl] = useState("");
    const [status,setStatus] = useState(true);
    const [type,setType] = useState("athleteslab");

    const editorDesc = useRef(null);

    const [titleEn,setTitleEn] = useState("");
    const [descEn,setDescEn] = useState("");

    const [titleEl,setTitleEl] = useState("");
    const [descEl,setDescEl] = useState("");
 
    const editorDescEn = useRef(null);
    const editorDescEl = useRef(null);

    useEffect(() => {
      if (isEdit){
        fetchSingleBlog();
      }
    }, []);

  
    const fetchSingleBlog = () => {
      let postData = {
        "articleId": blogId
    };
      dispatch((singleBlog(postData)));
    }
  
   
    useEffect(() => {
      if (addBlogData != null){
        if (addBlogData.status){
          history.replace('/app/blog-list');
        }
        dispatch({type: ADD_BLOG, payload: null});
      }
    }, [addBlogData]);

    useEffect(() => {
      if (editBlogData != null){
        if (editBlogData.status){
          toast.success(editBlogData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
        } else {
          toast.error(editBlogData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
        }
        dispatch({type: EDIT_BLOG, payload: null});
      }
    }, [editBlogData]);
  
  
    useEffect(() => {
      if (singleBlogData != null && isEdit){
        console.log(singleBlogData);
        setTitle(singleBlogData.title);
        setDesc(singleBlogData.desc);
        setImage(singleBlogData.image);
        setVideoUrl(singleBlogData.videoUrl);
        setStatus(singleBlogData.status);
        setType(singleBlogData.type);
        if (singleBlogData.imgObj != null){
          setImgThumb(singleBlogData.imgObj.img_thumb);
        }
        
        setTitleEn("");
        setTitleEl("");
        setDescEn("");
        setDescEl("");

        if (singleBlogData.titles != null){
          singleBlogData.titles.forEach(element => {
            if (element.langLoc == "en"){
              setTitleEn(element.text);
            } else {
              setTitleEl(element.text);
            }
          });
        } else {
          setTitleEn("");
          setTitleEl("");
        }
       
        

        if (singleBlogData.content != null){
          singleBlogData.content.forEach(element => {
            if (element.langLoc == "en"){
              setDescEn(element.text);
            } else {
              setDescEl(element.text);
            }
          });
        } else {
          setDescEn("");
          setDescEl("");
        }
       
       
      }
    }, [singleBlogData]);
  

    const uploadImage = () => {
      document.getElementById("post-images").click()
    }

  const _handleImageChange = (event) => {
    if (event.target.files.length === 0)
      return;
    

    var mimeType = event.target.files[0].type;

    if (mimeType.match(/image\/*/) == null) {
      return;
    }
  
    let file = event.target.files[0];
    
    let reader = new FileReader();
    reader.onloadend = () => {
      setImgThumb(reader.result);
    }
   reader.readAsDataURL(file)

    var type = "blog";

    var images = [];
    images.push(file);


    dispatch(postUploadImageFile(type, images))
  }

  const handleBlogStatus = (e) => {
    if (e.target.value == "true"){
        setStatus(true);
      } else {
        setStatus(false);
      } 
  }

  const addArticle = (e) => {
    var titles = [];
    var content = [];

    titles.push({text: titleEn,langLoc: "en"});
    titles.push({text: titleEl,langLoc: "el"});

    content.push({text: descEn,langLoc: "en"});
    content.push({text: descEl,langLoc: "el"});

    let postData = {
      "titles": titles,
      "content": content,
      "image":image,
      "status": status,
      "videoUrl": videoUrl,
      "type":type
    };

    if (isEdit){
      postData.articleId = blogId; 
      dispatch(editBlog(postData));
    } else {
      dispatch(addBlog(postData));
    }

    
  }
  

  useEffect(() => {
    if (uploadImagesData != null){
          let image = uploadImagesData.images[0];
          setImage(image);
      }
  },[uploadImagesData])

    return (
      <Fragment>
      <Breadcrumb parent={trans.blog_add_title} title={trans.blog_add_title} />
      <Container fluid={true}>
         <Card>
           <CardHeader>
              <h4 className="font-weight-bold"> {trans.blog_add_title}</h4>
           </CardHeader>
         <CardBody>
            {
              isEdit && singleBlogData == null ? <div>
               <LoaderBox/>
              </div> :
               <div>
                 <Row>
                    <Col md="9">
                      <Row>
                      {/* <Col md="12">
                     <br />
                        <FormGroup>
                            <Label className="form-label font-weight-bold">{trans.blog_title}</Label>
                            <Input className="form-control" type="text" placeholder={trans.blog_title} value={title} onChange={(e)=>{setTitle(e.target.value)}}/>
                        </FormGroup>
                    </Col>  */}
                    <Col md="12">
                     <br />
                        <FormGroup>
                            <Label className="form-label font-weight-bold">{`${trans.blog_title} (EN)`}</Label>
                            <Input className="form-control" type="text" placeholder={`${trans.blog_title} (EN)`} value={titleEn} onChange={(e)=>{setTitleEn(e.target.value)}}/>
                        </FormGroup>
                    </Col> 
                    <Col md="12">
                     <br />
                        <FormGroup>
                            <Label className="form-label font-weight-bold">{`${trans.blog_title} (EL)`}</Label>
                            <Input className="form-control" type="text" placeholder={`${trans.blog_title} (EL)`} value={titleEl} onChange={(e)=>{setTitleEl(e.target.value)}}/>
                        </FormGroup>
                    </Col> 
                    {/* <Col  md="12">
                     <br />    
                     <FormGroup>
                            <Label className="form-label font-weight-bold">{trans.blog_desc}</Label>
                            <JoditEditor
                          ref={editorDesc}
                           value={desc}
                          config={config}
                          tabIndex={1} // tabIndex of textarea
                          onBlur={newContent => setDesc(newContent)} // preferred to use only this option to update the content for performance reasons
                          onChange={newContent => {}} />
                        </FormGroup>
                    </Col>  */}
                    <Col  md="12">
                     <br />    
                     <FormGroup>
                            <Label className="form-label font-weight-bold">{`${trans.blog_desc} (EN)`}</Label>
                            <JoditEditor
                          ref={editorDescEn}
                           value={descEn}
                          config={config}
                          tabIndex={1} // tabIndex of textarea
                          onBlur={newContent => setDescEn(newContent)} // preferred to use only this option to update the content for performance reasons
                          onChange={newContent => {}} />
                        </FormGroup>
                    </Col> 
                    <Col  md="12">
                     <br />    
                     <FormGroup>
                            <Label className="form-label font-weight-bold">{`${trans.blog_desc} (EL)`}</Label>
                            <JoditEditor
                          ref={editorDescEl}
                           value={descEl}
                          config={config}
                          tabIndex={1} // tabIndex of textarea
                          onBlur={newContent => setDescEl(newContent)} // preferred to use only this option to update the content for performance reasons
                          onChange={newContent => {}} />
                        </FormGroup>
                    </Col> 
                      </Row>
                    </Col>

                    <Col md="3">
                      <Row>
                      <Col md="12">
                      <br /> 
                      <Row>
                        <Col md="6">
                           <Label className="form-label font-weight-bold">{""}</Label>
                        </Col>  
                        <Col  md="6">
                          <div  onClick={uploadImage}>
                              <Button color="primary" size="sm"><i className="fa fa-plus"></i> {trans.add_btn}</Button>
                              <input id="post-images" type="file" name="post-images" style={{display: 'none'}}
                                      accept="image/x-png,image/jpeg" onChange={(e)=>_handleImageChange(e)} />
                              </div>
                        </Col>
                        <Col md="12">
                         <div style={{marginTop: '10px',width: '100%',height:'200px'}}>
                              <Media
                                style={{height:'100%', width:'100%', objectFit:'contain'}}
                                    src={imgThumb}
                                    alt={`Image Article`}
                                    className="img-thumbnail"
                                />
                          </div>
                        </Col>
                        <Col md='12'>
                          <br />
                            <FormGroup>
                                    <Label className="form-label font-weight-bold" for="exampleSelect">{trans.blog_status}</Label>
                                    <Input className="custom-select" value={status} onChange={(e)=> handleBlogStatus(e)} type="select" name="toAll" id="toAll">
                                    <option key={`status_${true}`} value={true}>{trans.blog_status_published}</option>
                                    <option  key={`status_${false}`} value={false}>{trans.blog_status_unpublished}</option>
                                    </Input>
                           </FormGroup>
                        </Col>
                        <Col md='12'>
                            <FormGroup>
                                    <Label className="form-label font-weight-bold" for="exampleSelect">{trans.blog_type}</Label>
                                    <Input className="custom-select" value={status} onChange={(e)=> setType(e.target.value)} type="select" name="toAll" id="toAll">
                                    <option key={`blog_type_athleteslab`} value={'athleteslab'}>Athletes Lab</option>
                                   
                                    </Input>
                           </FormGroup>
                        </Col>
                      </Row>   
                    </Col>
                      </Row>
                    </Col>

                 </Row>
                  <Row>
                  
                   
                  </Row>

                 
              </div> 
            }
            </CardBody>
            <CardFooter className="text-right">
                  <button  onClick={(e)=> {addArticle(e)}} className="btn btn-primary" type="submit">{trans.submit_btn}</button>
            </CardFooter>
         </Card>
      </Container>
    </Fragment>
  );
}

export default AddBlog;