import {
    INIT_URL,
    SIGNOUT_USER_SUCCESS,
    USER_DATA,
    USER_TOKEN_SET,
    EDIT_USER,
  RESET_PASS,
FORGOT_PASS,
SHOW_MESSAGE,
USER_INFO_STORED,
USER_ID_SET,
SIGN_OUT,
SIGN_IN,
CREATE_USER_SUCCESS,
UPDATE_USER_ROLES,
CHANGE_STATUS,
USER_IS_ADMIN,
USER_IS_MANAGER,
USER_IS_COACH,
UPDATE_LAST_ONLINE,
USER_NOTIFICATION_SETTINGS,
EDIT_USER_SELECTED_TRACK
} from '../actionTypes';
  
  const INIT_STATE = {
    initURL: '',
    authUser: null,
    editUser: null,
    userInfo: null,
    resetPass: null,
    forgotPass: null,
    message: null,
    logout: null,
    login: null,
    signUp: null,
    updateUserRoles: null,
    changeStatusData: null,
    updateLastOnline: null,
    userIsAdmin: false,
    userIsManager: false,
    userIsCoach: false,
    userNotificationSettings: null,
    editUserSelectedTrackData: null
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case INIT_URL: {
          return {...state, initURL: action.payload};
        }
      case SIGNOUT_USER_SUCCESS: {
        return {
          ...state,
          token: null,
          authUser: null,
          editUser: null,
          userId: null,
          initURL: '',
          login: null
        }
      }
      case SIGN_OUT: {
        return {
          ...state,
          logout: action.payload,
        };
      }
      case SIGN_IN: {
        return {
          ...state,
          logout: null,
          login: action.payload,
        };
      }
      case USER_DATA: {
        return {
          ...state,
          authUser: action.payload,
        };
      }
      case UPDATE_USER_ROLES: {
        return {
          ...state,
          updateUserRoles: action.payload,
        };
      }
      case EDIT_USER: {
        return {
          ...state,
          editUser: action.payload,
        };
      }
      case USER_INFO_STORED: {
        return {
          ...state,
          userInfo: action.payload,
        };
      }
      case RESET_PASS: {
        return {
          ...state,
          resetPass: action.payload,
        };
      }
      case FORGOT_PASS: {
        return {
          ...state,
          forgotPass: action.payload,
        };
      }
      case SHOW_MESSAGE: {
        return {
          ...state,
          message: action.payload,
        };
      }
      case CREATE_USER_SUCCESS: {
        return {
          ...state,
          signUp: action.payload,
        };
      }
      case CHANGE_STATUS:
        return {
        ...state,
        changeStatusData: action.payload
      }
      case USER_IS_ADMIN: 
        return {
        ...state,
        userIsAdmin: action.payload
      }
      case USER_IS_MANAGER: 
        return {
        ...state,
        userIsManager: action.payload
      }
      case USER_IS_COACH: 
        return {
        ...state,
        userIsCoach: action.payload
      }
      case UPDATE_LAST_ONLINE: 
      return {
        ...state,
        updateLastOnline: action.payload
      }
      case USER_NOTIFICATION_SETTINGS: 
      return {
        ...state,
        userNotificationSettings: action.payload
      }
      case EDIT_USER_SELECTED_TRACK: 
      return {
        ...state,
        editUserSelectedTrackData: action.payload
      }
      default:
        return state;
    }
  }
  