import './chats.css';
import classnames from 'classnames';
import parse from 'html-react-parser';
import React, { Fragment, useEffect, useRef, useState } from "react";
import Moment from 'react-moment';
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from 'react-router';
import { Link } from "react-router-dom";
import { animateScroll } from "react-scroll";
import {
  Button, Card,
  CardBody, Col, Container, Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon, Media, Nav,
  NavItem,
  NavLink, Pagination, PaginationItem, PaginationLink, Row
} from "reactstrap";
import openSocket from 'socket.io-client';
import LoaderBox from '../../components/reuse/loaderbox';
// import {CALL,STATUS,PROFILE,VincentPorter,Active,ChatRoomaApp_p1,ChatRoomaApp_p2,Following,Follower,MarkJecno,Send} from '../../../constant'
import {
  DATE_TIME_FORMAT, Send, SOCKET_URL
} from "../../constant";
// import Breadcrumb from '../../../layout/breadcrumb'
import Breadcrumb from "../../layout/breadcrumb";
import {
  CHAT_CREATE_ROOM, CHAT_MESSAGES
} from "../../redux/actionTypes";
import { addRemoveChatRoom, createChatRoom, fetchChatMessages, fetchChatRoomsList, postChangeReadChatMessage, postChatMessage } from '../../redux/chat/action';
import { getAdminUsers, getCoachesData, getUserData } from "../../redux/user/action";
import * as General from '../../util/General';
import './chats.css';

const ChatScreen = (props) => {
  
  const socket = openSocket(SOCKET_URL);

  const userLocale = General.getLocale();

  const history = useHistory();
  const dispatch = useDispatch();

  const {chatRoomPassedId,selectedUser,chatRoomUserId,modalOpen,onChatRoomReload} = props;


  const userInfo = useSelector(({ auth }) => auth.authUser);
  const trans = useSelector(({translation}) => translation.translationsData);
  const userIsAdmin = useSelector(({auth}) => auth.userIsAdmin);  
  const createChatRoomData = useSelector(({chat}) => chat.createChatRoomData);  
  const chatMessagesData = useSelector(({chat}) => chat.chatMessagesData);  
  const userViewData = useSelector(({user}) => user.userViewData);

  const chatReadMessageData = useSelector(({chat}) => chat.chatReadMessageData);  


  //STATS
  const [curPage, setCurPage] = useState(0);
  const [usersPages, setUsersPages] = useState(0);
  const [chatUsers, setChatUsers] = useState([]);
  const [seletedChatRoomId,setSeletedChatRoomId] = useState(null);
  const [chatUser,setChatUser] = useState(null);
 
  const [chatMessages, setChatMessages] = useState([]);
  const [messagesLoading, setMessagesLoading] = useState(false);

  var curChatMessagesPage = 1;
  let isLoadingPage = false;

  const [messageInput, setMessageInput] = useState("");

  

   const chatScroll = useRef(null);
   const scrollToBottom = () => {
      if (chatScroll != null && chatScroll.current  != null){ 
      //chatScroll.current.scrollIntoView({ behavior: "smooth" });
        animateScroll.scrollToBottom({
          containerId: "chatMessagesContainer"
        });
      }
    };
    useEffect(scrollToBottom, [chatScroll,chatMessages]);


    useEffect(() => {
     // fetchCoaches();
       //COMPONENT CREATE HERE
       clearingData();
  
      return () => {
        //COMPONENT DIE HERE LETS TELL THE API THAT WE ARE OUT OF THE ROOM
        addRemoveFromChatRoom(false);
        socket.disconnect()
      };
    }, []);


    useEffect(() => {
          
        if (selectedUser == null && chatRoomUserId != null && chatRoomUserId != ""){
            console.log("geting single user",chatRoomUserId);
            getSingleUser();
          }
      },[chatRoomUserId])

    useEffect(() => {
        if (userViewData != null){
            setChatUser(userViewData.userInfo);
          }
      },[userViewData])


    useEffect(() => {
        if (modalOpen != null){
            clearingData()
          }
      },[modalOpen])


      useEffect(() => {
        if (chatReadMessageData != null){
          if (onChatRoomReload != null){
            onChatRoomReload();
          } 
          }
      },[chatReadMessageData])
      
    
      const clearingData = () => {
        setChatMessages([]);
        setChatUser(null);
        setSeletedChatRoomId(null);
      }

    const getSingleUser = () => {
      let postData = {
        userId: chatRoomUserId
      };
      dispatch(getUserData(postData))
    }


    const addRemoveFromChatRoom = (addRemove) => {
        let postData = {
            "toAdd": addRemove,
            "roomId": seletedChatRoomId,
        }
        dispatch(addRemoveChatRoom(postData));
    }


    //CHAT FUNCTIONS
    const subscribeToRoom = () => {
      if (seletedChatRoomId != null){
        const roomID = seletedChatRoomId
        const roomToSub = `room_${roomID}`
       // console.log(roomToSub);
        //console.log("socket",socket);

        socket.on(roomToSub, (message) => {
          onNewMessage(message)
        });

        addRemoveFromChatRoom(true);
      
        
      }
    }  

    const onNewMessage = (message) => {
      //console.log("ney message",message);
      // this.items.push(<FadeDiv className="_chat_message" key={`chat_message_${message.id}`}>
      //     {this.renderChatMessage(message)}
      //   </FadeDiv>
      // );
      // this.setState({});
     // setChatMessages([...chatMessages, message]);

    //  var exists = false;

    //  console.log("chatMessages",chatMessages);

    //  chatMessages.forEach(element => {
    //    console.log("element.id",element.id);
    //    console.log("message.id",message.id);

    //    if (element.id == message.id){ 
    //       exists = true;
    //    }
    //  });

    //   if (!exists){
        setChatMessages(chatMessages => [...chatMessages, message]);
     // }

     

      setChangeRead(message);

    }
    
    useEffect(() => {
        if (chatUser != null){
            getChatRoomId();
        }
      }, [chatUser]);


    useEffect(() => {
      if (selectedUser != null){
          setChatUser(selectedUser);
      }
    }, [selectedUser]);

    useEffect(() => {
      if (seletedChatRoomId != null){
        getChatMessages(false);
        subscribeToRoom();
      }
    }, [seletedChatRoomId]);


    // useEffect(() => {
    //   console.log("chatItemsScroll",chatItemsScroll);
    //   if (chatItemsScroll != null && chatItemsScroll.current != null){
    //     // if (chatMessagesData != null && chatMessagesData.cur_page == 1){
    //       // const scrollHeight = chatItemsScroll.current.scrollHeight;
    //       // const height = chatItemsScroll.current.clientHeight;
    //       // const maxScrollTop = scrollHeight - height;
    //       //chatItemsScroll.current.scrollIntoView({ behavior: "smooth" });
    //       console.log("SCROLLING TO BOTTOM");
    //       chatItemsScroll.current.scrollTop = chatItemsScroll.current.scrollHeight;
    //     // }
    //   }
    // }, [chatItemsScroll]);

    // useEffect(() => {
    //   if (chatScroll != null && chatScroll.current != null){
    //     console.log( chatScroll.current.scrollHeight);
    //     chatScroll.current.scrollIntoView();
    //   }
      
    // }, [chatScroll,chatScroll.current]);
    
  


    useEffect(() => {
      //console.log("chat scroll did");
    }, [chatScroll]);
  

    useEffect(() => {
      if (chatMessagesData != null){
        isLoadingPage = false;
        if (chatMessagesData.cur_page == 1){
          
            setMessagesLoading(false);
            setChatMessages(chatMessagesData.messages)
            
            if (chatScroll != null && chatScroll.current != null){
            chatScroll.current.addEventListener("scroll", () => {
              if (chatScroll && chatScroll.current.scrollTop <= 10) {
                  getChatMessages(true);
              }
             });
            if (chatScroll.current) {
                window.scrollTo({
                    behavior: "instant",
                    top: chatScroll.current.offsetTop
                });
             }
            }

              if (chatMessagesData.messages.length > 0){
                   var lastMessage = chatMessagesData.messages.slice().reverse().find(element => element.userId != userInfo.userId);
                   if (lastMessage != null){
                    setChangeRead(lastMessage);
                   }
                  
            }

        } else {
          chatMessagesData.messages.forEach(element => {
            setChatMessages(chatMessages => [element, ...chatMessages]);
          });
        }

        dispatch({type: CHAT_MESSAGES, payload: null});
      }
    }, [chatMessagesData]);

    


    const getChatRoomId = () => {
      //LETS CREATE OR FETCH THE CHATROOM BETWEEN TWO USERS
      // console.log("userInfo",userInfo);
      // console.log("selectedUser",selectedUser);
      let postData = {users:[userInfo.userId,chatUser.userId]}
      dispatch(createChatRoom(postData));
    }

    useEffect(() => {
      if (createChatRoomData != null){
        //NOW THAT WE HAVE THE ROOM WE NEED TO FETCH THE MESSAGES THAT ARE ASSOCIATED WITH THIS ROOM ID
        setSeletedChatRoomId(createChatRoomData.room_id);
        dispatch({type: CHAT_CREATE_ROOM, payload: null});
      }
    }, [createChatRoomData]);
  
  
    const getChatMessages = (toLoadMore) => {
      if (toLoadMore){
        // console.log(('I DID isLoadingPage',isLoadingPage));
        // console.log(chatMessages.length);
        if (isLoadingPage){
          isLoadingPage = false;
          return;
        }

        // console.log("TOTAL",`${chatMessagesData.total} == ${chatMessages.length}`);
        if (chatMessagesData != null && chatMessagesData.pages == curChatMessagesPage){
          isLoadingPage = false;
          return
        }
        curChatMessagesPage = curChatMessagesPage + 1;
      }

      isLoadingPage = true;
      

      if (curChatMessagesPage == 1){
          setMessagesLoading(true);
      }


      let postData = {roomId: seletedChatRoomId, page: curChatMessagesPage}
      dispatch(fetchChatMessages(postData));
    }
  
    const sendChatMessage = () => {
      let postData = {roomId: seletedChatRoomId, message: messageInput}
      dispatch(postChatMessage(postData));
      setMessageInput("");
    }
    //END OF CHAT FUNCTIONS



  const handleMessageChange = (message) => {
      setMessageInput(message)
  }

  const handleMessagePress = (e) => {
      if (e.key === "Enter" || e === "send") {

        if (messageInput != null && messageInput != ""){
          sendChatMessage();
        }
      }
  }


  const openUserProfile = (e,user) => {
    e.preventDefault();
    history.push(`/app/users/userProfile/${user.nickname}`);
  }


  const setChangeRead = (message) => {
    // if (message != null && message.userId != getUserId()) { // THIS IS NOT OUR MESSAGE
    //   final postData = ChangeReadMessagePost(messageId: message.id, readValue: true,langLoc: userLang());
    //   await changeReadMessage(postData);
    //   eventBus.fire(ReloadChatHistory());
    // }
    if (message != null && message.userId != userInfo.userId) {
      let postData = {messageId: message.id, readValue: true}
      dispatch(postChangeReadChatMessage(postData));
    }
  }

  return (
    <div>
        {chatUser == null ? <LoaderBox/> : 
          <Row className="chat-box">
                      <Col className="pr-0 chat-right-aside">
                        <div className="chat">
                         <div className="chat-header clearfix">
                            <Media style={{objectFit:'cover'}} src={chatUser.img_thumb} className="rounded-circle" alt="" />
                            <div className="about">
                                <div  className="name">
                                    <Link className="font-weight-bold"  to={`/app/users/userProfile/${chatUser.nickname}`} ><span>{chatUser.fullName}</span></Link>
                                </div>
                                <div className="status digits" >
                                    {chatUser.chatStatus ? 'online' : <div>{trans.last_online} <Moment locale={userLocale} format={DATE_TIME_FORMAT} >{chatUser.lastOnline}</Moment> </div> }
                                </div>
                            </div>
                            <ul className="list-inline float-left float-sm-right chat-menu-icons"></ul>
                        </div>
                        <div     id="chatMessagesContainer"  ref={chatScroll} className="chat-history chat-msg-box custom-scrollbar" >
                        {
                            chatMessages.length == 0 ? <div>
                                {messagesLoading ?  <LoaderBox/>  : <h4>{trans.no_chat_messages}</h4>} 
                            </div> :
                             chatMessages.map((item, index) => {
                                return (<div key={index} className="clearfix">
                                      <div className={`message my-message`} style={{float:`${item.userId == userInfo.userId ? 'right' : 'left'}`}}>
                                        <img style={{objectFit:'cover',height:'30px',width:'30px'}} src={item.img} className={`rounded-circle media shadow-sm ${item.userId !== userInfo.userId ? 'float-right' : 'float-left'} chat-user-img img-30`} alt="" />
                                        <span style={{margin:'2px'}}>{parse(General.httpHtml(item.text))}</span>
                                        <br />
                                        <div style={{float:'right',margin:'2px'}}>
                                         <Moment className="message-data-time" locale={userLocale} format={DATE_TIME_FORMAT} unix>{item.timestamp}</Moment>
                                        </div>
                                      </div>
                                </div>)
                                })  
                          }
                        </div>
                          <div className="chat-message clearfix">
                            <Row>
                              <Col xl="12" className="d-flex">
                                <InputGroup className="text-box">
                                    <Input
                                        type="text"
                                        className="form-control input-txt-bx"
                                        placeholder="Type a message......"
                                        value={messageInput}
                                        onKeyPress={(e) => handleMessagePress(e)}
                                        onChange={(e) => handleMessageChange(e.target.value)}
                                    />
                                    <InputGroupAddon addonType="append">
                                        <Button color="primary" onClick={() => handleMessagePress('send')} >{Send}</Button>
                                    </InputGroupAddon>
                                </InputGroup>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                      
                    </Row>
                    }
    </div>
  ) 
};

export default ChatScreen;
