import React,{useState,useEffect} from 'react';
import man from '../assets/images/dashboard/profile.jpg';
import {Container,Row,Col,Form,FormGroup,Input,Label,Button,NavItem, NavLink, Nav,TabContent,TabPane} from 'reactstrap'
import { handleResponse } from '../services/fack.backend'
import { useAuth0 } from '@auth0/auth0-react'
import { toast } from 'react-toastify';
import {withRouter} from 'react-router-dom'
import {Password,SignIn, EmailAddress,RememberPassword,ForgotPassword} from '../constant';
import {userSignIn,getUser} from '../redux/auth/action';
import {useDispatch, useSelector} from 'react-redux';
import { useHistory } from 'react-router-dom'
import { USER_TOKEN_SET } from '../redux/actionTypes';


const Logins = (props) => {

  const dispatch = useDispatch();
  
     const history = useHistory();
    const {loginWithRedirect} = useAuth0()
    //  const [email, setEmail] = useState("achalkias@hotmail.com");
    //  const [password, setPassword] = useState("12345678");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading,setLoading] = useState(false) 
    const [selected, setSelected] = useState("jwt");
    const [togglePassword,setTogglePassword] = useState(false);
    const {login}= useSelector(({auth}) => auth);
    const message = useSelector(({auth}) => auth.message);

    const token =  JSON.parse(localStorage.getItem(USER_TOKEN_SET));
    const trans = useSelector(({translation}) => translation.translationsData);


    const [value, setValue] = useState(
        localStorage.getItem('profileURL' || man)
    );
    const [name, setName] = useState(
        localStorage.getItem('Name')
    );


    useEffect(() => {
      if (login != null){
        if (login.status){
          if (token != null){
            window.location.reload();
          }
        }
      }
    }, [login]);

    useEffect(() => {
          if (message != null){
            toast.error(message, {
              position: toast.POSITION.TOP_RIGHT
              });
          }
    }, [message]);

    
    const loginWithJwt = (e) => {
      e.preventDefault();
      dispatch(userSignIn(email, password));
    }

    return (
        <Container fluid={true} className="p-0">
        <Row>
        <Col xs="12">     
          <div className="login-card">
            <div>
              <div>
                <a className="logo" href="index.html">
                  <img className="img-fluid for-light" src={require("../assets/images/logo/login.png")} alt=""/>
                  <img className="img-fluid for-dark" src={require("../assets/images/logo/logo_dark.png")} alt=""/>
                </a>
              </div>
              <div className="login-main login-tab"> 
                {/* <Nav className="border-tab flex-column" tabs>
                  <NavItem>
                    <NavLink className={selected === 'firebase' ? 'active' : ''} onClick={() => setSelected('firebase')}>
                      <img src={require("../assets/images/firebase.svg")} alt="" />
                      <span>{FIREBASE}</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={selected === 'jwt' ? 'active' : ''} onClick={() => setSelected('jwt')}>
                    <img src={require("../assets/images/jwt.svg")} alt="" />
                    <span>{JWT}</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={selected === 'auth0' ? 'active' : ''} onClick={() => setSelected('auth0')}>
                    <img src={require("../assets/images/auth0.svg")} alt="" />
                    <span>{AUTH0}</span>
                    </NavLink>
                  </NavItem>
                </Nav> */}
                <TabContent activeTab={selected} className="content-login">
                  <TabPane  className="fade show" tabId={selected === "firebase" ? "firebase" : "jwt"}>
                    <Form className="theme-form">
                      <h4>{trans.login_title}</h4>
                      <p>{trans.login_descr}</p>
                      <FormGroup>
                        <Label className="col-form-label">{trans.email_field}</Label>
                        <Input className="form-control" type="email" required="" onChange={e => setEmail(e.target.value)} defaultValue={email} />
                      </FormGroup>
                      <FormGroup>
                        <Label className="col-form-label">{trans.pass_field}</Label>
                        <Input className="form-control" type={togglePassword ?  "text" : "password"} onChange={e => setPassword(e.target.value)} defaultValue={password} required=""/>
                        <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}><span >{togglePassword ? trans.pass_hide : trans.pass_view}</span></div>
                      </FormGroup>
                      <div className="form-group mb-0">
                        {/* <div className="checkbox ml-3">
                          <Input id="checkbox1" type="checkbox"/>
                          <Label className="text-muted" for="checkbox1">{RememberPassword}</Label>
                        </div> */}
                        <br/>
                        <br/>
                        <a className="link" href="/forgetPwd">{trans.forgot_pass_btn}</a>
                        <br/>
                        <Button color="primary" className="btn-block" disabled={loading ? loading : loading} onClick={(e) => loginWithJwt(e)}>{loading ? "LOADING..." : trans.sign_in_btn }</Button>
                       
                      </div>
                      <br/>
                      <hr/>
                      <p className="mt-4 mb-0">{trans.dont_have_account}<a className="ml-2" href="/register">{trans.create_account_btn}</a></p>

                      {/* <h6 className="text-muted mt-4 or">{"Or Sign in with"}</h6>
                      <div className="social mt-4">
                        <div className="btn-showcase">
                          <Button color="light" onClick={facebookAuth} >
                            <Facebook className="txt-fb" />
                          </Button>
                          <Button color="light" onClick={googleAuth} >
                            <i className="icon-social-google txt-googleplus"></i>
                          </Button>
                          <Button color="light" onClick={twitterAuth} >
                            <Twitter className="txt-twitter" />
                          </Button>
                          <Button color="light" onClick={githubAuth} >
                            <GitHub />
                          </Button>
                        </div>
                      </div>
                      <p className="mt-4 mb-0">{"Don't have account?"}<a className="ml-2" href="#javascript">{CreateAccount}</a></p> */}
                    </Form>
                  </TabPane>
                  {/* <TabPane  className="fade show" tabId="auth0">
                    <div className="auth-content">
                        <img src={require("../assets/images/auth-img.svg")} alt="" />
                        <h4>{"Welcome to login with Auth0"}</h4>
                        <p>{"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy"}</p>
                        <Button color="info" onClick={loginWithRedirect}>{AUTH0}</Button> 
                    </div>
                  </TabPane> */}
                </TabContent>
              </div>
            </div>
          </div>
        </Col>
        </Row>
        </Container>
    );
}

export default withRouter(Logins);