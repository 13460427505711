import React,{useState,useEffect} from 'react';
import {Container,Row,Col,Form,FormGroup,Label,Input,Button} from 'reactstrap' 

import {useDispatch, useSelector} from 'react-redux';
import {NotificationContainer, NotificationManager} from "react-notifications";

import {forgotPass} from '../../redux/auth/action';

const Forgetpwd = (props) => {

  const dispatch = useDispatch();
  const trans = useSelector(({translation}) => translation.translationsData);
  const [email,setEmail] = useState("");

  const forgotPassData = useSelector(({auth}) => auth.forgotPass);

    useEffect(() => {
      if (forgotPassData != null) {
        console.log(forgotPassData);
        if (forgotPassData.status){
          NotificationManager.listNotify = [];
          NotificationManager.success(forgotPassData.message);
          setEmail('');
          setTimeout(function () {
            window.location.href = `${process.env.PUBLIC_URL}/login`
           }, 2000);
        } else {
          NotificationManager.listNotify = [];
          NotificationManager.error(forgotPassData.message);
        }
      }
    }, [forgotPassData]);
    
    const sendForgotPass = (e) => {
      e.preventDefault();
      if (email != ""){
        let postData = {email: email}
        dispatch(forgotPass(postData));
      }
    }



    return (
      <div>
        <NotificationContainer/>
      <div>   
      <Container fluid={true}>
      <Row>
          <Col xs="12">     
            <div className="login-card">
              <div>
                <div><a className="logo" href="#javascript"><img className="img-fluid for-light" src={require("../../assets/images/logo/login.png")} alt="looginpage"/><img className="img-fluid for-dark" src={require("../../assets/images/logo/logo_dark.png")} alt="looginpage"/></a></div>
                <div className="login-main"> 
                  <Form className="theme-form">
                    <h4>{trans.forgot_pass_title}</h4>
                    <p>{trans.forgot_pass_descr}</p>
                    <FormGroup>
                      <Label className="col-form-label">{}</Label>
                      <Row>
                        <Col md="12">
                          <FormGroup>
                          <Label className="col-form-label">{trans.email_field}</Label>
                          <Input className="form-control" type="email" required="" onChange={e => setEmail(e.target.value)} defaultValue={email} />
                        </FormGroup>
                          
                        </Col>
                        <Col xs="12">
                          <Button onClick={(e) => sendForgotPass(e)} color="primary" disabled={email == ""} className="btn-block m-t-10" type="submit">{trans.submit_btn}</Button>
                        </Col>
                      </Row>
                    </FormGroup>
                
                  </Form>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
   
      </div>
      
      </div>
    );
}

export default Forgetpwd;