import './notifications.css';
import React, { Fragment,useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter, Media, Form, FormGroup, Label, Input, Button, Pagination,
  PaginationItem,
  PaginationLink } from 'reactstrap'
import axios from 'axios'
import {DATE_TIME_FORMAT, MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete} from '../../constant'
import { useParams } from 'react-router';
import NoAccess from '../../components/reuse/noaccess';
import { getUserData } from '../../redux/user/action';
import { Loader } from 'react-bootstrap-typeahead';
import { toast } from 'react-toastify';
import { editUser, forgotPass, getUser,editRoles, changeAccountStatus } from '../../redux/auth/action';

import DatePicker from "react-datepicker";
import { Link } from 'react-router-dom';
import * as General from '../../util/General'
import SweetAlert from 'sweetalert2'
import { checkUserSub } from '../../redux/subscription/action';
import LoaderBox from '../../components/reuse/loaderbox';
import { map } from 'leaflet';
import { postNotificationsList } from '../../redux/settings/action';
import Moment from "react-moment";


const NotificationsList = (props) => {
  const userLocale = General.getLocale();
    const dispatch = useDispatch();

    const trans = useSelector(({translation}) => translation.translationsData);
    const notificationsData = useSelector(({settings}) => settings.notificationsData);

    const [notificationList, setNotificationList] = useState([]);
    const [curPage, setCurPage] = useState(1);
    
   useEffect(() => {
    fetchNotifications();
  }, []);



  const fetchNotifications = () => {
    let postData = {
        "page": curPage,
        "sort": {"_id":-1}
    };
    dispatch((postNotificationsList(postData)));
  }



  useEffect(() => {
    if (notificationsData != null){
        var ar = [];
        notificationsData.data.forEach(element => {
                ar.push(element);
        });
        setNotificationList(ar);
    }
  }, [notificationsData]);


  const handlePageClick = (e, newPage) => {
    e.preventDefault();
    setCurPage(newPage);
  };


    return (
      <Fragment>
      <Breadcrumb parent={trans.my_notifications} title={trans.my_notifications} />
      <Container fluid={true}>
        <Card>
            <CardBody>
            <div className="table-responsive">
                  <table className="table card-table table-vcenter text-nowrap">
                    <thead>
                      <tr >
                        <th className="font-weight-bold" key={"notification_title"}>{trans.notification_title}</th>
                        <th className="font-weight-bold" key={"notification_description"}>{trans.notification_description}</th>
                        <th className="font-weight-bold" key={"date_field"}>{trans.date_field}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {notificationList.map((item, i) => {
                        // let values = item.values.map((value,valIndex) => {
                        //    return ( <span key={`trans_value_${i}_val_${valIndex}`} className="_translation_value">{`${value.langLoc} - ${value.value}`}</span>)
                        // })


                        return (
                          <tr
                            key={`notification_item_${i}`}
                          >
                            
                            <td>{item.title}</td>
                            <td>{item.msg}</td>
                            <td>
                              <Moment
                                locale={userLocale}
                                format={DATE_TIME_FORMAT}
                                unix
                                >
                                {item.timestamp}
                              </Moment>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
            </CardBody>
            <CardFooter>

            {notificationsData != null ? 
            <div style={{ float: "right" }}>
                    <Pagination aria-label="" className="pagination-secondary">
                      <PaginationItem disabled={curPage <= 0}>
                        <PaginationLink
                          onClick={(e) => handlePageClick(e, curPage - 1)}
                          previous
                          href="#"
                        />
                      </PaginationItem>

                      {[...Array(notificationsData.pages)].map((page, i) => (
                        <PaginationItem active={i === curPage} key={i}>
                          <PaginationLink
                            onClick={(e) => handlePageClick(e, i)}
                            href="#"
                          >
                            {i + 1}
                          </PaginationLink>
                        </PaginationItem>
                      ))}

                      <PaginationItem
                        disabled={curPage >= notificationsData.pages - 1}
                      >
                        <PaginationLink
                          onClick={(e) => handlePageClick(e, curPage + 1)}
                          next
                          href="#"
                        />
                      </PaginationItem>
                    </Pagination>
                  </div> : <div></div> }
            </CardFooter>
        </Card>
      </Container>
    </Fragment>
  );
}

export default NotificationsList;