import { getUserLocales } from 'get-user-locale';

import * as React from 'react';
import { USER_LOCALE } from '../redux/actionTypes';

export const numberNames = (value) => {
    var newValue = value;
    if (value >= 1000) {
        var suffixes = ["", "k", "m", "b","t"];
        var suffixNum = Math.floor( (""+value).length/3 );
        var shortValue = '';
        for (var precision = 2; precision >= 1; precision--) {
            shortValue = parseFloat( (suffixNum !== 0 ? (value / Math.pow(1000,suffixNum) ) : value).toPrecision(precision));
            var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g,'');
            if (dotLessShortValue.length <= 2) { break; }
        }
        if (shortValue % 1 !== 0)  shortValue = shortValue.toFixed(1);
        newValue = shortValue+suffixes[suffixNum];
    }
    return newValue;
}

export const isEmptyOrSpaces = (str) => {
    return str === null || str.match(/^ *$/) !== null;
}


export const findHashtags = (searchText) => {
    var regexp = /\B\#\w\w+\b/g
    var result = searchText.match(regexp);
    var newResult = [];
    if (result === null){
      return null;
    }
    result.forEach(hashtag => {
      let cleanHashtag = hashtag.replace("#", "");
      newResult.push(cleanHashtag);
    });
    return newResult;
}

export const insert = (str, index, value) => {
    return str.substr(0, index) + value + str.substr(index);
}

export const validURL =(str) => {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
}

export const removeAccents = (strAccents) => {
   var strAccents = strAccents.split('');
   var strAccentsOut = new Array();
   var strAccentsLen = strAccents.length;
   var accents = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
   var accentsOut = "AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";
   for (var y = 0; y < strAccentsLen; y++) {
     if (accents.indexOf(strAccents[y]) != -1) {
       strAccentsOut[y] = accentsOut.substr(accents.indexOf(strAccents[y]), 1);
     } else
       strAccentsOut[y] = strAccents[y];
   }
   strAccentsOut = strAccentsOut.join('');
   return strAccentsOut.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
 }

 export const setLocale = (locale) => {
  localStorage.setItem(USER_LOCALE,locale)
  return locale;
 }

export const getLocale = () => {
  try{
    var userLocale =  localStorage.getItem(USER_LOCALE);
    if (userLocale == null || userLocale == ""){
      userLocale = getUserLocales()[0];
    }
    if (userLocale.includes("-")){
      userLocale = userLocale.split('-')[0];
    }
  return userLocale;
  } catch(ex) {
    return "en";
  }
}


export const getISOWeekInMonth = (d) => {
    var date = d.getDate();
    var day = d.getDay();

    return Math.ceil((date + 6 - day)/7);
  }


 
export const getISOMonth = (d) =>{
    return d.getMonth() + 1;
}


export const getISOYear = (d) =>{
    return d.getFullYear();
  }

 export const getThisWeekDates = (current) => {
    var week= new Array();
    // Starting Monday not Sunday
    current.setDate((current.getDate() - current.getDay() +1));
    for (var i = 0; i < 7; i++) {
        week.push(
            new Date(current)
        );
        current.setDate(current.getDate() +1);
    }
    return week;
}

export const getDaysInMonth = (month, year) => {
    var date = new Date(year, month-1, 1);
    var days = [];
    while (date.getMonth() == month-1) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return days;
  }

  export const getId = (url) => {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length == 11) {
        return match[2];
    } else {
        return 'error';
    }
}


export const isSameDateAs = function(sDate,pDate) {
  return (
    sDate.getFullYear() === pDate.getFullYear() &&
    sDate.getMonth() === pDate.getMonth() &&
    sDate.getDate() === pDate.getDate()
  );
}


export const httpHtml =  function(content) {
  const reg = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g;
  return content.replace(reg, "<a style='color:red;' target='_blank' href='$1$2'>$1$2</a>");
}