import {
  FETCH_BLOGS,
  ADD_BLOG,
  EDIT_BLOG,
  DELETE_BLOG,
  SINGLE_BLOG,
USER_ID_SET,
ATHLETES_LAB
  } from '../actionTypes';
  import axios from '../../util/Api'
  import * as General from '../../util/General'
  
  import {GENERAL_ERROR} from '../../constant';
  
  

  export const getBlogs = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;
    console.log(postData);
    return (dispatch) => {
      axios.post('blog/all', postData
      ).then(({data}) => {
         console.log("blogs",data);
        if (data){
          dispatch({type: FETCH_BLOGS, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };

  export const addBlog = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.creator = userId;
    postData.langLoc = userLocale;
   // console.log(postData);
    return (dispatch) => {
      axios.post('blog/add', postData
      ).then(({data}) => {
        // console.log(data);
        if (data){
          dispatch({type: ADD_BLOG, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };


  export const editBlog = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;
    //console.log(postData);
    return (dispatch) => {
      axios.post('blog/edit', postData
      ).then(({data}) => {
        // console.log(data);
        if (data){
          dispatch({type: EDIT_BLOG, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };
  
  export const deleteBlog = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;
    //console.log(postData);
    return (dispatch) => {
      axios.post('blog/delete', postData
      ).then(({data}) => {
        // console.log(data);
        if (data){
          dispatch({type: DELETE_BLOG, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };
  
  export const singleBlog = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;
    //console.log(postData);
    return (dispatch) => {
      axios.post('blog/single', postData
      ).then(({data}) => {
        // console.log(data);
        if (data){
          dispatch({type: SINGLE_BLOG, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };

  export const fetchAthletesLab = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;
    //console.log(postData);
    return (dispatch) => {
      axios.post('blogcategory/all', postData
      ).then(({data}) => {
        // console.log(data);
        if (data){
          dispatch({type: ATHLETES_LAB, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };