import './nutrition.css';
import React, { Fragment,useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter, Media, Form, FormGroup, Label, Input, Button , Pagination,
  PaginationItem,
  PaginationLink  } from 'reactstrap'
  import { useHistory } from 'react-router-dom';
import axios from 'axios'
import { DATE_TIME_FORMAT,MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, PaddingRight} from '../../constant'
import { useParams } from 'react-router';
import NoAccess from '../../components/reuse/noaccess';
import { getUserData } from '../../redux/user/action';
import { Loader } from 'react-bootstrap-typeahead';
import { toast } from 'react-toastify';
import { editUser, forgotPass, getUser,editRoles, changeAccountStatus } from '../../redux/auth/action';

import DatePicker from "react-datepicker";
import { Link } from 'react-router-dom';
import * as General from '../../util/General'
import SweetAlert from 'sweetalert2'
import { checkUserSub } from '../../redux/subscription/action';
import LoaderBox from '../../components/reuse/loaderbox';
import { getNutritionMenu } from '../../redux/nutrition/nutritionMenu/action';
import Moment from "react-moment";

const Nutrition = (props) => {

  const history = useHistory();
  const userLocale = General.getLocale();
    const dispatch = useDispatch();

    const trans = useSelector(({translation}) => translation.translationsData);
    const nutritionMenuData = useSelector(({nutritionMenu}) => nutritionMenu.fetchNutritionMenuData);

    

    const userIsAdmin = useSelector(({auth}) => auth.userIsAdmin);
    const userIsManager = useSelector(({auth}) => auth.userIsManager);
    const userIsCoach = useSelector(({auth}) => auth.userIsCoach);

    const [menusList, setMenusList] = useState([]);
  
    const [nutritionMenuLoading, setNutritionMenuLoading] = useState(false);

    const checkSubData = useSelector(({subscription}) => subscription.checkSubData);

    const [userHasOneSub,setUserHasOneSub] = useState(false);

    var selectedType = "home";
    let { id } = useParams();
    if (id != null){
      selectedType = id;
    }


 
    useEffect(() => {
      //LETS RELOAD THE NUTRITION MENUS
      fetchMenus();
    }, [selectedType]);


    //WHEN COMPONENT LOADS
    useEffect(() => {
      //LETS GET THE NUTRITION MENUS
      fetchMenus();
      getUserSubs();
    }, []);


  useEffect(() => {
    if (checkSubData != null){
      setUserHasOneSub(checkSubData.hasOneSub)
    }
   
  }, [checkSubData]);


    const getUserSubs = () => {
      let postData = {}
      dispatch(checkUserSub(postData));
    }
  

  const fetchMenus = () => {
    let postData = {
        "fromApp": true,
        "parent": selectedType
    };
    setNutritionMenuLoading(true);
    dispatch(getNutritionMenu(postData));
   }


   useEffect(() => {
    if (nutritionMenuData != null){
      //SET THE DATA ARE LOADED
      setNutritionMenuLoading(false);

      //ADD THEM TO THE LIST
      var data = [];
      nutritionMenuData.data.forEach(element => {
        data.push(element);
      });
      setMenusList(data);
    }
  }, [nutritionMenuData]);


  const openNutritionMenu = (item) => {

    if (item.hasMore){
      history.push(`/app/nutrition/${item.id}`);
    }else {
      history.push(`/app/nutritionplan/${item.id}`);
    }
    
  }

  const handleNutritionToolClick = (e,slug) => {
    e.preventDefault();
    switch (slug) {
      case "nutrition_menu_type":
        history.push(`/app/nutritionmenutype`);
        break;
      case "nutrition_menu":
        history.push(`/app/nutritionmenu`);
        break;  
      case "nutrition_plan":
        history.push(`/app/nutritionplan-build`);
        break;  
      case "nutrition_meal":
        history.push(`/app/nutritionmeal-build`);
        break;  
      default:
        break;
    }
  }

  function getNutritionAdminToolsUI() {
    if (userIsAdmin || userIsManager){
        return (<div>
          <Card>

          <CardBody>
            <Row>

              <Col>
                  <Card>
                    <div style={{padding:"20px"}}>
                      <div>
                        <span style={{fontWeight:'600'}}>{trans.nutrition_menu_types}</span>
                      </div>
                      <br/>
                      <div >
                      <Button style={{float:'right'}} onClick={(e) => {handleNutritionToolClick(e,"nutrition_menu_type")}} color="primary" size="sm"><i className="fa fa-pencil"></i> {trans.nutrition_add_edit}</Button>
                      </div>
                    </div>
                  </Card>
              </Col>
              <Col>
                  <Card>
                    <div style={{padding:"20px"}}>
                      <div>
                        <span style={{fontWeight:'600'}}>{trans.nutrition_menus}</span>
                      </div>
                      <br/>
                      <div >
                      <Button style={{float:'right'}}  onClick={(e) => {handleNutritionToolClick(e,"nutrition_menu")}} color="primary" size="sm"><i className="fa fa-pencil"></i> {trans.nutrition_add_edit}</Button>
                      </div>
                    </div>
                  </Card>
              </Col>
              <Col>
                  <Card>
                    <div style={{padding:"20px"}}>
                      <div>
                        <span style={{fontWeight:'600'}}>{trans.nutrition_plans}</span>
                      </div>
                      <br/>
                      <div >
                      <Button style={{float:'right'}} onClick={(e) => {handleNutritionToolClick(e,"nutrition_plan")}} color="primary" size="sm"><i className="fa fa-pencil"></i> {trans.nutrition_add_edit}</Button>
                      </div>
                    </div>
                  </Card>
              </Col>

              <Col>
                  <Card>
                    <div style={{padding:"20px"}}>
                      <div>
                        <span style={{fontWeight:'600'}}>{trans.nutrition_meals}</span>
                      </div>
                      <br/>
                      <div >
                      <Button style={{float:'right'}} onClick={(e) => {handleNutritionToolClick(e,"nutrition_meal")}} color="primary" size="sm"><i className="fa fa-pencil"></i> {trans.nutrition_add_edit}</Button>
                      </div>
                    </div>
                  </Card>
              </Col>

              

            </Row>
            

          </CardBody>

          </Card>
      </div>) 
      } else {
        return (<div></div>)
      }
  }

  function getNutritionMenuUI(){
    if (nutritionMenuLoading){
      return(<div>
           <Card>
            <CardBody>
              <LoaderBox/>
            </CardBody>
            </Card>
      </div>)
    } else {

      var menusUI = [];

      menusList.forEach(element => {
        menusUI.push(<Col md={6} sm={12} xs={12} lg={6} key={element.id}>
            <div onClick={(e) => {openNutritionMenu(element)}} style={{cursor:'pointer'}}>
              <div className="_nutrition_card">
                <div style={{textAlign:'center',display:'block'}}>
                  <div style={{fontWeight:'700'}}>
                    <span >{element.title}</span>
                  </div>
                  <br/>
                  <img className='_nutrition_card_img' src={element.imgObj.img_full} alt="" />
                </div>
              </div>
            </div>
        </Col>)
      });

      if (userIsAdmin || userIsCoach || userHasOneSub){
        return(<div>
          <Card>
            <CardHeader>
              <h5>{trans.nutrition_select_menu}</h5>
            </CardHeader>
           <CardBody>
              <Row>
                {menusUI}
              </Row>
           </CardBody>
           </Card>
     </div>)
      } else {
        return (<NoAccess/>)
      }

     
    }
  }

  return (
      <Fragment>
      <Breadcrumb parent={trans.bottom_nav_nutrition} title={trans.bottom_nav_nutrition} />
      <Container fluid={true}>
          {getNutritionAdminToolsUI()}
          {getNutritionMenuUI()}
      </Container>
    </Fragment>
  );
}

export default Nutrition;