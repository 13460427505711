import './users.css';
import React, { Fragment,useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter, Media, Form, FormGroup, Label, Input, Button } from 'reactstrap'
import axios from 'axios'
import { MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete} from '../../constant'
import { useParams } from 'react-router';
import NoAccess from '../../components/reuse/noaccess';
import { getUserData } from '../../redux/user/action';
import { Loader } from 'react-bootstrap-typeahead';
import { toast } from 'react-toastify';
import { editUser, forgotPass, getUser,editRoles, changeAccountStatus } from '../../redux/auth/action';

import DatePicker from "react-datepicker";
import { Link } from 'react-router-dom';
import * as General from '../../util/General'
import SweetAlert from 'sweetalert2'
import { checkUserSub } from '../../redux/subscription/action';
import LoaderBox from '../../components/reuse/loaderbox';


const UserGeneralSettings = (props) => {

    const dispatch = useDispatch();

    //LETS GET THE TRANSLATIONS
    const trans = useSelector(({translation}) => translation.translationsData);

    //LETS GET THE LOGGED IN USER DATA
   const userInfo = useSelector(({auth}) => auth.authUser);


   const [languageValue,setLanguageValue] = useState("en");

   useEffect(() => {
    const userLocale = General.getLocale();
    setLanguageValue(userLocale);
  }, []);

   

   const changeSelectedLanguage = (lang) => {
   
      General.setLocale(lang);

      window.location.reload(); 
 
   }

   const changeLanguageAttempt = (newValue) => {

    if (newValue != languageValue) { 
      SweetAlert.fire({
        title:trans.language_setting_title,
        text:trans.change_lang_alert,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText:trans.ok_btn,
        cancelButtonText: trans.cancel_btn,
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
          changeSelectedLanguage(newValue);
        } else {
          
        }
      });
    }
    
   }

    return (
            <Card>
                <CardHeader>
                        <h4 className="font-weight-bold">{trans.settings_title}</h4>
                </CardHeader>
                <CardBody>
                   <Row>
                     <Col>
                      <FormGroup>
                        <Label className="form-label">{trans.language_setting_title}</Label>
                        <Input type="select" name="select" className="form-control btn-square" value={languageValue} onChange={(e)=>{changeLanguageAttempt(e.target.value)}}>
                      
                         <option  key={"lang_en"} value={"en"}>Εnglish</option>
                         <option key={"lang_el"} value={"el"}>Ελληνικά</option>
                        </Input>
                      </FormGroup>
                     </Col>   
                    </Row>  
                </CardBody>
            </Card>
  );
}

export default UserGeneralSettings;