import {
    FETCH_BLOGS,
    ADD_BLOG,
    EDIT_BLOG,
    DELETE_BLOG,
    SINGLE_BLOG,
    ATHLETES_LAB} from '../actionTypes';

  const INIT_STATE = {
        fetchBlogData: null,
        addBlogData: null,
        editBlogData: null,
        deleteBlogData: null,
        singleBlogData: null,
        athletesLabData: null
    };
    
    export default (state = INIT_STATE, action) => {
        switch(action.type) {
        case FETCH_BLOGS:
             return {
                ...state,
                fetchBlogData: action.payload
        }
        case ADD_BLOG:
             return {
                ...state,
                addBlogData: action.payload
        }
        case EDIT_BLOG:
             return {
                ...state,
                editBlogData: action.payload
        }
        case DELETE_BLOG:
            return {
               ...state,
               deleteBlogData: action.payload
       }
       case SINGLE_BLOG:
        return {
           ...state,
           singleBlogData: action.payload
        }
       case ATHLETES_LAB:
        return {
           ...state,
           athletesLabData: action.payload
        }
        default:
              return state;
        }
    }
    