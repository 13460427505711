import {
    CHAT_SEND,
    CHAT_CREATE_ROOM,
    CHAT_ROOMS_LIST,
    CHAT_MESSAGES,
    CHAT_ADD_REMOVE_TO_ROOM,
    CHAT_DELETE_ROOM,
    CHAT_DELETE_MESSAGE,
    CHAT_CHANGE_READ_MESSAGE
} from '../actionTypes';

  const INIT_STATE = {
    sendChatMessageData: null,
    createChatRoomData: null,
    chatRoomsListData: null,
    chatMessagesData: null,
    chatAddRemoveToRoomData: null,
    chatDeleteRoom: null,
    chatDeleteMesssage: null,
    chatReadMessageData: null
    };
    
    export default (state = INIT_STATE, action) => {
        switch(action.type) {
        case CHAT_SEND:
             return {
                ...state,
                sendChatMessageData: action.payload
        }
        case CHAT_CREATE_ROOM:
             return {
                ...state,
                createChatRoomData: action.payload
        }
        case CHAT_ROOMS_LIST:
             return {
                ...state,
                chatRoomsListData: action.payload
        }
        case CHAT_MESSAGES:
            return {
               ...state,
               chatMessagesData: action.payload
       }
       case CHAT_ADD_REMOVE_TO_ROOM:
        return {
           ...state,
           chatAddRemoveToRoomData: action.payload
        }
        case CHAT_DELETE_ROOM:
            return {
                ...state,
                chatDeleteRoom: action.payload
        }
        case CHAT_DELETE_MESSAGE:
            return {
                ...state,
                chatDeleteMesssage: action.payload
        }
        case CHAT_CHANGE_READ_MESSAGE:
            return {
                ...state,
                chatReadMessageData: action.payload
        }

        
        default:
              return state;
        }
    }
    