import {
    FETCH_CLIENT_TOKEN,
    GET_SUBSCRIPTION,
    CHECK_SUB,
    CUSTOM_SUBS,
    CREATE_CUSTOM_SUB,
    DELETE_CUSTOM_SUB,
    CANCEL_SUB
} from '../actionTypes';

  const INIT_STATE = {
    clientTokenData: null,
    getSubData: null,
    checkSubData: null,
    customSubsData: null,
    createCustomSubsData: null,
    deleteCustomSubsData: null,
    cancelSubData: null
    };
    
    export default (state = INIT_STATE, action) => {
        switch(action.type) {
        case FETCH_CLIENT_TOKEN:
             return {
                ...state,
            clientTokenData: action.payload
        }
        case GET_SUBSCRIPTION:
            return {
               ...state,
            getSubData: action.payload
        }
        case CHECK_SUB:
            return {
               ...state,
               checkSubData: action.payload
        }
        case CUSTOM_SUBS:
            return {
               ...state,
               customSubsData: action.payload
        }
        case CREATE_CUSTOM_SUB:
            return {
               ...state,
               createCustomSubsData: action.payload
        }
        case DELETE_CUSTOM_SUB:
            return {
               ...state,
               deleteCustomSubsData: action.payload
        }
        case CANCEL_SUB:
            return {
               ...state,
               cancelSubData: action.payload
        }
        default:
              return state;
        }
    }
    