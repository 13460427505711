/* TODO APP*/
export const WATCH_TODO_LIST = "WATCH_TODO_LIST";
export const GET_TODO_LIST = "GET_TODO_LIST";
export const GET_LIST_SUCCESS = "GET_LIST_SUCCESS";
export const MARK_ALL_ITEMS = "MARK_ALL_ITEMS";
export const ADD_NEW_ITEM = "ADD_NEW_ITEM";
export const REMOVE_ITEM = "REMOVE_ITEM";
export const SELECTED_ITEM = "SELECTED_ITEM";

/* ECOMMERRCE APP */
export const GET_LIST = "GET_LIST";
export const WATCH_PRODUCT_LIST = "WATCH_PRODUCT_LIST";
export const WATCH_SINGLE_ITEM = "WATCH_SINGLE_ITEM"
export const GET_ALL_PRODUCT = "GET_ALL_PRODUCT";
export const GET_SINGLE_ITEM = "GET_SINGLE_ITEM";
export const DECREMENT_QTY = "DECREMENT_QTY";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const REMOVE_FROM_WISHLIST = "REMOVE_FROM_WISHLIST";

/* Bookmark App */
export const WATCH_BOOKMARK_LIST = "WATCH_BOOKMARK_LIST"
export const GET_BOOKMARK_LIST = "GET_BOOKMARK_LIST"
export const ADD_TO_MY_BOOKMARK = "ADD_TO_MY_BOOKMARK"
export const ADD_NEW_BOOKMARK = "ADD_NEW_BOOKMARK"
export const REMOVE_BOOKMARK = "REMOVE_BOOKMARK"
export const REMOVE_FROM_MY_BOOKMARK = "REMOVE_FROM_MY_BOOKMARK"
export const UPDATE_BOOKMARK = "UPDATE_BOOKMARK"
export const UPDATE_MY_BOOKMARK = "UPDATE_MY_BOOKMARK"

// Project 
export const ADD_NEW_PROJECT = "ADD_NEW_PROJECT"


/* Task App */

export const WATCH_TASK_LIST = "WATCH_TASK_LIST"
export const FETCH_ALL_TASK = "FETCH_ALL_TASK"
export const NEW_TASK = "NEW_TASK"
export const REMOVE_TASK = "REMOVE_TASK"

/* Cart */
export const ADD_TO_CART = 'ADD_TO_CART';

/* Wishlist */
export const ADD_TO_WISHLIST = 'ADD_TO_WISHLIST';

/* Filters */
export const FILTER_BRAND = 'FILTER_BRAND';
export const FILTER_COLOR = 'FILTER_COLOR';
export const FILTER_PRICE = 'FILTER_PRICE';
export const SEARCH_BY = 'SEARCH_BY';
export const SORT_BY = 'SORT_BY';

/* CHAT APP */
export const GET_MEMBERS = "GET_MEMBERS";
export const WATCH_CHAT_MEMBERS = "WATCH_CHAT_MEMBERS";
export const WATCH_CHAT_SUCCESS = "WATCH_CHAT_SUCCESS";
export const WATCH_ALL_CHAT_SUCCESS = "WATCH_ALL_CHAT_SUCCESS";
export const GET_MEMBERS_SUCCESS = "GET_MEMBERS_SUCCESS";
export const GET_CHATS = "GET_CHATS";
export const GET_CHATS_SUCCESS = "GET_CHATS_SUCCESS";
export const UPDATE_SELECTED_USER = "UPDATE_SELECTED_USER";
export const CHANGE_CHAT = "CHANGE_CHAT";
export const CREATE_CHAT = "CREATE_CHAT";
export const CREATE_CHAT_WATCHER = "CREATE_CHAT_WATCHER";
export const SEARCH_MEMBER = "SEARCH_MEMBER";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const SEND_MESSAGE_WATCHER = "SEND_MESSAGE_WATCHER";
export const REPLY_BY_SELECTED_USER = "REPLY_BY_SELECTED_USER";
export const REPLY_MESSAGE_WATCHER = "REPLY_MESSAGE_WATCHER"

/* Email-app */
export const WATCH_EMAIL = "WATCH_EMAIL";
export const WATCH_ALL_TYPE_EMAIL = "WATCH_ALL_TYPE_EMAIL";
export const GET_ALL_EMAIL_ASYN = "GET_ALL_EMAIL_ASYN";
export const GET_ALL_TYPE_ASYN = "GET_ALL_TYPE_ASYN";
export const GET_EMAILS = "GET_EMAILS";
export const GET_ALL_EMAILS = "GET_ALL_EMAILS";
export const GET_EMAIL_TYPES = "GET_EMAIL_TYPES";
export const UPDATE_EMAIL_TYPES = "UPDATE_EMAIL_TYPES";


/* CUSTOMIZER */
export const WATCH_CUSTOMIZER ="WATCH_CUSTOMIZER";
export const ADD_COSTOMIZER ="ADD_COSTOMIZER";
export const ADD_LAYOUT = "ADD_LAYOUT";
export const ADD_SIDEBAR_TYPES = "ADD_SIDEBAR_TYPES";
export const ADD_SIDEBAR_SETTINGS ="ADD_SIDEBAR_SETTINGS";
export const ADD_COLOR = "ADD_COLOR";
export const ADD_MIX_BACKGROUND_LAYOUT = "ADD_MIX_BACKGROUND_LAYOUT";
export const ROUTER_ANIMATION = "ROUTER_ANIMATION";


//NEW ADDED

//FETCH
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_DATA = 'FETCH_DATA';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';
export const RESET_STORE = 'RESET_STORE';

//AUTH
export const SIGNUP_USER = 'signup_user';
export const SIGNUP_USER_SUCCESS = 'signup_user_success';
export const SIGNIN_GOOGLE_USER = 'signin_google_user';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'signin_google_user_success';
export const SIGNIN_FACEBOOK_USER = 'signin_facebook_user';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'signin_facebook_user_success';
export const SIGNIN_TWITTER_USER = 'signin_twitter_user';
export const SIGNIN_TWITTER_USER_SUCCESS = 'signin_twitter_user_success';
export const SIGNIN_GITHUB_USER = 'signin_github_user';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_SUCCESS';
export const SIGNIN_USER = 'signin_user';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNOUT_USER = 'signout_user';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'init_url';
export const USER_DATA = 'user_data';
export const USER_TOKEN_SET = 'user_token_set';
export const USER_ID_SET = 'USER_ID_SET';
export const USER_INFO_STORED = "USER_INFO_STORED";
export const USER_LOCALE = 'USER_LOCALE';
export const USER_PROGRAM_TRACK = "USER_PROGRAM_TRACK";
export const USER_PROGRAM_TRAINING = "USER_PROGRAM_TRAINING";

//USERS
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const FETCH_USERS = 'FETCH_USERS';
export const SINGLE_USER = 'SINGLE_USER';
export const EDIT_USER = 'EDIT_USER';
export const FORGOT_PASS = 'FORGOT_PASS';
export const RESET_PASS = 'RESET_PASS';
export const SIGN_OUT = "SIGN_OUT";
export const SIGN_IN = "SIGN_IN";
export const UPDATE_USER_ROLES = 'UPDATE_USER_ROLES';
export const UPLOAD_PROFILE_IMAGE = 'UPLOAD_PROFILE_IMAGE';
export const CHANGE_STATUS = 'CHANGE_STATUS';
export const UPDATE_LAST_ONLINE = 'UPDATE_LAST_ONLINE';
export const USER_IS_ADMIN = "USER_IS_ADMIN";
export const USER_IS_MANAGER = "USER_IS_MANAGER";
export const USER_IS_COACH = "USER_IS_COACH";
export const USER_NOTIFICATION_SETTINGS = 'USER_NOTIFICATION_SETTINGS';
export const USER_CHANGE_NOTIFICATION_SETTING = 'USER_CHANGE_NOTIFICATION_SETTING';
export const EDIT_USER_SELECTED_TRACK = 'EDIT_USER_SELECTED_TRACK';

//TRANSLATIONS
export const FETCH_ADMIN_TRANSLATIONS = 'FETCH_ADMIN_TRANSLATIONS';
export const FETCH_TRANSLATIONS = 'FETCH_TRANSLATIONS';
export const ADD_TRANSLATION = 'ADD_TRANSLATION';
export const EDIT_TRANSLATION = 'EDIT_TRANSLATION';
export const DELETE_TRANSLATION = 'DELETE_TRANSLATION';
export const STORED_TRANSLATIONS = 'STORED_TRANSLATIONS';

//USERS
export const FETCH_ADMIN_USERS = 'FETCH_ADMIN_USERS';
export const FETCH_VIEW_USER_DATA = 'FETCH_VIEW_USER_DATA';
export const FETCH_COACHES = "FETCH_COACHES";

//SETTINGS
export const FETCH_SETTINGS = 'FETCH_SETTINGS';
export const SEND_PUSH_DATA = 'SEND_PUSH_DATA';
export const NOTIFICATIONS_LIST = 'NOTIFICATIONS_LIST';

//FEEDBACK
export const FETCH_FEEDBACKS = "FETCH_FEEDBACKS";
export const ADD_FEEDBACK = "ADD_FEEDBACK";

//USERMEDIA
export const FETCH_USER_MEDIA = 'FETCH_USER_MEDIA';
export const ADD_USER_MEDIA = 'ADD_USER_MEDIA';
export const EDIT_USER_MEDIA = 'EDIT_USER_MEDIA';
export const DELETE_USER_MEDIA = 'DELETE_USER_MEDIA';
export const SINGLE_USER_MEDIA = 'SINGLE_USER_MEDIA';

//UPLOAD
export const UPLOAD_IMAGE_FILE = 'UPLOAD_IMAGE_FILE';
export const UPLOAD_VIDEO_FILE = 'UPLOAD_VIDEO_FILE';

//SCORETYPES
export const FETCH_SCORETYPES = "FETCH_SCORETYPES";

//TRAININGS
export const FETCH_TRAININGS = "FETCH_TRAININGS";

//TRACKS
export const FETCH_TRACKS = "FETCH_TRACKS";
export const ADD_TRACK = "ADD_TRACK";
export const EDIT_TRACK = "EDIT_TRACK";
export const DELETE_TRACK = "DELETE_TRACK";

//WORKOUTS
export const FETCH_WORKOUTS = "FETCH_WORKOUTS";
export const ADD_WORKOUT = "ADD_WORKOUT";
export const EDIT_WORKOUT_DATA = "EDIT_WORKOUT_DATA";
export const SINGLE_WORKOUT = "SINGLE_WORKOUT";
export const DELETE_WORKOUT = "DELETE_WORKOUT";

//SCHEDULE
export const FETCH_SCHEDULES = "FETCH_SCHEDULES";
export const ADD_SCHEDULE = "ADD_SCHEDULE";
export const EDIT_SCHEDULE = "EDIT_SCHEDULE";
export const SINGLE_SCHEDULE = "SINGLE_SCHEDULE";
export const DELETE_SCHEDULE = "DELETE_SCHEDULE";
export const CLONE_WEEK = "CLONE_WEEK";
export const EDIT_SCHEDULE_WORKOUT = "EDIT_SCHEDULE_WORKOUT";

//DONE SCHEDULE
export const FETCH_DONE_SCHEDULES = "FETCH_DONE_SCHEDULES";
export const ADD_DONE_SCHEDULE = "ADD_DONE_SCHEDULE";
export const EDIT_DONE_SCHEDULE = "EDIT_DONE_SCHEDULE";
export const SINGLE_DONE_SCHEDULE = "SINGLE_DONE_SCHEDULE";
export const DELETE_DONE_SCHEDULE = "DELETE_DONE_SCHEDULE";
export const WORKOUTBOARD_DONE_SCHEDULE = "WORKOUTBOARD_DONE_SCHEDULE";
export const USER_PROGRESS_KGS_LBS = "USER_PROGRESS_KGS_LBS";
export const USER_PROGRESS_METERS = "USER_PROGRESS_METERS";
export const USER_PROGRESS_REPS = "USER_PROGRESS_REPS";
export const USER_PROGRESS_CALORIES = "USER_PROGRESS_CALORIES";
export const USER_PROGRESS_TIME = "USER_PROGRESS_TIME";
export const USER_PROGRESS_CHECKBOX = "USER_PROGRESS_CHECKBOX";
export const USER_STATS = "USER_STATS";

//SUBSCRIPTION
export const FETCH_CLIENT_TOKEN = "FETCH_CLIENT_TOKEN";
export const GET_SUBSCRIPTION = "GET_SUBSCRIPTION";
export const CUSTOM_SUBS = "CUSTOM_SUBS";
export const CREATE_CUSTOM_SUB = "CREATE_CUSTOM_SUB";
export const CHECK_SUB = "CHECK_SUB";
export const DELETE_CUSTOM_SUB = "DELETE_CUSTOM_SUB";
export const CANCEL_SUB = "CANCEL_SUB";

//CHAT
export const CHAT_SEND = "CHAT_SEND";
export const CHAT_CREATE_ROOM = "CHAT_CREATE_ROOM";
export const CHAT_ROOMS_LIST = "CHAT_ROOMS_LIST";
export const CHAT_MESSAGES = "CHAT_MESSAGES";
export const CHAT_ADD_REMOVE_TO_ROOM = "CHAT_ADD_REMOVE_TO_ROOM";
export const CHAT_DELETE_ROOM = "CHAT_DELETE_ROOM";
export const CHAT_DELETE_MESSAGE = "CHAT_DELETE_MESSAGE";
export const CHAT_CHANGE_READ_MESSAGE = "CHAT_CHANGE_READ_MESSAGE";

//BLOG
export const FETCH_BLOGS = "FETCH_BLOGS";
export const ADD_BLOG = "ADD_BLOG";
export const EDIT_BLOG = "EDIT_BLOG";
export const DELETE_BLOG = "DELETE_BLOG";
export const SINGLE_BLOG = "SINGLE_BLOG";
export const ATHLETES_LAB = "ATHLETES_LAB";


//SCORETYPES
export const FETCH_PRWORKOUTS = "FETCH_PRWORKOUTS";
export const DONE_PRWORKOUT_SUBMIT = "DONE_PRWORKOUT_SUBMIT";
export const DONE_PRWORKOUT_HISTORY = "DONE_PRWORKOUT_HISTORY";
export const DONE_PRWORKOUT_EDIT = "DONE_PRWORKOUT_EDIT";
export const DONE_PRWORKOUT_DELETE = "DONE_PRWORKOUT_DELETE";

//NUTRITION MENU TYPE
export const FETCH_NUTRITION_MENU_TYPE = "FETCH_NUTRITION_MENU_TYPE";
export const ADD_NUTRITION_MENU_TYPE = "ADD_NUTRITION_MENU_TYPE";
export const EDIT_NUTRITION_MENU_TYPE = "EDIT_NUTRITION_MENU_TYPE";
export const DELETE_NUTRITION_MENU_TYPE = "DELETE_NUTRITION_MENU_TYPE";
export const SINGLE_NUTRITION_MENU_TYPE = "SINGLE_NUTRITION_MENU_TYPE";

//NUTRITION MENU
export const FETCH_NUTRITION_MENU = "FETCH_NUTRITION_MENU";
export const ADD_NUTRITION_MENU = "ADD_NUTRITION_MENU";
export const EDIT_NUTRITION_MENU = "EDIT_NUTRITION_MENU";
export const DELETE_NUTRITION_MENU = "DELETE_NUTRITION_MENU";
export const SINGLE_NUTRITION_MENU = "SINGLE_NUTRITION_MENU";

//NUTRITION PLAN
export const FETCH_NUTRITION_PLAN = "FETCH_NUTRITION_PLAN";
export const ADD_NUTRITION_PLAN = "ADD_NUTRITION_PLAN";
export const EDIT_NUTRITION_PLAN = "EDIT_NUTRITION_PLAN";
export const DELETE_NUTRITION_PLAN = "DELETE_NUTRITION_PLAN";
export const SINGLE_NUTRITION_PLAN = "SINGLE_NUTRITION_PLAN";

//NUTRITION MEAL
export const FETCH_NUTRITION_MEAL = "FETCH_NUTRITION_MEAL";
export const ADD_NUTRITION_MEAL = "ADD_NUTRITION_MEAL";
export const EDIT_NUTRITION_MEAL = "EDIT_NUTRITION_MEAL";
export const DELETE_NUTRITION_MEAL = "DELETE_NUTRITION_MEAL";
export const SINGLE_NUTRITION_MEAL = "SINGLE_NUTRITION_MEAL";

//NUTRITION MEAL
export const FETCH_NUTRITION_USER_MEAL = "FETCH_NUTRITION_USER_MEAL";
export const ADD_NUTRITION_USER_MEAL = "ADD_NUTRITION_USER_MEAL";
export const EDIT_NUTRITION_USER_MEAL = "EDIT_NUTRITION_USER_MEAL";
export const DELETE_NUTRITION_USER_MEAL = "DELETE_NUTRITION_USER_MEAL";
export const SINGLE_NUTRITION_USER_MEAL = "SINGLE_NUTRITION_USER_MEAL";