import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, Media, TabContent, TabPane, Nav, NavItem, NavLink,Button } from 'reactstrap';
import {Email,MarekjecnoMailId,BOD,DDMMYY,Designer,ContactUs,ContactUsNumber,LocationDetails,JOHANDIO,UserProfileDesc1,UserProfileDesc2,UserProfileDesc3,Comment,MarkJecno,Like,Follower,Following,Location} from '../../constant'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getUserData } from '../../redux/user/action';
import { getUser, postUploadUserImage } from '../../redux/auth/action';
import UserMedia from './userMedia';
import { FETCH_VIEW_USER_DATA } from '../../redux/actionTypes';
import { Link } from "react-router-dom";
import { fetchUserStats } from '../../redux/doneschedule/action';
import UserProgressCalendar from './userProgressCalendar';
import Prworkoutlist from '../prworkout/prworkoutlist';


const UserProfile = (props) => {

  const dispatch = useDispatch();

  //LETS GET THE TRANSLATIONS and settings
  const trans = useSelector(({translation}) => translation.translationsData);
  const settingsData = useSelector(({settings}) => settings.settingsData);

   //LETS GET THE LOGGED IN USER DATA
   const userInfo = useSelector(({auth}) => auth.authUser);
 


  //LETS GET THE VIEW USER DATA
  const [userView, setUserView] = useState(null);
  const [userViewId, setUserViewId] = useState(null);
  const userViewData = useSelector(({user}) => user.userViewData);
  const userStatsData = useSelector(({doneSchedules}) => doneSchedules.userStatsData);



  const userIsAdmin = useSelector(({auth}) => auth.userIsAdmin);  
  const userIsManager = useSelector(({auth}) => auth.userIsManager); 
  const userIsCoach = useSelector(({auth}) => auth.userIsCoach); 



    //SO LETS CREATE A BOOLEAN TO HOLD IF THE USER IS EDITABLE
  const [canEditUser,setCanEditUser] = useState(false);

  //HOLD USER PROFILE IMAGE TO CHANGE LATER
  const [profile_img, setProfileImage] = useState({url: "", file: null});

  //TABS
   const [activeTab, setActiveTab] = useState('1');



  //LETS GRAB THE PASSED NICKNAME
  var userNickname = "";
  let { id } = useParams();
 

   //NOW WHEN THE COMPONENT LOAD WE WILL DO OUR CHECKS
   useEffect(() => {
    if (id != null){
      userNickname = id;
      getUserViewData();
    }
  },[id])


   //NOW WHEN THE COMPONENT LOAD WE WILL DO OUR CHECKS
   useEffect(() => {
   //  console.log("i do stuff");
    //REQUEST THE DATA OF THE USER WE ARE CURRENTLY VIEWING
   

      //IF BOTH USERS HAVE THE SAME ID THEN WE CAN EDIT
      if (userInfo.nickname == userNickname){
        setCanEditUser(true);
      }
      
      //USER CAN ALSO EDIT OTHER USERS IF HE IS A MANAGER OR SUPER ADMIN
      if (userIsAdmin|| userIsManager || userIsCoach){
        setCanEditUser(true);
        return;
      }

  },[])

  useEffect(() => {
    if (userViewData != null){
        setUserView(userViewData);
        if (userViewData != null && userViewData.userInfo != null){
          setProfileImage({url: userViewData.userInfo.img_thumb, file: null})
        }

        getUserStats();

        setUserViewId(userViewData.userInfo.userId);
        
        dispatch({type: FETCH_VIEW_USER_DATA, payload: null});
        
      }
  },[userViewData])

  

  //GET THE USER WE WANT TO SEE DATA
  const getUserViewData = () =>{
    let postData = {
      nickname: userNickname
    };
    dispatch(getUserData(postData))
  }

    //GET THE USER WE WANT TO SEE DATA
   const getUserStats = () =>{
      let postData = {
        userId: userViewData.userInfo.userId
      };
      dispatch(fetchUserStats(postData))
    }

  const uploadProfileImageFile = (event) => {
    if (event.target.files.length === 0)
      return;
    

    var mimeType = event.target.files[0].type;

    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    let file = event.target.files[0];
    
    let reader = new FileReader();
    reader.onloadend = () => {
      setProfileImage({url:reader.result, file: file});
    }
    reader.readAsDataURL(file)

    dispatch(postUploadUserImage(userView.userInfo.userId,file))
  }

  const viewUserTabs = () => {
    return  <TabPane tabId="1">
        <UserMedia key={userView.userId} viewUser={userView.userInfo}/>
    </TabPane>
  }
  

  function renderUserTabs() {
    return (<div className="user-profile social-app-profile">
    <Row>
        <Col sm="12">
            <Card className="hovercard text-center">
             
                <div className="info market-tabs p-0">
                    <Nav tabs className="border-tab tabs-scoial">
                        <NavItem className="nav" id="myTab" role="tablist">
                            <NavLink tag="a" href="#javascript" className={activeTab === '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
                                {trans.profile_media_tab}
                        </NavLink>
                        </NavItem>
                        {/* <NavItem className="nav" id="myTab" role="tablist">
                            <NavLink tag="a" href="#javascript" className={activeTab === '2' ? 'active' : ''} onClick={() => setActiveTab('2')}>
                                {trans.profile_stats}
                        </NavLink>
                        </NavItem> */}
                        {/* <NavItem className="nav" id="myTab" role="tablist">
                            <NavLink tag="a" href="#javascript" className={activeTab === '2' ? 'active' : ''} onClick={() => setActiveTab('2')}>
                                {About}
                        </NavLink>
                        </NavItem>
                        <NavItem>
                            <div className="user-designation"></div>
                            <div className="title"><a target="_blank" href="#javascripts">{"ElANA"}</a></div>
                            <div className="desc mt-2">{GeneralManager}</div>
                        </NavItem>
                        <NavItem className="nav" id="myTab" role="tablist">
                            <NavLink tag="a" href="#javascript" className={activeTab === '3' ? 'active' : ''} onClick={() => setActiveTab('3')}>
                                {Friends}
                        </NavLink>
                        </NavItem>
                        <NavItem className="nav" id="myTab" role="tablist">
                            <NavLink tag="a" href="#javascript" className={activeTab === '4' ? 'active' : ''} onClick={() => setActiveTab('4')}>
                                {Photos}
                        </NavLink>
                        </NavItem> */}
                    </Nav>
                </div>
            </Card>
        </Col>
    </Row>
    <TabContent activeTab={activeTab} className="tab-content">
       {viewUserTabs()}
    </TabContent>
</div>);
  }
  
  return (
    <Fragment>
      <Breadcrumb parent={trans.menu_users} title={trans.profile_title} />
      <Container fluid={true}>
        {userView == null ? null : <div className="user-profile">
          <Row>
            <Col sm="12">
              <Card className="card hovercard text-center">
                <Media className="_img_profile_banner" body alt="" src={userView.userInfo.banner_img} />
                <div className="user-image">
                  <div className="avatar"><Media style={{border: '0px',objectFit: "cover"}} body className="img-150 rounded-circle shadow-sm" src={profile_img.url} /></div>
                  {canEditUser  ?
                  <div className="icon-wrapper"   data-intro="Change Profile image here">
                    <i className="icofont icofont-pencil-alt-5 btn">
                      <input className="upload btn" type="file" onChange={(e) => uploadProfileImageFile(e)} />
                    </i>
                  </div>
              : null}
                </div>
                <br/>
                <div className="info">
                  <Row>
                    <Col sm="6" lg="4" className="order-sm-1 order-xl-0">
                      <Row >
                        {/* <Col md="6">
                          <div className="ttl-info text-left">
                            <h6><i className="fa fa-envelope mr-2"></i> {trans.email_field}</h6><span>{userView.userInfo.email}</span>
                          </div>
                        </Col> */}
                        <Col md="4">
                          <div className="ttl-info text-left ttl-sm-mb-0">
                            <h6 className="font-weight-bold">{trans.age_title}</h6><span>{userView.userInfo.age}</span>
                          </div>
                        </Col>
                        <Col md="4">
                          <div className="ttl-info text-left ttl-sm-mb-0">
                            <h6 className="font-weight-bold">{trans.height_field}</h6><span>{userView.userInfo.height}cm</span>
                          </div>
                        </Col>
                        <Col md="4">
                          <div className="ttl-info text-left ttl-sm-mb-0">
                            <h6 className="font-weight-bold">{trans.weight_field}</h6><span>{userView.userInfo.weight}Kg</span>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm="12" lg="4" className="order-sm-0 order-xl-1">
                      <div className="user-designation">
                        <div className="title">{userView.userInfo.fullName}</div>
                        <div className="desc mt-2">{userView.userInfo.roleName.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}</div>
                      </div>
                    </Col>
                    <Col sm="6" lg="4" className="order-sm-2 order-xl-2">
                      <Row>
                        {}
                        <Col >
                          <div className="ttl-info text-left ttl-xs-mt">
                          <h6 className="font-weight-bold">{trans.contact_methods_title}</h6>
                          {userView.userInfo.contact_methods != null && userView.userInfo.contact_methods.length > 0 ? 
                           <div>
                           { userView.userInfo.contact_methods.map((items,i) => {
                             if (items.type == 1 || items.type == 2){
                                return (<span key={i} value={items._id}><a href={`tel:+${items.value}`} >  {items.value} </a></span>)
                              }
                           }
                          )}</div>
                           : <div>{trans.no_contact_methods}</div>}
                           </div>
                        </Col>
                        <Col >
                          <div className="ttl-info text-left ttl-sm-mb-0">
                            {userView.userInfo.nation != null && userView.userInfo.nation != "" ? 
                            <div><h6><i className="fa fa-location-arrow"></i>   {trans.nationality_hint}</h6><span>{userView.userInfo.nation.name}</span></div>
                          : <div></div>}
                          </div>
                        </Col>
                        {canEditUser  ? <Col>
                          <Link to={`/app/users/userEdit/${userView.userInfo.nickname}`} target="_blank">
                                <Button color="primary" size="sm">
                                  <i className="fa fa-pencil"></i> {trans.edit_btn}
                                </Button>
                              </Link>
                        </Col> : null }
                      </Row>
                    </Col>
                  </Row>
                  <hr />
                  {userView.userInfo.bio != null && userView.userInfo.bio != "" ? 
                  <Row>
                    <Col>
                    <div >
                       <h4 >{trans.user_bio_field}</h4>
                        <p>{userView.userInfo.bio}</p>          
                    </div>
                    <hr/>
                    </Col>
                  </Row> :
                  null}
                  <Row>
                    <Col>
                        <h4>Workouts</h4>
                        <div>
                           {userStatsData != null ? <div>
                            <p>{trans.workouts_completed_this_week}</p>
                            <p style={{fontWeight:700, fontSize:'18px'}}>{userStatsData.doneWorkoutsThisWeekCount}/{userStatsData.notDoneWorkoutsThisWeek + userStatsData.doneWorkoutsThisWeekCount}</p>
                           </div>  : <div></div>}
                        </div>
                    </Col>
                    {/* <Col>
                       <h4>Personal Records</h4>
                    </Col> */}
                    <Col>
                        <h4>Leaderboard</h4>
                        <div>
                           {userStatsData != null ? <div>
                            <p>{trans.number_of_first_places}</p>
                            <p style={{fontWeight:700, fontSize:'18px'}}>{userStatsData.firstPlaces}</p>
                           </div>  : <div></div>}
                        </div>
                    </Col>
                  </Row>
                  {/* <div className="social-media step4" data-intro="This is your Social details">
                    <ul className="list-inline">
                      <li className="list-inline-item"><a href="#javascript"><i className="fa fa-facebook"></i></a></li>
                      <li className="list-inline-item"><a href="#javascript"><i className="fa fa-google-plus"></i></a></li>
                      <li className="list-inline-item"><a href="#javascript"><i className="fa fa-twitter"></i></a></li>
                      <li className="list-inline-item"><a href="#javascript"><i className="fa fa-instagram"></i></a></li>
                      <li className="list-inline-item"><a href="#javascript"><i className="fa fa-rss"></i></a></li>
                    </ul>
                  </div>
                  <div className="follow">
                    <Row>
                      <Col col="6" className="text-md-right border-right">
                        <div className="follow-num counter">{"25869"}</div><span>{Follower}</span>
                      </Col>
                      <Col col="6" className="text-md-left">
                        <div className="follow-num counter">{"659887"}</div><span>{Following}</span>
                      </Col>
                    </Row>
                  </div> */}
                </div>
              </Card>
            </Col>
         

            {/* <Col sm="12" >
              <Card>
                <div className="profile-img-style">
                  <Row>
                    <Col sm="8">
                      <div className="media"><Media className="img-thumbnail rounded-circle mr-3" src={require("../../assets/images/user/7.jpg")} alt="Generic placeholder image" />
                        <div className="media-body align-self-center">
                          <h5 className="mt-0 user-name">{JOHANDIO}</h5>
                        </div>
                      </div>
                    </Col>
                    <Col sm="4" className="align-self-center">
                      <div className="float-sm-right"><small>{"10 Hours ago"}</small></div>
                    </Col>
                  </Row>
                  <hr />
                  <p>{UserProfileDesc1}</p>
                  <div className="img-container">
                    <div id="aniimated-thumbnials">
                      <a href="#javascript">
                        <Media className="img-fluid rounded" src={require("../../assets/images/other-images/profile-style-img3.png")} alt="gallery" />
                      </a>
                    </div>
                  </div>
                  <div className="like-comment mt-4">
                    <ul className="list-inline">
                      <li className="list-inline-item border-right pr-3">
                        <label className="m-0"><a href="#javascript"><i className="fa fa-heart"></i></a>  {Like}</label><span className="ml-2 counter">{"2659"}</span>
                      </li>
                      <li className="list-inline-item ml-2">
                        <label className="m-0"><a href="#javascript"><i className="fa fa-comment"></i></a>  {Comment}</label><span className="ml-2 counter">{"569"}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </Card>
            </Col>
            <Col sm="12">
              <Card>
                <div className="profile-img-style">
                  <Row>
                    <Col sm="8">
                      <div className="media"><Media className="img-thumbnail rounded-circle mr-3" src={require("../../assets/images/user/7.jpg")} alt="Generic placeholder image" />
                        <div className="media-body align-self-center">
                          <h5 className="mt-0 user-name">{JOHANDIO}</h5>
                        </div>
                      </div>
                    </Col>
                    <Col sm="4" className="align-self-center">
                      <div className="float-sm-right"><small>{"10 Hours ago"}</small></div>
                    </Col>
                  </Row>
                  <hr />
                  <p>{UserProfileDesc1}</p>
                  <Row className="mt-4 pictures">
                    <a className="col-sm-6" href="#javascript">
                      <Media body className="img-fluid rounded" src={require("../../assets/images/other-images/profile-style-img.png")} alt="gallery" />
                    </a>
                    <a className="col-sm-6" href="#javascript">
                      <Media body className="img-fluid rounded" src={require("../../assets/images/other-images/profile-style-img.png")} alt="gallery" />
                    </a>
                  </Row>
                  <div className="like-comment mt-4">
                    <ul className="list-inline">
                      <li className="list-inline-item border-right pr-3">
                        <label className="m-0"><a href="#javascript"><i className="fa fa-heart"></i></a>  {Like}</label><span className="ml-2 counter">{"2659"}</span>
                      </li>
                      <li className="list-inline-item ml-2">
                        <label className="m-0"><a href="#javascript"><i className="fa fa-comment"></i></a>  {Comment}</label><span className="ml-2 counter">{"569"}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </Card>
            </Col>

            <Col sm="12">
              <Card>
                <div className="profile-img-style">
                  <Row>
                    <Col sm="8">
                      <div className="media"><Media className="img-thumbnail rounded-circle mr-3" src={require("../../assets/images/user/7.jpg")} alt="Generic placeholder image" />
                        <div className="media-body align-self-center">
                          <h5 className="mt-0 user-name">{JOHANDIO}</h5>
                        </div>
                      </div>
                    </Col>
                    <Col sm="4" className="align-self-center">
                      <div className="float-sm-right"><small>{"10 Hours ago"}</small></div>
                    </Col>
                  </Row>
                  <hr />
                  <p>{UserProfileDesc2}</p>
                  <div className="like-comment mt-4">
                    <ul className="list-inline">
                      <li className="list-inline-item border-right pr-3">
                        <label className="m-0"><a href="#javascript"><i className="fa fa-heart"></i></a>  {Like}</label><span className="ml-2 counter">{"2659"}</span>
                      </li>
                      <li className="list-inline-item ml-2">
                        <label className="m-0"><a href="#javascript"><i className="fa fa-comment"></i></a>  {Comment}</label><span className="ml-2 counter">{"569"}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </Card>
            </Col>

            <Col sm="12">
              <Card>
                <div className="profile-img-style">
                  <Row>
                    <Col sm="8">
                      <div className="media"><Media className="img-thumbnail rounded-circle mr-3" src={require("../../assets/images/user/7.jpg")} alt="Generic placeholder image" />
                        <div className="media-body align-self-center">
                          <h5 className="mt-0 user-name">{JOHANDIO}</h5>
                        </div>
                      </div>
                    </Col>
                    <Col sm="4" className="align-self-center">
                      <div className="float-sm-right"><small>{"10 Hours ago"}</small></div>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col lg="12" xl="4">
                      <div id="aniimated-thumbnials-3"><a href="#javascript"><Media body className="img-fluid rounded" src={require("../../assets/images/blog/img.png")} alt="gallery" /></a></div>
                      <div className="like-comment mt-4 like-comment-lg-mb">
                        <ul className="list-inline">
                          <li className="list-inline-item border-right pr-3">
                            <label className="m-0"><a href="#javascript"><i className="fa fa-heart"></i></a>  {Like}</label><span className="ml-2 counter">{"2659"}</span>
                          </li>
                          <li className="list-inline-item ml-2">
                            <label className="m-0"><a href="#javascript"><i className="fa fa-comment"></i></a>  {Comment}</label><span className="ml-2 counter">{"569"}</span>
                          </li>
                        </ul>
                      </div>
                    </Col>
                    <Col xl="6">
                      <p>{UserProfileDesc3}</p>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col> */}
          </Row>
          {/* {renderUserTabs()} */}
            <Row>
              <Col>
                <UserMedia   key={`user_media_${userView.userId}`} viewUser={userView.userInfo}/>
              </Col>
            </Row>

            {canEditUser ? 
            <Row>
              <Col>
                <UserProgressCalendar 
                  key={`progress_calendar_user_${userView.userId}`}
                  progressUserId={userViewId}
                />
              </Col>
            </Row>:null}

            {canEditUser ? 
            <Row>
            <Col>
              <Prworkoutlist
                key={`pr_workouts_${userView.userId}`}
                progressUserId={userViewId}
              />
            </Col>
          </Row>:null}
          
        </div> }
      </Container>
    </Fragment>
  );
}

export default UserProfile;