import  './prworkout.css';
import React,{useEffect, useState} from 'react';
import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Form, FormGroup, Input, Label, Button,NavItem, NavLink, Nav,TabContent,TabPane, Breadcrumb, Card, CardHeader, Modal, ModalHeader, ModalBody, ModalFooter,Table,Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import NumericInput from 'react-numeric-input';
import { SCORE_TYPE_CALORIES, SCORE_TYPE_CHECKBOX, SCORE_TYPE_KGS_LBS, SCORE_TYPE_METERS, SCORE_TYPE_REPS, SCORE_TYPE_TIME } from '../../constant';
import { doneDeletePrworkout, doneHistoryPrworkout, donePrworkout } from '../../redux/prworkout/action';
import LoaderBox from '../../components/reuse/loaderbox';
import { toast } from 'react-toastify';
import { DONE_PRWORKOUT_SUBMIT } from '../../redux/actionTypes';
import AddPrworkout from './addprworkout';
import SweetAlert from 'sweetalert2';

const PrworkoutHistory = (props) => {

    const dispatch = useDispatch();

    const {onHistoryPrworkoutClose, open, selectedPrWorkout,scoreTypeTitle,onEditPrworkoutStored,progressUserId} = props;
    const trans = useSelector(({translation}) => translation.translationsData);


    const doneHistoryPrWorkoutsData = useSelector(({prworkout}) => prworkout.doneHistoryPrWorkoutsData);
    const doneDeletePrworkoutData = useSelector(({prworkout}) => prworkout.doneDeletePrworkoutData);

    const [curPage, setCurPage] = useState(1);
    const [dataPages, setDataPages] = useState(0);
  

    const [historyList, setHistoryList] = useState([]);

    const [isLoading, setIsLoading] = useState(false);


    const [addPrWorkoutOpen, setAddPrWorkoutOpen] = useState(false);
    const [isEditPrWorkout, setIsEditPrWorkout] = useState(false);
    const [editPrworkoutItem, setEditPrworkoutItem] = useState(null);

    useEffect(() => {
        if (open){
          getPrworkoutDoneHistory()
        } 
    }, [open]);

    const getPrworkoutDoneHistory = () =>  {
        let postData ={
          "page":curPage,
          "prworkoutId": selectedPrWorkout.id,
          "userId": progressUserId
      };
      setIsLoading(true);
      dispatch(doneHistoryPrworkout(postData));
    }
 
    useEffect(() => {
        if (doneHistoryPrWorkoutsData != null){
            var arr = [];
            doneHistoryPrWorkoutsData.doneprs.forEach(element => {
                arr.push(element);
            });

            setHistoryList(arr);
            setIsLoading(false);
            setDataPages(doneHistoryPrWorkoutsData.pages);
        } 
    }, [doneHistoryPrWorkoutsData]);

    useEffect(() => {
      if (doneDeletePrworkoutData != null){
        getPrworkoutDoneHistory();
        onAddPrworkoutStored();
      }
    }, [doneDeletePrworkoutData]);



    const handlePageClick = (e,newPage) => {
        e.preventDefault();
        setCurPage(newPage);
      }


      const onAddPrworkoutClose = () => {
        setEditPrworkoutItem(null);
        setIsEditPrWorkout(false);
        setAddPrWorkoutOpen(false);
    }

    const onAddPrworkoutStored = () => {
       onEditPrworkoutStored();
      getPrworkoutDoneHistory();
    }


    const handleEditClick = (e,item) => {
      e.preventDefault();
      setIsEditPrWorkout(true);
      setEditPrworkoutItem(item);
      setAddPrWorkoutOpen(true);
    }

   const handleDeleteCick = (e,item) => {
    SweetAlert.fire({
      title:trans.delete_dialog_title,
      text:trans.delete_dialog_desc,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText:trans.ok_btn,
      cancelButtonText: trans.cancel_btn,
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
          //OK
            //REMOVE
          let postData = {id: item.id};
          dispatch(doneDeletePrworkout(postData));
      }
      else {
        
      }
    })
   }
    
    

    return (<Modal isOpen={open} toggle={onHistoryPrworkoutClose} centered size="xl">
             <ModalHeader toggle={onHistoryPrworkoutClose}>{trans.personal_record_history_title}</ModalHeader>
             <ModalBody>
             {isLoading ? <div>
                        <LoaderBox/>
                    </div> : 
                <Row>


                    <Col>
                        <div>
                         <Table>
                          <thead>
                            <tr>
                                <th className="font-weight-bold">{trans.date_field}</th>
                                <th className="font-weight-bold">{'Score'}</th>
                                <th className="font-weight-bold">{trans.done_schedule_notes}</th>
                                <th ></th>
                            </tr>
                            </thead>
                            <tbody>
                            {historyList.map((item,index) => {
                                
                            
                              

                                return (<tr key={`pr_workout_history_${item.id}`}>
                                     <td>{item.readableDate}</td>
                                    <td>{item.typeText}</td>
                                    <td>{item.notes == "" ? "N/A" : item.notes}</td>
                                    <td><Button onClick={(e) => {handleEditClick(e,item)}} color="primary" size="sm"><i className="fa fa-edit"></i> {trans.edit_btn}</Button></td>
                                    <td><Button onClick={(e) => {handleDeleteCick(e,item)}} color="secondary" size="sm"><i className="fa fa-delete"></i> {trans.delete_btn}</Button></td>
                                </tr>)
                            })}
                            
                            </tbody>
                        </Table> 
                        </div>
                    </Col>

                </Row>
                   }
               
            </ModalBody>

            <ModalFooter>

            <div style={{float: "right"}}>
                  <Pagination aria-label="" className="pagination-secondary">
                    
                    <PaginationItem disabled={curPage <= 0}>
                      
                      <PaginationLink
                        onClick={e => handlePageClick(e, curPage - 1)}
                        previous
                        href="#"
                      />
                      
                    </PaginationItem>

                    {[...Array(dataPages)].map((page, i) => 
                      <PaginationItem active={i === curPage} key={i}>
                        <PaginationLink onClick={e => handlePageClick(e, i)} href="#">
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    )}

                    <PaginationItem disabled={curPage >= dataPages - 1}>
                      
                      <PaginationLink
                        onClick={e => handlePageClick(e, curPage + 1)}
                        next
                        href="#"
                      />
                      
                    </PaginationItem>
                    
                  </Pagination>
                </div>
            </ModalFooter>


            <AddPrworkout
              onAddPrworkoutClose={onAddPrworkoutClose}
              open={addPrWorkoutOpen}
              prWorkout={selectedPrWorkout}
              scoreTypeTitle={scoreTypeTitle}
              onAddPrworkoutStored={onAddPrworkoutStored}
              isEdit={isEditPrWorkout}
              editItem={editPrworkoutItem}
            />

     </Modal>);
};

export default PrworkoutHistory;