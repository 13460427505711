
import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Breadcrumb,
  Card,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Register from "../authentication/register";
import signUp from "../../auth/signUp";
import { toast } from 'react-toastify';
import { ARG_USER_ROLE_USER } from "../../constant";
import { userSignUp } from "../../redux/auth/action";
import { CREATE_USER_SUCCESS } from "../../redux/actionTypes";

const AddUserModal = (props) => {

  const dispatch = useDispatch();

  //RROPS
  const { onUserStored, onAddUserModalClose, open } = props;

  //SELECTORS
  const trans = useSelector(({ translation }) => translation.translationsData);
  const settingsData = useSelector(({settings}) => settings.settingsData);
  const {signUp}= useSelector(({auth}) => auth);

  //STATE
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [nickname, setNickname] = useState("");
  const [togglePassword,setTogglePassword] = useState(false);
  const [loading,setLoading] = useState(false) ;
  

  useEffect(() => {
    if (signUp != null){
      if (signUp.status){
        props.onUserStored();
        props.onAddUserModalClose();
      } else {
        setLoading(false);
        toast.error(signUp.message, {
          position: toast.POSITION.TOP_RIGHT
          });
      }
      dispatch({type: CREATE_USER_SUCCESS, payload: null});
    }
  }, [signUp]);

  const registerAccount = (e) => {
    e.preventDefault();


    var userRoles = [];
    settingsData.userRoles.forEach((element) => {
      if (element.role_id == ARG_USER_ROLE_USER){
        userRoles.push(element._id);
      }
    });
   

    let postData = {
      email,
      password: password,
      firstName,
      lastName,
      nickname,
      userRoles: userRoles
    }
    setLoading(true);
    dispatch(userSignUp(postData))
  }


  return (
    <Modal isOpen={open} toggle={onAddUserModalClose} centered size="lg">
      <ModalHeader toggle={onAddUserModalClose}>
        {trans.add_user_title}
      </ModalHeader>
      <ModalBody>
      <Container fluid={true} className="p-0">
      <Row>
        <Col xs="12"> 
          <div className="login-card">
          <div className="login-main"> 
             <Form className="theme-form">
                  <h4>{trans.add_user_title}</h4>
                  <p>{trans.register_descr}</p>
                  <FormGroup>
                    <Label className="col-form-label pt-0">{trans.fullname_field}</Label>
                    <div className="form-row">
                      <Col xs="6">
                        <Input value={firstName} onChange={e => setFirstName(e.target.value)} className="form-control" type="text" required  placeholder={trans.first_name_field}/>
                      </Col>
                      <Col xs="6">
                        <Input value={lastName} onChange={e => setLastName(e.target.value)}  className="form-control" type="text" required="" placeholder={trans.last_name_field}/>
                      </Col>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">{trans.email_field}</Label>
                    <Input  autoComplete="new-password" value={email} onChange={e => setEmail(e.target.value)} className="form-control" type="email" required="" placeholder={trans.email_field}/>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">{trans.nickname_field}</Label>
                    <Input autoComplete="new-password" value={nickname} onChange={e => setNickname(e.target.value)} className="form-control" type="nickname" required="" placeholder={trans.nickname_field}/>
                  </FormGroup>
                  <FormGroup>
                        <Label className="col-form-label">{trans.pass_field}</Label>
                        <Input autoComplete="new-password" className="form-control" type={togglePassword ?  "text" : "password"} onChange={e => setPassword(e.target.value)} defaultValue={password} required="" placeholder={trans.pass_field}/>
                        <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}><span >{togglePassword ? trans.pass_hide : trans.pass_view}</span></div>
                  </FormGroup>
                  <div className="form-group mb-0">
                   
                    <Button disabled={loading} onClick={(e) => registerAccount(e)} color="primary" className="btn-block" type="submit">
                     {loading ? <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span> : ""}
                      {trans.create_account_btn}
                      </Button>
                  </div>
               
                </Form>
                </div>
            </div>
        </Col>
      </Row>
      </Container>
      </ModalBody>
    </Modal>
  );
};

export default AddUserModal;
