import {
    FETCH_NUTRITION_MEAL,
    ADD_NUTRITION_MEAL,
    EDIT_NUTRITION_MEAL,
    DELETE_NUTRITION_MEAL,
    SINGLE_NUTRITION_MEAL} from '../../actionTypes';

  const INIT_STATE = {
        fetchNutritionMealData: null,
        addNutritionMealData: null,
        editNutritionMealData: null,
        deleteNutritionMealData: null,
        singleNutritionMealData: null
    };
    
    export default (state = INIT_STATE, action) => {
        switch(action.type) {
        case FETCH_NUTRITION_MEAL:
             return {
                ...state,
                fetchNutritionMealData: action.payload
        }
        case ADD_NUTRITION_MEAL:
             return {
                ...state,
                addNutritionMealData: action.payload
        }
        case EDIT_NUTRITION_MEAL:
             return {
                ...state,
                editNutritionMealData: action.payload
        }
        case DELETE_NUTRITION_MEAL:
            return {
               ...state,
               deleteNutritionMealData: action.payload
       }
       case SINGLE_NUTRITION_MEAL:
        return {
           ...state,
           singleNutritionMealData: action.payload
        }
        default:
              return state;
        }
    }
    