import './blog.css';
import React, { Fragment,useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter, Media, Form, FormGroup, Label, Input, Button, Pagination,
  PaginationItem,
  PaginationLink  } from 'reactstrap'
  import { useHistory } from 'react-router-dom';
import axios from 'axios'
import {DATE_TIME_FORMAT, MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete} from '../../constant'
import { useParams } from 'react-router';
import NoAccess from '../../components/reuse/noaccess';
import { getUserData } from '../../redux/user/action';
import { Loader } from 'react-bootstrap-typeahead';
import { toast } from 'react-toastify';
import { editUser, forgotPass, getUser,editRoles, changeAccountStatus } from '../../redux/auth/action';

import DatePicker from "react-datepicker";
import { Link } from 'react-router-dom';
import * as General from '../../util/General'
import SweetAlert from 'sweetalert2'
import { checkUserSub } from '../../redux/subscription/action';
import LoaderBox from '../../components/reuse/loaderbox';
import { deleteBlog, getBlogs } from '../../redux/blog/action';
import Moment from "react-moment";

import parse from 'html-react-parser';

const BlogList = (props) => {

  const userLocale = General.getLocale();
  const dispatch = useDispatch();

  const history = useHistory();

    const trans = useSelector(({translation}) => translation.translationsData);
    
    const fetchBlogData = useSelector(({blog}) => blog.fetchBlogData);
    const deleteBlogData = useSelector(({blog}) => blog.deleteBlogData);



    const [blogList, setBlogList] = useState([]);
    const [curPage, setCurPage] = useState(1);

    const userIsAdmin = useSelector(({auth}) => auth.userIsAdmin);  
    const userIsManager = useSelector(({auth}) => auth.userIsManager); 
    const userIsCoach = useSelector(({auth}) => auth.userIsCoach); 


    useEffect(() => {
      fetchBlogs();
    }, []);
  
    useEffect(() => {
      if (deleteBlogData && deleteBlogData.status){
        fetchBlogs();
      }
  }, [deleteBlogData]);
  
    const fetchBlogs = () => {
      let postData = {
          "page": curPage,
      };
      dispatch((getBlogs(postData)));
    }
  
  
  
    useEffect(() => {
      if (fetchBlogData != null){
          var ar = [];
          fetchBlogData.articles.forEach(element => {
                  ar.push(element);
          });
          setBlogList(ar);
      }
    }, [fetchBlogData]);
  

    const handlePageClick = (e, newPage) => {
      e.preventDefault();
      setCurPage(newPage);
    };
  
  

    const removeArticle = (e,item) => {
      SweetAlert.fire({
        title:trans.delete_dialog_title,
        text:trans.delete_dialog_desc,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText:trans.ok_btn,
        cancelButtonText: trans.cancel_btn,
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
            //OK
          let postData = {articleId: item.id};
          dispatch(deleteBlog(postData));
        }
        else {

        }
      })
    }
   
    const addArticle = (e,item) => {
      history.push("blog-build")
    }


    return (
      <Fragment>
      <Breadcrumb parent={trans.blog_list_title} title={trans.blog_list_title} />
      <Container fluid={true}>

        {userIsAdmin || userIsCoach || userIsManager ? 
         <Card>
        

           <CardHeader>
              <Button color="primary" style={{float:'right'}} onClick={(e) => addArticle(e)}  size="sm"><i className="fa fa-plus"></i> {trans.add_btn}</Button>
           </CardHeader>
         <CardBody>
            <div className="table-responsive">
                  <table className="table card-table table-vcenter text-nowrap">
                    <thead>
                      <tr >
                        <th className="font-weight-bold" key={"notification_title"}>{trans.blog_title}</th>
                        <th className="font-weight-bold" key={"notification_description"}>{trans.blog_desc}</th>
                        <th className="font-weight-bold" key={"blog_status"}>{trans.blog_status}</th>
                        <th className="font-weight-bold" key={"blog_type"}>{trans.blog_type}</th>
                        <th className="font-weight-bold" key={"blog_publish_date"}>{trans.blog_publish_date}</th>
                        <th className="font-weight-bold" key={"edit"}></th>
                        <th className="font-weight-bold" key={"delete"}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {blogList.map((item, i) => {
                        // let values = item.values.map((value,valIndex) => {
                        //    return ( <span key={`trans_value_${i}_val_${valIndex}`} className="_translation_value">{`${value.langLoc} - ${value.value}`}</span>)
                        // })


                        return (
                          <tr
                            key={`blog_item_${i}`}
                          >
                            
                            <td>{item.title}</td>
                            <td > <div style={{maxWidth:'100px',maxHeight:'20px', maxLines:'1 !important', overflow:'hidden', whiteSpace:'nowrap',textOverflow:'ellipsis'}}>{parse(item.desc) }</div></td>
                            <td>{item.status == true ? trans.blog_status_published  : trans.blog_status_unpublished}</td>
                            <td>{item.type}</td>
                            <td>
                              <Moment
                                locale={userLocale}
                                format={DATE_TIME_FORMAT}
                                unix
                                >
                                {item.timestamp}
                              </Moment>
                            </td>
                            <td className="text-right">
                            <Link to={`blog-build/${item.id}`}>
                              <Button  color="primary" size="sm"><i className="fa fa-pencil"></i> {trans.edit_btn}</Button>
                            </Link>

                            &nbsp;   
                            <Button onClick={(e) => removeArticle(e,item)} color="danger" size="sm"><i className="fa fa-trash"></i> {trans.delete_btn}</Button>
                          </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
            </CardBody>
            <CardFooter>

            {fetchBlogData != null ? 
            <div style={{ float: "right" }}>
                    <Pagination aria-label="" className="pagination-secondary">
                      <PaginationItem disabled={curPage <= 0}>
                        <PaginationLink
                          onClick={(e) => handlePageClick(e, curPage - 1)}
                          previous
                          href="#"
                        />
                      </PaginationItem>

                      {[...Array(fetchBlogData.pages)].map((page, i) => (
                        <PaginationItem active={i === curPage} key={i}>
                          <PaginationLink
                            onClick={(e) => handlePageClick(e, i)}
                            href="#"
                          >
                            {i + 1}
                          </PaginationLink>
                        </PaginationItem>
                      ))}

                      <PaginationItem
                        disabled={curPage >= fetchBlogData.pages - 1}
                      >
                        <PaginationLink
                          onClick={(e) => handlePageClick(e, curPage + 1)}
                          next
                          href="#"
                        />
                      </PaginationItem>
                    </Pagination>
                  </div> : <div></div> }
            </CardFooter>
         </Card>
        : <Card>
           
              <NoAccess/>

         </Card>}
      </Container>
    </Fragment>
  );
}

export default BlogList;