import {
    UPLOAD_IMAGE_FILE,
    UPLOAD_VIDEO_FILE
  } from '../actionTypes';
  
    const INIT_STATE = {
        uploadImageFileData: null,
        uploadVideoFileData: null
      };
      
      export default (state = INIT_STATE, action) => {
          switch(action.type) {
            case UPLOAD_IMAGE_FILE:
                 return {
                 ...state,
                 uploadImageFileData: action.payload
              }
            case UPLOAD_VIDEO_FILE:
                return {
                ...state,
                uploadVideoFileData: action.payload
            }
          default:
                return state;
          }
      }
      