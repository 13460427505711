import './feedbacks.css';
import React, { Fragment,useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardFooter, Media, Button, Form, FormGroup, Input, Pagination, PaginationItem, PaginationLink,CardBody,Label } from 'reactstrap'

import {DATE_TIME_FORMAT, Follower,Following,TotalPost} from '../../constant'
import axios from 'axios'
import { STORED_TRANSLATIONS } from '../../redux/actionTypes';
import { Loader } from 'react-bootstrap-typeahead';
import Moment from 'react-moment';
import * as General from '../../util/General'
import { useHistory } from 'react-router';
import { addFeedback, getFeedbacks } from '../../redux/feedback/action';
import { toast } from 'react-toastify';

const FeedbackList = (props) => {
  
  const userLocale = General.getLocale();
  const history = useHistory();
  const dispatch = useDispatch();

  const userInfo = useSelector(({auth}) => auth.authUser);
  const feedbackData = useSelector(({feedback}) => feedback.feedbackData);
  const addFeedbackRreponse = useSelector(({feedback}) => feedback.addFeedbackData);

  const trans = useSelector(({translation}) => translation.translationsData);
  const userIsAdmin = useSelector(({auth}) => auth.userIsAdmin);

  //SEARCH
  const [searchText, setSearchText] = useState('');
  var searchKeyword = "";

  //DATA
  const [curPage, setCurPage] = useState(0);
  const [email, setEmail] = useState(userInfo != null ? userInfo.email : "");
  const [descr, setDescr] = useState("");
  const [addFeedbackData, setAddFeedbackData] = useState(null);



  useEffect(() => {
    getFeedbacksData();
}, []);

  useEffect(() => {
    if (addFeedbackRreponse != null) {
       setAddFeedbackData(addFeedbackRreponse);
    }
  }, [addFeedbackRreponse]);

  useEffect(() => {
    if (addFeedbackData != null) {
      if (addFeedbackData.status){
        toast.success(addFeedbackData.message, {
          position: toast.POSITION.TOP_RIGHT
          });
          //RELOAD
        if (userIsAdmin){
          getFeedbacksData();
        }
      } else {
        toast.error(addFeedbackData.message, {
          position: toast.POSITION.TOP_RIGHT
          });
      }
      
    }
  }, [addFeedbackData]);


  const getFeedbacksData = () => {
    let postData = {page: curPage+1,sort: {"_id": -1},query: {},searchKeyword: searchKeyword}
    dispatch(getFeedbacks(postData));
}

  useEffect(() => {
    getFeedbacksData();
  }, [curPage]);


  const addEditFeedbackModaltoggle = () => {

  }

  const handleSearchKeyword = async (keyword)   => {
    searchKeyword = keyword;
    setSearchText(keyword);
    getFeedbacksData();
  }

  const handleFeedbackEditClick = (e,user) => {
    e.preventDefault();
    // history.push(`/app/users/userEdit/${user.nickname}`);
  }

  const removeFeedback = (user)=> {

  }

  const handleFeedBackAnswer = (e,item) => {
    e.preventDefault();
    if ( item != null){
      window.location = `mailto:${item.email}`
    }
  }

  const handlePageClick = (e,newPage) => {
    e.preventDefault();
    setCurPage(newPage);
  }

  const sendFeedback = (e) => {
    e.preventDefault();
    let postData = {descr: descr, email: email};
    dispatch(addFeedback(postData));
    setDescr("");
  }


  return (
    <Fragment>
      <Breadcrumb parent={trans.feedback_title} title={trans.feedback_title} />
      <Container fluid={true}>

      <Row>
      <Col xl="12">
              <Form className="card">
                <CardHeader>
                  <h4 className="card-title mb-0 font-weight-bold">{trans.feedback_title}</h4>
                  <div className="card-options">
                    <a className="card-options-collapse" href="#javascript">
                      <i className="fe fe-chevron-up"></i>
                    </a>
                    <a className="card-options-remove" href="#javascript">
                      <i className="fe fe-x"></i>
                    </a>
                  </div>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col sm="12" md="12">
                      <FormGroup>
                        <Label className="form-label font-weight-bold">{trans.email_field}</Label>
                        <Input value={email} className="form-control" type="email" placeholder={trans.email_field}/>
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <div className="form-group mb-0">
                        <Label className="form-label font-weight-bold">{trans.feed_back_field}</Label>
                        <Input value={descr} type="textarea" className="form-control" rows="5" placeholder={trans.feed_back_field}  onChange={(e)=>{setDescr(e.target.value)}}/>
                      </div>
                    </Col>
                  
                  </Row>
                </CardBody>
                <CardFooter className="text-right">
                  <button disabled={descr==''|| email ==""} onClick={(e)=> {sendFeedback(e)}} className="btn btn-primary" type="submit">{trans.submit_btn}</button>
                </CardFooter>
              </Form>
              </Col>

      {userIsAdmin && feedbackData != null ? 
            <Col md="12">
              <Card>
                <CardHeader >
                  <Row>
                  <Col xl="12" sm="12"> 
                    <div style={{alignItems: 'center',}}> 
                        <div className="card-title mb-0" style={{float: "right"}}>
                            {/* <Button onClick={addEditFeedbackModaltoggle} color="secondary" size="sm"><i className="fa fa-pencil"></i> {trans.add_btn}</Button> */}
                            <Form>
                            <FormGroup className="m-0">
                              <Input
                                className="form-control"
                                type="text"
                                placeholder={trans.search_hint}
                                value={searchText}
                                onChange={(e) => handleSearchKeyword(e.target.value)}
                                />
                            </FormGroup>
                            </Form>
                        </div>
                    </div>
                  </Col>
                  <br/>
                  <br/>
                 
                    </Row>
                </CardHeader>
                <div className="table-responsive">
                <table className="table card-table table-vcenter text-nowrap">
                    <thead>
                      <tr className="_table_feedback_item">
                        <th key={"email_field"}>{trans.email_field}</th>
                        <th key={"descr"}>{trans.feed_back_field}</th>
                        <th key={"timestamp"}>{trans.date_field}</th>
                      </tr>
                    </thead>
                    <tbody>

                      {feedbackData.data.map((item,i) => {
                        // let values = item.values.map((value,valIndex) => {
                        //    return ( <span key={`trans_value_${i}_val_${valIndex}`} className="_translation_value">{`${value.langLoc} - ${value.value}`}</span>)
                        // })

                        return (<tr  key={`feedback_item_${i}`} className="_table_feedback_item">
                          <td>{item.email}</td>
                          <td>{item.descr}</td>
                          <td> <Moment locale={userLocale} format={DATE_TIME_FORMAT} unix>{item.timestamp}</Moment></td>
                          <td className="text-right">
                            <Button onClick={(e) => {handleFeedBackAnswer(e,item)}} color="primary" size="sm"><i className="fa fa-envelope"></i></Button>     
                          </td>
                        </tr>)
                      }
                      )}
                      
                    </tbody>
                  </table>
                </div>
                <CardFooter>
                <div style={{float: "right"}}>
                  <Pagination aria-label="" className="pagination-secondary">
                    
                    <PaginationItem disabled={curPage <= 0}>
                      
                      <PaginationLink
                        onClick={e => handlePageClick(e, curPage - 1)}
                        previous
                        href="#"
                      />
                      
                    </PaginationItem>

                    {[...Array(feedbackData.pages)].map((page, i) => 
                      <PaginationItem active={i === curPage} key={i}>
                        <PaginationLink onClick={e => handlePageClick(e, i)} href="#">
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    )}

                    <PaginationItem disabled={curPage >= feedbackData.pages - 1}>
                      
                      <PaginationLink
                        onClick={e => handlePageClick(e, curPage + 1)}
                        next
                        href="#"
                      />
                      
                    </PaginationItem>
                    
                  </Pagination>
                </div>
                </CardFooter>
              </Card>
            </Col>
             : userIsAdmin ? <Loader/> : null}
          </Row>
       
      </Container>
    </Fragment>
  );
}

export default FeedbackList;