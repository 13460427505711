import {
    FETCH_NUTRITION_MENU_TYPE,
    ADD_NUTRITION_MENU_TYPE,
    EDIT_NUTRITION_MENU_TYPE,
    DELETE_NUTRITION_MENU_TYPE,
    SINGLE_NUTRITION_MENU_TYPE} from '../../actionTypes';

  const INIT_STATE = {
        fetchNutritionMenuTypeData: null,
        addNutritionMenuTypeData: null,
        editNutritionMenuTypeData: null,
        deleteNutritionMenuTypeData: null,
        singleNutritionMenuTypeData: null
    };
    
    export default (state = INIT_STATE, action) => {
        switch(action.type) {
        case FETCH_NUTRITION_MENU_TYPE:
             return {
                ...state,
                fetchNutritionMenuTypeData: action.payload
        }
        case ADD_NUTRITION_MENU_TYPE:
             return {
                ...state,
                addNutritionMenuTypeData: action.payload
        }
        case EDIT_NUTRITION_MENU_TYPE:
             return {
                ...state,
                editNutritionMenuTypeData: action.payload
        }
        case DELETE_NUTRITION_MENU_TYPE:
            return {
               ...state,
               deleteNutritionMenuTypeData: action.payload
       }
       case SINGLE_NUTRITION_MENU_TYPE:
        return {
           ...state,
           singleNutritionMenuTypeData: action.payload
        }
        default:
              return state;
        }
    }
    