import './schedule.css';
import 'react-big-calendar/lib/css/react-big-calendar.css'
import React, { Fragment,useState,useEffect,useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, FormGroup, Input, Label, CardBody,Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import parse from 'html-react-parser';
import ReactHorizontalDatePicker from 'react-horizontal-strip-datepicker'
import 'react-horizontal-strip-datepicker/dist/ReactHorizontalDatePicker.css'
import * as General from '../../util/General'
import { fetchProgress, fetchWorkoutLeaderboard } from '../../redux/doneschedule/action';
import { SCORE_TYPE_CALORIES, SCORE_TYPE_CHECKBOX, SCORE_TYPE_KGS_LBS, SCORE_TYPE_METERS, SCORE_TYPE_REPS, SCORE_TYPE_TIME } from '../../constant';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import LoaderBox from '../../components/reuse/loaderbox';
import WorkoutLeaderboardDetailsModal from './workoutLeaderboardDetailsModal';
import { USER_PROGRESS_CHECKBOX,
  USER_PROGRESS_TIME,
  USER_PROGRESS_KGS_LBS,
  USER_PROGRESS_METERS,
  USER_PROGRESS_REPS,
  USER_PROGRESS_CALORIES } from '../../redux/actionTypes';

const WorkoutLeaderboard = (props) => {
  
  const userLocale = General.getLocale();
  const dispatch = useDispatch();
  
  const {scheduleWorkout,fromProgress,scoreType} = props;
  
  const trans = useSelector(({translation}) => translation.translationsData);
  const workoutBoardData = useSelector(({doneSchedules}) => doneSchedules.workoutBoardData);
  const userProgressKgsLbs = useSelector(({doneSchedules}) => doneSchedules.userProgressKgsLbs);
  const userProgressMeters = useSelector(({doneSchedules}) => doneSchedules.userProgressMeters);
  const userProgressReps = useSelector(({doneSchedules}) => doneSchedules.userProgressReps);
  const userProgressCalories = useSelector(({doneSchedules}) => doneSchedules.userProgressCalories);
  const userProgressTime = useSelector(({doneSchedules}) => doneSchedules.userProgressTime);
  const userProgressCheckbox = useSelector(({doneSchedules}) => doneSchedules.userProgressCheckbox);


  const [boardItems, setBoardItems] = useState([]); 
  
  const [curPage, setCurPage] = useState(1);


  const [workoutLeaderboardDetailsItem,setWorkoutLeaderboardDetailsItem] = useState(null);
  const [workoutLeaderboardDetailsOpen,setWorkoutLeaderboardDetailsOpen] = useState(false);
  const [workoutLeaderboardDetailsIndex, setWorkoutLeaderboardDetailsIndex] = useState(0);

  const [isDataLoading, setIsDataLoading] = useState(true);
  const [dataPages, setDataPages] = useState(0);
  
  // useEffect(() => {
  //   if (fromProgress){
  //     setCurPage(1);
  //     getProgressData();
  //   }
  // }, [fromProgress]);

  useEffect(() => {
  
      if (fromProgress){
        setCurPage(1);
        getProgressData();
      }
    
  }, []);
  
  

  const getProgressData = () => {
    let postData = {scheduleWorkoutId: "",page:curPage,scoreType:props.scoreType,fromProgress:true}
    dispatch(fetchProgress(postData,props.scoreType));
  }

  useEffect(() => {
    if (userProgressKgsLbs != null){
      setBoardItems(userProgressKgsLbs.boardItems);
      setDataPages(userProgressKgsLbs.pages);
      setIsDataLoading(false);
      dispatch({type: USER_PROGRESS_KGS_LBS, payload: null});
    }
  }, [userProgressKgsLbs]);

  useEffect(() => {
    if (userProgressMeters != null){
      setBoardItems(userProgressMeters.boardItems);
      setDataPages(userProgressMeters.pages);
      setIsDataLoading(false);
      dispatch({type: USER_PROGRESS_METERS, payload: null});
    }
  }, [userProgressMeters]);

  useEffect(() => {
    if (userProgressReps != null){
      setBoardItems(userProgressReps.boardItems);
      setDataPages(userProgressReps.pages);
      setIsDataLoading(false);
      dispatch({type: USER_PROGRESS_REPS, payload: null});
    }
  }, [userProgressReps]);
  
  useEffect(() => {
    if (userProgressCalories != null){
      setBoardItems(userProgressCalories.boardItems);
      setDataPages(userProgressCalories.pages);
      setIsDataLoading(false);
      dispatch({type: USER_PROGRESS_CALORIES, payload: null});
    }
  }, [userProgressCalories]);
  
  useEffect(() => {
    if (userProgressTime != null){
      setBoardItems(userProgressTime.boardItems);
      setDataPages(userProgressTime.pages);
      setIsDataLoading(false);
      dispatch({type: USER_PROGRESS_TIME, payload: null});
    }
  }, [userProgressTime]);
 
  useEffect(() => {
    if (userProgressCheckbox != null){
      setBoardItems(userProgressCheckbox.boardItems);
      setDataPages(userProgressCheckbox.pages);
      setIsDataLoading(false);
      dispatch({type: USER_PROGRESS_CHECKBOX, payload: null});
    }
  }, [userProgressCheckbox]);

  //HOOKS
  useEffect(() => {
    if (scheduleWorkout != null && !fromProgress){
     getWorkoutleaderboard();
    }
  }, [scheduleWorkout]);

  useEffect(() => {
    if (workoutBoardData != null){
      setBoardItems(workoutBoardData.boardItems);
      setDataPages(workoutBoardData.pages);
      setIsDataLoading(false);
    }
  }, [workoutBoardData]);

  const getWorkoutleaderboard = () => {
    console.log("scheduleWorkout.id",scheduleWorkout.id);
    let postData = {scheduleWorkoutId: scheduleWorkout.id,page:curPage,scoreType:scheduleWorkout.workoutdata.scoring.type}
    dispatch(fetchWorkoutLeaderboard(postData));
  }

  useEffect(() => {
    if (workoutBoardData != null){
      setBoardItems(workoutBoardData.boardItems);
    }
  }, [workoutBoardData]);

  const handlePageClick = (e,newPage) => {
    e.preventDefault();
    setCurPage(newPage);
  }

  const openWorkoutDetails = (e,item,index) => {
    e.preventDefault();
    //console.log(item);
    setWorkoutLeaderboardDetailsItem(item);
    setWorkoutLeaderboardDetailsIndex(index);
    setWorkoutLeaderboardDetailsOpen(true);
  }

  const onWorkoutLeaderboardDetailsModalClose = () => {
    setWorkoutLeaderboardDetailsItem(null)
    setWorkoutLeaderboardDetailsIndex(0);
    setWorkoutLeaderboardDetailsOpen(false);
  }

  function pad(num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
}


  function renderWorkoutLeader(item,index) {

    var finishedSetsText = "";
    let toProfile = `/app/users/userProfile/${item.creator.nickname}`;

  
    let checkBoxValue = ""
    let timeValue = ""
    let caloriesValue = ""
    let repsValue = ""
    let metersValue = ""
    let kgsValue = ""



    if (item.scoring.type == SCORE_TYPE_CHECKBOX){
      finishedSetsText = `${trans.leaderboard_from_sets_finished} ${item.doneSetsCount}/${item.scoring.amount}`
    } else  if (item.scoring.type == SCORE_TYPE_TIME){
      finishedSetsText = `${trans.leaderboard_time_count}:  ${pad(item.bestSet.minutes,2)}:${pad(item.bestSet.seconds,2)}`
    } else  if (item.scoring.type == SCORE_TYPE_KGS_LBS){
      var type = item.bestSet.customType != null && item.bestSet.customType == "LB" ? "lbs" : "kgs"
      finishedSetsText = `${trans.leaderboard_time_count}: ${item.bestSet.value}${type}`
    } else  if (item.scoring.type == SCORE_TYPE_METERS){
      finishedSetsText = `${trans.leaderboard_time_count}: ${item.bestSet.value}m`
    } else  if (item.scoring.type == SCORE_TYPE_REPS){
      finishedSetsText = `${trans.leaderboard_time_count}: ${item.bestSet.value}reps`
    } else  if (item.scoring.type == SCORE_TYPE_CALORIES){
      finishedSetsText = `${trans.leaderboard_time_count}: ${item.bestSet.value}cal`
    } 
        
    return (<tr key={`leaderboard_item_${index}`} className="_table_leaderboard_item">
    <td><span className="_leaderboard_index">{index+1}</span></td>
    <td style={{ display: 'flex',textAlign: 'left',flexDirection: 'row'}}>
      <Link to={toProfile}><img className="rounded-circle shadow-sm" style={{objectFit: "cover",width:40, height: 40}} src={item.creator.img_thumb} alt="40x40"  data-holder-rendered="true" /></Link>
      <div style={{marginLeft:'10px'}}>
                    <div>
                      <span >{item.creator.name}</span>
                    </div>
                    <div>
                      <span >@{item.creator.nickname}</span>
                    </div>
                </div>
      </td>
    {/* <td ><div>
                    <div>
                      <span >{item.creator.name}</span>
                    </div>
                    <div>
                    <span >@{item.creator.nickname}</span>
                    </div>
                </div>
    </td> */}
    <td><span className="_leaderboard_finished_text">{finishedSetsText}</span></td>
    <td><span onClick={(e)=> openWorkoutDetails(e,item,index)} className="_workout_details_btn">{trans.workout_details}</span></td>
    {/* <td >< Moment locale={userLocale} format={DATE_TIME_FORMAT}>{item.doneDate}</Moment></td> */}
    {/* <td>{`${trans.done_schedule_notes}: `} <b>{item.notes}</b> </td> */}
    {/* <td>    <div style={{display:'flex'}}>
                {item.doneSets.map((doneSetItem,doneSetIndex)=> {
                  var finishedSet = trans.leaderboard_set_finished;
                  if (item.scoring.scoreType.type == SCORE_TYPE_TIME){ 
                    finishedSet = `${doneSetItem.minutes}:${doneSetItem.seconds}`
                  }
                  if (item.scoring.scoreType.type == SCORE_TYPE_KGS_LBS){ 
                    finishedSet = `${doneSetItem.value}kgs`
                  }

                  if (item.scoring.scoreType.type == SCORE_TYPE_METERS){ 
                    finishedSet = `${doneSetItem.value}m`
                  }

                  if (item.scoring.scoreType.type == SCORE_TYPE_REPS){ 
                    finishedSet = `${doneSetItem.value}reps`
                  }


                  if (item.scoring.scoreType.type == SCORE_TYPE_CALORIES){ 
                    finishedSet = `${doneSetItem.value}cal`
                  }


                  return (<div className="_leaderboard_set_item">
                      <div>{`Set #${doneSetIndex+1}`}</div>
                      <div className="font-weight-bold">{finishedSet}</div>
                  </div>)
                })}
              </div></td> */}

  </tr>)

    
  }
  
  return (
    <div>
      
  
      {!isDataLoading ? 
     
     boardItems != null && boardItems.length > 0 ? 
       <div className="table-responsive">
                  <table className="table card-table table-vcenter text-nowrap">
                    <thead>
                      {/* <tr className="">
                        <th key={"user_img"}></th>
                        <th key={"user_nickname"}>{trans.nickname_field}</th>
                        <th key={"user_fullname"}>{trans.fullname_field}</th>
                        <th key={"user_email"}>{trans.email_field}</th>
                        <th key={"user_joined"}>{trans.user_joined}</th>
                        <th key={"user_roles"}>{trans.user_roles_field}</th>
                        <th key={"user_workouts"}>{trans.user_workouts}</th>
                        <th key={"trans_edit"}></th>
                        <th key={"trans_delete"}></th>
                      </tr> */}
                    </thead>
                    <tbody style={{margin:'4px'}}>

                      {boardItems.map((item,index) => {
                        return renderWorkoutLeader(item,index);
                      }
                      )}
                      
                    </tbody>
                  </table>
                  <div style={{float: "right"}}>
                  <Pagination aria-label="" className="pagination-secondary">
                    
                    <PaginationItem disabled={curPage <= 0}>
                      
                      <PaginationLink
                        onClick={e => handlePageClick(e, curPage - 1)}
                        previous
                        href="#"
                      />
                      
                    </PaginationItem>

                    {[...Array(dataPages)].map((page, i) => 
                      <PaginationItem active={i === curPage} key={i}>
                        <PaginationLink onClick={e => handlePageClick(e, i)} href="#">
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    )}

                    <PaginationItem disabled={curPage >= dataPages - 1}>
                      
                      <PaginationLink
                        onClick={e => handlePageClick(e, curPage + 1)}
                        next
                        href="#"
                      />
                      
                    </PaginationItem>
                    
                  </Pagination>
                </div>
                </div>
                : <div>
                <p>
                 {trans.workout_board_no_data}
                </p>
              </div>
                : <div>
                  <LoaderBox/>
               </div> 
          }

          <WorkoutLeaderboardDetailsModal
            scheduleWorkout={scheduleWorkout}
            open={workoutLeaderboardDetailsOpen}
            workoutBoardData={workoutLeaderboardDetailsItem}
            woroutBoardIndex={workoutLeaderboardDetailsIndex}
            onWorkoutLeaderboardDetailsModalClose={onWorkoutLeaderboardDetailsModalClose}
          />
  </div> 
  );
}

export default WorkoutLeaderboard;