import './users.css';
import React, { useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card, CardHeader, CardBody, } from 'reactstrap'
import * as General from '../../util/General'
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from "@fullcalendar/timegrid";
import { getDoneSchedules } from '../../redux/doneschedule/action';
import { SCORE_TYPE_CALORIES, SCORE_TYPE_CHECKBOX, SCORE_TYPE_KGS_LBS, SCORE_TYPE_METERS, SCORE_TYPE_REPS, SCORE_TYPE_TIME } from '../../constant';
import { USER_ID_SET } from '../../redux/actionTypes';
import WorkoutLeaderboardDetailsModal from '../schedule/workoutLeaderboardDetailsModal';


const UserProgressCalendar = (props) => {

    const dispatch = useDispatch();


    const {progressUserId} = props;


    //MODAL
    const [scheduleWorkout, setScheduleWorkout] = useState(null);
    const [workoutLeaderboardDetailsItem,setWorkoutLeaderboardDetailsItem] = useState(null);
    const [workoutLeaderboardDetailsOpen,setWorkoutLeaderboardDetailsOpen] = useState(false);
    const [workoutLeaderboardDetailsIndex, setWorkoutLeaderboardDetailsIndex] = useState(0);
    

    //SELECTORS
    const trans = useSelector(({translation}) => translation.translationsData);
    const settingsData = useSelector(({settings}) => settings.settingsData);

    //STATES
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [calendarStartDate, setCalendarStartDate] = useState(new Date());
    const doneSchedulesData = useSelector(({doneSchedules}) => doneSchedules.doneSchedulesData);
    

    //VARIABLES
    var currentDate = new Date();
    var weekOfMonth = General.getISOWeekInMonth(currentDate);
    var month = General.getISOMonth(currentDate);
    var year = General.getISOYear(currentDate);
    var dayOfMonth = currentDate.getDay();
    var query = {'$or':[{"scheduleData.month": month},{"scheduleData.month": month + 1},{"scheduleData.month": month - 1}],"scheduleData.year":year,'creator':progressUserId};

    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
      if(activeTab !== tab) setActiveTab(tab);
    }



   useEffect(() => {
      getProgressData();
      // getCheckBoxData();
      // getTimeData();
      // getCaloriesData();
      // getRepsData();
      // getMetersData();
   }, []);


    
  

   const getProgressData = () => {
    let postData = {page: 1,sort: {"_id": -1},query: query}
    dispatch(getDoneSchedules(postData));
   }

   useEffect(() => {
    if (doneSchedulesData != null){
      var events = [];
      doneSchedulesData.doneSchedules.forEach(doneSchedule => {

        var doneScheduleWorkout = null;
        //WE NEED TO FIND THE SCHEDULE WORKOUT
        doneSchedule.schedule.workouts.forEach(scheduleWorkout => {
          if (scheduleWorkout.workout.id == doneSchedule.doneWorkout.id){
            doneScheduleWorkout = scheduleWorkout;
          }
        });

        //console.log("DONE_WORKOUT",doneScheduleWorkout);
      
          var stateColor = "#fff" //DEFAULT RED T
          let eventItem = {
            'title': doneScheduleWorkout.workoutdata.name,
            'start': new Date(Date.parse(doneScheduleWorkout.eventDate)) ,
            'end': new Date(Date.parse(doneScheduleWorkout.eventDateEnd)),
            'desc': doneScheduleWorkout.desc,
            'allDay': doneScheduleWorkout.allDay,
            'id': doneSchedule.id,
            'eventOrder': doneScheduleWorkout.order
           };
           events.push(eventItem);



       
      });
      setCalendarEvents(events);
    }
   }, [doneSchedulesData]);

   const getEventWorkoutByID = (doneScheduleId) => {
    let event = null;
    var doneScheduleWorkout = null;

    //LETS FIND THE EVENT
    doneSchedulesData.doneSchedules.forEach(doneSchedule => {
      if (doneScheduleId == doneSchedule.id){
        doneSchedule.schedule.workouts.forEach(scheduleWorkout => {
          if (scheduleWorkout.workout.id == doneSchedule.doneWorkout.id){
            event = scheduleWorkout;
          }
        });
     }
    });
    return event;
  }

  const getDoneScheduleByID = (doneScheduleId) => {
    var doneScheduleItem = null;

    //LETS FIND THE EVENT
    doneSchedulesData.doneSchedules.forEach(doneSchedule => {
      if (doneScheduleId == doneSchedule.id){
        doneScheduleItem = doneSchedule;
     }
    });
    return doneScheduleItem;
  }

   function renderEventContent(eventItem) {

    //console.log(eventItem);
    
    let event = getEventWorkoutByID(eventItem.event.id);

    let doneSchedule = getDoneScheduleByID(eventItem.event.id);


    //console.log("DONESID",eventItem.event.url);


    if (event != null ){

    //   var finishedSetsText = "";
    //  // console.log("event.workoutdata",event);

    //   if (event.workoutdata.scoring.type == SCORE_TYPE_CHECKBOX){
    //     finishedSetsText = `${trans.leaderboard_from_sets_finished} ${doneSchedule.doneSetsCount}/${event.workoutdata.scoring.amount}`
    //   } else  if (event.workoutdata.scoring.type == SCORE_TYPE_TIME){
    //     finishedSetsText = `${trans.leaderboard_time_count}: ${doneSchedule.bestSet.value}`
    //   } else  if (event.workoutdata.scoring.type == SCORE_TYPE_KGS_LBS){
    //    // console.log("doneSchedule.bestSet",doneSchedule.bestSet);
    //     var type = doneSchedule.bestSet.customType == "LB" ? "lbs" : "kgs"
    //     finishedSetsText = `${trans.leaderboard_time_count}: ${doneSchedule.bestSet.value}${type}`
    //   } else  if (event.workoutdata.scoring.type == SCORE_TYPE_METERS){
    //     finishedSetsText = `${trans.leaderboard_time_count}: ${doneSchedule.bestSet.value}m`
    //   } else  if (event.workoutdata.scoring.type == SCORE_TYPE_REPS){
    //     finishedSetsText = `${trans.leaderboard_time_count}: ${doneSchedule.bestSet.value}reps`
    //   } else  if (event.workoutdata.scoring.type == SCORE_TYPE_CALORIES){
    //     finishedSetsText = `${trans.leaderboard_time_count}: ${doneSchedule.bestSet.value}cal`
    //   } 
         

      return(<div style={{cursor:'pointer'}} onClick={(e) => {openWorkoutDetails(event,doneSchedule)}} key={eventItem.id}>

            <div>
              <h3 style={{fontSize:'14px'}}>{event.workoutdata.name}</h3>
            </div>   

            {/* <div>
              <p style={{wordWrap:'break-word'}}>{finishedSetsText} </p>
            </div> */}
        
        </div>);
    }
  }
   
  const openWorkoutDetails = (workout, doneSchedule) => {
    console.log("doneSchedule",doneSchedule);
    setScheduleWorkout(workout);
    setWorkoutLeaderboardDetailsItem(doneSchedule.boardItem);
    setWorkoutLeaderboardDetailsIndex(0);
    setWorkoutLeaderboardDetailsOpen(true);
  }

  const onWorkoutLeaderboardDetailsModalClose = () => {
    setWorkoutLeaderboardDetailsItem(null)
    setWorkoutLeaderboardDetailsIndex(0);
    setWorkoutLeaderboardDetailsOpen(false);
  }


    return (<div>
            <Row>
                    <Col >
                        <Card>
                                <CardHeader>
                                <h3 className="font-weight-bold">{trans.progress_history_title}</h3>
                                </CardHeader>

                                <CardBody>

                                <FullCalendar
                                plugins={[dayGridPlugin, timeGridPlugin]}
                                headerToolbar={{
                                    left: "prev,next today",
                                    center: "title",
                                    right: "dayGridMonth,timeGridWeek,timeGridDay"
                                }}
                                    initialView="dayGridMonth"
                                    weekends={true}
                                    selectable={true}
                                    events={calendarEvents}
                                    eventContent={renderEventContent}
                                    datesSet={(dateSet) => setCalendarStartDate(dateSet.start)}
                                    firstDay={1}
                                />

                                </CardBody>

                            </Card>   
                    </Col>
                    </Row>

         <WorkoutLeaderboardDetailsModal
            scheduleWorkout={scheduleWorkout}
            open={workoutLeaderboardDetailsOpen}
            workoutBoardData={workoutLeaderboardDetailsItem}
            woroutBoardIndex={workoutLeaderboardDetailsIndex}
            onWorkoutLeaderboardDetailsModalClose={onWorkoutLeaderboardDetailsModalClose}
          />


    </div>
      
    );
}

export default UserProgressCalendar;