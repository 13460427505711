import './users.css';
import React, { Fragment,useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter, Media, Form, FormGroup, Label, Input, Button } from 'reactstrap'
import axios from 'axios'
import { MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete} from '../../constant'
import { useParams } from 'react-router';
import NoAccess from '../../components/reuse/noaccess';
import { getUserData } from '../../redux/user/action';
import { Loader } from 'react-bootstrap-typeahead';
import { toast } from 'react-toastify';
import { editUser, forgotPass, getUser,editRoles, changeAccountStatus } from '../../redux/auth/action';

import DatePicker from "react-datepicker";
import { Link } from 'react-router-dom';
import * as General from '../../util/General'
import SweetAlert from 'sweetalert2'

const UserStatus = (props) => {

  const {userInfo} = props;
  
  const dispatch = useDispatch();

  //IN THIS COMPONENTS WE CAN EDIT THE USER
 
  const userLocale = General.getLocale();
  //LETS GET THE TRANSLATIONS and settings
  const trans = useSelector(({translation}) => translation.translationsData);
  const settingsData = useSelector(({settings}) => settings.settingsData);
  const changeStatusData = useSelector(({auth}) => auth.changeStatusData);

  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    setEnabled(userInfo.enabled);
  },[userInfo])
  

  useEffect(() => {
   if (changeStatusData != null && changeStatusData.status){
      window.location.reload(); 
   }
  },[changeStatusData])




  const changeUserStatus = (e) => {
    //SHOW CONFIRMATIONS
    e.preventDefault();
    if (enabled){
    SweetAlert.fire({
        title:trans.account_status_title,
        text:trans.disable_account_alert,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText:trans.ok_btn,
        cancelButtonText: trans.cancel_btn,
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
            //OK
            sendUserStatus();
        }
        else {

        }
      })
    } else {
      sendUserStatus();
    }
  }

  const sendUserStatus = () => {
    let postData = {enabled: !enabled,userId:userInfo.userId };
    dispatch(changeAccountStatus(postData));
  }

  return (
    <Card>
               <CardHeader>
                  <h4 className="card-title mb-0">{trans.account_status_title}</h4>
                  <div className="card-options">
                    <a className="card-options-collapse" href="#javascript">
                      <i className="fe fe-chevron-up"></i>
                    </a>
                    <a className="card-options-remove" href="#javascript">
                      <i className="fe fe-x"></i>
                    </a>
                  </div>
                </CardHeader>
                <CardBody>
                <Form className="row" noValidate autoComplete="off">
                      <Col md="12">
                        <Label style={{fontSize:"12px"}} className="form-label">{enabled ? trans.disable_account_descr : trans.enable_account_descr}</Label>

                        </Col>
                      <Col md="12">
                        <button
                        style={{float:"right"}}
                        className="add_contact_method_button btn btn-secondary"
                            onClick={(e) => {changeUserStatus(e)}}>
                         {enabled ? trans.btn_disable : trans.btn_enable}
                        </button>
                      </Col>
                  
                    </Form>
                </CardBody>
              </Card>
  );
}

export default UserStatus;