import {
  FETCH_ADMIN_USERS,
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    FETCH_USER_MEDIA,
    ADD_USER_MEDIA,
    EDIT_USER_MEDIA,
    DELETE_USER_MEDIA,
    SINGLE_USER_MEDIA,
    INIT_URL,
    USER_ID_SET
  } from '../actionTypes';
  import axios from '../../util/Api'
  import * as General from '../../util/General'
  
  import {GENERAL_ERROR} from '../../constant';
  
  export const setInitUrl = (url) => {
    return {
      type: INIT_URL,
      payload: url
    };
  };

  export const getUserMedia = (postData) => {
    //Get user locale of the machine he is on
  
    const userLocale = General.getLocale();


    postData.langLoc = userLocale;

    console.log(postData);
    return (dispatch) => {
      dispatch({type: FETCH_START});
      axios.post('usermedia/all', postData
      ).then(({data}) => {
        console.log(data);
        if (data){
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: FETCH_USER_MEDIA, payload: data});
        } else {
          dispatch({type: FETCH_ERROR, payload: data.error});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };

  export const addUserMedia = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;

    //console.log(postData);

    return (dispatch) => {
      dispatch({type: FETCH_START});
      axios.post('usermedia/add', postData
      ).then(({data}) => {
        if (data){
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: ADD_USER_MEDIA, payload: data});
        } else {
          dispatch({type: FETCH_ERROR, payload: data.error});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };

  export const editUserMedia = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();


    postData.langLoc = userLocale;

    return (dispatch) => {
      dispatch({type: FETCH_START});
      axios.post('usermedia/edit', postData
      ).then(({data}) => {
        if (data){
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: EDIT_USER_MEDIA, payload: data});
        } else {
          dispatch({type: FETCH_ERROR, payload: data.error});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };

  export const deleteUserMedia = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;

    return (dispatch) => {
      dispatch({type: FETCH_START});
      axios.post('usermedia/delete', postData
      ).then(({data}) => {
        if (data){
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: DELETE_USER_MEDIA, payload: data});
        } else {
          dispatch({type: FETCH_ERROR, payload: data.error});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };


  export const singleUserMedia = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();


    postData.langLoc = userLocale;

    return (dispatch) => {
      dispatch({type: FETCH_START});
      axios.post('usermedia/single', postData
      ).then(({data}) => {
        if (data){
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: SINGLE_USER_MEDIA, payload: data});
        } else {
          dispatch({type: FETCH_ERROR, payload: data.error});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };