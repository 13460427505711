import {
  FETCH_TRAININGS,
USER_ID_SET
  } from '../actionTypes';
  import axios from '../../util/Api'
  import * as General from '../../util/General'
  
  import {GENERAL_ERROR} from '../../constant';
  
  

  export const getTrainings = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;
    //console.log(postData);
    return (dispatch) => {
      axios.post('training/all', postData
      ).then(({data}) => {
        // console.log(data);
        if (data){
          dispatch({type: FETCH_TRAININGS, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };