import {
    FETCH_DONE_SCHEDULES,
    ADD_DONE_SCHEDULE,
    EDIT_DONE_SCHEDULE,
    DELETE_DONE_SCHEDULE,
    SINGLE_DONE_SCHEDULE,
    WORKOUTBOARD_DONE_SCHEDULE,
    USER_PROGRESS_KGS_LBS,
    USER_PROGRESS_METERS,
    USER_PROGRESS_REPS,
    USER_PROGRESS_CALORIES,
    USER_PROGRESS_TIME,
    USER_PROGRESS_CHECKBOX,
    USER_STATS
} from '../actionTypes';

  const INIT_STATE = {
      doneSchedulesData: null,
      addDoneScheduleData: null,
      editDoneScheduleData: null,
      singleDoneScheduleData: null,
      deleteDoneScheduleData: null,
      workoutBoardData: null,
      userProgressKgsLbs: null,
      userProgressMeters: null,
      userProgressReps: null,
      userProgressCalories: null,
      userProgressTime: null,
      userProgressCheckbox: null,
      userStatsData: null
    };
    
    export default (state = INIT_STATE, action) => {
        switch(action.type) {
        case FETCH_DONE_SCHEDULES:
             return {
                ...state,
                doneSchedulesData: action.payload
        }
        case ADD_DONE_SCHEDULE:
            return {
               ...state,
               addDoneScheduleData: action.payload
       }
        case EDIT_DONE_SCHEDULE:
             return {
                ...state,
                editDoneScheduleData: action.payload
        }
        case DELETE_DONE_SCHEDULE:
            return {
               ...state,
               deleteDoneScheduleData: action.payload
           }
        case SINGLE_DONE_SCHEDULE:
            return {
            ...state,
            singleDoneScheduleData: action.payload
            }
        case WORKOUTBOARD_DONE_SCHEDULE:
            return {
            ...state,
            workoutBoardData: action.payload
            }
            case USER_PROGRESS_KGS_LBS:
                return {
                ...state,
                userProgressKgsLbs: action.payload
                }

                case USER_PROGRESS_METERS:
            return {
            ...state,
            userProgressMeters: action.payload
            }

            case USER_PROGRESS_REPS:
            return {
            ...state,
            userProgressReps: action.payload
            }

            case USER_PROGRESS_CALORIES:
            return {
            ...state,
            userProgressCalories: action.payload
            }

            case USER_PROGRESS_TIME:
            return {
            ...state,
            userProgressTime: action.payload
            }

            case USER_PROGRESS_CHECKBOX:
            return {
            ...state,
            userProgressCheckbox: action.payload
            }
            case USER_STATS:
                return {
                 ...state,
                  userStatsData: action.payload
               }
        default:
              return state;
        }
    }
    