import React, { Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap'
const Footer = (props) => {
  return (
    <Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md="12" className="footer-copyright text-center">
              <div><p className="mb-0">{`Copyright ${new Date().getFullYear()} `} © <a target="_blank" href="https://atplab.app/"><b>ATPLab App</b></a> by <a target="_blank" href="https://www.focus-on.gr/"><b>FOCUS ON GROUP</b></a> V {process.env.REACT_APP_VERSION} </p></div>
            </Col>
          </Row>
        </Container>
      </footer>
    </Fragment>
  );
}

export default Footer;