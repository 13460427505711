import  './prworkout.css';
import React,{useEffect, useState} from 'react';
import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Form, FormGroup, Input, Label, Button,NavItem, NavLink, Nav,TabContent,TabPane, Breadcrumb, Card, CardHeader, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import NumericInput from 'react-numeric-input';
import { SCORE_TYPE_CALORIES, SCORE_TYPE_KGS_LBS, SCORE_TYPE_REPS, SCORE_TYPE_TIME } from '../../constant';
import { doneEditPrworkout, donePrworkout } from '../../redux/prworkout/action';
import LoaderBox from '../../components/reuse/loaderbox';
import { toast } from 'react-toastify';
import { DONE_PRWORKOUT_EDIT, DONE_PRWORKOUT_SUBMIT } from '../../redux/actionTypes';


const AddPrworkout = (props) => {

    const dispatch = useDispatch();

    const {onAddPrworkoutClose, open, prWorkout,scoreTypeTitle,onAddPrworkoutStored,isEdit,editItem} = props;
    const trans = useSelector(({translation}) => translation.translationsData);

    const donePrWorkoutsData = useSelector(({prworkout}) => prworkout.donePrWorkoutsData);
    const doneEditPrworkoutData = useSelector(({prworkout}) => prworkout.doneEditPrworkoutData);
 

    const [value, setValue] = useState("");
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [customType, setCustomType] = useState("KGS");
    const [notes, setNotes] = useState("");
    
    const [isLoading, setIsLoading] = useState(false);

    const [editResponse, setEditResponse] = useState(null);

    useEffect(() => {
        if (open){
            setValue("")
            setMinutes(0)
            setSeconds(0)
            setCustomType("KGS")
            setNotes("")
            setIsLoading(false)

            if (isEdit){
                setValue(editItem.value);
                setMinutes(editItem.minutes);
                setSeconds(editItem.seconds);
                setCustomType(editItem.customType);
                setNotes(editItem.notes);
            }
        }
    }, [open]);

    useEffect(() => {
        if (donePrWorkoutsData != null){
            if (donePrWorkoutsData.status){
                toast.success(donePrWorkoutsData.message, {
                  position: toast.POSITION.TOP_RIGHT
                  });
                  
                  onAddPrworkoutClose();
                  onAddPrworkoutStored();

              } else {
                toast.error(donePrWorkoutsData.message, {
                  position: toast.POSITION.TOP_RIGHT
                  });
              }
              dispatch({type: DONE_PRWORKOUT_SUBMIT, payload: null});
        }
    }, [donePrWorkoutsData]);

    useEffect(() => {
        if (doneEditPrworkoutData != null){
            setEditResponse(doneEditPrworkoutData);
   
        }
    }, [doneEditPrworkoutData]);


    useEffect(() => {
        if (editResponse != null){

            if (editResponse.status){
                  onAddPrworkoutClose();
                  onAddPrworkoutStored();
              } 
              
              setEditResponse(null);
              dispatch({type: DONE_PRWORKOUT_EDIT, payload: null});
        }
    }, [editResponse]);

    const setWorkoutValue = (e) => {
        setValue(e.target.value);
    }

    const onCheckBoxSet = (e) => {
        setValue(e.target.checked == true ? 1 : 0);
    }

    const setSetMinutes = (e,minuteValue) => {
        var itemValue =  parseInt(e.value);
        if (itemValue <= 59){
            setMinutes(minuteValue)
            var newValue = `${itemValue == 0 ? "00" : itemValue}:${seconds == 0 ? "00" : seconds}`;
            setValue(newValue);
        }
    
    
    }


    const setSetSeconds = (e,secondsValue) => {
        var itemValue =  parseInt(e.value);
        if (itemValue <= 59){
            setSeconds(secondsValue)
            var newValue = `${minutes == 0 ? "00" : minutes}:${itemValue == 0 ? "00" : itemValue}`;
            setValue(newValue);
        }
    }

    const setKGSLBSValue = (e) => {
        setValue(e.target.value);
    }


    const setIntSet = (e,value) => {
       setValue(value);
    }
    

    function intFormat(num) {
        return parseInt(num);
    }

    function renderWorkoutInput(){
        var title = `${scoreTypeTitle}`;
        var inputId = `pr_workout_score`;
        var input = (<Input defaultValue={value} id={inputId} onChange={(e)=> setWorkoutValue(e)} type="number"/>);

        if (prWorkout.type == "SCORE_TYPE_CHECKBOX") {
            input = ( 
            <div className="checkbox checkbox-success _done_schedule_checbkox_container">
               <Input id={inputId}  type="checkbox" defaultChecked={parseInt(value) == 1} onChange={(e)=> onCheckBoxSet(e)}/>
               <Label for={inputId} >{trans.workout_checkbox_label}</Label>
           </div>
           )
        } else  if (prWorkout.type == "SCORE_TYPE_TIME") {
            input = ( 
            <div className="">
                    <FormGroup>
                    <Label className="form-label font-weight-bold">{trans.minutes_field}</Label>
                    <NumericInput min={0} max={59}  strict name={`workout_minute_set_`} id={`workout_minute_set_`} value={minutes} className="form-control" type="number" placeholder={trans.minutes_field} onChange={(value,stringValue,e)=>{setSetMinutes(e,value)}}/>
                    </FormGroup>

                    <FormGroup>
                    <Label className="form-label font-weight-bold">{trans.seconds_field}</Label>
                    <NumericInput min={0} max={59} strict  name={`workout_second_set_`} id={`workout_second_set_`} value={seconds} className="form-control" type="number" placeholder={trans.seconds_field} onChange={(value,stringValue,e)=>{setSetSeconds(e,value)}} />
                    </FormGroup>
            </div>
            )
        } else  if (prWorkout.type == "SCORE_TYPE_KGS_LBS") {
            input = ( 
            <div className="">
                <FormGroup>
                    <Label className="form-label font-weight-bold">{trans.workout_kgs_field}</Label>
                    <Input defaultValue={value} id={inputId} onChange={(e)=> setKGSLBSValue(e)} type="number"/>
                </FormGroup>
            </div>
            )
       } else if (prWorkout.type == SCORE_TYPE_CALORIES) {
                input = ( 
                    <div className="">
                        <FormGroup>
                        <NumericInput  strict id={inputId} value={value} className="form-control" type="number"  onChange={(value,stringValue,e)=>{setIntSet(e,value)}} format={intFormat} />
                            {/* <Input defaultValue={setsArray[i].value} id={inputId} onChange={(e)=> setSet(e)} type="number"/> */}
                        </FormGroup>
                    
                </div>
                )
        } else if (prWorkout.type == SCORE_TYPE_REPS) {
            input = ( 
                <div className="">
                    <FormGroup>
                    <NumericInput  strict id={inputId} value={value} className="form-control" type="number"  onChange={(value,stringValue,e)=>{setIntSet(e,value)}} format={intFormat} />
                        {/* <Input defaultValue={setsArray[i].value} id={inputId} onChange={(e)=> setSet(e)} type="number"/> */}
                    </FormGroup>
                
            </div>
            )
       }
       
       return ( 
       <FormGroup key={"_add_pr_workout_form"} >
         <Label for={inputId}>{title}</Label>
         {input}
       </FormGroup>);

    }

    function renderCustomTypeInput() {
        if (prWorkout.type == SCORE_TYPE_KGS_LBS){
            return (<div>
                <FormGroup>
                                <Label className="form-label font-weight-bold">{trans.workout_kgs_field_type}</Label>
                                <Input defaultValue={customType} className="custom-select" onChange={(e)=> setCustomType(e.target.value)} type="select" id={`pr_workout_custom_type`}>
                                    <option value={"KGS"}>{trans.kgs_type}</option>
                                    <option value={"LB"}>{trans.lb_type}</option>
                                </Input>
                            </FormGroup>
            </div>)
        } else {
            return (null);
        }
    }

    const storeDonePrWorkout = (e) => {
        e.preventDefault();
        let postData = {
            "prworkoutId": prWorkout.id,
            "scoreType": prWorkout.type,
            "value": value,
            "minutes": minutes,
            "seconds": seconds,
            "customType": customType,
            "notes": notes
        };
        setIsLoading(true);

        if (isEdit){
            postData.prworkoutDoneId = editItem.id;
            dispatch(doneEditPrworkout(postData))
        } else {
            dispatch(donePrworkout(postData))
        }
       
    }

    const validateSubmition = () => {
        if (value == "") {
            return true;
        }

       

        return false;
    }

    return (<Modal isOpen={open} toggle={onAddPrworkoutClose} centered size="xl">
             <ModalHeader toggle={onAddPrworkoutClose}>{trans.add_personal_record_score}</ModalHeader>
             <ModalBody>
                
                {prWorkout == null ? <div></div> : 
                isLoading ? <div>
                    <LoaderBox/>
                </div> :
                <div>
                    <p >{trans.selected_pr_workout}:</p>
                    <p style={{fontSize:16,fontWeight:700}}>{prWorkout.name}</p>
                    {renderCustomTypeInput()}
                    {renderWorkoutInput()}
                    <FormGroup >
                       <Label className="font-weight-bold">{trans.done_schedule_notes}</Label>
                       <Input value={notes} type="textarea" onChange={e => setNotes(e.target.value)}/>
                    </FormGroup>
                </div> }
            </ModalBody>

            <ModalFooter>
                 <button disabled={validateSubmition() || isLoading} onClick={(e)=>{storeDonePrWorkout(e)}} className="btn btn-secondary">{trans.submit_btn}</button>
            </ModalFooter>
     </Modal>);
};

export default AddPrworkout;