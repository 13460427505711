import {
    FETCH_WORKOUTS,
    ADD_WORKOUT,
    SINGLE_WORKOUT,
    EDIT_WORKOUT_DATA,
    DELETE_WORKOUT} from '../actionTypes';

  const INIT_STATE = {
     workoutsData: null,
     addWorkoutData: null,
     editWorkoutData: null,
     singleWorkoutData: null,
     deleteWorkoutData: null
    };
    
    export default (state = INIT_STATE, action) => {
        switch(action.type) {
        case FETCH_WORKOUTS:
             return {
                ...state,
            workoutsData: action.payload
        }
        case ADD_WORKOUT:
             return {
                ...state,
                addWorkoutData: action.payload
        }
        case EDIT_WORKOUT_DATA:
             return {
                ...state,
                editWorkoutData: action.payload
        }
        case SINGLE_WORKOUT:
            return {
                ...state,
                singleWorkoutData: action.payload
            }
        case DELETE_WORKOUT:
            return {
             ...state,
             deleteWorkoutData: action.payload
            }
        default:
              return state;
        }
    }
    