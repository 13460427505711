import './nutritionMeal.css';
import React, { Fragment,useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Breadcrumb from '../../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardFooter, Media, Button, Form, FormGroup, Input, Pagination, PaginationItem, PaginationLink,CardBody,Label } from 'reactstrap'


import * as General from '../../../util/General'
import { useHistory } from 'react-router';
import {getNutritionMeal, addNutritionMeal, deleteNutritionMeal, editNutritionMeal } from '../../../redux/nutrition/nutritionMeal/action';
import { toast } from 'react-toastify';
import AddNutritionMeal from './addNutritionMeal';
import NoAccess from '../../../components/reuse/noaccess';
import SweetAlert from 'sweetalert2'
import LoaderBox from '../../../components/reuse/loaderbox';
import { ADD_NUTRITION_USER_MEAL, DELETE_NUTRITION_USER_MEAL } from '../../../redux/actionTypes';

import parse from 'html-react-parser';
import { addNutritionUserMeal ,deleteNutritionUserMeal} from '../../../redux/nutrition/nutritionUserMeal/action';
import NutritionMealItem from './NutritionMealItem';
import { checkUserSub } from '../../../redux/subscription/action';

const NutritionMealList = (props) => {


  var selectedPlan = "";
  var selectedMenu = "";
  let { planId,menuId } = useParams();
  if (planId != null){
    selectedPlan = planId;
  }
  if (menuId != null){
    selectedMenu = menuId;
  }

  
 
  
  const userLocale = General.getLocale();
  const history = useHistory();
  const dispatch = useDispatch();

  const userInfo = useSelector(({auth}) => auth.authUser);
  const nutritionMealData = useSelector(({nutritionMeal}) => nutritionMeal.fetchNutritionMealData);
  const addNutritionUserMealData = useSelector(({nutritionUserMeal}) => nutritionUserMeal.addNutritionUserMealData);
  const deleteNutritionUserMealData = useSelector(({nutritionUserMeal}) => nutritionUserMeal.deleteNutritionUserMealData);
  const trainingsData = useSelector(({trainings}) => trainings.trainingsData);
 

  const trans = useSelector(({translation}) => translation.translationsData);
  const userIsAdmin = useSelector(({auth}) => auth.userIsAdmin);
  const userIsManager = useSelector(({auth}) => auth.userIsManager);
  const userIsCoach = useSelector(({auth}) => auth.userIsCoach);

  const checkSubData = useSelector(({subscription}) => subscription.checkSubData);

  const [userHasOneSub,setUserHasOneSub] = useState(false);

  const [selectedDate, setSelectedDate] = useState(new Date());
  
  
  

    useEffect(() => {
      getMealsData();
      getUserSubs();
    }, []);

    useEffect(() => {
      if (checkSubData != null){
        setUserHasOneSub(checkSubData.hasOneSub)
      }
    
    }, [checkSubData]);


  const getUserSubs = () => {
      let postData = {}
      dispatch(checkUserSub(postData));
    }
   
   
    useEffect(() => {
      if (addNutritionUserMealData != null){
        if (addNutritionUserMealData.status){
          toast.success(addNutritionUserMealData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
            getMealsData();
        } else {
          toast.error(addNutritionUserMealData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
        }
    
        dispatch({type:ADD_NUTRITION_USER_MEAL, payload: null});
      }
    }, [addNutritionUserMealData]);


    useEffect(() => {
      if (deleteNutritionUserMealData != null){
        if (deleteNutritionUserMealData.status){
          toast.success(deleteNutritionUserMealData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
            getMealsData();
        } else {
          toast.error(deleteNutritionUserMealData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
        }
    
        dispatch({type:DELETE_NUTRITION_USER_MEAL, payload: null});
      }
    }, [deleteNutritionUserMealData]);
    
 
    
   


  const getMealsData = () => {
    let postData = {
      fromApp: true,
      visible:true,
      menuId: selectedMenu,
      planId:selectedPlan,
      hasDayFilter: true,
      month: selectedDate.getMonth()+1,
      monthDay:selectedDate.getDate(),
      year: selectedDate.getFullYear(),
    }
    dispatch(getNutritionMeal(postData));
  }



  const addToMeal = (item) => {

    console.log(item);

    if (item.isDone){
      SweetAlert.fire({
        title:trans.delete_dialog_title,
        text:trans.delete_dialog_desc,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText:trans.ok_btn,
        cancelButtonText: trans.cancel_btn,
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
            //OK
              //REMOVE
            let postData = {itemId: item.doneId}
            dispatch(deleteNutritionUserMeal(postData));
        }
        else {
          
        }
      })
    } else {
    
      SweetAlert.fire({
        title:trans.add_to_meal_title,
        text:trans.add_to_meal_desc,
        icon: 'success',
        showCancelButton: true,
        confirmButtonText:trans.ok_btn,
        cancelButtonText: trans.cancel_btn,
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
            //OK
              //REMOVE
            let postData = {mealId: item.id}
            dispatch(addNutritionUserMeal(postData));
        }
        else {
          
        }
      })

    }
  }

 
  const getNutritionMealsUI = () => {
    var nutritionMeals = [];

    if (nutritionMealData == null){
      return(<LoaderBox></LoaderBox>)
    }

    nutritionMealData.data.map((item,i) => {

      nutritionMeals.push(<Col key={item.id}>
          <NutritionMealItem
              item={item}
              onUserMealAdd={addToMeal}
              onUserMealDelete={() => addToMeal(item)}
              viewMode={item.isDone}
            />
      </Col>);

      
    });

    if (nutritionMeals.length > 0){
      return (<div className="table-responsive">
         {nutritionMeals}
      </div>);
    } else {
      return (<div className="m-10">
          {trans.no_training_nutritionMeals}
        </div>)
    }

  }



  return (
    <Fragment>
      <Breadcrumb parent={trans.nutrition_meals} title={trans.nutrition_meals} />
      {checkSubData == null ? <div></div> :
      <Container fluid={true}>
        {(!userIsAdmin || !userIsCoach || !userIsManager) && !userHasOneSub ?
          <Col md="12">
          <NoAccess/>
          </Col>
        : 
        
       <div>
    
    <div>
                      {getNutritionMealsUI()}
                    </div>
                
  </div>}
      </Container> }
    
    </Fragment>
  );
}

export default NutritionMealList;