import './users.css';
import React, { Fragment,useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter, Media, Form, FormGroup, Label, Input, Button } from 'reactstrap'
import axios from 'axios'
import { MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete} from '../../constant'
import { useParams } from 'react-router';
import NoAccess from '../../components/reuse/noaccess';
import { getUserData } from '../../redux/user/action';
import { Loader } from 'react-bootstrap-typeahead';
import { toast } from 'react-toastify';
import { editUser, forgotPass, getUser,editRoles, changeAccountStatus } from '../../redux/auth/action';

import DatePicker from "react-datepicker";
import { Link } from 'react-router-dom';
import * as General from '../../util/General'
import SweetAlert from 'sweetalert2';
import { cancelSub, checkUserSub } from '../../redux/subscription/action';
import LoaderBox from '../../components/reuse/loaderbox';


const UserSubscriptionSettings = (props) => {

    const dispatch = useDispatch();

    //LETS GET THE TRANSLATIONS
    const trans = useSelector(({translation}) => translation.translationsData);

    //LETS GET THE LOGGED IN USER DATA
   const userInfo = useSelector(({auth}) => auth.authUser);


   const checkSubData = useSelector(({subscription}) => subscription.checkSubData);
   const cancelSubData = useSelector(({subscription}) => subscription.cancelSubData);

   useEffect(() => {
    getUserSubs();
  }, []);

  useEffect(() => {
    if (cancelSubData != null) {
      if (cancelSubData.status){
        toast.success(cancelSubData.message, {
          position: toast.POSITION.TOP_RIGHT
          });
          getUserSubs();
      } else {
        toast.error(cancelSubData.message, {
          position: toast.POSITION.TOP_RIGHT
          });
      }
    }

  }, [cancelSubData]);

   
   const getUserSubs = () => {
    let postData = {fromUserSettings: true}
    dispatch(checkUserSub(postData));
  }

  const deleteItem = (e,item) => {
    e.preventDefault();
    SweetAlert.fire({
      title:trans.cancel_sub_button,
      text:trans.cancel_sub_descr,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText:trans.ok_btn,
      cancelButtonText: trans.cancel_btn,
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
          //OK
        let postData = {subscriptionId: item.id};
        dispatch(cancelSub(postData));
      }
      else {

      }
    });
  }

    return (
            <Card>
                <CardHeader>
                        <h4 className="font-weight-bold">{trans.user_subscriptions_title}</h4>
                </CardHeader>
                <CardBody>
                   <Row>
                     <Col>
                        {checkSubData == null ? <LoaderBox/> : 
                        checkSubData.subscriptions.length == 0 ? <div>
                          {trans.no_subscriptions}
                        </div> :
                         <div className="table-responsive">
                            <table className="table card-table table-vcenter text-nowrap">
                            <thead>
                                <tr className="">
                                <th key={"training_name"}>{trans.training_name}</th>
                                <th key={"amount"}>{trans.value_price}</th>
                                <th key={"billingPeriodStartDate"}>{trans.billing_period_start_date}</th>
                                <th key={"nextBillingDate"}>{trans.next_billing_date}</th>
                                <th key={"value_status"}>{trans.value_status}</th>
                                <th key={"cancel_btn"}></th>
                                </tr>
                            </thead>
                            <tbody>
        
                                {checkSubData.subscriptions.map((item,i) => {
                                
                                // const diffInMs   = new Date(item.nextBillingDate) - new Date()
                                // const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
                           

                                var remainingDays = item.diffInDays <= 0 ? "" : `. ${trans.remaining_text} ${item.diffInDays} ${trans.remaining_days_text}`
                                var statusText = item.status ? trans.subscription_status_true : `${trans.subscription_status_false}${remainingDays}`  
                                var statusColor = item.status ? 'green' : 'red'
                                return (<tr role="button"  key={`subscription_item_${i}`} className="_table_subscription_item">
                                    <td >{item.training.title}</td>
                                    <td >{item.amount}</td>
                                    <td >{item.billingPeriodStartDate}</td>
                                    <td >{item.nextBillingDate}</td>
                                    <td style={{fontWeight:500,color:statusColor}}>{statusText}</td>
                                    <td >{item.isCustom == false && item.status ?  
                                     <Button onClick={(e)=> deleteItem(e,item)} color="secondary" size="sm">
                                           {trans.cancel_sub_button}
                                     </Button> : null
                                      }</td>
                                </tr>)
                                }
                                )}
                                
                            </tbody>
                            </table>
                         </div>
                        }
                     </Col>   
                    </Row>  
                </CardBody>
            </Card>
  );
}

export default UserSubscriptionSettings;