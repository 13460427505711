import  './schedule.css';
import React,{useEffect, useState} from 'react';
import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Form, FormGroup, Input, Label, Button,NavItem, NavLink, Nav,TabContent,TabPane, Breadcrumb, Card, CardHeader, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { addDoneSchedule, editDoneSchedule } from '../../redux/doneschedule/action';
import { ItemBullets, SCORE_TYPE_CALORIES, SCORE_TYPE_KGS_LBS, SCORE_TYPE_REPS, SCORE_TYPE_TIME } from '../../constant';
import NumericInput from 'react-numeric-input';

const DoneSchedule = (props) => {

    const dispatch = useDispatch();

    const {onStored, onDoneScheduleClose, open, scheduleWorkout, isEdit} = props;
  
  
    const settingsData = useSelector(({settings}) => settings.settingsData);
    const addDoneScheduleData = useSelector(({doneSchedules}) => doneSchedules.addDoneScheduleData);
    const editDoneScheduleData = useSelector(({doneSchedules}) => doneSchedules.editDoneScheduleData);

    const trans = useSelector(({translation}) => translation.translationsData);

    const [notes,setNotes] = useState(""); 
    const [setsArray,setSetsArray] = useState([]); 
    const [privateResult,setPrivateResult] = useState(false); 

    useEffect(() => {
        if (open){

            setNotes("");
            setPrivateResult(false);

            var arr = [];
            
            var i = 0;
            var amount = parseInt(scheduleWorkout.workoutdata.scoring.amount);
            for (i = 0; i < amount; i++) {
                arr.push({value: 0, scoreType: scheduleWorkout.workoutdata.scoring.id,customType: "KGS"});
            }

           
            
            if (isEdit){
                if (scheduleWorkout.doneSchedule != null){
                    setNotes(scheduleWorkout.doneSchedule.notes);
                    scheduleWorkout.doneSchedule.sets.forEach((storedSet,setIndex) => {
                        arr[setIndex].value = storedSet.value;

                        arr[setIndex].minutes = storedSet.minutes;

                        arr[setIndex].seconds = storedSet.seconds;

                        arr[setIndex].customType = storedSet.customType;

                        
                    });
                    setPrivateResult(scheduleWorkout.doneSchedule.privateResult);
                }
            }

            setSetsArray(arr);
           
        }
     }, [open]);

     useEffect(() => {
        // console.log(addDoneScheduleData);
        if (addDoneScheduleData != null){
            if (addDoneScheduleData.status){
                onStored();
            }
        }
     }, [addDoneScheduleData]);

     useEffect(() => {
        //console.log(editDoneScheduleData);
       if (editDoneScheduleData != null){
           if (editDoneScheduleData.status){
               onStored();
           }
       }
    }, [editDoneScheduleData]);
     

    const setSet = (e) => {
        let setIndex = e.target.id.replace("workout_set_","");
        setsArray[setIndex].value = e.target.value;
    }

    const setIntSet = (e,value) => {
        let setIndex = e.id.replace("workout_set_","");
        setsArray[setIndex].value = value;
    }


    const onCheckBoxSet = (e,inputId) => {
        //console.log(e);
        // console.log(inputId);
        let setIndex = e.target.id.replace("workout_set_","");
        console.log('setIndex',setIndex);
        setsArray[setIndex].value = e.target.checked == true ? 1 : 0;
    }


    const setSetMinutes = (e,value) => {
        let setIndex = e.id.replace("workout_minute_set_","");
        var value =  parseInt(e.value);
        if (value <= 59){
            setsArray[setIndex].minutes = value;
        }
    }


    const setSetSeconds = (e,value) => {
        let setIndex = e.id.replace("workout_second_set_","");
        var value =  parseInt(e.value);
        if (value <= 59){
            setsArray[setIndex].seconds = value;
        }
    }

    const setCustomType = (e) => {
        //console.log(e);
        //let setIndex = e.target.id.replace("workout_custom_type_set_","");
        var value =  e.target.value;
        setsArray.forEach((element,setIndex) => {
            setsArray[setIndex].customType = value;
        });   
    }

    function intFormat(num) {
        return parseInt(num);
    }

 
    function renderSets() {
        if (scheduleWorkout != null) {
       // console.log("scheduleWorkout.workout.scoring",scheduleWorkout.workout.scoring);
        var i;
        var setsToAdd = [];
        for (i = 0; i < setsArray.length; i++) {
            var title = `#${i+1} - ${scheduleWorkout.workoutdata.scoring.name}`;
            var inputId = `workout_set_${i}`;
            var input = (<Input defaultValue={setsArray[i].value} id={inputId} onChange={(e)=> setSet(e)} type="number"/>);
            
            if (scheduleWorkout.workoutdata.scoring.type == "SCORE_TYPE_CHECKBOX") {
                 input = ( 
                 <div className="checkbox checkbox-success _done_schedule_checbkox_container">
                    <Input id={inputId}  type="checkbox" defaultChecked={parseInt(setsArray[i].value) == 1} onChange={(e)=> onCheckBoxSet(e,inputId)}/>
                    <Label for={inputId} >{trans.workout_checkbox_label}</Label>
                </div>
                )
            } else  if (scheduleWorkout.workoutdata.scoring.type == "SCORE_TYPE_TIME") {
                input = ( 
                <div className="">
                     <FormGroup>
                        <Label className="form-label font-weight-bold">{trans.minutes_field}</Label>
                        <NumericInput min={0} max={59}  strict name={`workout_minute_set_${i}`} id={`workout_minute_set_${i}`} value={setsArray[i].minutes} className="form-control" type="number" placeholder={trans.minutes_field} onChange={(value,stringValue,e)=>{setSetMinutes(e,value)}}/>
                      </FormGroup>

                      <FormGroup>
                        <Label className="form-label font-weight-bold">{trans.seconds_field}</Label>
                        <NumericInput min={0} max={59} strict  name={`workout_second_set_${i}`} id={`workout_second_set_${i}`} value={setsArray[i].seconds} className="form-control" type="number" placeholder={trans.seconds_field} onChange={(value,stringValue,e)=>{setSetSeconds(e,value)}} />
                      </FormGroup>
              </div>
               )
           } else  if (scheduleWorkout.workoutdata.scoring.type == "SCORE_TYPE_KGS_LBS") {
                input = ( 
                <div className="">
                    <FormGroup>
                        <Label className="form-label font-weight-bold">{trans.workout_kgs_field}</Label>
                        <Input defaultValue={setsArray[i].value == 0 ? "" : setsArray[i].value} id={inputId} onChange={(e)=> setSet(e)} type="number"/>
                    </FormGroup>

                   
            </div>
            )
        } else if (scheduleWorkout.workoutdata.scoring.type == SCORE_TYPE_CALORIES) {
            input = ( 
                <div className="">
                    <FormGroup>
                    <NumericInput  strict id={inputId} value={setsArray[i].value} className="form-control" type="number"  onChange={(value,stringValue,e)=>{setIntSet(e,value)}} format={intFormat} />
                        {/* <Input defaultValue={setsArray[i].value} id={inputId} onChange={(e)=> setSet(e)} type="number"/> */}
                    </FormGroup>
                
            </div>
            )
       } else if (scheduleWorkout.workoutdata.scoring.type == SCORE_TYPE_REPS) {
        input = ( 
            <div className="">
                <FormGroup>
                <NumericInput  strict id={inputId} value={setsArray[i].value} className="form-control" type="number"  onChange={(value,stringValue,e)=>{setIntSet(e,value)}} format={intFormat} />
                    {/* <Input defaultValue={setsArray[i].value} id={inputId} onChange={(e)=> setSet(e)} type="number"/> */}
                </FormGroup>
            
        </div>
        )
   } 

            setsToAdd.push(
            <FormGroup key={inputId} >
             <Label for={inputId}>{title}</Label>
             {input}
            </FormGroup>
          )
        }
        return (
            <div>
                {scheduleWorkout.workoutdata.scoring.type == SCORE_TYPE_KGS_LBS ? 
                <div>
                 
                            <FormGroup>
                                <Label className="form-label font-weight-bold">{trans.workout_kgs_field_type}</Label>
                                <Input defaultValue={setsArray[0] != null && setsArray[0].customType != null ? setsArray[0].customType : "KGS"} className="custom-select" onChange={(e)=> setCustomType(e)} type="select" id={`workout_custom_type_set_${0}`}>
                                    <option value={"KGS"}>{trans.kgs_type}</option>
                                    <option value={"LB"}>{trans.lb_type}</option>
                                </Input>
                            </FormGroup>
                            <br />
                            <br />
                 </div> : null}
            {setsToAdd}
            </div>
            )
        } else {
            return (<div></div>)
        }
    }

    const storeDoneSchedule = (e) => {
        e.preventDefault();
        if (isEdit){
            let postData = {
                "doneScheduleId": scheduleWorkout.doneSchedule.id,
                "notes": notes,
                "sets": setsArray,
                "scoreType": scheduleWorkout.workoutdata.scoring.type,
                "privateResult": privateResult
            };
            dispatch(editDoneSchedule(postData));
        } else {
            let postData = {
                "scheduleWorkoutId": scheduleWorkout.id,
                "scheduleId": scheduleWorkout.scheduleId,
                "doneWorkout": scheduleWorkout.workout.id,
                "notes": notes,
                "sets": setsArray,
                "scoreType": scheduleWorkout.workoutdata.scoring.type,
                "privateResult": privateResult
            }
            //SEND DATA TO SERVERE
            dispatch(addDoneSchedule(postData));
     }
    }

  return (
      <Modal isOpen={open} toggle={onDoneScheduleClose} centered size="lg">
                      <ModalHeader toggle={onDoneScheduleClose}>
                      {trans.schedule_submit}
                      </ModalHeader>
                      <ModalBody>
                        <div>
                         <h5>{scheduleWorkout != null ? scheduleWorkout.workoutdata.name : ""}</h5>
                        </div>

                        <Row>
                        <Col  sm="12" md="12">
                                <FormGroup>
                                    <Label className="form-label font-weight-bold" for="exampleSelect">{trans.workout_visible_to}</Label>
                                    <Input className="custom-select" value={privateResult} onChange={(e)=> setPrivateResult(e.target.value)} type="select" name="privateResult" id="privateResult">
                                        <option key={`visible_to_${1}`} value={false}>{trans.workout_visible_to_everyone}</option>
                                        <option key={`visible_to_${2}`} value={true}>{trans.workout_visible_to_coaches}</option>
                                    </Input>
                                </FormGroup>
                        </Col>
                         <Col sm="12" md="12">
                            <FormGroup >
                             <Label className="font-weight-bold">{trans.done_schedule_notes}</Label>
                               <Input value={notes} type="textarea" onChange={e => setNotes(e.target.value)}/>
                            </FormGroup>
                         </Col>
                         <Col>
                             {renderSets()}
                         </Col>
                       </Row>
                      </ModalBody>
                      <ModalFooter>
                          <button onClick={(e)=>{storeDoneSchedule(e)}} className="btn btn-secondary">{trans.submit_btn}</button>
                      </ModalFooter>
                </Modal>
  );
}

export default DoneSchedule;