import './notifications.css';
import React, { Fragment,useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter, Media, Form, FormGroup, Label, Input, Button, CardTitle } from 'reactstrap'
import axios from 'axios'
import { MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete} from '../../constant'
import { useParams } from 'react-router';
import NoAccess from '../../components/reuse/noaccess';
import { getUserData } from '../../redux/user/action';
import { Loader } from 'react-bootstrap-typeahead';
import { toast } from 'react-toastify';
import { editUser, forgotPass, getUser,editRoles, changeAccountStatus } from '../../redux/auth/action';

import DatePicker from "react-datepicker";
import { Link } from 'react-router-dom';
import * as General from '../../util/General'
import SweetAlert from 'sweetalert2'
import { checkUserSub } from '../../redux/subscription/action';
import LoaderBox from '../../components/reuse/loaderbox';
import { postSendNotification } from '../../redux/settings/action';


const SendNotification = (props) => {

    const dispatch = useDispatch();
    const userLocale = General.getLocale();

    const trans = useSelector(({translation}) => translation.translationsData);
    const settingsData = useSelector(({settings}) => settings.settingsData);
    const sendPushData = useSelector(({settings}) => settings.sendPushData);

    const [notificationTitle,setNotificationTitle] = useState("");

    const [notificationDesc,setNotificationDesc] = useState("");

    const [toAll,setToAll] = useState(true);
    const [userRoleValue, setUserRoleValue] = useState('');

    const userIsAdmin = useSelector(({auth}) => auth.userIsAdmin);  
    const userIsManager = useSelector(({auth}) => auth.userIsManager); 
    const userIsCoach = useSelector(({auth}) => auth.userIsCoach); 

    useEffect(() => {
    
    }, []);

    useEffect(() => {
    if (sendPushData != null){
        if (sendPushData.status){
            toast.success(sendPushData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
          } else {
            toast.error(sendPushData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
          }
    }
    }, [sendPushData]);


  const sendPushNotification = () => {
    let postData = {title: notificationTitle,msg:notificationDesc,userRoleValue: userRoleValue, toAll: toAll};
    dispatch(postSendNotification(postData));
    setNotificationDesc("");
    setNotificationTitle("");
    setToAll(true);
  }

  const handleUserRoleValue = (e) => {
    setUserRoleValue(e.target.value);
  }

  var userRolesSelectionItems = [];
  settingsData.userRoles.forEach(role => {
      userRolesSelectionItems.push(role);
  });

  const handlePushToAll = (e) => {
    if (e.target.value == "true"){
        setToAll(true);
    } else {
        setToAll(false);
    } 
  }

    return (
      <Fragment>
      <Breadcrumb parent={`Push Notifications`} title={`Push Notifications`} />
      <Container fluid={true}>
        <Card>
            <CardHeader>
                <h4 className="font-weight-bold"> {trans.send_notification_title}</h4>
            </CardHeader>


            <CardBody>
              {userIsAdmin || userIsManager || userIsCoach ? 
                  <Row>
                    <Col sm="12" md="12">
                    <br />
                        <FormGroup>
                            <Label className="form-label font-weight-bold">{trans.notification_title}</Label>
                            <Input className="form-control" type="text" placeholder={trans.notification_title} value={notificationTitle} onChange={(e)=>{setNotificationTitle(e.target.value)}}/>
                        </FormGroup>
                    </Col>    
                    <Col  sm="12" md="12">
                    <br />
                                <FormGroup>
                                    <Label className="form-label font-weight-bold" for="exampleSelect">{trans.notification_receivers}</Label>
                                    <Input className="custom-select" value={toAll} onChange={(e)=> handlePushToAll(e)} type="select" name="toAll" id="toAll">
                                    <option key={`toAll_${true}`} value={true}>{trans.notification_to_all}</option>
                                    <option  key={`toAll_${false}`} value={false}>{trans.notification_to_some}</option>
                                    </Input>
                                </FormGroup>
                    </Col>
                    
              {!toAll ? <Col sm="12" md="12">
                    <br />
                        <Label className="form-label font-weight-bold">{trans.user_role_single}</Label>
                        <Input
                          id='userRoleValue'
                          type="select" name="select"
                          value={userRoleValue}
                          onChange={(e) => handleUserRoleValue(e)}
                          margin="normal"
                          fullWidth>
                          {userRolesSelectionItems.map((option) => {
                            var nameText = option.names[0];
                            option.names.map((item) => {
                                if (item.lang == userLocale){
                                  nameText = item.value;
                                }
                            });
                            return (<option key={option._id} value={option._id}>
                              {nameText}
                            </option>)
                          })}
                        </Input>
                    </Col> : null}
                    
                    <Col sm="12" md="12">
                    <br />
                        <FormGroup>
                            <Label className="form-label font-weight-bold">{trans.notification_description}</Label>
                            <Input type="textarea" rows="5" className="form-control"  placeholder={trans.notification_description} value={notificationDesc} onChange={(e)=>{setNotificationDesc(e.target.value)}}/>
                        </FormGroup>
                    </Col>   
                
                  </Row>  
              : <Row>
                  <Col>
                        <NoAccess/>
                  </Col>
                </Row>}
            </CardBody>
            <CardFooter className="text-right">
                  <button disabled={notificationTitle == "" || notificationDesc == ""} onClick={(e)=> {sendPushNotification(e)}} className="btn btn-primary" type="submit">{trans.submit_btn}</button>
            </CardFooter>

        </Card>
      </Container>
    </Fragment>
  );
}

export default SendNotification;