import {
  FETCH_ADMIN_USERS,
  FETCH_COACHES,
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    FETCH_VIEW_USER_DATA,
    INIT_URL,
    USER_ID_SET
  } from '../actionTypes';
  import axios from '../../util/Api'
  import * as General from '../../util/General'
  
  import {GENERAL_ERROR} from '../../constant';
  
  export const setInitUrl = (url) => {
    return {
      type: INIT_URL,
      payload: url
    };
  };

  export const getAdminUsers = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;

    return (dispatch) => {
      dispatch({type: FETCH_START});
      axios.post('admin/users', postData
      ).then(({data}) => {
        //console.log(data);
        if (data){
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: FETCH_ADMIN_USERS, payload: data});
        } else {
          dispatch({type: FETCH_ERROR, payload: data.error});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };

  export const getUserData = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();


    postData.langLoc = userLocale;

    return (dispatch) => {
      dispatch({type: FETCH_START});
      axios.post('account/userinfo', postData
      ).then(({data}) => {
        console.log(data);
        if (data){
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: FETCH_VIEW_USER_DATA, payload: data});
        } else {
          dispatch({type: FETCH_ERROR, payload: data.error});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };

  export const getCoachesData = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;
    //console.log(postData);
    return (dispatch) => {
      dispatch({type: FETCH_START});
      axios.post('account/coaches', postData
      ).then(({data}) => {
        //console.log(data);
        if (data){
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: FETCH_COACHES, payload: data});
        } else {
          dispatch({type: FETCH_ERROR, payload: data.error});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };