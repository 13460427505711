import {
  FETCH_FEEDBACKS,
  ADD_FEEDBACK} from '../actionTypes';

  const INIT_STATE = {
      feedbackData: null,
      addFeedbackData: null
    };
    
    export default (state = INIT_STATE, action) => {
        switch(action.type) {
          case FETCH_FEEDBACKS:
                     return {
                     ...state,
                     feedbackData: action.payload
            }
       case ADD_FEEDBACK:
              return {
              ...state,
              addFeedbackData: action.payload
          }
        default:
              return state;
        }
    }
    