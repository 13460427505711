import {
USER_ID_SET,
CHAT_SEND,
CHAT_CREATE_ROOM,
CHAT_ROOMS_LIST,
CHAT_MESSAGES,
CHAT_ADD_REMOVE_TO_ROOM,
CHAT_DELETE_ROOM,
CHAT_DELETE_MESSAGE,
CHAT_CHANGE_READ_MESSAGE
  } from '../actionTypes';
  import axios from '../../util/Api'
  import * as General from '../../util/General'
  
  import {GENERAL_ERROR} from '../../constant';
  
  

  export const postChatMessage = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.lang_loc = userLocale;
    // console.log(postData);

    return (dispatch) => {
      axios.post('chat/send', postData
      ).then(({data}) => {
        //  console.log("tracks",data);
        if (data){
          dispatch({type: CHAT_SEND, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };

  export const createChatRoom = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.lang_loc = userLocale;
     console.log(postData);

    return (dispatch) => {
      axios.post('chat/create-room', postData
      ).then(({data}) => {
          console.log("create-room",data);
        if (data){
          dispatch({type: CHAT_CREATE_ROOM, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };

  export const fetchChatRoomsList = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.lang_loc = userLocale;
    //  console.log(postData);

    return (dispatch) => {
      axios.post('chat/room-list', postData
      ).then(({data}) => {
         // console.log("tracks",data);
        if (data){
          dispatch({type: CHAT_ROOMS_LIST, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };

  export const fetchChatMessages = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.lang_loc = userLocale;
     console.log(postData);

    return (dispatch) => {
      axios.post('chat/messages', postData
      ).then(({data}) => {
          console.log("messages",data);
        if (data){
          dispatch({type: CHAT_MESSAGES, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };

  export const addRemoveChatRoom = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.lang_loc = userLocale;
    // console.log(postData);

    return (dispatch) => {
      axios.post('chat/addRemoveRoom', postData
      ).then(({data}) => {
        //  console.log("tracks",data);
        if (data){
          dispatch({type: CHAT_ADD_REMOVE_TO_ROOM, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };



  export const deleteChatRoom = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.lang_loc = userLocale;
    // console.log(postData);

    return (dispatch) => {
      axios.post('chat/deleteRoom', postData
      ).then(({data}) => {
        //  console.log("tracks",data);
        if (data){
          dispatch({type: CHAT_DELETE_ROOM, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };



  export const deleteChatMessage = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.lang_loc = userLocale;
    // console.log(postData);

    return (dispatch) => {
      axios.post('chat/deleteMessage', postData
      ).then(({data}) => {
        //  console.log("tracks",data);
        if (data){
          dispatch({type: CHAT_DELETE_MESSAGE, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };



  export const postChangeReadChatMessage = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;
    // console.log(postData);

    return (dispatch) => {
      axios.post('chat/changeRead', postData
      ).then(({data}) => {
          console.log("postChangeReadChatMessage",data);
        if (data){
          dispatch({type: CHAT_CHANGE_READ_MESSAGE, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };
