import {
  FETCH_WORKOUTS,
  ADD_WORKOUT,
  EDIT_WORKOUT_DATA,
  SINGLE_WORKOUT,
  DELETE_WORKOUT,
USER_ID_SET
  } from '../actionTypes';
  import axios from '../../util/Api'
  import * as General from '../../util/General'
  
  import {GENERAL_ERROR} from '../../constant';
  
  

  export const getWorkouts = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;
  //  console.log(postData);
    return (dispatch) => {
      axios.post('workout/all', postData
      ).then(({data}) => {
        // console.log(data);
        if (data){
          dispatch({type: FETCH_WORKOUTS, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };

  export const addWorkout = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.creator = userId;
    postData.langLoc = userLocale;
    console.log(postData);
    return (dispatch) => {
      axios.post('workout/add', postData
      ).then(({data}) => {
         console.log(data);
        if (data){
          dispatch({type: ADD_WORKOUT, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };


  export const editWorkout = (postData) => {
   // console.log("EDITING_WORKOUT");
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;
    //console.log(postData);
    return (dispatch) => {
      axios.post('workout/edit', postData
      ).then(({data}) => {
        //  console.log(data);
        if (data){
          dispatch({type: EDIT_WORKOUT_DATA, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };
  
  export const singleWorkout = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;
    //console.log(postData);
    return (dispatch) => {
      axios.post('workout/single', postData
      ).then(({data}) => {
        // console.log(data);
        if (data){
          dispatch({type: SINGLE_WORKOUT, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };

  export const deleteWorkout = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;
    console.log(postData);
    return (dispatch) => {
      axios.post('workout/delete', postData
      ).then(({data}) => {
         console.log(data);
        if (data){
          dispatch({type: DELETE_WORKOUT, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };