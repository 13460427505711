import {
    FETCH_TRACKS,
    ADD_TRACK,
    EDIT_TRACK,
    DELETE_TRACK,} from '../actionTypes';

  const INIT_STATE = {
    tracksData: null,
    addTrackData: null,
    editTrackData: null,
    deleteTrackData: null
    };
    
    export default (state = INIT_STATE, action) => {
        switch(action.type) {
        case FETCH_TRACKS:
             return {
                ...state,
            tracksData: action.payload
        }
        case ADD_TRACK:
             return {
                ...state,
                addTrackData: action.payload
        }
        case EDIT_TRACK:
             return {
                ...state,
                editTrackData: action.payload
        }
        case DELETE_TRACK:
            return {
               ...state,
               deleteTrackData: action.payload
       }
        default:
              return state;
        }
    }
    