import {
    FETCH_USER_MEDIA,
    ADD_USER_MEDIA,
    EDIT_USER_MEDIA,
    DELETE_USER_MEDIA,
    SINGLE_USER_MEDIA} from '../actionTypes';
  
    const INIT_STATE = {
        userMediaData: null,
        addUserMediaData: null,
        editUserMediaData: null,
        deleteUserMediaData: null,
        singleUserMediaData: null
      };
      
      export default (state = INIT_STATE, action) => {
          switch(action.type) {
            case FETCH_USER_MEDIA:
                       return {
                       ...state,
                       userMediaData: action.payload
              }
         case ADD_USER_MEDIA:
                return {
                ...state,
                addUserMediaData: action.payload
            }
            case EDIT_USER_MEDIA:
                return {
                ...state,
                editUserMediaData: action.payload
            }
            case DELETE_USER_MEDIA:
              return {
              ...state,
              deleteUserMediaData: action.payload
          }
          case SINGLE_USER_MEDIA:
            return {
            ...state,
            singleUserMediaData: action.payload
        }
       
          default:
                return state;
          }
      }
      