import {
  FETCH_NUTRITION_MEAL,
  ADD_NUTRITION_MEAL,
  EDIT_NUTRITION_MEAL,
  DELETE_NUTRITION_MEAL,
  SINGLE_NUTRITION_MEAL,
USER_ID_SET,
} from '../../actionTypes';
import axios from '../../../util/Api'
import * as General from '../../../util/General'
  
  

  export const getNutritionMeal = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;
    console.log(postData);
    return (dispatch) => {
      axios.post('nutritionmeal/all', postData
      ).then(({data}) => {
         console.log("nutritionmeals",data);
        if (data){
          dispatch({type: FETCH_NUTRITION_MEAL, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };

  export const addNutritionMeal = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.creator = userId;
    postData.langLoc = userLocale;
   // console.log(postData);
    return (dispatch) => {
      axios.post('nutritionmeal/add', postData
      ).then(({data}) => {
        // console.log(data);
        if (data){
          dispatch({type: ADD_NUTRITION_MEAL, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };


  export const editNutritionMeal = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;
    //console.log(postData);
    return (dispatch) => {
      axios.post('nutritionmeal/edit', postData
      ).then(({data}) => {
        // console.log(data);
        if (data){
          dispatch({type: EDIT_NUTRITION_MEAL, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };
  
  export const deleteNutritionMeal = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;
    //console.log(postData);
    return (dispatch) => {
      axios.post('nutritionmeal/delete', postData
      ).then(({data}) => {
        // console.log(data);
        if (data){
          dispatch({type: DELETE_NUTRITION_MEAL, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };
  
  export const singleNutritionMeal = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;
    //console.log(postData);
    return (dispatch) => {
      axios.post('nutritionmeal/single', postData
      ).then(({data}) => {
        // console.log(data);
        if (data){
          dispatch({type: SINGLE_NUTRITION_MEAL, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };

  