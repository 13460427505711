import  './translations.css';
import React,{useEffect, useState} from 'react';
import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Form, FormGroup, Input, Label, Button,NavItem, NavLink, Nav,TabContent,TabPane, Breadcrumb, Card, CardHeader, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { addTranslation, deleteTranslation, editTranslation, getAdminTranslations } from '../../redux/translation/action';
import AddTransaltion from './addTranslation';
import SweetAlert from 'sweetalert2'
import NoAccess from '../../components/reuse/noaccess';

const Translations = (props) => {

    const dispatch = useDispatch();

    const adminTranslations = useSelector(({translation}) => translation.adminTranslations);
    const addTranslationData = useSelector(({translation}) => translation.addTranslation);
    const editTranslationData = useSelector(({translation}) => translation.editTranslation);
    const deleteTranslationData = useSelector(({translation}) => translation.deleteTranslation);
    
    const userIsAdmin = useSelector(({auth}) => auth.userIsAdmin);

    const [translationsAr,setTranslationsAr] = useState([]); 
    const [addEditTransModal, setAddEditTransModal] = useState(false);
    const [translationToEdit, setTranslationToEdit] = useState(null);
    const [translationIsEdit, setTranslationIsEdit] = useState(false);
    const trans = useSelector(({translation}) => translation.translationsData);
    const [searchKeyword, setSearchKeyword] = useState('');

    useEffect(() => {
        if (addTranslationData && addTranslationData.status){
            getTranslationsData();
        }
    }, [addTranslationData]);

    useEffect(() => {
        if (editTranslationData && editTranslationData.status){
            getTranslationsData();
        }
    }, [editTranslationData]);

    useEffect(() => {
        if (deleteTranslationData && deleteTranslationData.status){
            getTranslationsData();
        }
    }, [deleteTranslationData]);

    useEffect(() => {
        getTranslationsData();
    }, []);

    useEffect(() => {
        if (adminTranslations != null){
            setTranslationsAr([]);
            setTranslationsAr(adminTranslations.data);
        }
    }, [adminTranslations]);

    const getTranslationsData = () => {
        dispatch(getAdminTranslations());
    }

    const addEditTransModaltoggle = () => {
        setTranslationToEdit(null); 
        setTranslationIsEdit(false); 
        setAddEditTransModal(!addEditTransModal)
    }

    const onSaveTranslation = (data) => {
        if (translationIsEdit){
            dispatch(editTranslation(data))
        } else {
            dispatch(addTranslation(data))
        }
    }

    const handleUserClick = (e,item) => {
        setTranslationToEdit(item);
        setTranslationIsEdit(true);
        setAddEditTransModal(true);
      }

 const removeTranslation = (item) => {
        SweetAlert.fire({
          title:trans.delete_dialog_title,
          text:trans.delete_dialog_desc,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText:trans.ok_btn,
          cancelButtonText: trans.cancel_btn,
          reverseButtons: true
        }).then((result) => {
          if (result.value) {
              //OK
            let postData = {slug: item.slug};
            dispatch(deleteTranslation(postData));
          }
          else {

          }
        })
   }

   const handleSearchKeyword = (keyword) => {
    setSearchKeyword(keyword);
    if (keyword == ""){
        setTranslationsAr(adminTranslations.data);
    } else {
        var filteredTrans = [];
        var fixedKeyword = keyword.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
        adminTranslations.data.forEach(trans => {
            var fixedSlug = trans.slug.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                if (fixedSlug.includes(fixedKeyword)){
                    filteredTrans.push(trans);
                } 
                trans.values.forEach(value => {
                    var fixedValue = value.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                    if (fixedValue.includes(fixedKeyword)){
                        var exists = filteredTrans.filter(filterEd => filterEd.slug == trans.slug).length > 0
                        if (!exists){
                            filteredTrans.push(trans);
                        }
                    }
                });
        });
        setTranslationsAr(filteredTrans);
    }
  }

  return (
    <Fragment>
     <br/>
      <Container fluid={true}>

      {!userIsAdmin ?
       <Col md="12">
       <NoAccess/>
       </Col>
       : 

        <div >
           {adminTranslations != null ?
          <Row>
            <Col md="12">
              <Card>
                <CardHeader >
                  <Row>
                  <Col xl="12" sm="12"> 
                    <div style={{alignItems: 'center',}}> 
                        <h4 className="card-title mb-0" style={{float: "left"}}>{trans.translations_title}</h4>
                        <div className="card-title mb-0" style={{float: "right"}}>
                            <Button onClick={addEditTransModaltoggle} color="primary" size="sm"><i className="fa fa-pencil"></i> {trans.add_btn}</Button>
                        </div>
                    </div>
                  </Col>
                  <br/>
                  <br/>
                  <Col xl="12" sm="12"> 
                        <Form>
                        <FormGroup className="m-0">
                          <Input
                            className="form-control"
                            type="text"
                            placeholder={trans.search_hint}
                            defaultValue={searchKeyword}
                            onChange={(e) => handleSearchKeyword(e.target.value)}
                            />
                        </FormGroup>
                        </Form>
                    </Col>
                    </Row>
                </CardHeader>
                <div className="table-responsive">
                  <table className="table card-table table-vcenter text-nowrap">
                    <thead>
                      <tr>
                        <th key={"trans_slug"}>Name</th>
                        <th key={"trans_values"}>Values</th>
                        <th key={"trans_is_app"}>ISAPP</th>
                        <th key={"trans_edit"}></th>
                        <th key={"trans_delete"}></th>
                      </tr>
                    </thead>
                    <tbody>

                      {translationsAr.map((item,i) => {
                        let values = item.values.map((value,valIndex) => {
                           return ( <span key={`trans_value_${i}_val_${valIndex}`} className="_translation_value">{`${value.langLoc} - ${value.value}`}</span>)
                        })

                        return (<tr key={`translation_item_${i}`}>
                          <td>{item.slug}</td>
                          <td>{values}</td>
                          <td>{item.values[0].isApp  == 1 ? "YES" : "NO" }</td>
                          <td className="text-right">
                            <Button onClick={(e) => {handleUserClick(e,item)}} color="primary" size="sm"><i className="fa fa-pencil"></i> {trans.edit_btn}</Button>
                            &nbsp;   
                            <Button onClick={() => removeTranslation(item)} color="danger" size="sm"><i className="fa fa-trash"></i> {trans.delete_btn}</Button>
                          </td>
                        </tr>)
                      }
                      )}
                      
                    </tbody>
                  </table>
                </div>
              </Card>
            </Col>
          </Row>
          : null}
        </div>
        }
      </Container>

      <AddTransaltion
        open={addEditTransModal}
        onAddTranslationClose={addEditTransModaltoggle}
        onSaveTranslation={(data) => onSaveTranslation(data)}
        isEditTranslation={translationIsEdit}
        translationToEdit={translationToEdit}
      />
    </Fragment>
  );
}

export default Translations;