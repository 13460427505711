import {
    FETCH_NUTRITION_MENU,
    ADD_NUTRITION_MENU,
    EDIT_NUTRITION_MENU,
    DELETE_NUTRITION_MENU,
    SINGLE_NUTRITION_MENU} from '../../actionTypes';

  const INIT_STATE = {
        fetchNutritionMenuData: null,
        addNutritionMenuData: null,
        editNutritionMenuData: null,
        deleteNutritionMenuData: null,
        singleNutritionMenuData: null
    };
    
    export default (state = INIT_STATE, action) => {
        switch(action.type) {
        case FETCH_NUTRITION_MENU:
             return {
                ...state,
                fetchNutritionMenuData: action.payload
        }
        case ADD_NUTRITION_MENU:
             return {
                ...state,
                addNutritionMenuData: action.payload
        }
        case EDIT_NUTRITION_MENU:
             return {
                ...state,
                editNutritionMenuData: action.payload
        }
        case DELETE_NUTRITION_MENU:
            return {
               ...state,
               deleteNutritionMenuData: action.payload
       }
       case SINGLE_NUTRITION_MENU:
        return {
           ...state,
           singleNutritionMenuData: action.payload
        }
        default:
              return state;
        }
    }
    