import React, { Fragment,useState,useEffect } from 'react';
import {Redirect,BrowserRouter,Switch,Route} from 'react-router-dom';
import axios from './util/Api'
import {useDispatch, useSelector} from "react-redux";
import Loader from "./layout/loader";
import Signin from './auth/signin'
import configureStore, {history} from './store';
import { getUser } from './redux/auth/action';
import { getTranslations } from './redux/translation/action';
import { USER_ID_SET,USER_TOKEN_SET } from './redux/actionTypes';
import { getSettings } from './redux/settings/action';
import { routes } from './route';



const PrivateRoute = (props) =>  {

  
  const dispatch = useDispatch();
  const authUserData = useSelector(({auth}) => auth.authUser);
  const token =  JSON.parse(localStorage.getItem(USER_TOKEN_SET));
  const [translationsLoaded,setTranslationsLoaded] = useState(false);
  const [settingsLoaded,setSettingsLoaded] = useState(false)
  const [userDataLoaded,setUserDataLoaded] = useState(false);

  const translationsData = useSelector(({translation}) => translation.translationsData);
  const settingsData = useSelector(({settings}) => settings.settingsData);
  
  var isTokenValid = false;

  useEffect(() => { //ONLOAD
    //LETS GET THE TRANSLATIONS FOR LATER USE
    setTranslationsLoaded(false);
    setSettingsLoaded(false);
    setUserDataLoaded(false);
    dispatch(getSettings()); 
  }, []);

  useEffect(() => {
    if (translationsData !== null) {
        setTranslationsLoaded(true);
        //WE GOT TRANSLATIONS. NOW GET USER INFO
           //SET THE TOKEN BEARER TO BE AVAILALBLE
           if (token !== null && token !== ""){
            axios.defaults.headers.common['Authorization'] = "Bearer " + token;
            const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
            //GET USER
            dispatch(getUser(userId));
          } 
    } 
  }, [translationsData]);

  useEffect(() => {
    if (settingsData !== null) {
      setSettingsLoaded(true); 
      dispatch(getTranslations()); 
    } 
  }, [settingsData]);

  useEffect(() => {
    if (authUserData !== null) {
      setUserDataLoaded(true); 
      if (authUserData && authUserData.userId == null){ 
        localStorage.removeItem(USER_TOKEN_SET,null)
      } 
    } 
  }, [authUserData]);
 


  

  if (translationsLoaded && settingsLoaded){
    // console.log("crying gh");
    if (token !== null && token !== ""){
        if (userDataLoaded){
         
            if (authUserData && authUserData.userId != null){
              if (props.path == "/login" || props.path == "/register"){
                return (<Redirect to='/'/>)
              } else {
                return (<Route { ...props } />)
              }
               
            } else {
                return (<Redirect to='/login'/>)
            }
        } else {
          return (<Loader/>)
        }
    } else {
      var isAppLayoutItem = false;
      routes.forEach(route => {
        //  console.log(props.location);
            if ((props.path != null && props.path == route.path) || 
            (props.location != null && props.location.pathname == route.path)){
              isAppLayoutItem = true
            }
        });

        var isGenericRoute = false;
        let genericRoutes = ['/login','/register','/terms','/faq','/privacy-policy','/forgetPwd','/change-password','/not-found']
        genericRoutes.forEach(route => {
            //console.log(props.location);
              if ((props.path != null && props.path == route) || 
              (props.location != null && (props.location.pathname == route || props.location.pathname.includes(route)))){
                isGenericRoute = true
                //console.log("i am sorry");
              }
          });

        if (isAppLayoutItem){
          return (<Redirect to='/login'/>)
        } else {
          if (!isGenericRoute){
            if ((props.path != null && props.path == '/') || 
            (props.location != null && props.location.pathname == '/')){
              return (<Redirect to='/login'/>)
            } else {
              return (<Redirect to='/not-found'/>)
            }
            
          } else {
           //console.log("i go here 222");
            return (<Route { ...props } />)
          }
        }
    }
  } else {
      return (<Loader/>)
  }
}

export default PrivateRoute;
